import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import MyAccountMenu from './MyAccountMenu';
import { getToken } from "../services/LocalStorageSrv";
import axios from 'axios';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const DownloadVideo = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const nvg = useNavigate()
    const localdata = getToken('data')
    const [loading, setLoading] = useState(true);
    const [myvideolist, setVideoList] = useState([]);

    useEffect(() => {

      
        const fetchVideo = async () => {
          try {
             setLoading(true);
         
             const response = await axios.get(`${apiUrl}/getProductVideos`,{
               headers: {
                 Authorization: `Bearer ${localdata.data.token}`,
                 'Content-Type': 'application/json; charset=UTF-8',
                 'Access-Control-Allow-Origin': '*',
                 Accept: 'application/json',
               },
             });
             setVideoList(response?.data?.data);
             // console.log('Fetched address:', response.data.data);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
        
        fetchVideo();
       }, []);
    
    return (
        <div>
            {loading ? <Loader /> : null}
           {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
 <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
            <section id="product-listing-sec" className="inner-page-bg mb-5">
                <div className="container">
                    <div className="row">
                    <MyAccountMenu/>
                        <div className="col-lg-9  col-sm-12  Myitems-box" style={{ alignSelf: 'flex-start', marginTop: '17px',paddingBottom:'80px' }}>
                            <div className="row">

                                <h2 className="myItems">Product Video</h2>
                                

                                <div className="row">

                                {myvideolist.map((adds,index) => (   
                                    <div className="col-lg-4 col-md-6">
                                    <div className="video-block">
                                        <div>
                                        <img src={adds.placeholder}/>
                                        </div>
                                        <div className="video-title">
                                        <a onClick={()=>{nvg('/product-detail/'+adds.slug)}} href="javascript:void(0);" className="video-title-height" style={{color:'#151515',display:'block',padding:'5px 15px',fontSize:'15px',fontWeight:'500'}}> {adds.product_name} </a>
                                        
                                        <div className="video-button action-button">
                                        <a className="in-process">In Process</a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>

                                ))}
                                </div>



                               
                            </div>
                       
                       
                           
                       
                        </div>
                    
                    
                    





                    </div>


                 



                </div>
            </section>



            <Footer />
        </div>

    )
}
export default DownloadVideo;