import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import {Link} from 'react-router-dom';
const ECatalogue= () => {
    //   const [showsidebar, setshowsidebar] = useState(false);

    return (
 <h1>Ecatalogue</h1>
    )}

    export default ECatalogue;