import React, { useEffect, useState, useRef } from "react";
// import Header from '.../components/Header/Header.css';
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { FaCheckCircle, FaChevronDown } from "react-icons/fa";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';
const Checkout = () => {
  const addresspopup = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  // console.log('use de:',localdata.data.name);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedItemIdAdd, setSelectedItemIdAdd] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    landmark: "",
    otp: "",
  });
  const [face1, setface1] = useState(false);
  const [face2, setface2] = useState(false);
  const [dropaddress, setdropaddress] = useState(false);
  const [dropaddress2, setdropaddress2] = useState(false);
  const [dropaddress3, setdropaddress3] = useState(false);
  const [editform, seteditform] = useState(false);

  const [citemid, setItemid] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [QuantityVerientid, setQuantityVerientid] = useState("");
  const [ProductidQuantity, setProductidQuantity] = useState("");
  const [QytSubTotals, setSubTotals] = useState("");
  const [cartitems, setCartItem] = useState(null);
  const [cartitemsAtt, setCartItemAtt] = useState(null);
  const [myaddresslist, setAddressList] = useState([]);
  const [fixed_address, setFixedAddress] = useState(null);
  const [errorOTPmsg, setErrorOTPMessage] = useState("");
  const [successResponseMessage, setsuccessResponseMessage] = useState("");
  const [addaddressresponseMessage, setaddaddressresponseMessage] =
    useState("");
  const [addressAllinfo, setAllinfo] = useState("");
  const [addAddress, setAddAddress] = useState(false);
  const [userDatas, setUserdetail] = useState([]);
  const [editaddressresponseMessage, setEditaddressresponseMessage] =
    useState("");
  const [edittAddress, setEdittAddress] = useState(false);

  const [CheckoutdetailPage, setCheckoutdetailPage] = useState("");

  const [CheckoutSpecialRemark, setCheckoutSpecialRemark] = useState("");
  const [CheckoutByPayment, setCheckoutByPayment] = useState("");

  const handleAddSubmit = (event) => {
    event.preventDefault();
    if (!validateForm1()) {
      // If the form is not valid, don't submit
      return;
    }

    fetchsubmitAddadressData();
  };
  // use for order place
  //  const [CheckoutDefaultAddress, setCheckoutDefaultAddress] = useState({});
  const [selectedItemId, setSelectedItemId] = useState({});
  const [checkout_lpo_number, setlpo_number] = useState("");
  const [checkout_special_remarks, setspecial_remarks] = useState("");
  const [checkout_payment_methode_input, setpayment_methode_input] =
    useState("");

  const [payable_amount, setpayable_amount] = useState({});
  const [coupon_code, setcoupon_code] = useState({});
  const [total_amount, settotal_amount] = useState({});
  const [total_discount, settotal_discount] = useState({});
  const [vat, setvat] = useState({});
  const [vat_cost, setvat_cost] = useState({});
  const [orderaallitems, setorderaallitems] = useState({});
  const [lastOrderIds, setLastOrderIds] = useState({});
  // console.log('all order ids:',orderaallitems);
  //  console.log('default ad and selected add:',selectedItemId);
  // end order place
  const fetchAddress = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/addresses`, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      });
      setAddressList(response?.data?.data);
      if (response.data.data.length > 0) {
        const lastAddress = response.data.data[response.data.data.length - 1];
        // setLastAddressId(lastAddress.id);
        setFixedAddress(lastAddress.id);
        // console.log('Fetched address fixed1:', lastAddress.id)
      }
      // response.data.data.map((fix_add, index) => (
      //   console.log('Fetched address fixed:', fix_add.id)
      //     // setFixedAddress(fix_add.address_id);
      // ))
      
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchCheckoutInfo = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/checkout`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setCheckoutdetailPage(response?.data?.data);
        setCheckoutSpecialRemark(response?.data?.data?.special_remark);
        setCheckoutByPayment(response?.data?.data?.payment_methods);
        setSelectedItemId(response?.data?.data?.address_id);
        // console.log('Fetched chckout:', response.data.data.address_id);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/user`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setUserdetail(response?.data?.data);
        // console.log('Fetched address:', response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchCartsDe = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/carts`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setCartItem(response.data.data);
        setCartItemAtt(response.data.data.items);
        // const orderProducts = response.data.data.items.map((order_p) => ({
        //   product_id: order_p.id,
        //   product_option: [{
        //     variant_id: order_p.products_attributes.variant_id,
        //     quantity: order_p.products_attributes.quantity,
        //   }],
        // }));

        // console.log('orderProducts data:', orderProducts);
        const orderProducts = response.data.data.items.map((order_p) => {
          const orderProducts1 = order_p.products_attributes.map(
            (order_variant) => {
              return {
                variant_id: order_variant?.variant_id,
                quantity: order_variant?.quantity,
              };
            }
          );

          return {
            product_id: order_p.id,
            product_option: orderProducts1,
          };
        });

        // console.log('orderProducts data:', orderProducts);

        setorderaallitems(orderProducts);
        //order place variable
        setpayable_amount(response.data.data.amount_payable);
        setcoupon_code(null);
        settotal_amount(response.data.data.grandTotal);
        settotal_discount(response.data.data.total_discount);
        setvat(response.data.data.vat);
        setvat_cost(response.data.data.vat_amount);
        //end order place variable
        //   console.log('cart de:',response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

   

    const fetchLastOrderIds = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/lastOrderIdGet`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        
        setLastOrderIds(response.data);
        // console.log('last orders id:', response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchCheckoutInfo();
    fetchUser();
    fetchAddress();
    fetchCartsDe();
    fetchLastOrderIds();
  }, []);

  const handleAlertClick = () => {
    setResponseMessage(false);
  };

  const handleOTPm = () => {
    setTimeout(() => {
      setaddaddressresponseMessage("");
      setEditaddressresponseMessage("");
    }, 1000);
  };

  const fetchsubmitAddadressData = async () => {
    // console.log('all add info new:',addressAllinfo);
    // console.log('otp n:',formData.otp);
    const collectdata1 = {
      name: addressAllinfo.name,
      email: addressAllinfo.email,
      phone_number: addressAllinfo.phone,
      pincode: addressAllinfo.pincode,
      address: addressAllinfo.address,
      city: addressAllinfo.city,
      state: addressAllinfo.state,
      country: addressAllinfo.country,
      landmark: addressAllinfo.landmark,
      otp: formData.otp,
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/addresses/add`,
        collectdata1,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.data.success === true) {
        setsuccessResponseMessage(response.data.message);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    } catch (err) {
      if (err.response?.data?.message) {
        setErrorOTPMessage(err.response?.data?.message);
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate headline
    if (!formData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!formData.pincode) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    if (!formData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!formData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!formData.state) {
      newErrors.state = "State is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setAddressList((prevData) => ({ ...prevData, [name]: value }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    // setAddressList((prevData) => ({ ...prevData, [name]: value }));
    setEditFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors2((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const validateForm1 = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!formData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!formData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!formData.state) {
      newErrors.state = "State is required";
      valid = false;
    }
    //  Validate
    if (!formData.otp) {
      newErrors.otp = "OTP is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleAddAddressSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      // If the form is not valid, don't submit
      return;
    }

    fetchAddadressData();
  };

  const fetchAddadressData = async (event) => {
    const collectdata = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      pincode: formData.pincode,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      landmark: formData.landmark,
      // phone: event.target.phone.value,
      // address: event.target.address.value,
      // city: event.target.city.value,
      // state: event.target.state.value,
      // country: event.target.country.value,
      // landmark: event.target.landmark.value,
    };
    setAllinfo(collectdata);
    // console.log('all inf:',collectdata);
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/passwordSendOtp`, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      if (response.data.success === true) {
        setAddAddress(response.data.success);
        setaddaddressresponseMessage(response.data.message);
        // setTimeout(() => {
        //   setaddaddressresponseMessage('');
        // }, 3000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [responseMessage, setResponseMessage] = useState("");
  const handleDeleteButtonClick = (id) => {
    setItemid(id);
    const collectdata = {
      product_id: id,
    };
    const fetchDeleteCartProduct = async (id) => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const response = await axios.post(
          `${apiUrl}/carts/destroy`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);
          //           const updatedData = cartitems.items.filter(item => item.id !== id);
          // console.log('update d1:',updatedData);
          // setCartItem(updatedData);
          // const updatedData1 =  cartitems.items(prevItems => Array.isArray(prevItems) ? prevItems.filter(item => item.id !== id) : []);
          // console.log('update d2:',updatedData1);
          setResponseMessage(response.data.message);
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          setTimeout(() => window.location.reload(true), 2000);
          // const updatedData = cartitems.filter(items => items.id !== id);
          // setCartItem(updatedData);

          // setTimeout(() => {
          //   // Code to execute after the initial timeout (e.g., window.location.reload())
          //   // window.location.reload();

          //   // Set up a periodic refresh using setInterval
          //   const refreshInterval = setInterval(() => {
          //     setCartItem(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== citemid) : []);
          //     // Code to execute at regular intervals
          //     // For example, you can call another function here
          //     console.log('Interval action');
          //   }, 2000); // Refresh every 5 seconds (adjust as needed)

          //   // Optionally, you can clear the interval after a certain duration
          //   setTimeout(() => {
          //     setCartItem(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== citemid) : []);
          //     clearInterval(refreshInterval);
          //   }, 20000); // Clear the interval after 30 seconds (adjust as needed)
          // }, 2000); // Initial timeout of 2 seconds
          // const refreshInterval = setInterval(() => {
          //   cartitems(); // Call fetchData at regular intervals
          // }, 1000); // Refresh every minute
          // const refreshInterval1 = setInterval(() => {
          //   cartitemsAtt(); // Call fetchData at regular intervals
          // }, 1000); // Refresh every minute
          // setTimeout(() => window.location.reload(), 2000);
          // Optionally, you can set up a timer or other triggers to refresh the data periodically

          // setTimeout(() => window.location.reload());
          // console.log('succeess',response.data.message);
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchDeleteCartProduct();
  };
  const handleBlur = () => {
    if (isValidQuantity()) {
      updateQuantityApiCall();
    } else {
      // Handle invalid quantity (display an error, prevent the API call, etc.)
      console.error("Invalid quantity entered");
    }
  };
  const updateQuantityApiCall = async () => {
    // console.log("update qyt:", quantity, QuantityVerientid, ProductidQuantity);
    const collectdata = {
      product_id: ProductidQuantity,
      variant_id: QuantityVerientid,
      quantity: quantity,
    };
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await axios.post(`${apiUrl}/carts/update`, collectdata, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      });
      if (response.data.success === true) {
        // Assuming you have a function like storeToken to handle token storage
        // storeToken(collectdata);

        // setCartItem(prevItems => Array.isArray(prevItems) ? prevItems.filter(item => item.id !== id) : []);
        setSubTotals(response.data.data.sub_total);
        setResponseMessage(response.data.message);
        toast.success(
          <div>
            <div>{response.data.message}</div>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
        setTimeout(() => {
          window.location.reload(true);
        }, 2000); // 7000 milliseconds is the autoClose duration
        // setTimeout(() => window.location.reload(), 2000);
        // setTimeout(() => window.location.reload());
        // console.log('succeess',response.data.message);
        // setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const handleQuantityInputChange = (event, veriant_id, pro_id) => {
  //   const value = parseInt(event);

  //   // Update local state only if it's a positive integer
  //   // if (!isNaN(value) && value >= 0) {
  //   //     setQuantity(value);
  //   // setQuantityVerientid(veriant_id);
  //   // setProductidQuantity(pro_id);
  //   // }else {
  //   //     setQuantity(1);

  //   //     setQuantityVerientid(veriant_id);
  //   //     setProductidQuantity(pro_id);
  //   // }

  //   if (isNaN(value) || value <= 0) {
  //     alert("Quantity must be greater than 0");
  //     // window.location.reload();
  //     return;
  //   } else {
  //     setQuantity(value);
  //     setQuantityVerientid(veriant_id);
  //     setProductidQuantity(pro_id);
  //   }
  // };
  const handleQuantityInputChange = (event, variant_id, pro_id, stock) => {
    const value = parseInt(event);
    if (isNaN(value) || value <= 0) {
      alert("Quantity must be a positive integer greater than 0");
      return;
    }
    if (value > stock) {
      alert("Quantity cannot exceed available stock is " + stock);
      return;
    }
    setQuantity(value);
    setQuantityVerientid(variant_id);
    setProductidQuantity(pro_id);
  };
  const isValidQuantity = () => {
    return !isNaN(quantity) && quantity >= 0;
  };

  const handleDeleteAddress = async (address_id) => {
    setShowDeletePopup(true);
    setSelectedItemIdAdd(address_id);
    const collectdata = {
      address_id: address_id,
    };
    // console.log("del add:", collectdata);
    const fetchDeleteAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addresses/delete`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setResponseMessage(response.data.message);
          // setTimeout(() => window.location.reload(), 3000);
          // console.log('succeess',response.data.message);
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
          setLoading(false);
          setShowDeletePopup(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error deleting address");
          setShowDeletePopup(false);
        }
      } catch (error) {
        console.error("An error occurred while deleting the address", error);
        setShowDeletePopup(false);
      }
    };
    fetchDeleteAd();
  };
  const handleDeleteCancel = () => {
    // Clear the selected item ID and close the delete popup
    setSelectedItemIdAdd(null);
    setShowDeletePopup(false);
  };

  const fetchDefaultAd = async (add_id) => {
    // console.log('select add id:',add_id);
    const collectdata = {
      address_id: add_id,
    };
    // setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/setDefaultAddress`,
        collectdata,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }
      );
      if (response.data.success === true) {
        // Assuming you have a function like storeToken to handle token storage
        // storeToken(collectdata);

        // setResponseMessageDefault(response.data.message);
        // setTimeout(() => window.location.reload(), 3000);
        // console.log('succeess',response.data.message);
        fetchAddress();
        window.location.reload();
        setLoading(false);
      } else {
        // Handle error, maybe show an error message to the user
        console.error("Error deleting address");
      }
    } catch (error) {
      console.error("An error occurred while deleting the address", error);
    }
  };
  const handleRadioChange = (value) => {
    setSelectedItemId(value);
    // console.log('select add:',value);
    fetchDefaultAd(value);
  };
// const handleRequiredAddress = () => {
//   // alert('hi');

//        if (selectedItemId == null) {
//         setdropaddress(true);
//       setdropaddress2(false);
//       setdropaddress3(false);    
//           toast.success(
//             <div>
//               <div>Address not found, please add address first then order placed</div>
//             </div>,
//             {
//               position: toast.POSITION.TOP_RIGHT,
//               autoClose: 2000,
//             }
//           );
//      }
// }
  const [EditFormData, setEditFormData] = useState({
    name: "",
    email: "",
    phone: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    landmark: "",
    otp: "",
  });
  const [editItemId, setEditItemId] = useState(null);
  const handleEditAdd = (data) => {
    // console.log("edit v:", data);
    setEditFormData({
      name: data.name,
      email: data.email,
      phone: data.phone,
      pincode: data.pincode,
      address: data.address,
      city: data.city,
      state: data.state,
      country: data.country,
      landmark: data.landmark,
      otp: "",
    });
    setEditItemId(data.id);
  };
  const validateForm2 = () => {
    let valid = true;
    const newErrors = {};

    // Validate headline
    if (!EditFormData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!EditFormData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(EditFormData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!EditFormData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!EditFormData.pincode) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    if (!EditFormData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!EditFormData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!EditFormData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!EditFormData.state) {
      newErrors.state = "State is required";
      valid = false;
    }

    setErrors2(newErrors);
    return valid;
  };

  const fetchAddressEditData = async (event) => {
    const collectdata = {
      name: EditFormData.name,
      email: EditFormData.email,
      phone: EditFormData.phone,
      pincode: EditFormData.pincode,
      address: EditFormData.address,
      city: EditFormData.city,
      state: EditFormData.state,
      country: EditFormData.country,
      landmark: EditFormData.landmark,
    };
    setAllinfo(collectdata);
    // console.log('all inf:',collectdata);
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/passwordSendOtp`, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      // console.log("efit form response of it",response);
      if (response.data.success === true) {
        setEdittAddress(response.data.success);
        setEditaddressresponseMessage(response.data.message);
        setTimeout(() => {
          setEditaddressresponseMessage("");
        }, 3000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEditInfoFormSubmit = (event) => {
    event.preventDefault();
    if (!validateForm2()) {
      // If the form is not valid, don't submit
      return;
    }
    // console.log("checking validation");
    fetchAddressEditData();
  };

  const validateForm3 = () => {
    let valid = true;
    const newErrors = {};

    // Validate headline
    if (!EditFormData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!EditFormData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(EditFormData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!EditFormData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!EditFormData.pincode) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    if (!EditFormData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!EditFormData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!EditFormData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!EditFormData.state) {
      newErrors.state = "State is required";
      valid = false;
    }
    if (!EditFormData.otp) {
      newErrors.otp = "otp is required";
      valid = false;
    }
    setErrors2(newErrors);
    return valid;
  };

  const fetchAddressUpdateData = async (event) => {
    const collectdata3 = {
      name: addressAllinfo.name,
      email: addressAllinfo.email,
      phone_number: addressAllinfo.phone,
      pincode: addressAllinfo.pincode,
      address: addressAllinfo.address,
      city: addressAllinfo.city,
      state: addressAllinfo.state,
      country: addressAllinfo.country,
      landmark: addressAllinfo.landmark,
      otp: EditFormData.otp,
      address_id: editItemId,
    };
    // console.log("update v:", collectdata3);
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/addresses/update`,
        collectdata3,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.data.success === true) {
        // setsuccessResponseMessage(response.data.message);

        toast.success(
          <div>
            <div>{response.data.message}</div>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
        setLoading(false);
      } else {
        setErrorOTPMessage(response?.data?.message);
        setLoading(false);
        // console.log('erroD:',response?.data?.message);
      }
    } catch (err) {
      if (err.response?.data?.message) {
        setErrorOTPMessage(err.response?.data?.message);
        setLoading(false);
      }
    }
  };
  const handleEditUpdateSubmit = (event) => {
    event.preventDefault();
    if (!validateForm3()) {
      // If the form is not valid, don't submit
      return;
    }
    fetchAddressUpdateData();
  };

  const [errorpayment_methode_input, seterrorpayment_methode_input] =
    useState("");
    const [erroradd_address, seterroradd_address] =
    useState("");
  
    const orderinfo = async () => {
      try {
        setLoading(true);
        const formdata = {
          shipping_address: selectedItemId,
          lpo_number: checkout_lpo_number,
          special_remark: checkout_special_remarks,
          payment_method: '99',
          payable_amount: payable_amount,
          coupon_code: coupon_code,
          total_amount: total_amount,
          discount: total_discount,
          vat: vat,
          vat_cost: vat_cost,
          items: orderaallitems,
        };
        // console.log('order data list1:',formdata);
        const response = await axios.post(`${apiUrl}/placeOrder`, formdata, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        // if (response.data.data.success == true) {
        //   toast.success(
        //     <div>
        //       <div>{response.data.message}</div>
        //     </div>,
        //     {
        //       position: toast.POSITION.TOP_RIGHT,
        //       autoClose: 2000,
        //     }
        //   );

        //   setTimeout(() => {
        //     // window.location.href = response.data.data.link;
        //     window.location.href = "/thank-you/" + response.data.data.order_id;
        //   }, 2000);
        // }
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    const bulkorderplacesubmit = async () => {
      // console.log('jhsdfjk');
      if (checkout_payment_methode_input !== "") {
        if (checkout_payment_methode_input == "4" || checkout_payment_methode_input == "5") {
        try {
          setLoading(true);
          const formdata = {
            shipping_address: selectedItemId,
            lpo_number: checkout_lpo_number,
            special_remark: checkout_special_remarks,
            payment_method: checkout_payment_methode_input,
            payable_amount: payable_amount,
            coupon_code: coupon_code,
            total_amount: total_amount,
            discount: total_discount,
            vat: vat,
            vat_cost: vat_cost,
            items: orderaallitems,
          };
          // console.log('order data list:',formdata);
          const response = await axios.post(`${apiUrl}/placeOrder`, formdata, {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          });
          if (response.data.data.success == true) {
            toast.success(
              <div>
                <div>{response.data.message}</div>
              </div>,
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              }
            );
  
            setTimeout(() => {
              // window.location.href = response.data.data.link;
              window.location.href = "/thank-you?order_id=" + response.data.data.order_id;
            }, 2000);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
       }else if (checkout_payment_methode_input == "3") {
  
        // alert('online');
const uname = userDatas.first_name;
const price = payable_amount.toFixed(2);
const order_id = lastOrderIds + 1;
// console.log('nams:',uname,price,order_id);
try {
  setLoading(true);
  const formdata = {
    Currency: 'AED',
    TransactionHint: 'CPT:Y;VCC:Y;',
    OrderID: order_id,
    OrderName: 'Online',
    Channel: 'Web',
    Amount: price,
    Customer: 'Demo Merchant',
    UserName: 'Demo_fY9c',
    Password: 'Comtrust@20182018',
    SpecialRemark: checkout_special_remarks,
  };
  
  const initiationResponse = await axios.post(`${apiUrl}/paymentCheckout`, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Options",
      "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE, OPTIONS"
    },
  });
  
  if (initiationResponse.status === 200) {
    //alert('test');
    
    window.location.replace(initiationResponse.data.Transaction.PaymentPortal);
    orderinfo();
	//alert(initiationResponse.data.Transaction.TransactionID);
     //window.location.href = "/thank-you/" + initiationResponse.data.Transaction.TransactionID;
	 
	
	 
	 
    //
  }else{
    nvg(`/cancel-order`);
  }
} catch (error) {
  console.error('Error initiating payment:', error);
} finally {
  setLoading(false);
}
        
  
       }
      } else {
        if (checkout_payment_methode_input == "") {
          seterrorpayment_methode_input("Please select payment method");
        } else {
          seterrorpayment_methode_input("");
        }
      }
    };
  const handleAddressPopup = () => {
    if (addresspopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(addresspopup.current);
      modal.show();
    }
  };
  return (
    <div>
      {loading ? <Loader /> : null}
      <ToastContainer />
      <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      {/* style={{ zIndex: "-5" }} */}
      <section class="cart-detail-sec inner-page-bg" >
        <div class="container">
          <div class="hedding-text d-flex justify-content-center">
            <h2 class="text-capitalize">Checkout</h2>
          </div>

          {/* <div
            className="row"
            style={{ margin: "0px", justifyContent: "center" }}
          > */}
            <div className="row blogsection" style={{ display: "flex" }}>
              <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 post-detail">
                <div className="row" style={{ margin: "0px" }}>
                  <div
                    className="col-12 d-flex justify-content-between"
                    style={{
                      background: "#fff",
                      borderBottom: "2px solid #e3e3e3",
                      border: '1px solid rgb(227, 227, 227)'
                    }}
                  >
                    <div className="row" style={{ width: "100%" }}>
                      <div
                        className="py-3 col-3 afterdot"
                        style={{
                          color: "#299e22",
                          position: "relative",
                          textAlign: "center",
                          fontSize: "15px",
                          fontWeight: "600",
                          borderBottom: "2px solid #299e22",
                        }}
                      >
                        SIGN IN
                      </div>
                      <div
                        className="py-3 col-6 afterdot"
                        style={{
                          color: "#299e22",
                          position: "relative",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "15px",
                          borderBottom: "2px solid #299e22",
                        }}
                      >
                        ADDRESS & PRODUCT SUMMARY
                      </div>
                      <div
                        className="py-3 col-3"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        PAYMENT
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 d-flex justify-content-between my-3"
                    style={{
                      background: "#fff",
                      border: "1px solid #e3e3e3",
                    }}
                  >
                    <div className="py-3">
                      <h6 className="title5">
                        {" "}
                        <span className="p-2">
                          <FaCheckCircle
                            style={{ color: "#57cd70", fontSize: "22px" }}
                          />
                        </span>
                        Sign In
                      </h6>
                      <p className="desc65" style={{
                        fontWeight: '400',
                        color: '#565656',
                        fontSize: '16px',
                        lineHeight: '24px'
                        }}>
                        {localdata.data.email ? localdata.data.email : ""}

                      </p>
                    </div>
                  </div>

                  <div
                    className="col-12 d-flex justify-content-between mb-3"
                    style={{
                      background: "#fff",
                      border: "1px solid #e3e3e3",
                    }}
                  >
                    <div className="py-3">
                      <h6 className="title5">
                        {" "}
                        <span className="p-2">
                          <FaCheckCircle
                            style={{ color: "#57cd70", fontSize: "22px" }}
                          />
                        </span>
                        Address
                      </h6>
                      <p className="desc65">
                        {userDatas.first_name ? userDatas.first_name : ""}&nbsp;{userDatas.last_name ? userDatas.last_name : ""} |
                        Mobile:
                        {localdata.data.mobile ? localdata.data.mobile : ""}
                      </p>
                    </div>
                    <div className="py-3 editAddressHover">
                      <p
                        onClick={() => {
                          setdropaddress(!dropaddress);
                        }}
                        style={{
                          color: "#059fe2",
                          fontWeight: "600",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      >
                        EDIT
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-12 d-flex justify-content-between mb-1"
                    style={{
                      background: "#fff",
                      border: "1px solid #e3e3e3",
                      backgroundColor: "#059fe2",
                      borderTopRightRadius: "4px",
                      borderTopLeftRadius: "4px",
                    }}
                    onClick={() => {
                      setdropaddress(!dropaddress);
                    }}
                  >
                    <div className="py-3">
                      <h6 className="title90"> Address</h6>
                    </div>
                    <div className="py-3">
                      <p className="" style={{ fontSize: "14px" }}>
                        <FaChevronDown color="#fff" />
                      </p>
                    </div>
                  </div>

                  {/* <div className={dropaddress == true ? "col-12 pb-2 d-flex justify-content-between bg-white collsaped" : "col-12 pb-2 d-flex justify-content-between bg-white uncollsaped"}> */}
                  <div
                    className={
                      dropaddress == false
                        ? "col-12 pb-2 d-flex justify-content-between bg-white collsaped"
                        : "col-12 pb-2 d-flex justify-content-between bg-white uncollsaped"
                    }
                    style={{border: '1px solid rgb(227, 227, 227)'}}
                  >
                    <div
                      className="row mt-4"
                      style={{
                        width: "100%",
                        gap: "17px",
                        justifyContent: "center",
                        display: dropaddress == false ? "flex" : "none",
                        // display:"flex"
                      }}
                    >
                      <div
                        className="py-2 col-6"
                        style={{
                          color: "#000",
                          fontWeight: "500",
                          fontSize: "18px",
                          alignItems: "center",
                          background: "#eee",
                        }}
                      >
                        Delivery Address
                      </div>
                      <div
                        className="py-2 col-5"
                        onClick={() => handleAddressPopup()}
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "15px",
                          border: "2px solid #059fe2",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span style={{ color: "#059fe2", fontSize: "23px" }}>
                          +
                        </span>
                        <span>Add Delivery Address</span>
                      </div>
                      <div class="col-12" style={{ marginBottom: "9px" }}>
                        {myaddresslist[0]
                          ? myaddresslist.map((adds, index) => (
                              <div
                                class="card"
                                style={{
                                  border: "1px solid #e2e2e2",
                                  background: "#f9f9f9",
                                  borderRadius: "0px",
                                  display: userDatas.address_id == adds.address_id ? 'block' : 'none'
                                }}
                              >
                                <div class="card-body">
                                  <h5
                                    class="card-title acounttitle d-flex justify-content-between"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <span className="d-flex justify-content-center align-items-end mb-2 title65">
                                      {/* <span className="px-2">
                                        {userDatas.address_id === adds.id ? (
                                          <input
                                            name="address_radio"
                                            type="radio"
                                            className="big"
                                            id={`radio-${adds.id}`}
                                            // value={adds.id}
                                            checked
                                            onClick={() =>
                                              handleRadioChange(adds.id)
                                            }
                                          />
                                        ) : (
                                          <input
                                            name="address_radio"
                                            type="radio"
                                            className="big"
                                            id={`radio-${adds.id}`}
                                            // value={adds.id}
                                            checked={userDatas.address_id !== adds.id}
                                            onClick={() =>
                                              handleRadioChange(adds.id)
                                            }
                                          />
                                        )}
                                      </span> */}
                                       <span className="px-2">
                                    {/* {console.log('addrs info:',userDatas,'address data single:',adds)} */}
                                        {adds.id == adds.address_id ? (
                                          <input
                                            name="address_radio"
                                            type="radio"
                                            className="big"
                                            id={`radio-${adds.id}`}
                                            // value={adds.id}
                                            checked
                                            onClick={() =>
                                              handleRadioChange(adds.id)
                                            }
                                          />
                                        ) : (
                                          <input
                                            name="address_radio"
                                            type="radio"
                                            className="big"
                                            id={`radio-${adds.id}`}
                                            // value={adds.id}
                                            // defaultValue={}
                                            // checked={userDatas.address_id === fixed_address}
                                            onClick={() =>
                                              handleRadioChange(adds.id)
                                            }
                                          />
                                        )}
                                      </span>
                                      {/* Shikha, 9630461168 */}
                                      {adds.name}, {adds.phone}
                                    </span>
                                    <span className="hello">
                                      {/* <i class="fa-solid fa-ellipsis-vertical"></i> */}
                                      <div class="dropdown">
                                        <button
                                          class="dot-dot secondary dropdown-toggle"
                                          style={{
                                            border: "none",
                                            background: "#f9f9f9",
                                          }}
                                          type="three dots"
                                          id="dropdownMenu"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i class="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <ul
                                          class="dropdown-menu dropdownMenu lateststyle p-0"
                                          style={{ borderRadius: "0px" }}
                                        >
                                          <li>
                                            <button
                                              type="button"
                                              style={{
                                                fontSize: "14px",
                                                color: "#222",
                                                padding: "10px 10px 0px 10px",
                                              }}
                                              class="dropdown-item"
                                              onClick={() =>
                                                handleEditAdd(adds)
                                              }
                                            >
                                              {/* <i class="fa fa-pen-to-square "></i> */}
                                              {/* &nbsp; */}
                                              Edit
                                            </button>
                                          </li>
                                          <li>
                                            <button
                                              type="button"
                                              style={{
                                                fontSize: "14px",
                                                color: "#222",
                                                padding: "10px 10px",
                                              }}
                                              // data-bs-toggle="modal"
                                              // data-bs-target="#exampleModal1400000"
                                              onClick={() =>
                                                handleDeleteAddress(adds.id)
                                              }
                                              class="dropdown-item"
                                            >
                                              {/* <i class="fa fa-trash-can "></i> */}
                                              {/* &nbsp; */}
                                              Delete
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </span>
                                  </h5>
                                  <p className="small-text desc44">
                                    {adds.address}
                                    <br />
                                    {adds.city}, {adds.state}, {adds.country}
                                    <br />
                                    {adds.pincode} &nbsp;
                                  </p>
                                </div>
                              </div>
                            ))
                          : ""}
                          
                      </div>

                      {editItemId == null || editItemId == "" ? (
                        ""
                      ) : (
                        <div class="col-12" style={{ marginBottom: "9px" }}>
                          <div
                            class="card"
                            style={{
                              border: "1px solid #e2e2e2",
                              background: "#f9f9f9",
                              borderRadius: "0px",
                            }}
                          >
                            <div class="card-body">
                              <h4
                                class="card-title title76 acounttitle d-flex justify-content-between mb-2"
                                style={{ textTransform: "capitalize" }}
                              >
                                Edit Address Information
                              </h4>
                              <div style={{ width: "100%" }}>
                                <form onSubmit={handleEditInfoFormSubmit}>
                                  <div
                                    className="row"
                                    style={{ width: "100%" }}
                                  >
                                    {/* <div className="col-6"> */}
                                    <input
                                      type="hidden"
                                      name="address_id"
                                      value={editItemId}
                                    />
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                      <label className="acounttitle tilte54 mb-2">
                                        First Name*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your First Name"
                                        onChange={handleChangeEdit}
                                        value={EditFormData.name}
                                      />
                                      {errors2.name && (
                                        <div className="error">
                                          {errors2.name}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    {/* </div> */}

                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                      <label className="acounttitle tilte54 mb-2">
                                        Email Address*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your Email"
                                        value={EditFormData.email}
                                        onChange={handleChangeEdit}
                                      />
                                      {errors2.email && (
                                        <div className="error">
                                          {errors2.email}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3">
                                      <label className="acounttitle tilte54 mb-2">
                                        Phone Number*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="number"
                                        name="phone"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your number"
                                        onChange={handleChangeEdit}
                                        value={EditFormData.phone}
                                      />
                                      {errors2.phone && (
                                        <div className="error">
                                          {errors2.phone}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3">
                                      <label className="acounttitle tilte54 mb-2">
                                        Pincode*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="number"
                                        name="pincode"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your Pincode"
                                        onChange={handleChangeEdit}
                                        value={EditFormData.pincode}
                                      />
                                      {errors2.pincode && (
                                        <div className="error">
                                          {errors2.pincode}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                      <label className="acounttitle tilte54 mb-2">
                                        Address*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="text"
                                        name="address"
                                        className="form-control"
                                        style={{
                                          borderRadius: "0px",
                                          height: "55px",
                                        }}
                                        placeholder="Enter Your address"
                                        onChange={handleChangeEdit}
                                        value={EditFormData.address}
                                      />
                                      {errors2.address && (
                                        <div className="error">
                                          {errors2.address}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3">
                                      <label className="acounttitle tilte54 mb-2">
                                        City/ Town/ District*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="text"
                                        name="city"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your city"
                                        onChange={handleChangeEdit}
                                        value={EditFormData.city}
                                      />
                                      {errors2.city && (
                                        <div className="error">
                                          {errors2.city}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3">
                                      <label className="acounttitle tilte54 mb-2">
                                        State*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="text"
                                        name="state"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your state"
                                        onChange={handleChangeEdit}
                                        value={EditFormData.state}
                                      />
                                      {errors2.state && (
                                        <div className="error">
                                          {errors2.state}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3">
                                      <label className="acounttitle tilte54 mb-2">
                                        Country*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="text"
                                        name="country"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your Country"
                                        onChange={handleChangeEdit}
                                        value={EditFormData.country}
                                      />
                                      {errors2.country && (
                                        <div className="error">
                                          {errors2.country}
                                        </div>
                                      )}
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>

                                    <div className="form-group col-12 mt-3">
                                      <label className="acounttitle tilte54 mb-2">
                                        Landmark (Optional)*
                                      </label>
                                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                      <input
                                        type="text"
                                        name="landmark"
                                        className="form-control"
                                        style={{ borderRadius: "0px" }}
                                        placeholder="Enter Your Landmark (Optional)"
                                        value={EditFormData.landmark}
                                        onChange={handleChangeEdit}
                                      />
                                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                                    </div>
                                    {/* <div className="form-group col-12 mt-3">
                                  <button
                                    className="btn sendotpbtn"
                                    style={{
                                      border: "2px solid #059fe2",
                                      borderRadius: "0px",
                                    }}
                                  >
                                    SEND OTP
                                  </button>
                                </div> */}
                                    <div class="col-md-8">
                                      {edittAddress ? (
                                        <div className="form-group col-12 mt-3 px-2 pb-2">
                                          <label className="acounttitle tilte541 mb-2">
                                            Enter OTP*
                                          </label>
                                          <input
                                            type="number"
                                            name="otp"
                                            className="form-control"
                                            style={{
                                              borderRadius: "0px",
                                              // width: "50%",
                                            }}
                                            placeholder="Enter OTP"
                                            onChange={handleChangeEdit}
                                            value={EditFormData.otp}
                                            onClick={(e) => handleOTPm()}
                                          />
                                          {errors2.otp && (
                                            <div className="error">
                                              {errors2.otp}
                                            </div>
                                          )}

                                          {editaddressresponseMessage && (
                                            <div className="alert alert-success">
                                              {editaddressresponseMessage}
                                            </div>
                                          )}
                                          {errorOTPmsg && (
                                            <div className="alert alert-error">
                                              {errorOTPmsg}
                                            </div>
                                          )}
                                          <br></br>
                                          <br></br>
                                          <br></br>
                                          <button
                                            className="btn sendotpbtn"
                                            style={{
                                              border: "2px solid #059fe2",
                                              borderRadius: "0px",
                                              width: "100%",
                                            }}
                                            onClick={handleEditUpdateSubmit}
                                          >
                                            SAVE ADDRESS
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="form-group col-12 mt-3 px-2 pb-2">
                                          <button
                                            className="btn sendotpbtn"
                                            style={{
                                              border: "2px solid #059fe2",
                                              borderRadius: "0px",
                                              width: "100%",
                                            }}
                                          >
                                            SEND OTP
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="col-12 d-flex justify-content-end mt-4"
                        onClick={() => {
                          setdropaddress(!dropaddress);
                        }}
                      >

  <button
                          className="btn contunubtn"
                          onClick={() => {
                            setdropaddress2(!dropaddress2);
                            setdropaddress(false);
                            // handleRadioChange(fixed_address)
                          }}
                          style={{ borderRadius: "3px" }}
                          
                        >
                          CONTINUE
                        </button>
     

                      </div>
                    </div>
                  </div>

                  <div
                    className="col-12 d-flex justify-content-between mb-1"
                    style={{
                      background: "#fff",
                      border: "1px solid #e3e3e3",
                      backgroundColor: "#059fe2",
                      borderTopRightRadius: "4px",
                      borderTopLeftRadius: "4px",
                    }}
                    onClick={() => {
                      setdropaddress2(!dropaddress2);
                    }}
                    
                  >
                    <div className="py-3">
                      <h6 className="title90"> Product Summary</h6>
                    </div>
                    <div className="py-3">
                      <p className="" style={{ fontSize: "14px" }}>
                        <FaChevronDown color="#fff" />
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      dropaddress2 == true
                        ? "col-12 pb-2 d-flex justify-content-between bg-white collsaped"
                        : "col-12 pb-2 d-flex justify-content-between bg-white uncollsaped"
                    }
                    style={{border: '1px solid rgb(227, 227, 227)'}}
                  >
                    <div
                      className="row mt-4 mx-0"
                      style={{
                        width: "100%",
                        gap: "17px",
                        justifyContent: "center",
                        display: dropaddress2 == true ? "flex" : "none",
                      }}
                    >
                      <div class="col-12 px-0" style={{ marginBottom: "9px" }}>
                        <div
                          class="card"
                          style={{
                            background: "#fff",
                            borderRadius: "0px",
                            border: "none",
                          }}
                        >
                          <div class="card-body p-0">
                            <div style={{ width: "100%" }}>
                              <div
                                className="row"
                                style={{ width: "100%", margin: "0px" }}
                              >
                                {/* {responseMessage && (
                                  <div
                                    className="alert alert-success"
                                    onClick={handleAlertClick}
                                  >
                                    {responseMessage}
                                  </div>
                                )} */}
                                <br />
                                {cartitemsAtt?.[0] ? (
                                  cartitemsAtt.map((cart_info, index) => (
                                    <div className="form-group col-12 mt-3">
                                      <div
                                        style={{ border: "1px solid #e2e2e2" }}
                                      >
                                        <div className="row">
                                          <div className="col-lg-3 col-md-4  d-flex justify-content-center">
                                            {cart_info.thumbnail_image !==
                                            null ? (
                                              <img
                                                src={cart_info.thumbnail_image}
                                                alt={cart_info.product_name}
                                                style={{
                                                  verticalAlign: "middle",
                                                  maxWidth: "100%",
                                                  margin: "10px",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={cart_info.main_image}
                                                alt={cart_info.product_name}
                                                style={{
                                                  verticalAlign: "middle",
                                                  maxWidth: "100%",
                                                  margin: "10px",
                                                }}
                                              />
                                            )}
                                          </div>
                                          <div className="col-lg-6 col-md-8 px-lg-4 px-md-4 my-3">
                                            <h2 className="title345">
                                              {cart_info.product_name}
                                            </h2>
                                            {cart_info.product_categories.map(
                                              (allCat) => (
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                  className="pro-cat"
                                                >
                                                  {allCat.category_name},{" "}
                                                </span>
                                              )
                                            )}
                                            <table
                                              style={{
                                                width: "100%",
                                                marginTop: "8px",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      width: "25%",
                                                      fontSize: "13px",
                                                      fontWeight: "600",
                                                      padding: "4px 0px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Size
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "25%",
                                                      fontSize: "13px",
                                                      fontWeight: "600",
                                                      padding: "4px 0px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Quantity
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "25%",
                                                      fontSize: "13px",
                                                      fontWeight: "600",
                                                      padding: "4px 0px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Cartons
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "25%",
                                                      fontSize: "13px",
                                                      fontWeight: "600",
                                                      padding: "4px 0px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Price
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {cart_info.products_attributes.map(
                                                  (variant_info, index) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          width: "25%",
                                                          fontSize: "13px",
                                                          fontWeight: "500",
                                                          padding: "5px 0px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {variant_info.item_code}
                                                      </td>
                                                      {variant_info.stock ==
                                                      "0" ? (
                                                        <td
                                                          style={{
                                                            width: "25%",
                                                            fontSize: "13px",
                                                            fontWeight: "500",
                                                            padding: "5px 0px",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <input
                                                            type="number"
                                                            name={`quantity[${variant_info.variant_id}]`}
                                                            defaultValue={0}
                                                            min="1"
                                                            max={
                                                              variant_info.stock
                                                                ? variant_info.stock
                                                                : "0"
                                                            }
                                                            disabled
                                                            style={{
                                                              width: "50px",
                                                              height: "24px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          />
                                                        </td>
                                                      ) : (
                                                        <td
                                                          style={{
                                                            width: "25%",
                                                            fontSize: "13px",
                                                            fontWeight: "500",
                                                            padding: "5px 0px",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <input
                                                            type="number"
                                                            name={`quantity[${variant_info.variant_id}]`}
                                                            defaultValue={
                                                              variant_info.quantity
                                                            }
                                                            min="1"
                                                            max={
                                                              variant_info.stock
                                                                ? variant_info.stock
                                                                : "0"
                                                            }
                                                            // value={
                                                            //   variant_info.quantity
                                                            // }
                                                            style={{
                                                              width: "50px",
                                                              height: "24px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                              handleQuantityInputChange(
                                                                e.target.value,
                                                                variant_info.variant_id,
                                                                cart_info.id,
                                                                variant_info.stock
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      )}
                                                      <td
                                                        style={{
                                                          width: "25%",
                                                          fontSize: "13px",
                                                          fontWeight: "500",
                                                          padding: "5px 0px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {cart_info &&
                                                        (
                                                          cart_info.quantity /
                                                          cart_info.U_SCartQty
                                                        ).toFixed(2)
                                                          ? (
                                                              cart_info.quantity /
                                                              cart_info.U_SCartQty
                                                            ).toFixed(3)
                                                          : "0.00"}
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "25%",
                                                          fontSize: "13px",
                                                          fontWeight: "500",
                                                          padding: "5px 0px",
                                                          color: "#059fe2",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        AED{" "}
                                                        {cart_info.price
                                                          ? cart_info.price
                                                          : "0.00"}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                          <div
                                            className="col-xl-3 col-lg-3 col-md-12 p-xl-0 p-lg-0"
                                            style={{ display: "flex" }}
                                          >
                                            <div
                                              className="row m-0"
                                              style={{
                                                position: "relative",
                                                width: "100%",
                                                background: "#f6f6f6",
                                              }}
                                            >
                                              <div className="col-12 combo1">
                                                <h4 className="title3451">
                                                  PRICE
                                                </h4>
                                                <h3 className="title098">
                                                  AED{" "}
                                                  {cart_info.subtotal
                                                    ? cart_info.subtotal
                                                    : "0.00"}
                                                </h3>
                                                <p className="delete_hover">
                                                  <a
                                                    href="javascript:void(0);"
                                                    onClick={() =>
                                                      handleDeleteButtonClick(
                                                        cart_info.id
                                                      )
                                                    }
                                                    className=""
                                                  >
                                                    <i className="fa-solid fa-trash-can checkout_delete"></i>
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="col-12">
                                    Your shopping cart is empty.
                                  </div>
                                )}

                                <div className="form-group col-lg-6 mt-3 ">
                                  <label className="acounttitle tilte54 mb-2">
                                    LPO Number (Optional) :
                                  </label>
                                  <input
                                    type="text"
                                    name="lpo_number"
                                    className="form-control"
                                    style={{
                                      borderRadius: "0px",
                                      padding: "8px",
                                    }}
                                    value={checkout_lpo_number}
                                    onChange={(e) => {
                                      setlpo_number(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className=" col-5 mt-3 "></div>
                                <div className="form-group col-lg-6 mt-3">
                                  <label className="acounttitle tilte54 mb-2">
                                    Special Remark (Optional) :
                                  </label>
                                  <select
                                    name="special_remarks"
                                    className="form-select form-control"
                                    aria-label="Default select example"
                                    style={{
                                      borderRadius: "0px",
                                      padding: "8px",
                                    }}
                                    value={checkout_special_remarks}
                                    onChange={(e) => {
                                      setspecial_remarks(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Select Special Remark
                                    </option>
                                    {CheckoutSpecialRemark &&
                                      CheckoutSpecialRemark.map((sremark) => (
                                        <option value={sremark.title}>
                                          {`${sremark.title}`}
                                        </option>
                                      ))}
                                  </select>
                                  {/* <input
                                    type="text"
                                    name="special_remarks"
                                    className="form-control"
                                    style={{
                                      borderRadius: "0px",
                                      padding: "8px",
                                    }}
                                  /> */}
                                </div>

                                <div className="form-group col-6 mt-3 d-flex align-items-end">
                                  <button
                                    type="button"
                                    className="btn contunubtn"
                                    onClick={() => {
                                      setdropaddress3(true);
                                      setdropaddress2(false);
                                    }}
                                    style={{ borderRadius: "3px" }}
                                  >
                                    CONTINUE
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 d-flex justify-content-between mb-1"
                    onClick={() => {
                      setdropaddress3(!dropaddress3);
                    }}
                    style={{
                      background: "#fff",
                      border: "1px solid #e3e3e3",
                      backgroundColor:
                        face1 == true && face2 == true ? "#059fe2" : "#67c4ec",
                      borderTopRightRadius: "4px",
                      borderTopLeftRadius: "4px",
                    }}
                  >
                    <div className="py-3">
                      <h6 className="title90"> Payment Methods</h6>
                    </div>
                    <div className="py-3">
                      <p className="" style={{ fontSize: "14px" }}>
                        <FaChevronDown color="#fff" />
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      dropaddress3 == true
                        ? "col-12 pb-2 d-flex justify-content-between bg-white collsaped"
                        : "col-12 pb-2 d-flex justify-content-between bg-white uncollsaped"
                    }
                    style={{border: '1px solid rgb(227, 227, 227)'}}
                  >
                    <div
                      className="row mt-4"
                      style={{
                        width: "100%",
                        gap: "17px",
                        justifyContent: "center",
                        display: dropaddress3 == true ? "flex" : "none",
                      }}
                    >
                      {errorpayment_methode_input == "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {errorpayment_methode_input}
                        </p>
                      )}
                       {erroradd_address == "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {erroradd_address}
                        </p>
                      )}
                      <div
                        class="col-12 d-flex justify-content-between"
                        style={{ marginBottom: "4px" }}
                      >
                        {CheckoutByPayment &&
      CheckoutByPayment.map((payment_m) => (
               
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "end",
                          }}
                        >
                          <input
                            className="form-check-input "
                            type="radio"
                            name="payment_methode_input"
                            id="flexRadioDefault1"
                            value={payment_m.id}    
                            onChange={(e) => {
                              setpayment_methode_input(e.target.value);
                            }}
                            />
                         {payment_m.payment_method}
                        </div>
 ))}
                        {/* <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "end",
                          }}
                        >
                          <input
                            className="form-check-input "
                            type="radio"
                            name="payment_methode_input"
                            id="flexRadioDefault1"
                            value="4"
                            onChange={(e) => {
                              setpayment_methode_input(e.target.value);
                            }}
                          />
                          Cash On Delivery
                        </div>
                       
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "end",
                          }}
                        >
                          <input
                            className="form-check-input "
                            type="radio"
                            name="payment_methode_input"
                            id="flexRadioDefault1"
                            value="3"
                            onChange={(e) => {
                              setpayment_methode_input(e.target.value);
                            }}
                          />
                          Pay Online
                        </div> */}
                        {/* <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "end",
                          }}
                        >
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          Cash On Delivery
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "end",
                          }}
                        >
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          Pay Online
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "end",
                          }}
                        >
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          Other
                        </div> */}
                      </div>

                      <div className="col-12 d-flex justify-content-start ">
                        <button
                          type="button"
                          onClick={bulkorderplacesubmit}
                          className="btn contunubtn px-5"
                          style={{ borderRadius: "0px", fontSize: "18px" }}
                        >
                          PLACE ORDER
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-12 col-sm-12 p-0 sidebar2"
                style={{ alignSelf: "flex-start" }}
              >
                <div className="row" style={{ margin: "0px" }}>
                  <div
                    className="col-12 d-flex justify-content-between py-3"
                    style={{
                      background: "#f6f6f6",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      Products Your Shopping Cart Contains
                    </p>
                    <p
                      style={{
                        color: "#059fe2",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {cartitemsAtt && cartitemsAtt?.length
                        ? cartitemsAtt?.length
                        : "0"}
                    </p>
                  </div>
                  <div
                    className="col-12 d-flex justify-content-between py-3"
                    style={{
                      background: "#f6f6f6",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      Total Number Of Cartons:
                    </p>
                    <p
                      style={{
                        color: "#059fe2",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {cartitems && cartitems.cartonsTotal
                        ? cartitems.cartonsTotal
                        : "0.00"}
                    </p>
                  </div>
                  <div className="payment-summary">
                    <br />
                    <div
                      className="header-part"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      <h2>Payment Summary</h2>
                    </div>
                    <div
                      className="col-12 d-flex justify-content-between py-3 mt-3"
                      style={{
                        background: "#f6f6f6",
                        // borderTop: "1px solid #e3e3e3",
                      }}
                    >
                      <p style={{ fontSize: "15px", color: "#000" }}>
                        Item Sub Total Amount:
                      </p>
                      <p
                        style={{
                          color: "#059fe2",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        AED{" "}
                        {cartitems && cartitems.grandTotal
                          ? cartitems.grandTotal
                          : "0.00"}
                      </p>
                    </div>
                    <div
                      className="col-12 d-flex justify-content-between py-3"
                      style={{ background: "#fff" }}
                    >
                      <p
                        style={{
                          fontSize: "15px",
                          // fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Total Discount:
                      </p>
                      <p
                        style={{
                          color: "#299e22",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        AED{" "}
                        {cartitems && cartitems.total_discount
                          ? cartitems.total_discount
                          : "0.00"}
                      </p>
                    </div>
                    <div
                      className="col-12 d-flex justify-content-between py-3"
                      style={{ background: "#fff" }}
                    >
                      <p
                        style={{
                          fontSize: "15px",
                          // fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        VAT ({cartitems && cartitems.vat ? cartitems.vat : "0%"}
                        ):
                      </p>
                      <p
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        AED{" "}
                        {cartitems && cartitems.vat_amount
                          ? cartitems.vat_amount
                          : "0.00"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-12 d-flex justify-content-between py-3 mb-3"
                    style={{
                      background: "#f6f6f6",
                      borderBottom: "1px solid #e3e3e3",
                      fontWeight: "600",
                    }}
                  >
                    <p style={{ color: 'rgb(0, 0, 0)', fontWeight: '500', fontSize: '18px', alignItems: 'center'}}>
                      Amount Payable:
                    </p>
                    <p
                      style={{
                        color: "#059fe2",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      AED{" "}
                      {cartitems && cartitems.amount_payable
                        ? cartitems.amount_payable.toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </section>

      <div
        ref={addresspopup}
        class="modal fade"
        id="exampleModal1400000"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered popaddresswidth">
          <div className="modal-content" style={{ borderRadius: "0px" }}>
            <div
              className="modal-header"
              style={{ padding: "8px", border: "none" }}
            >
              {/* <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ background: "#059fe2", borderRadius: "0px" }}
              >
                <span style={{ color: "white" }}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
            <div className="modal-body py-0">
              <div className="row">
                <div className="col-12 pb-3">
                  <h5 className="title451 text-center pb-4">
                    ADD DELIVERY ADDRESS
                  </h5>
                </div>
                <div className="px-4">
                {errors.country || errors.citstatey || errors.city || errors.address || errors.pincode || errors.phone || errors.email || errors.name ? (
              <div className="error-message-password-address mb-2">
                <p>Error occured</p>

              </div>
              ):null}
                </div>
   
              </div>
              <div className="" style={{ width: "100%" }}>
                <form onSubmit={handleAddAddressSubmit}>
                  <div className="row customrow" style={{ width: "100%" }}>
                    {/* <div className="col-6"> */}
                    <div className="form-group col-md-6 pb-lg-0 pb-2  col-sm-6 col-xs-12 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        First Name*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="name"
                        className={`old-password-password form-control ${errors.name ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your First Name"
                        onChange={handleChange}
                        value={formData.name}
                      />
                       {errors.name && <i className=" warning-icon-address"> <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
                 
                      
                    </div>

                    <div className="form-group col-md-6 col-sm-6 col-xs-12 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Email Address*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="email"
                        name="email"
                        className={`old-password-password form-control ${errors.email ? 'error-border-password' : ''}`}

                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your Email"
                        onChange={handleChange}
                        value={formData.email}
                      />
                       {errors.email && <i className="warning-icon-address"><svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
             
       
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Phone Number*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="number"
                        name="phone"
                        className={`old-password-password form-control ${errors.phone ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your number"
                        onChange={handleChange}
                        value={formData.phone}
                      />
                       {errors.phone && <i className=" warning-icon-address"><svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
        
                      {/* {errors.phone && (
                        <div className="error">{errors.phone}</div>
                      )} */}
                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Pincode*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="number"
                        name="pincode"
                        className={`old-password-password form-control ${errors.pincode ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your Pincode"
                        onChange={handleChange}
                        value={formData.pincode}
                      />
                      
                      {errors.pincode && <i className=" warning-icon-address"><svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
          
    
                    </div>
                    <div className="form-group col-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Address*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="address"
                        className={`old-password-password form-control ${errors.address ? 'error-border-password' : ''}`}
                        style={{
                          borderRadius: "0px",
                          height: "55px",
                        }}
                        placeholder="Enter Your address"
                        onChange={handleChange}
                        value={formData.address}
                      />
                        {errors.address && <i className=" warning-icon-address"> <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
         
                    </div>
                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        City/ Town/ District*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="city"
                        className={`old-password-password form-control ${errors.city ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your city"
                        onChange={handleChange}
                        value={formData.city}
                      />
                       {errors.city && <i className=" warning-icon-address"> <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
                     
                    </div>
                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        State*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="state"
                        className={`old-password-password form-control ${errors.state ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your state"
                        onChange={handleChange}
                        value={formData.state}
                      />
                       {errors.state && <i className="f warning-icon-address"><svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
      
                    </div>
                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Country*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="country"
                        className={`old-password-password form-control ${errors.country ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your country"
                        onChange={handleChange}
                        value={formData.country}
                      />
                      {errors.country && <i className=" warning-icon-address"><svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
       
                      {/* {errors.country && (
                        <div className="error">{errors.country}</div>
                      )} */}
                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                    </div>

                    <div className="form-group col-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Landmark (Optional)
                      </label>
                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                      <input
                        type="text"
                        name="landmark"
                        className="form-control"
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your Landmark (Optional)"
                        onChange={handleChange}
                        value={formData.landmark}
                      />

                      {/* <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                        </div> */}
                    </div>

                    {addAddress ? (
                      <div className="form-group col-12 mt-3 px-2 pb-2">
                        <label className="acounttitle tilte541 mb-2">
                          Enter OTP*
                        </label>
                        <input
                          type="number"
                          name="otp"
                          className="form-control"
                          style={{ borderRadius: "0px", width: "50%" }}
                          placeholder="Enter OTP"
                          onChange={handleChange}
                          value={formData.otp}
                          onClick={(e) => handleOTPm()}
                        />
                        {errors.otp && (
                          <div className="error">{errors.otp}</div>
                        )}

{addaddressresponseMessage && (
                          <div className="alert-adress alert-success" style={{width :'50%'}}>
                            {addaddressresponseMessage}
                          </div>
                        )}
                        {errorOTPmsg && (
                          <div className="alert-adress alert-error"  style={{width :'50%'}}>{errorOTPmsg}</div>
                        )}
                        <br></br>
                        <br></br>
                        <br></br>
                  <button
                          onClick={handleAddSubmit}
                          className="btn sendotpbtn-address-page"
                         
                        >
                          SAVE ADDRESS
                        </button>
                      </div>
                    ) : (
                      <div className="form-group col-12 mt-3 px-2 pb-2">
                        <button
                          className="btn sendotpbtn"
                          style={{
                            border: "2px solid #059fe2",
                            borderRadius: "0px",
                            width: "100%",
                          }}
                        >
                          SEND OTP
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        class="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">
                Modal 2
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Hide this modal and show the first with the button below.
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
              >
                Back to first
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* delete address popup */}

      {/* Delete Popup */}
      {/* {showDeletePopup && (
        <div className="delete-popup">
          <p>Are you sure you want to delete this Address?</p>
          <button onClick={handleDeleteAddress}>Yes</button>
          <button onClick={handleDeleteCancel}>No</button>
        </div>
      )} */}
      {/* end delete popup */}
      <Footer />
    </div>
  );
};

export default Checkout;
