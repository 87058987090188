import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
const MyAccountMenu = () => {
  const location = useLocation();
  const result = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  // console.log("result to ",result);
  const nvg = useNavigate();
  const [Tabcontent, setTab] = useState("My Profile");
  return (
    <div className=" col-lg-3  col-sm-12  py-3" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <div className="card" style={{width:"97%"}}>
        <div className="">
          <div className="collection-brand-filter">
            <div className={location.pathname == "/profile" ? "hand1" : location.pathname == "/update-profile" ? "hand1" : "hand"}>
              <NavLink
                className={
                  location.pathname == "/profile" ? "cattext1" : location.pathname == "/update-profile" ? "cattext1" : "cattext"
                }
                to="/profile"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/profile" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Profile-Update-hover.png` : location.pathname == "/update-profile" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Profile-Update-hover.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Profile-Update.png`}
                    alt=""
                    className="userIcon"
                  />
                  &nbsp; My Profile
                </a>
              </NavLink>
            </div>

            <div className={location.pathname == "/invoice" ? "hand1" : "hand"}>
              <NavLink
                className={
                  location.pathname == "/invoice" ? "cattext1" : "cattext"
                }
                to="/invoice"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/invoice" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/downloaded-Video-white.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/downloaded-Video-blue.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; Invoices
                </a>
              </NavLink>
            </div>
            <div
              className={location.pathname == "/myorders" ? "hand1" : result == "/my-order-detail" ? "hand1" : "hand"}
            >
              <NavLink
                className={
                  location.pathname == "/myorders" ? "cattext1" : result == "/my-order-detail" ? "cattext1" : "cattext"
                }
                to="/myorders"
              >
                <a href="#">
                  {" "}
                  <img
                   src={location.pathname == "/myorders" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/My-Orders-hover.png` :  result == "/my-order-detail" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/My-Orders-hover.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/My-Orders.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; My Order
                </a>
              </NavLink>
            </div>
            <div className={location.pathname == "/myitems" ? "hand1" : "hand"}>
              <NavLink
                className={
                  location.pathname == "/myitems" ? "cattext1" : "cattext"
                }
                to="/myitems"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/myitems" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/My-Items-hover.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/My-Items.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; My Items
                </a>
              </NavLink>
            </div>

            <div className={location.pathname == "/rfq" ? "hand1" : "hand"}>
              <NavLink
                className={location.pathname == "/rfq" ? "cattext1" : "cattext"}
                to="/rfq"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/rfq" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/My-RFQ-hover.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/My-RFQ.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; My RFQ
                </a>
              </NavLink>
            </div>
            <div
              className={location.pathname == "/whishlist" ? "hand1" : "hand"}
            >
              <NavLink
                className={
                  location.pathname == "/whishlist" ? "cattext1" : "cattext"
                }
                to="/whishlist"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/whishlist" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist-hover.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; Whishlist
                </a>
              </NavLink>
            </div>

            <div className={location.pathname == "/address" ? "hand1" : "hand"}>
              <NavLink
                className={
                  location.pathname == "/address" ? "cattext1" : "cattext"
                }
                to="/address"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/address" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Address-hover.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Address.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; Address
                </a>
              </NavLink>
            </div>
            <div
              className={location.pathname == "/password" ? "hand1" : "hand"}
            >
              <NavLink
                className={
                  location.pathname == "/password" ? "cattext1" : "cattext"
                }
                to="/password"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/password" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Password-hover.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Password.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; Password
                </a>
              </NavLink>
            </div>

            <div
              className={
                location.pathname == "/download-video" ? "hand1" : "hand"
              }
            >
              <NavLink
                className={
                  location.pathname == "/download-video"
                    ? "cattext1"
                    : "cattext"
                }
                to="/download-video"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/download-video" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/downloaded-Video-white.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/downloaded-Video-blue.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; Download Video
                </a>
              </NavLink>
            </div>
            <div
              className={
                location.pathname == "/notification" ? "hand1" : "hand"
              }
            >
              <NavLink
                className={
                  location.pathname == "/notification" ? "cattext1" : "cattext"
                }
                to="/notification"
              >
                <a href="#">
                  {" "}
                  <img
                    src={location.pathname == "/notification" ? `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/notification-white.png` : `${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/notification-blue.png`}
                    alt=""
                    className="invoimg"
                  />
                  &nbsp; Notification
                </a>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccountMenu;
