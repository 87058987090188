import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import axios from "axios";
import { getToken } from "../services/LocalStorageSrv";
import { TfiYoutube } from "react-icons/tfi";
// import Loader from '../Loader';
const Footer = () => {
  const location = useLocation();
  const localdata = getToken("data");
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mycatlist, setCatList] = useState([]);
  const [settingdata, setSettingData] = useState([]);
  const ref_fun = ()=>{
    if(location.pathname.includes("reset-password")){
      setTimeout(() => {
        // window.location.reload()
  
        // nvg('/')
        const anchor = document.createElement('a');
        anchor.href = '/';
        anchor.target = '_blank';
        anchor.rel = 'noopener noreferrer';
        
        // Simulate a click event on the anchor element
        anchor.click();
        window.history.back();
      }, 900);
   
    } 
  }
  useEffect(() => {
    
    const fetchCatItems = async () => {
      try {
         setLoading(true);
     
         const response = await axios.get(`${apiUrl}/settings`);
          setSettingData(response.data.data);
          setCatList(response.data.data.footer_cats);
          // console.log('Fetched cats:', response.data.data);
          setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchCatItems();
   }, []);




  return (
<footer>
{/* {loading ? <Loader /> : null} */}
  <div className="footer">

   <div className="topfooter" style={{display:'flex', justifyContent:'space-between',}}>
  <div className="sb_topfooter rightsidefooter">
      <h4>We are always here to help</h4>
      <p>Reach out to us through any of this support chennels</p>
    </div>
    <div className="sb_topfooter">
      
    <div className="row support"  >
                <div className="col-xl-6 col-lg-6 col-md-6 cssforcenter" style={{display:'flex',paddingRight:'30px'}}>
                  <a href="#" target="_blank" className="info p-0" >
                 
                    <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Help-icon.png`} style={{background:'white',marginRight:'10px',border:'1px solid gray',borderRadius:'30px'}}/>
                  </a>
                  <div className="help"  style={{ color: '#9ba0b1'}}> HELP CENTER
                      <div style={{paddingTop:'3px'}}> <a href="mailto:help.sbmmarketplace.com" style={{fontSize :'16px',color: '#000',fontWeight:'500'}}>help.sbmmarketplace.com</a></div>
                  </div>
                </div>
               
                <div className="col-xl-6 col-lg-6 col-md-6 cssforcenter" style={{display:'flex',paddingRight:'30px'}}>
                  <a href="#" target="_blank" className="mail">
                    <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Email-icon.png`} style={{background:'white',marginRight:'10px',border:'1px solid gray',borderRadius:'30px'}}/>
                  </a>
                  <div className="help" style={{ color: '#9ba0b1'}}> EMAIL SUPPORT
                      <div style={{paddingTop:'3px'}}> <a href="mailto:care.sbmmarketplace.com#" style={{fontSize :'16px',color: '#000',fontWeight:'500'}}>Care@sbmmarketplace.com</a></div>
                  </div>
                </div>
              </div>



      {/* <h5>HELP CENTRE</h5>
      <img src="./images/icons/Help-icon.png" alt="" />
        <p>help.sbmmarketplace.com</p>
     
    </div>
<div className="sb_topfooter">
  <h5> EMAIL SUPPORT </h5>
    <p>Care@sbmmarket.com</p> */}
</div>
</div>






   <div className=" row px-3 pt-4 sb_footer-links">

{mycatlist.map((cat_n) => (
  <div className="sb_footer-links_div col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6" key={cat_n.id}>
    <a href="javascript:void(0);" 
    // onClick={() => { nvg("/product-category-detail", { state: { name: cat_n.slug, cat_id: cat_n.id } }) }}
    >
      <h4 className="bold-uppercase">{cat_n.category_name}</h4>
    </a>

    {cat_n.childCats.map((child_cat_n) => (
      <a key={child_cat_n.id} href="javascript:void(0);" className="hovereffect" onClick={() => { nvg("/product-category-detail", { state: { name: child_cat_n.slug, cat_id: child_cat_n.id } }) }}>
        <p onClick={()=>{ref_fun()}} className="hovereffect">{child_cat_n.category_name}</p>
      </a>
    ))}
  </div>
))}




 {/* <div className="main-footer" v-if="settings">
  <div className="container-fluid">
    <div className="row mt-5" style={{display:'flex',justifyContent:''}}>
      <div className=" col-lg-6 col-md-6  store" style={{paddingLeft:'',alignItems: 'center'}}>
        <h3>SHOP ON THE GO</h3>
        <div className="store-icon" >
          <div className="app-store">
            <a href="#" target="_blank"> 
              <img src="./images/icons/apple-app-store.png" />
            </a>
          </div>
          <div className="app-store ml-3">
            <a href="#" target="_blank">
              <img src="./images/icons/Google-play-store.png" />
            </a>
          </div>
        </div>
      </div>
      <div className=" col-lg-6 col-md-6 social-media" style={{paddingLeft:'',}}>
        <h3>CONNECT WITH US</h3>
        <div className="media-icon" >
          <a href="settings.facebook_link" target="_blank" style={{fontSize: '28px',paddingTop: '10px'}}><i className="fa-brands fa-facebook-f" aria-hidden="true" /></a>
          <a href="settings.twitter_link" target="_blank"style={{fontSize: '28px',paddingTop: '10px'}} ><i className="fa-brands fa-twitter" aria-hidden="true" /></a>
          <a href="settings.linkedin_link" target="_blank" style={{fontSize: '28px',paddingTop: '10px'}}><i className="fa-brands fa-linkedin" aria-hidden="true" /></a>
          <a href="settings.youtube_link" target="_blank" style={{fontSize: '28px',paddingTop: '10px'}}><i className="fa-brands fa-youtube" aria-hidden="true" /></a>
        </div>
      </div>
    </div>
  </div>
</div>  */}






 
 {/* <div className="sb_footer-below">
 
<div className="bottom-footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-2">
          <p>@ 2022 All Rights Reserved.</p>
        </div>
        <div
          className="col-xl-4 col-lg-4 col-md-4 visa-card"
          
        >
          <img src="https://sbmmarketplace.com/img/Payment-Methods.ca45911e.png"/>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 other-option">
          <a href="#" target="_blank">Career </a>
          <a href="#" target="_blank">Warranty policy</a>
          <a href="#" target="_blank"> Sell with us</a>
         <a href="#" target="_blank">Terms of use</a>
          <a href="#" target="_blank" >Terms of sales</a>
          <a href="#" target="_blank" >Terms & Conditions</a>
          <a href="#" target="_blank" >Privacy Policy</a>
        
          
        </div>
      </div>
    </div>

      <div className="help-btn"> 
      <a href="https://api.whatsapp.com/send?phone=${settings.whatsapp_no}&text=${settings.whatsapp_text}"><i className="fa-brands fa-whatsapp"></i> Whatsapp </a>
           
  
      </div>
  </div>

 



 </div> */}

   </div>


   <div className="main-footer">
  <div className="container-fluid">
    <div className="row " style={{display:'flex',justifyContent:''}}>
      <div className=" col-lg-6 col-md-6  store" style={{paddingLeft:'',alignItems: 'center'}}>
        <h3>SHOP ON THE GO</h3>
        <div className="store-icon" >
          <div className="app-store">
            <a href="#" target="_blank"> 
           
              <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/apple-app-store.png`} />
            </a>
          </div>
          <div className="app-store ml-3">
            <a href="#" target="_blank">
              <img src= {`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Google-play-store.png`}/>
            </a>
          </div>
        </div>
      </div>
      <div className=" col-lg-6 col-md-6 social-media" style={{paddingLeft:'',}}>
        <h3>CONNECT WITH US</h3>
        <div className="media-icon" >
          <a href={setSettingData.facebook_link} target="_blank" style={{fontSize: '28px',paddingTop: '10px'}}><i className="fa-brands fa-facebook-f" aria-hidden="true" /></a>
          <a href={setSettingData.twitter_link} target="_blank"style={{fontSize: '28px',paddingTop: '10px'}} ><i className="fa-brands fa-twitter" aria-hidden="true" /></a>
          <a href={setSettingData.linkedin_link} target="_blank" style={{fontSize: '28px',paddingTop: '10px'}}><i className="fa-brands fa-linkedin" aria-hidden="true" /></a>
          <a href={setSettingData.youtube_link} target="_blank" style={{fontSize: '28px',paddingTop: '10px',display:'flex',justifyContent:'center'}}>
            {/* <i className="fa-brands fa-youtube" aria-hidden="true" /> */}
            <TfiYoutube />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
  {/* <div className="sb_footer-below"> */}
 
 <div className="bottom-footer mt-3">
     <div className="container-fluid">
       <div className="row">
         <div className="col-xl-2 col-lg-2 col-md-2">
         {settingdata.footer_copyright_text !== '<p>@ 2022 All Rights Reserved.</p>' && (
  <p dangerouslySetInnerHTML={{ __html: settingdata.footer_copyright_text }}></p>
)}
         </div>
         <div
           className="col-xl-3 col-lg-3 col-md-3 visa-card"
           
         >
        {settingdata.footer_image !== '<img src="https://sbmmarketplace.com/img/Payment-Methods.ca45911e.png"/>' && (
  <img src={settingdata.footer_image}/>
)}  
          
         </div>
         <div className="col-xl-7 col-lg-7 col-md-7 other-option" style={{display:'flex',justifyContent:'center'}}>
           {/* <a href="javascript:void(0);" onClick={() => { nvg("/blog") }}>Blog </a> */}
           <a href="#" className="text23" target="_blank">Career </a>
           <a href="#" className="text23" target="_blank">Warranty policy</a>
           <a href="#" className="text23" target="_blank"> Sell with us</a>
          {/* <a href="#" className="text23" target="_blank">Terms of use</a> */}
           <a href="#" className="text23"  target="_blank" >Terms of sales</a>
           
           <a href="javascript:void(0);" className="text23" onClick={() => { nvg('/Terms-Conditions'); }}>Terms & Conditions</a>
<a href="javascript:void(0);" className="text23" onClick={() => { nvg('/Privacy-Policy'); }}>Privacy Policy</a>
         </div>
       </div>
     </div>
 
     {/* {localdata ? ( */}
  <div className="help-btn"> 
    <a href={localdata ? `https://api.whatsapp.com/send?phone=${settingdata.whatsapp_no}&text=${settingdata.whatsapp_text}` : "javascript:void(0);"}>
      <i className="fa-brands fa-whatsapp"></i> Whatsapp
    </a>
  </div>
{/* ) : (
  ''
)} */}


   </div>


{/* </div>  */}
   
  </div>
</footer>


  )
}

export default Footer