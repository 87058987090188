import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import { Helmet } from 'react-helmet';
const OurBrand = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  const [loading, setLoading] = useState(true);
  const [mybrands, setMyBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.set("page", currentPage);
    queryParams.set("orderby", "id");
    queryParams.set("order", "DESC");
    const fetchBrands = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/brands?page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setMyBrands(response?.data?.data?.data);
        setTotalPages(response?.data?.data?.last_page);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchBrands();
  }, [currentPage]);
  const handlePageChange = (url) => {
    const fetchBrands = async (urlnumber) => {
      try {
        const response = await axios.get(
          `${apiUrl}/brands?page=${urlnumber.selected + 1}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setMyBrands(response?.data?.data?.data);
        setTotalPages(response?.data?.data?.last_page);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBrands(url);
  };

  return (
    <>
      <div>
        {loading ? <Loader /> : null}
        <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
        <section className="brands-sec inner-page-bg">
          <div className="container py-5 mb-5">
            <div className="row">
              <div className="col-lg-12  col-md-12">
                {/* <div className="row brand-header"> */}
                {/* <div className="col-md-12"> */}
                <h2 className="brand-section">Brands</h2>
                {/* </div> */}
                {/* </div> */}

                <div className="col-lg-12">
                  <div className="row">
                    <div class="card-group">
                      {mybrands.map((brnd) => (
                        <div className=" col-lg-3 col-md-6 ">
                          <div class="card" style={{borderRadius:'0px'}}>
                            <div
                            className="boxhover231"
                              // href="javascript:void(0);"
                              onClick={() => {
                                nvg("/brand-product/" + brnd.id);
                              }}
                            >
                              <div>
                                <img
                                  src={brnd.brand_logo}
                                  class="card-img-top"
                                  style={{ objectFit: "contain" }}
                                  alt={brnd.brand_name}
                                />
                              </div>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "300",
                                  lineHeight: "24px",
                                  color: "#565656",
                                  textAlign: "center",
                                }}
                                class="card-title"
                              >
                                {brnd.brand_name}
                              </h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <ReactPaginate
              pageCount={totalPages}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              activeClassName={'active'}
            /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default OurBrand;
