import "./Header.css";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Vtheaderright from "./Headercomponent/Vtheaderright";
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdErrorOutline } from "react-icons/md";
const Header = ({
  loginpopRef,
  newpop,
  newpop2,
  newpop3,
  signup,
  matchotps,
  forgotpotp,
  emailmsg,
  fromemailnextpopup,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const closeretriveaccess = useRef(null)
  const closefromemailnextpopup = useRef(null)
  const matchotp = useRef(null)
  const verifyotp = useRef(null)
  const [cartitemscount, setCartItemCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [login, setlogin] = useState(false);
  const [changepss, setchangepss] = useState(false);
  const [data, setUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [botherror, setBothError] = useState("");
  const [error, setError] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [errorpassword, seterrorpassword] = useState("");
  const [exitsvendor_code, setvendor_code] = useState("");
  const [mobilenumber, setMobileNumner] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setsuccess] = useState(true);
  const [successerror, setsuccessError] = useState('');

  const [otpSent, setOtpSent] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(180);
  // Function to start the countdown
  const startTimer = () => {
    setOtpSent(true);
    const interval = setInterval(() => {
      setSecondsRemaining(prevSeconds => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          setOtpSent(false); // Enable resend button after expiration
        }
        return prevSeconds > 0 ? prevSeconds - 1 : 0;
      });
    }, 1000);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (
      email !== "" &&
      password !== ""
    ) {
   
    // if (password.length < 5) {
    //   setError("Password must be at least 5 characters long.");
    //   return;
    // }
    try {
      // Make API request
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const response = await axios.post(
        `${apiUrl}/login`,
        {
          email,
          password,
        },
        headers
      );

      // Check for successful login

      // Check user activation status
      if (response?.data?.success === true) {
        const user = response?.data;
        storeToken(user);
        setlogin(true);
        setUserData(user);
        nvg("/cart");
        window.location.reload();
        // Clear any previous errors
        setError("");
      }
    } catch (error) {
      nvg("/cart");
      if (error.response?.data?.message) {
        setError(error.response?.data?.message);
        // setsuccessError(error.response.data.message);
        console.error("Error fetching data:", error);
        setLoading(false);
        // console.log('error find:',error.response.data.message);
        
      }
    }
  }
    if (email == "") {
      seterroremail("Email is Required");
    } else {
      seterroremail("");
    }
    if (password == "") {
      seterrorpassword("Password is Required");
    } else {
      seterrorpassword("");
    }
    if (email == "" || password == "") {
      setBothError("Both is Required");
    } else {
      setBothError("");
    }
  };
  const handleLogout = () => {
    removeToken("data");
    setlogin(false);
    setUserData(null);
  };

  function formatMobileNumber(mobileNumber) {
    // Check if the mobile number is not empty and is a string
    if (mobileNumber && typeof mobileNumber === "string") {
      // Extract the last four digits
      const lastFourDigits = mobileNumber.slice(-4);

      // Replace the digits before the last four with '*' characters
      const maskedNumber = "*".repeat(mobileNumber.length - 4) + lastFourDigits;

      // Add the prefix "+9" to the masked number
      const formattedNumber = "+9" + maskedNumber;

      return formattedNumber;
    } else {
      // Return the original number if it doesn't meet the criteria
      return mobileNumber;
    }
  }

  //  search function

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get("search");
  const [searchTerm, setSearchTerm] = useState("");
  const [settingdatas, setSettingData] = useState("");
  const [defaultValueCode, setdefaultValueCode] = useState("");
  const [SearchError, setSearchError] = useState("");
  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (searchTerm.trim() === "") {
      setSearchError(true);
    } else {
      setSearchError(false);
      // Perform your search logic here
      nvg(`/shop?search=${searchTerm}`);
      window.location.reload();
    }

    // window.location.reload();
  };

  const [RetriveAndForgot, setRetriveAndForgot] = useState("");
  const ManageRetriveAndForgot = (event) => {
    // console.log('manage:',event);
    setRetriveAndForgot(event);
  }

  useEffect(() => {
    if (otpSent) {
      startTimer(); // Start the timer if OTP is sent
    }
    // setSecondsRemaining(180); 
    // startTimer(); 
    const fetchCartsDe = async () => {
      try {
        // setLoading(true);
        const response = await axios.get(`${apiUrl}/carts`, {
          headers: {
            Authorization: `Bearer ${tokendata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setCartItemCount(response.data.data.items);
        // setItemCount(response.data.data.items.count);
        // console.log('cart de:',response?.data?.data?.items?.count);
        setLoading(false);
      } catch (error) {
        // console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    const fetchSettingData = async () => {
      try {
        // setLoading(true);
        const response = await axios.get(`${apiUrl}/settings`);
        setSettingData(response.data.data);

        // console.log('Fetched set:', response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchCartsDe();
    fetchSettingData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const [formData, setFormData] = useState({
    vendor_code: "",
    country: "",
    mobile: "",
    // inlineRadioOptions: "",
    email: "",
  });
  const validateForm1 = () => {
    let valid = true;
    const newErrors123 = {};

    // if (!formData.inlineRadioOptions) {
    //   newErrors.inlineRadioOptions = "select mobile or email is required";
    //   valid = false;
    // }else{
    //   newErrors.inlineRadioOptions = "";
    //   valid = true;
    // }
    if (!formData.vendor_code) {
      newErrors123.vendor_code = "vendor code is required";
      valid = false;
    } else {
      newErrors123.vendor_code = "";
      valid = true;
    }
    if (selectedValue == "mobile") {
      if (!formData.country) {
        newErrors123.country = "country is required";
        valid = false;
      }

      if (!formData.mobile) {
        newErrors123.mobile = "mobile is required";
        valid = false;
      }
    }else if (selectedValue == "email") {
      if (!sendmailemail) {
        newErrors123.email = "email is required";
        valid = false;
      }
    }
    setErrors(newErrors123);
    return valid;

   
  };
  const [openotppopup, setopenotppopup] = useState(false);
  const [openotppopupafteremail, setopenotppopupafteremail] = useState(false);
  const [openretievepopup, setopenretievepopup] = useState(true);
  const [matchotpretievepopup, setmatchotpretievepopup] = useState(true);
  const [closeretievepopup, setcloseretievepopup] = useState(false);
  const [nextpopupvalue, setnextpopupvalue] = useState("");
  const [nextpopupotpmatchvalue, setnextpopupotpmatchvalue] = useState("");
  const [otpmessage, setotpmessage] = useState("");
  const [error_otp_match, seterror_otp_match] = useState("");

  const [errorsendmailemail, seterrorsendmailemail] = useState("");
  const [sendmailemail, setsendmailemail] = useState("");
  const closepopups = () => {
    // console.log("clos pop");
    // const backdropElement = document.querySelector(".modal-backdrop.fade.show");

    // Check if the backdrop element is found
    // if (backdropElement) {
      // Remove the 'show' class

    //   backdropElement.classList.remove("show");
    //   backdropElement.classList.remove("modal-backdrop");
    //   backdropElement.classList.remove("fade");
    // }
    // if (newpop3.current) {
    //   // Show the modal using Bootstrap's modal API
    //   const modal = new window.bootstrap.Modal(newpop3.current);
    //   modal.hide();
    // }
  };

  const sendotpCode = async () => {
    // console.log('hf');
    // console.log('vc:',formData.vendor_code);
  if(formData.vendor_code !== ''){
    setLoading(true);
    const collect = {
      code: formData.vendor_code,
      mobile: formData.country + "" + formData.mobile,
    };
    setnextpopupvalue(collect);
    // console.log("all va:", collect);
    try {
      const response = await axios.post(`${apiUrl}/send-otp`, collect);

      if (response.data.success === true) {
        // Assuming response.data is an object containing profile data
        // setMobileNumner(response?.data?.data?.mobile);
        // console.log('mob:',response?.data?.data?.mobile);

        setopenotppopup(true);
        setopenretievepopup(false);
        setotpmessage(response.data.message);
        setSecondsRemaining(180); // Reset countdown to 176 seconds
        startTimer(); // Start the timer
        // const backdropElement1 = document.getElementById("staticBackdrop2");

        // // Check if the backdrop element is found
        // if (backdropElement1) {
        //   // Remove the 'show' class
    
        //   backdropElement1.classList.remove("show");
        //   backdropElement1.classList.remove("modal-backdrop");
        //   backdropElement1.classList.remove("fade");
        // }
        // console.log('otp m:',openotppopup);
        // console.log('popup true:',response.data.message);


        // if (newpop.current) {
        //   // Show the modal using Bootstrap's modal API
        //   const modal = new window.bootstrap.Modal(newpop.current);
      
        //   // Check if the modal is currently visible before attempting to hide it
        //   if (modal._isShown) {
        //     // Hide the modal using Bootstrap's modal API
        //     modal.hide();
        //   }
        // }
        if (closeretriveaccess.current) {
          // Trigger the click event on the button
          closeretriveaccess.current.click();
        }
        if (closefromemailnextpopup.current) {
          // Trigger the click event on the button
          closefromemailnextpopup.current.click();
        }
        if (newpop3.current) {
          // Show the modal using Bootstrap's modal API
          const modal = new window.bootstrap.Modal(newpop3.current);
          modal.show();
        }
        setLoading(false);
      } else {
        setError(response?.data?.message);
        setLoading(false);
      }
    } catch (err) {
      if (err.response?.data?.data) {
        setError(err.response?.data?.data);
        setLoading(false);
        setMobileNumner("");
      }
    }
  }
};

  const handleRetriveAccess = async (e) => {
    // console.log('hf1');
    e.preventDefault();
    if (!validateForm1()) {
      // If the form is not valid, don't submit
      return;
    }
    sendotpCode();
  };
  const handleResendOTPRetriveAccess = async (e) => {
    sendotpCode();
  };
  const validateFormmatchotp = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.otp) {
      newErrors.otp = "OTP is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const Mobileotpmatch = async () => {
    // console.log('extra email:',sendmailemail);
    setLoading(true);
    const collectM = {
      code: formData.vendor_code,
      mobile: formData.country + "" + formData.mobile,
      otp: formData.otp,
    };
    // console.log('mobile otp verfiy:',collectM)
    setnextpopupotpmatchvalue(collectM);
    try {
      const response = await axios.post(`${apiUrl}/verify-otp`, collectM);

      if (response.data.success === true) {
        setopenotppopup(false);
        setopenretievepopup(false);
        setotpmessage(response.data.message);
        if (matchotp.current) {
          // Trigger the click event on the button
          matchotp.current.click();
        }

        if(sendmailemail !== '')
        {
          // sendmailsubmit();
          sendmailsubmit(collectM.code,collectM.mobile,collectM.otp);
          // if (emailmsg.current) {
          //   // Show the modal using Bootstrap's modal API
          //   const modal = new window.bootstrap.Modal(emailmsg.current);
          //   modal.show();
          // }
          setLoading(false);
        }else{
            if (matchotps.current) {
              // Show the modal using Bootstrap's modal API
              const modal = new window.bootstrap.Modal(matchotps.current);
              modal.show();
            }
            setLoading(false);
        }

        // if (matchotps.current) {
        //   // Show the modal using Bootstrap's modal API
        //   const modal = new window.bootstrap.Modal(matchotps.current);
        //   modal.show();
        // }
        // setLoading(false);
      }
      //  else {
      //   console.log('er:',response?.data?.data);
      //   seterror_otp_match(response?.data?.data);
      //   setLoading(false);
      // }
    } catch (err) {
      if (err.response?.data?.data) {
        seterror_otp_match(err.response?.data?.data);
        setLoading(false);
        setotpmessage("");
      }
    }
  };
  const handleRetriveAccessMatchotp = async (e) => {
    e.preventDefault();
    if (!validateFormmatchotp()) {
      // If the form is not valid, don't submit
      return;
    }
    Mobileotpmatch();
  };
  const handleBlur = () => {
    matchVendorCode();
  };

  const matchVendorCode = async () => {
    // console.log('cvcv');
    // console.log('vc1:',exitsvendor_code);
    setLoading(true);
    if(exitsvendor_code === ''){
      setError('Vendor Code is required');
       return;   
    }else{
    const collect = {
      code: exitsvendor_code,
    };
    try {
      const response = await axios.post(
        `${apiUrl}/verify-vendor-code`,
        collect
      );

      if (response.data.success === true) {
        // Assuming response.data is an object containing profile data
        setMobileNumner(response?.data?.data?.mobile);
        // console.log('mob:',response?.data?.data?.mobile);
        setLoading(false);
        setError("");
      }
    } catch (err) {
      if (err.response?.data?.data) {
        setError(err.response?.data?.data);
        setLoading(false);
        setMobileNumner("");
      }
    }
     }
  };
  const handleExistVendorChange = (value) => {
    // console.log('vendor code:',value);
    setvendor_code(value);
    // setError('');
  };

  const [selectedValue, setSelectedValue] = useState('mobile');
  const handleRadioChange = (event) => {
    setSelectedValue(event);
  };
  const [signupselectedValue, setSignupSelectedValue] = useState('4');
  const handleSignupRadioChange = (event) => {
    setSignupSelectedValue(event);

  };
  const ref_fun = ()=>{
    if(location.pathname.includes("reset-password")){
      setTimeout(() => {
        // window.location.reload()
  
        // nvg('/')
        const anchor = document.createElement('a');
        anchor.href = '/';
        anchor.target = '_blank';
        anchor.rel = 'noopener noreferrer';
        
        // Simulate a click event on the anchor element
        anchor.click();
        window.history.back();
      }, 900);
   
    } 
  }
  const [allerrors, setallerror] = useState("");
  const [customer_type, setcustomer_type] = useState("4");
  const [error_customer_type, seterror_customer_type] = useState("");
  const [signupname, setsignupname] = useState("");

  const [signupemail, setsignupemail] = useState("");
  const [signupphone, setsignupphone] = useState("");
  const [signupaddress, setsignupaddress] = useState("");
  const [signupcity, setsignupcity] = useState("");
  const [signupcountry, setsignupcountry] = useState("");
  const [signuplandmark, setsignuplandmark] = useState("");
  const [signuppassword, setsignuppassword] = useState("");
  const [signupcon_password, setsignupcon_password] = useState("");
  const [errorsignupcon_password, seterrorsignupcon_password] = useState("");

  const [signupbusiness_card, setsignupbusiness_card] = useState("");
  const [errorsignupbusiness_card, seterrorsignupbusiness_card] = useState("");
  const [base64Image, setBase64Image] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
      };

      reader.readAsDataURL(file);
    }
  };
 
  const signupsubmit = async () => {
    // console.log('img:',base64Image);
    if (customer_type !== "" && signupcon_password !== "") {
      //  console.log('type:',customer_type,signupname);
     
    
      setLoading(true);
      try {
        setLoading(true);
        const formdata = {
          user_role: customer_type,
          name: signupname,
          email: signupemail,
          address: signupaddress,
          city: signupcity,
          country: signupcountry,
          password: signuppassword,
          phone_number: signupphone,
          landmark: signuplandmark,
          business_card:base64Image ? base64Image : 'null',

        };
        const response = await axios.post(`${apiUrl}/register`, formdata, {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        if (response.data.success === true) {
          // setsuccess(true);
          setsuccess(true);
          setLoading(true);
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          setTimeout(() => window.location.reload(true), 2000);
          // setLoading(false);
        }
      } catch (error) {
        setallerror(error.response.data.data);
        // console.log('name erro:',error.response.data.data.name[0]);
        setLoading(false);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      if (customer_type == "") {
        seterror_customer_type("select customer type is Required");
      } else {
        seterror_customer_type("");
      }

      if (signuppassword !== signupcon_password) {
        seterrorsignupcon_password(
          "Password & Confirm Password does not match."
        );
      } else {
        seterrorsignupcon_password("");
      }

      if (signupbusiness_card == '') {
        seterrorsignupbusiness_card(
          "Please upload business card image."
        );
      } else {
        seterrorsignupbusiness_card("");
      }
    }
  };

  const sendmailsubmit = async (code,mobile,otp) => {
    // console.log('hi msg');
    // console.log('all d:',nextpopupotpmatchvalue);
    if (sendmailemail !== "") {
      try {
        setLoading(true);
        const formdata = {
          code: code,
          email: sendmailemail,
          mobile: mobile,
          otp: otp,
        };
        const response = await axios.post(
          `${apiUrl}/send-forget-password-email`,
          formdata,
          {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          setopenotppopup(false);
          setopenretievepopup(false);
          setmatchotpretievepopup(false);
          setotpmessage(response.data.message);
          if (verifyotp.current) {
            // Trigger the click event on the button
            verifyotp.current.click();
          }
          if (emailmsg.current) {
            // Show the modal using Bootstrap's modal API
            const modal = new window.bootstrap.Modal(emailmsg.current);
            modal.show();
          }
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      if (sendmailemail == "") {
        seterrorsendmailemail("Email is Required");
      } else {
        seterrorsendmailemail("");
      }
    }
  };

  // const handleRetriveAccessPopup = () => {
  //   if (newpop.current) {
  //     // Show the modal using Bootstrap's modal API
  //     const modal = new window.bootstrap.Modal(newpop.current);
  //     modal.show();
  //   }
  // }

  const closelastpopups = () => {
    window.location.href = "/";
  }

  // const handleRetriveAccess = async (e) => {
  //   // console.log('hf1');
  //   e.preventDefault();
  //   if (!validateForm1()) {
  //     // If the form is not valid, don't submit
  //     return;
  //   }
  //   sendotpCode();
  // };
  const handleEmailRetriveAccess = async (e) => {
    // console.log('jdsf');
      e.preventDefault();
    if (!validateForm1()) {
      // If the form is not valid, don't submit
      return;
    }else{
      if (closeretriveaccess.current) {
        // Trigger the click event on the button
        closeretriveaccess.current.click();
      }
      if (newpop.current) {
        // Trigger the click event on the button
        newpop.current.click();
      }
      if (fromemailnextpopup.current) {
        // Show the modal using Bootstrap's modal API
        const modal = new window.bootstrap.Modal(fromemailnextpopup.current);
        modal.show();
      }

    }
   
   
  }
  return (
    <header id="header">
      {loading ? <Loader /> : null}
      <ToastContainer />
      <div className="main-header px-xl-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-sm-12">
              <div id="desktop-menu">
                {/* navbar-expand-lg */}
                <nav className="navbar justify-content-center navbar-expand-lg navbar-light">
                  <span
                    className="navbar-brand logo"
                    onClick={() => {
                      nvg("/");
                    }}
                  >
                    {tokendata ? (
                      <img onClick={()=>{ref_fun()}}
                        src={settingdatas.logo}
                        alt={settingdatas.website_name}
                      />
                    ) : (
                      <img onClick={()=>{ref_fun()}}
                        src="https://sbmmarketplace.com/backend/public/uploads/2021/06/logo.png"
                        alt="Vaultex"
                      />
                    )}
                  </span>
                  <div className="search-with-cat search-width custozsearch hideinmobile">
                    <form onSubmit={handleSearchSubmit}>
                      <div className="inner-form">
                        <div className="input-field first-wrap search-container">
                          <input
                            id="search"
                            type="text"
                            placeholder="Search for Products"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className={SearchError ? "error" : ""}
                          />
                        </div>
                        <div className="input-field second-wrap">
                          <button className="btn-search" type="submit">
                            <i className="fa fa-search" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="header-cart">
                    <NavLink to="/cart" style={{ textWrap: "nowrap" }}>
                      <img onClick={()=>{ref_fun()}}
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/cart.png`}
                        alt="cart icon"
                      />
                      {tokendata ? (
                        <span className="cart-count">
                          {cartitemscount && cartitemscount.length
                            ? cartitemscount.length
                            : "0"}
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="cart-heading" onClick={()=>{ref_fun()}}>Cart </span>
                    </NavLink>
                  </div>
                  {tokendata ? (
                    <div className="header-right">
                      <Vtheaderright />
                    </div>
                  ) : (
                    <div
                    // data-bs-toggle="modal"
                    // data-bs-target="#staticBackdrop"
                    >
                      <div className="header-right">
                        <div className="user-login">
                          <a href="javascript:void(0);">
                            <i className="fa fa-user" aria-hidden="true" />
                            <span>Login</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <Login/> */}

                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarToggleExternalContent"
                    aria-controls="navbarToggleExternalContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  {/* </vtheaderright> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* small screen navbar start here  */}
      <div
        className="collapse"
        id="navbarToggleExternalContent"
        data-bs-theme="white"
      >
        <div className="p-3" style={{ backgroundColor: "#059fe2" }}>
          <ul>
            <li className="nav-item"> </li>
            <li className="nav-item active">
              <NavLink
                style={{ display: "flex", color: "white", padding: "5px 0px" }}
                to="/"
                onClick={()=>{ref_fun()}}
              >
                {" "}
                Home{" "}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                style={{ display: "flex", color: "white", padding: "5px 0px" }}
                to="/best-seller"
                onClick={()=>{ref_fun()}}
              >
                {" "}
                Best Sellers{" "}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                style={{ display: "flex", color: "white", padding: "5px 0px" }}
                to="/new-products"
                onClick={()=>{ref_fun()}}
              >
                {" "}
                New Products{" "}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                style={{ display: "flex", color: "white", padding: "5px 0px" }}
                to="/bulk-order"
                onClick={()=>{ref_fun()}}
              >
                {" "}
                Bulk Order{" "}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                style={{ display: "flex", color: "white", padding: "5px 0px" }}
                to="/brand"
                onClick={()=>{ref_fun()}}
              >
                {" "}
                Our Brand{" "}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                style={{ display: "flex", color: "white", padding: "5px 0px" }}
                to="/offers"
                onClick={()=>{ref_fun()}}
              >
                {" "}
                Offers{" "}
              </NavLink>
            </li>
            {/* <li className="nav-item">
            <NavLink to="/shop" >Today's Offers</NavLink>
          </li> */}
            <li className="nav-item">
              <a
                href="http://flip.inventmedia.info/17-2/"
                style={{ display: "flex", color: "white", padding: "5px 0px" }}
                target="_blank"
              >
                {" "}
                E-Catalogue
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* small screen navbar end here  */}
      {/* small search bar start here */}
      <div className="search-with-cat search-width py-2 custozsearch2 showinmobile">
        <form method="POST">
          <div className="inner-form">
            <div className="input-field first-wrap d-flex">
              <input
                id="search"
                type="text"
                className="searchwidth"
                placeholder="Search for Products"
              />
              <div className="input-field second-wrap searchbtnwidth">
                <button className="btn-search" type="submit">
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* small search bar end here */}

      {/* large screen navbar start here  */}
      <div className="bottom-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="site-navigation">
                <ul>
                  <li className="nav-item"> </li>
                  <li className="nav-item active">
                    <NavLink to="/" onClick={()=>{ref_fun()}}> Home </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/best-seller" onClick={()=>{ref_fun()}}> Best Sellers </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/new-products" onClick={()=>{ref_fun()}}> New Products </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/bulk-order" onClick={()=>{ref_fun()}}> Bulk Order </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/brand" onClick={()=>{ref_fun()}}> Our Brand </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/offers" onClick={()=>{ref_fun()}}> Offers </NavLink>
                  </li>
                  <li className="nav-item">
                    <a
                      href="http://flip.inventmedia.info/17-2/"
                      target="_blank"
                    >
                      {" "}
                      E-Catalogue
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* start login popup model  1*/}

      <div
        className="modal fade"
        id="staticBackdrop"
        ref={loginpopRef}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ outline: "none", background: "none" }}
      >
        <div className="modal-dialog newwidth">
          <div className="modal-content" style={{ overflow: "hidden" }}>
            <div className="modal-header" style={{ padding: "8px" }}>
              {/* <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5> */}
              <button
                type="button"
                className="btn-close remove-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  background: " #059fe2",
                  color: "#fff",
                  borderRadius: "0px",
                  fontSize: "20px",
                }}
              >
                {" "}
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <form onSubmit={handleLogin}>
              <div className="modal-body">
                <h5 className="sign-text  text-center mt-1 pb-3">Sign In</h5>
                
                {botherror == "" ? (
                  ""
                ) : (
                  <div className="form-group form-row d-flex justify-content-center">
                  <div class="col-10">
                  <div class=" alert alert-danger mx-0" style={{width:'100%',textAlign:'center'}} role="alert">
                    Validation Error.
                  </div>
                  </div>
                  </div>
                )} 
                {error && 
                  <div className="form-group form-row d-flex justify-content-center">
                  <div class="col-10">
                  <div class=" alert alert-danger mx-0" style={{width:'100%',textAlign:'center'}} role="alert">
                   {error}
                  </div>
                  </div>
                  </div>
                } 

{/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                <div className="form-group form-row d-flex justify-content-center">
                  <input
                   style={{borderBottom:erroremail !== '' ? '1px solid red' : ''}}
                  //  style={{borderBottom:errors ? errors.email ? '1px solid red' : '' : ''}}
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                    name="email"
                    placeholder="Email address or Vendor code*"
                    className="input-areabutton"
                  />
                  {/* {erroremail !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''} */}
                </div>

                <div className="form-group form-row d-flex justify-content-center mt-3">
                  <input
                   style={{borderBottom:errorpassword !== '' ? '1px solid red' : ''}}
                  //  style={{borderBottom:errors ? errors.Password ? '1px solid red' : '' : ''}}
                    id="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                    type={changepss == false ? "password" : "text"}
                    name="password"
                    placeholder="Password*"
                    className="input-areabutton"
                  />
                  <span
                    className="icon is-small is-right"
                    onClick={() => {
                      setchangepss(!changepss);
                    }}
                  >
                    {changepss == false ? (
                      // <i className="fa fa-eye-slash"></i>
                      <div style={{position: 'absolute',
                        right: '72px',
                        bottom: '26px',
                        fontSize: '17px'}}><AiOutlineEyeInvisible /></div> 
                      
                    ) : (
                      <i className="fa fa-solid fa-eye"></i>
                    )}
                  </span>
                  {/* {errorpassword !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''} */}
                </div>
              </div>
              {/* <center>
                {" "}
                {error && <p style={{ color: "red" }}>{error}</p>}
              </center> */}
              <div className="form-group form-row d-flex justify-content-center mt-2 mb-3">
                <button
                  type="submit"
                  className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2"
                >
                  Continue
                </button>
              </div>
            </form>
            <div className="col-md-12 col-12">
              <div
                className="row px-2"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="forgot text-left col-6 mb-3">
                  <a
                    href="javascript:void(0);"
                    className="active"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop2"
                    onClick={() => ManageRetriveAndForgot('retrive_access')}
                  >
                    Retrive Access
                  </a>
                </div>
                <div className="forgot1 text-right col-6 mb-3">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop2"
                    // data-bs-toggle="modal"
                    // data-bs-target="#staticBackdrop3"
                    onClick={() => ManageRetriveAndForgot('forgot_password')}
                  >
                    Forgot Password ?
                  </a>
                </div>
              </div>
            </div>

            <div className="signin-footer pt-3 pb-3 mb-3">
              <div className="signup-text text-center">
                <h6>
                  Don't have an account ?
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalSignup"
                    href="javascript:void(0)"
                    className="active"
                  >
                    &nbsp;SIGN UP NOW
                  </a>
                </h6>
                <span>
                  By signing up you agree to our{" "}
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      nvg("/Terms-Conditions");
                    }}
                  >
                    Terms of use
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End login popup model */}

      {/* start Retrive Access popup model 2*/}
      {/* {openretievepopup && ( */}
        <div
          className="modal fade"
          ref={newpop}
          id="staticBackdrop2"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ outline: "none", background: "none", overflow: "auto" }}
        >
          <div className="modal-dialog newwidth">
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: "none" }}>
                <button
                ref={closeretriveaccess}
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <form>
                <div className="row">
                  <div className="col-lg-1"></div>

                  {/* <div className="col-lg-10">
                    {mobilenumber && (
                      <center>
                        <div className="alert alert-success">
                          Use&nbsp;{formatMobileNumber(mobilenumber)}
                          &nbsp;mobile number.
                        </div>
                      </center>
                    )}
                    {error && (
                      <center>
                        {" "}
                        <div
                          className="alert alert-error"
                          style={{ fontSize: "14px" }}
                        >
                          {error}
                        </div>
                      </center>
                    )}
                  </div> */}
                  <div className="col-lg-1"></div>
                  
                </div>
                <div className="modal-body">
                  <h5 className="sign-text  text-center mt-1 pb-4">
                  {RetriveAndForgot == 'retrive_access' ? 'Access Request' : 'Forgot Password'}
                    {/* Access Request */}
                  </h5>
                    <div className="col-lg-10" style={{marginLeft:'38px'}}>
                    {mobilenumber && (
                      <center>
                        <div className="alert alert-success">
                          Use&nbsp;{formatMobileNumber(mobilenumber)}
                          &nbsp;mobile number.
                        </div>
                      </center>
                    )}
                    {error && (
                      <center>
                        {" "}
                        <div
                          className="alert alert-error"
                          style={{ fontSize: "14px" }}
                        >
                          {error}
                        </div>
                      </center>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group form-row redbutton">
                      {selectedValue === "mobile" ? (    
                        <div className="" style={{ marginLeft: "12px" }}>
                          <div className="form-check form-check-inline">

                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="mobile"
                              style={{
                                position: "relative",
                                // top: "74px",
                                fontSize:'14px',
                                marginBottom: "0px!important",
                              }}
                              onClick={(e) =>
                                handleRadioChange(e.target.value)
                              }
                              // onChange={(e) => {
                              //   // handleRadioChange(e.target.value);
                              //   handleChange(e);
                              // }}
                              checked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Mobile
                            </label>
                          </div>
                        </div>
                      ):(
<div className="" style={{ marginLeft: "12px" }}>
                          <div className="form-check form-check-inline">

                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="mobile"
                              style={{
                                position: "relative",
                                // top: "131px",
                                marginBottom: "0px!important",
                              }}
                              onClick={(e) =>
                                handleRadioChange(e.target.value)
                              }
                              onChange={(e) => {
                                
                                setMobileNumner('');
                              }}
                              
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Mobile
                            </label>
                          </div>
                        </div>

                      )}
                        <div className="" style={{}}>
                          <center>OR</center>
                        </div>

                        <div className="" style={{}}>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="email"
                              style={{ position: "relative",fontSize:'14px' }}
                              onClick={(e) =>
                                handleRadioChange(e.target.value)
                              }
                              onChange={(e) => {
                                
                                setMobileNumner('');
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                      </div>
                      <center>
                        {/* {errors.inlineRadioOptions && (
                          <div className="error">
                            {errors.inlineRadioOptions}
                          </div>
                        )} */}
                      </center>
                      <div className="col-lg-12">
                        <div
                          className="form-group form-row d-flex justify-content-center mt-3"
                          style={{ marginBottom: "0px!important" }}
                        >
                          <input
                          style={{borderBottom:errors ? errors.vendor_code ? '1px solid red' : '' : ''}}
                            id="input"
                            type="text"
                            name="vendor_code"
                            placeholder="Enter Vendor Code*"
                            className="input-areabutton"
                            defaultValue={defaultValueCode}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleExistVendorChange(e.target.value);
                              handleChange(e);
                            }}
                          />
                        </div>
                        {/* <center>
                          {errors.vendor_code && (
                            <div className="error">{errors.vendor_code}</div>
                          )}
                        </center> */}
                      </div>
                      {selectedValue === "email" ? (
                        <div className="col-lg-12">
                          <div
                            className="form-group form-row d-flex justify-content-center mt-3"
                            style={{ marginBottom: "0px!important" }}
                          >
                            <input
                            style={{borderBottom:errors ? errors.email ? '1px solid red' : '' : ''}}
                              type="email"
                              name="email"
                              placeholder="Enter Email*"
                              className="input-areabutton"
                              // value={formData.email}
                              // onChange={handleChange}
                              value={sendmailemail}
                              onChange={(e) => {
                                setsendmailemail(e.target.value);
                              }}
                            />
                          </div>
                          {/* <center>
                            {errors.email && (
                              <div className="error">{errors.email}</div>
                            )}
                          </center> */}
                        </div>
                      ) : (
                        <div className="col-lg-12">
                          <div
                            className="form-group form-row d-flex justify-content-center mt-3"
                            style={{ marginBottom: "0px!important" }}
                          >
                            <select
                              name="country"
                              className="form-select"
                              aria-label="Default select example"
                              style={{ width: "80%" }}
                              value={formData.country}
                              onChange={handleChange}
                            >
                              <option value="">Select Country Code</option>
                              <option value="+971">
                                United Arab Emirates (+971){" "}
                              </option>
                              <option value="+7840">Abkhazia (+7840) </option>
                              <option value="+93">Afghanistan (+93) </option>
                              <option value="+355">Albania (+355) </option>
                              <option value="+213">Algeria (+213) </option>
                              <option value="+1684">
                                American Samoa (+1684){" "}
                              </option>
                              <option value="+376">Andorra (+376) </option>
                              <option value="+244">Angola (+244) </option>
                              <option value="+1264">Anguilla (+1264) </option>
                              <option value="+1268">
                                Antigua and Barbuda (+1268){" "}
                              </option>
                              <option value="+54">Argentina (+54) </option>
                              <option value="+374">Armenia (+374) </option>
                              <option value="+297">Aruba (+297) </option>
                              <option value="+247">Ascension (+247) </option>
                              <option value="+61">Australia (+61) </option>
                              <option value="+672">
                                Australian External Territories (+672){" "}
                              </option>
                              <option value="+43">Austria (+43) </option>
                              <option value="+994">Azerbaijan (+994) </option>
                              <option value="+1242">Bahamas (+1242) </option>
                              <option value="+973">Bahrain (+973) </option>
                              <option value="+880">Bangladesh (+880) </option>
                              <option value="+1246">Barbados (+1246) </option>
                              <option value="+1268">Barbuda (+1268) </option>
                              <option value="+375">Belarus (+375) </option>
                              <option value="+32">Belgium (+32) </option>
                              <option value="+501">Belize (+501) </option>
                              <option value="+229">Benin (+229) </option>
                              <option value="+1441">Bermuda (+1441) </option>
                              <option value="+975">Bhutan (+975) </option>
                              <option value="+591">Bolivia (+591) </option>
                              <option value="+387">
                                Bosnia and Herzegovina (+387){" "}
                              </option>
                              <option value="+267">Botswana (+267) </option>
                              <option value="+55">Brazil (+55) </option>
                              <option value="+246">
                                British Indian Ocean Territory (+246){" "}
                              </option>
                              <option value="+1284">
                                British Virgin Islands (+1284){" "}
                              </option>
                              <option value="+673">Brunei (+673) </option>
                              <option value="+359">Bulgaria (+359) </option>
                              <option value="+226">Burkina Faso (+226) </option>
                              <option value="+257">Burundi (+257) </option>
                              <option value="+855">Cambodia (+855) </option>
                              <option value="+237">Cameroon (+237) </option>
                              <option value="+1">Canada (+1) </option>
                              <option value="+238">Cape Verde (+238) </option>
                              <option value="+345">
                                Cayman Islands (+345){" "}
                              </option>
                              <option value="+236">
                                Central African Republic (+236){" "}
                              </option>
                              <option value="+235">Chad (+235) </option>
                              <option value="+56">Chile (+56) </option>
                              <option value="+86">China (+86) </option>
                              <option value="+61">
                                Christmas Island (+61){" "}
                              </option>
                              <option value="+61">
                                Cocos-Keeling Islands (+61){" "}
                              </option>
                              <option value="+57">Colombia (+57) </option>
                              <option value="+269">Comoros (+269) </option>
                              <option value="+242">Congo (+242) </option>
                              <option value="+243">
                                Congo, Dem. Rep. of (Zaire) (+243){" "}
                              </option>
                              <option value="+682">Cook Islands (+682) </option>
                              <option value="+506">Costa Rica (+506) </option>
                              <option value="+385">Croatia (+385) </option>
                              <option value="+53">Cuba (+53) </option>
                              <option value="+599">Curacao (+599) </option>
                              <option value="+537">Cyprus (+537) </option>
                              <option value="+420">
                                Czech Republic (+420){" "}
                              </option>
                              <option value="+45">Denmark (+45) </option>
                              <option value="+246">Diego Garcia (+246) </option>
                              <option value="+253">Djibouti (+253) </option>
                              <option value="+1767">Dominica (+1767) </option>
                              <option value="+1809">
                                Dominican Republic (+1809){" "}
                              </option>
                              <option value="+670">East Timor (+670) </option>
                              <option value="+56">Easter Island (+56) </option>
                              <option value="+593">Ecuador (+593) </option>
                              <option value="+20">Egypt (+20) </option>
                              <option value="+503">El Salvador (+503) </option>
                              <option value="+240">
                                Equatorial Guinea (+240){" "}
                              </option>
                              <option value="+291">Eritrea (+291) </option>
                              <option value="+372">Estonia (+372) </option>
                              <option value="+251">Ethiopia (+251) </option>
                              <option value="+500">
                                Falkland Islands (+500){" "}
                              </option>
                              <option value="+298">
                                Faroe Islands (+298){" "}
                              </option>
                              <option value="+679">Fiji (+679) </option>
                              <option value="+358">Finland (+358) </option>
                              <option value="+33">France (+33) </option>
                              <option value="+596">
                                French Antilles (+596){" "}
                              </option>
                              <option value="+594">
                                French Guiana (+594){" "}
                              </option>
                              <option value="+689">
                                French Polynesia (+689){" "}
                              </option>
                              <option value="+241">Gabon (+241) </option>
                              <option value="+220">Gambia (+220) </option>
                              <option value="+995">Georgia (+995) </option>
                              <option value="+49">Germany (+49) </option>
                              <option value="+233">Ghana (+233) </option>
                              <option value="+350">Gibraltar (+350) </option>
                              <option value="+30">Greece (+30) </option>
                              <option value="+299">Greenland (+299) </option>
                              <option value="+1473">Grenada (+1473) </option>
                              <option value="+590">Guadeloupe (+590) </option>
                              <option value="+1671">Guam (+1671) </option>
                              <option value="+502">Guatemala (+502) </option>
                              <option value="+224">Guinea (+224) </option>
                              <option value="+245">
                                Guinea-Bissau (+245){" "}
                              </option>
                              <option value="+595">Guyana (+595) </option>
                              <option value="+509">Haiti (+509) </option>
                              <option value="+504">Honduras (+504) </option>
                              <option value="+852">
                                Hong Kong SAR China (+852){" "}
                              </option>
                              <option value="+36">Hungary (+36) </option>
                              <option value="+354">Iceland (+354) </option>
                              <option value="+91">India (+91) </option>
                              <option value="+62">Indonesia (+62) </option>
                              <option value="+98">Iran (+98) </option>
                              <option value="+964">Iraq (+964) </option>
                              <option value="+353">Ireland (+353) </option>
                              <option value="+972">Israel (+972) </option>
                              <option value="+39">Italy (+39) </option>
                              <option value="+225">Ivory Coast (+225) </option>
                              <option value="+1876">Jamaica (+1876) </option>
                              <option value="+81">Japan (+81) </option>
                              <option value="+962">Jordan (+962) </option>
                              <option value="+77">Kazakhstan (+77) </option>
                              <option value="+254">Kenya (+254) </option>
                              <option value="+686">Kiribati (+686) </option>
                              <option value="+965">Kuwait (+965) </option>
                              <option value="+996">Kyrgyzstan (+996) </option>
                              <option value="+856">Laos (+856) </option>
                              <option value="+371">Latvia (+371) </option>
                              <option value="+961">Lebanon (+961) </option>
                              <option value="+266">Lesotho (+266) </option>
                              <option value="+231">Liberia (+231) </option>
                              <option value="+218">Libya (+218) </option>
                              <option value="+423">
                                Liechtenstein (+423){" "}
                              </option>
                              <option value="+370">Lithuania (+370) </option>
                              <option value="+352">Luxembourg (+352) </option>
                              <option value="+853">
                                Macau SAR China (+853){" "}
                              </option>
                              <option value="+389">Macedonia (+389) </option>
                              <option value="+261">Madagascar (+261) </option>
                              <option value="+265">Malawi (+265) </option>
                              <option value="+60">Malaysia (+60) </option>
                              <option value="+960">Maldives (+960) </option>
                              <option value="+223">Mali (+223) </option>
                              <option value="+356">Malta (+356) </option>
                              <option value="+692">
                                Marshall Islands (+692){" "}
                              </option>
                              <option value="+596">Martinique (+596) </option>
                              <option value="+222">Mauritania (+222) </option>
                              <option value="+230">Mauritius (+230) </option>
                              <option value="+262">Mayotte (+262) </option>
                              <option value="+52">Mexico (+52) </option>
                              <option value="+691">Micronesia (+691) </option>
                              <option value="+1808">
                                Midway Island (+1808){" "}
                              </option>
                              <option value="+373">Moldova (+373) </option>
                              <option value="+377">Monaco (+377) </option>
                              <option value="+976">Mongolia (+976) </option>
                              <option value="+382">Montenegro (+382) </option>
                              <option value="+1664">Montserrat (+1664) </option>
                              <option value="+212">Morocco (+212) </option>
                              <option value="+95">Myanmar (+95) </option>
                              <option value="+264">Namibia (+264) </option>
                              <option value="+674">Nauru (+674) </option>
                              <option value="+977">Nepal (+977) </option>
                              <option value="+31">Netherlands (+31) </option>
                              <option value="+599">
                                Netherlands Antilles (+599){" "}
                              </option>
                              <option value="+1869">Nevis (+1869) </option>
                              <option value="+687">
                                New Caledonia (+687){" "}
                              </option>
                              <option value="+64">New Zealand (+64) </option>
                              <option value="+505">Nicaragua (+505) </option>
                              <option value="+227">Niger (+227) </option>
                              <option value="+234">Nigeria (+234) </option>
                              <option value="+683">Niue (+683) </option>
                              <option value="+672">
                                Norfolk Island (+672){" "}
                              </option>
                              <option value="+850">North Korea (+850) </option>
                              <option value="+1670">
                                Northern Mariana Islands (+1670){" "}
                              </option>
                              <option value="+47">Norway (+47) </option>
                              <option value="+968">Oman (+968) </option>
                              <option value="+92">Pakistan (+92) </option>
                              <option value="+680">Palau (+680) </option>
                              <option value="+970">
                                Palestinian Territory (+970){" "}
                              </option>
                              <option value="+507">Panama (+507) </option>
                              <option value="+675">
                                Papua New Guinea (+675){" "}
                              </option>
                              <option value="+595">Paraguay (+595) </option>
                              <option value="+51">Peru (+51) </option>
                              <option value="+63">Philippines (+63) </option>
                              <option value="+48">Poland (+48) </option>
                              <option value="+351">Portugal (+351) </option>
                              <option value="+1787">
                                Puerto Rico (+1787){" "}
                              </option>
                              <option value="+974">Qatar (+974) </option>
                              <option value="+262">Reunion (+262) </option>
                              <option value="+40">Romania (+40) </option>
                              <option value="+7">Russia (+7) </option>
                              <option value="+250">Rwanda (+250) </option>
                              <option value="+685">Samoa (+685) </option>
                              <option value="+378">San Marino (+378) </option>
                              <option value="+966">Saudi Arabia (+966) </option>
                              <option value="+221">Senegal (+221) </option>
                              <option value="+381">Serbia (+381) </option>
                              <option value="+248">Seychelles (+248) </option>
                              <option value="+232">Sierra Leone (+232) </option>
                              <option value="+65">Singapore (+65) </option>
                              <option value="+421">Slovakia (+421) </option>
                              <option value="+386">Slovenia (+386) </option>
                              <option value="+677">
                                Solomon Islands (+677){" "}
                              </option>
                              <option value="+27">South Africa (+27) </option>
                              <option value="+500">
                                South Georgia and the South Sandwich Islands
                                (+500){" "}
                              </option>
                              <option value="+82">South Korea (+82) </option>
                              <option value="+34">Spain (+34) </option>
                              <option value="+94">Sri Lanka (+94) </option>
                              <option value="+249">Sudan (+249) </option>
                              <option value="+597">Suriname (+597) </option>
                              <option value="+268">Swaziland (+268) </option>
                              <option value="+46">Sweden (+46) </option>
                              <option value="+41">Switzerland (+41) </option>
                              <option value="+963">Syria (+963) </option>
                              <option value="+886">Taiwan (+886) </option>
                              <option value="+992">Tajikistan (+992) </option>
                              <option value="+255">Tanzania (+255) </option>
                              <option value="+66">Thailand (+66) </option>
                              <option value="+670">Timor Leste (+670) </option>
                              <option value="+228">Togo (+228) </option>
                              <option value="+690">Tokelau (+690) </option>
                              <option value="+676">Tonga (+676) </option>
                              <option value="+1868">
                                Trinidad and Tobago (+1868){" "}
                              </option>
                              <option value="+216">Tunisia (+216) </option>
                              <option value="+90">Turkey (+90) </option>
                              <option value="+993">Turkmenistan (+993) </option>
                              <option value="+1649">
                                Turks and Caicos Islands (+1649){" "}
                              </option>
                              <option value="+688">Tuvalu (+688) </option>
                              <option value="+1340">
                                U.S. Virgin Islands (+1340){" "}
                              </option>
                              <option value="+256">Uganda (+256) </option>
                              <option value="+380">Ukraine (+380) </option>
                              <option value="+44">United Kingdom (+44) </option>
                              <option value="+1">United States (+1) </option>
                              <option value="+598">Uruguay (+598) </option>
                              <option value="+998">Uzbekistan (+998) </option>
                              <option value="+678">Vanuatu (+678) </option>
                              <option value="+58">Venezuela (+58) </option>
                              <option value="+84">Vietnam (+84) </option>
                              <option value="+1808">
                                Wake Island (+1808){" "}
                              </option>
                              <option value="+681">
                                Wallis and Futuna (+681){" "}
                              </option>
                              <option value="+967">Yemen (+967) </option>
                              <option value="+260">Zambia (+260) </option>
                              <option value="+255">Zanzibar (+255) </option>
                              <option value="+263">Zimbabwe (+263) </option>
                            </select>
                          </div>
                          {/* <center>
                            {errors.country && (
                              <div className="error">{errors.country}</div>
                            )}
                          </center> */}

                          <div
                            className="form-group form-row  d-flex justify-content-center mt-3"
                            style={{ marginBottom: "0px!important" }}
                          >


{/* <div className="col-md-6">
  <label htmlFor="validationServer03" className="form-label">City</label>
  <input type="text" className="form-control is-invalid" id="validationServer03" aria-describedby="validationServer03Feedback" required />
  <div id="validationServer03Feedback" className="invalid-feedback">
    Please provide a valid city.
  </div>
</div> */}

                            <input
                            style={{borderBottom:errors ? errors.mobile ? '1px solid red' : '' : ''}}
                              type="number"
                              name="mobile"
                              
                              placeholder="Enter Mobile*"
                              
                              className="input-areabutton"
                              value={formData.mobile}
                              onChange={handleChange}
                              
                            />
                          </div>

                         
                          {/* <center>
                            
                            {errors.mobile &&  (
                              <div className="error">{errors.mobile}</div>
                            )} 
                          </center> */}
                        </div>
                      )}

                      <br />
                    </div>

                    <div className="form-group form-row d-flex justify-content-center mt-2 mb-4">
                      <button
                        type="button"
                        onClick={selectedValue == 'mobile' ? handleRetriveAccess : handleEmailRetriveAccess}
                        className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2"
                      >
                       {selectedValue == 'mobile' ? 'SEND OTP' : 'NEXT'}
                      
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      {/* // )} */}
      {/* End Retrive Access popup model */}

      {/* start match otp popup 3*/}
      <div
        id="staticBackdrop8"
        className={openotppopup == true ? `modal fade show` : `modal fade`}
        ref={newpop3}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel8"
        aria-hidden="true"
        style={{ outline: "none", background: "none", overflow: "auto" }}
      >
        <div className="modal-dialog newwidth">
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "none" }}>
              {/* <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5> */}
              <button
                onClick={() => {
                  closepopups();
                }}
                type="button"
                className="btn-close remove-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={matchotp}
                style={{
                  background: " #059fe2",
                  color: "#fff",
                  borderRadius: "0px",
                  fontSize: "20px",
                }}
              >
                {" "}
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <form onSubmit={handleRetriveAccessMatchotp}>
              <div className="row">
                <div className="col-lg-1"></div>

                <div className="col-lg-10">
                  {otpmessage && (
                    <center>
                      <div className="alert alert-success">{otpmessage}</div>
                    </center>
                  )}
                  {error_otp_match && (
                    <center>
                      {" "}
                      <div
                        className="alert alert-error"
                        style={{ fontSize: "14px" }}
                      >
                        {error_otp_match}
                      </div>
                    </center>
                  )}
                </div>
                <div className="col-lg-1"></div>
              </div>
              <div className="modal-body">
                <h5 className="sign-text  text-center mt-1 pb-4">
                {RetriveAndForgot == 'retrive_access' ? 'Access Request' : 'Forgot Password'}
                </h5>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="col-lg-12">
                      <div
                        className="form-group form-row d-flex justify-content-center mt-3"
                        style={{ marginBottom: "0px!important" }}
                      >
                        <input
                        
                          type="number"
                          name="otp"
                          placeholder="Enter OTP*"
                          className="input-areabutton"
                          value={formData.otp}
                          onChange={handleChange}
                          style={{
                            backgroundColor: "rgb(232, 240, 254)",
                            border: "1px solid #ccc",
                            ...(errors.otp && { border: errors.otp !== '' ? '1px solid red' : '' })
                          }}
                        />
                      </div>
                      {/* <center>
                        {errors.otp && (
                          <div className="error">{errors.otp}</div>
                        )}
                      </center> */}

                      {secondsRemaining > 0 && (
                      <center>
                        <p style={{color:'red',lineHeight: '21px'}}>
                        OTP will be expired in {secondsRemaining} seconds
                        </p>
                      </center>
                      )}

                    </div>

                    <br />
                  </div>
                
                  <div className="form-group form-row d-flex justify-content-center mt-2 mb-4">
                  {secondsRemaining === 0 ? (
  <button
  type="button"
    onClick={handleResendOTPRetriveAccess}
    className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2 mb-4"
  >
    RESEND OTP
  </button>
) : (
  <button
    type="submit"
    className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2 mb-4"
  >
    {sendmailemail !== '' ? 'Verify OTP And Send Mail' : 'VERIFY OTP'}
  </button>
)}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* end match otp popup */}

      {/* start match otp after enter mail 4*/}
      {matchotpretievepopup && (
        <div
          id="staticBackdrop9"
          className={
            openotppopupafteremail == true ? `modal fade show` : `modal fade`
          }
          ref={matchotps}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel9"
          aria-hidden="true"
          style={{ outline: "none", background: "none", overflow: "auto" }}
        >
          <div className="modal-dialog newwidth">
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: "none" }}>
                {/* <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5> */}
                <button
                  onClick={() => {
                    closepopups();
                  }}
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={verifyotp}
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>

              <div className="row">
                <div className="col-lg-1"></div>

                <div className="col-lg-10">
                  {otpmessage && (
                    <center>
                      <div className="alert alert-success">{otpmessage}</div>
                    </center>
                  )}
                </div>
                <div className="col-lg-1"></div>
              </div>
              <div className="modal-body">
                <h5 className="sign-text  text-center mt-1 pb-4">
                {RetriveAndForgot == 'retrive_access' ? 'Access Request' : 'Forgot Password'}
                </h5>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="col-lg-12">
                      <div
                        className="form-group form-row d-flex justify-content-center mt-1"
                        style={{ marginBottom: "0px!important" }}
                      >
                        <input
                        
                          type="email"
                          name="sendmailemail"
                          placeholder="Enter email*"
                          className="input-areabutton"
                          value={sendmailemail}
                          onChange={(e) => {
                            setsendmailemail(e.target.value);
                          }}
                          style={{
                            backgroundColor: "rgb(232, 240, 254)",
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>

                      <center>
                        {errorsendmailemail == "" ? (
                          ""
                        ) : (
                          <p style={{ color: "red" }}>{errorsendmailemail}</p>
                        )}
                      </center>
                    </div>

                    <br />
                  </div>

                  <div className="form-group form-row d-flex justify-content-center mt-2 mb-4">
                    <button
                      onClick={sendmailsubmit}
                      type="submit"
                      className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2 mb-4"
                    >
                      SEND MAIL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* end match otp after enter mail popup */}

      {/* start send mail popup 5*/}
      <div
        id="staticBackdrop10"
        className={
          openotppopupafteremail == false ? `modal fade show` : `modal fade`
        }
        ref={emailmsg}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel10"
        aria-hidden="true"
        style={{ outline: "none", background: "none", overflow: "auto" }}
      >
        <div className="modal-dialog newwidth">
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "none" }}>
              {/* <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5> */}
              <button
                onClick={() => {
                  closelastpopups();
                }}
                type="button"
                className="btn-close remove-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  background: " #059fe2",
                  color: "#fff",
                  borderRadius: "0px",
                  fontSize: "20px",
                }}
              >
                {" "}
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>

              <div className="col-lg-10"></div>
              <div className="col-lg-1"></div>
            </div>
            <div className="modal-body">
              <h5 className="sign-text  text-center mt-1 pb-4">
              {RetriveAndForgot == 'retrive_access' ? 'Access Request' : 'Forgot Password'}
              </h5>
              <div className="row">
                {/* <div className="col-lg-12">
                  {otpmessage && (
                    <center>
                      <div className="alert alert-success">{otpmessage}</div>
                    </center>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end send mail popup */}

      {/* start signup modal 7*/}
      <div
        className="modal fade"
        id="exampleModalSignup"
        ref={signup}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ outline: "none", background: "none", overflow: "auto" }}
      >
        <div className="modal-dialog modal-dialog-centered popaddresswidth">
          <div className="modal-content" style={{ borderRadius: "0px" }}>
            <div
              className="modal-header"
              style={{ padding: "8px", border: "none" }}
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ background: "#059fe2", borderRadius: "0px" }}
              >
                <span style={{ color: "white" }}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
            <div className="modal-body py-0">
              <div className="row">
                <div className="col-12 pb-3">
                  <h5 className="title451 text-center">Sign Up</h5>
                </div>
              </div>
              <div className="container-fluid" style={{ width: "100%" }}>
                <div className="form-group form-row redbutton">
                  <div className="">
                  
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="customer_type"
                        id="customer_type"
                        // defaultValue="Trading_Customer"
                        style={{ position: "relative" }}
                        value="3"
                        onClick={(e) =>
                          handleSignupRadioChange(e.target.value)
                        }
                        onChange={(e) => {
                          setcustomer_type(e.target.value);
                        }}
                        
                      />
                      <label
                        className="form-check-label"
                        style={{ marginTop: "3px" }}
                      >
                        Trading Customer
                      </label>
                    </div>
                     
                  </div>
                  {signupselectedValue === "4" ? ( 
                  <div className="" style={{}}>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="customer_type"
                        id="customer_type"
                        // defaultValue="Other_Customer"
                        style={{ position: "relative" }}
                        value="4"
                        onClick={(e) =>
                          handleSignupRadioChange(e.target.value)
                        }
                        onChange={(e) => {
                          setcustomer_type(e.target.value);
                        }}
                        checked
                      />
                      <label
                        className="form-check-label"
                        style={{ marginTop: "3px" }}
                      >
                        Other Customer
                      </label>
                    </div>
                  </div>
                   ):(
                    <div className="" style={{}}>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="customer_type"
                        id="customer_type"
                        // defaultValue="Other_Customer"
                        style={{ position: "relative" }}
                        value="4"
                        onClick={(e) =>
                          handleSignupRadioChange(e.target.value)
                        }
                        onChange={(e) => {
                          setcustomer_type(e.target.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        style={{ marginTop: "3px" }}
                      >
                        Other Customer
                      </label>
                    </div>
                  </div>
                   )}
                </div>
                <center>
                  {error_customer_type == "" ? (
                    ""
                  ) : (
                    <p style={{ color: "red" }}>{error_customer_type}</p>
                  )}
                </center>

                <br />
                {allerrors.email ? 
                  <div class="col-12 " style={{width:'100%'}} >
                  <div class=" alert alert-danger mx-0" style={{width:'100%',textAlign:'center'}} role="alert">
                  {allerrors.email}
                  </div>
                  </div>
                :''} 
                
                  
                <div className="row customrow" style={{ width: "100%" }}>
                  <div className="form-group col-md-6 pb-lg-0 pb-2  col-sm-6 col-xs-12 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Your Name*
                    </label>

                    <input
                      type="text"
                      name="signupname"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your First Name"
                      value={signupname}
                      onChange={(e) => {
                        setsignupname(e.target.value);
                      }}
                    />
                    {allerrors.name ? (
                      <p style={{ color: "red" }}>{allerrors.name}</p>
                    ) : (
                      ""
                    )}
                    {/* {errorsignupname == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{errorsignupname}</p>
                )} */}
                  </div>
                  {/* </div> */}

                  <div className="form-group col-md-6 col-sm-6 col-xs-12 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Email Address*
                    </label>
                    {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                    <input
                    
                      type="email"
                      name="signupemail"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your Email"
                      value={signupemail}
                      onChange={(e) => {
                        setsignupemail(e.target.value);
                      }}
                    />
                    {/* {allerrors.email ? (
                      <p style={{ color: "red" }}>{allerrors.email}</p>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Phone Number*
                    </label>

                    <input
                      type="number"
                      name="signupphone"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your number"
                      value={signupphone}
                      onChange={(e) => {
                        setsignupphone(e.target.value);
                      }}
                    />
                    {allerrors.phone_number ? (
                      <p style={{ color: "red" }}>{allerrors.phone_number}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-12 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Address*
                    </label>

                    <input
                      type="text"
                      name="signupaddress"
                      className="form-control"
                      style={{
                        borderRadius: "0px",
                        height: "55px",
                      }}
                      placeholder="Enter Your address"
                      value={signupaddress}
                      onChange={(e) => {
                        setsignupaddress(e.target.value);
                      }}
                    />
                    {allerrors.address ? (
                      <p style={{ color: "red" }}>{allerrors.address}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      City/ Town/ District*
                    </label>

                    <input
                      type="text"
                      name="signupcity"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your city"
                      value={signupcity}
                      onChange={(e) => {
                        setsignupcity(e.target.value);
                      }}
                    />
                    {allerrors.city ? (
                      <p style={{ color: "red" }}>{allerrors.city}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Country*
                    </label>
                  
                    {/* <input
                      type="text"
                      name="signupcountry"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your country"
                      value={signupcountry}
                      onChange={(e) => {
                        setsignupcountry(e.target.value);
                      }}
                    /> */}
                       <select
                                    name="signupcountry"
                                    className="form-select"
                                    style={{ borderRadius: "0px" }}
                                    value={signupcountry}
                                    onChange={(e) => {
                                      setsignupcountry(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Country</option>
                                    <option value="United Arab Emirates">
                                      {" "}
                                      United Arab Emirates{" "}
                                    </option>
                                    <option value="Abkhazia"> Abkhazia </option>
                                    <option value="Afghanistan">
                                      {" "}
                                      Afghanistan{" "}
                                    </option>
                                    <option value="Albania"> Albania </option>
                                    <option value="Algeria"> Algeria </option>
                                    <option value="American Samoa">
                                      {" "}
                                      American Samoa{" "}
                                    </option>
                                    <option value="Andorra"> Andorra </option>
                                    <option value="Angola"> Angola </option>
                                    <option value="Anguilla"> Anguilla </option>
                                    <option value="Antigua and Barbuda">
                                      {" "}
                                      Antigua and Barbuda{" "}
                                    </option>
                                    <option value="Argentina">
                                      {" "}
                                      Argentina{" "}
                                    </option>
                                    <option value="Armenia"> Armenia </option>
                                    <option value="Aruba"> Aruba </option>
                                    <option value="Ascension">
                                      {" "}
                                      Ascension{" "}
                                    </option>
                                    <option value="Australia">
                                      {" "}
                                      Australia{" "}
                                    </option>
                                    <option value="Australian External Territories">
                                      {" "}
                                      Australian External Territories{" "}
                                    </option>
                                    <option value="Austria"> Austria </option>
                                    <option value="Azerbaijan">
                                      {" "}
                                      Azerbaijan{" "}
                                    </option>
                                    <option value="Bahamas"> Bahamas </option>
                                    <option value="Bahrain"> Bahrain </option>
                                    <option value="Bangladesh">
                                      {" "}
                                      Bangladesh{" "}
                                    </option>
                                    <option value="Barbados"> Barbados </option>
                                    <option value="Barbuda"> Barbuda </option>
                                    <option value="Belarus"> Belarus </option>
                                    <option value="Belgium"> Belgium </option>
                                    <option value="Belize"> Belize </option>
                                    <option value="Benin"> Benin </option>
                                    <option value="Bermuda"> Bermuda </option>
                                    <option value="Bhutan"> Bhutan </option>
                                    <option value="Bolivia"> Bolivia </option>
                                    <option value="Bosnia and Herzegovina">
                                      {" "}
                                      Bosnia and Herzegovina{" "}
                                    </option>
                                    <option value="Botswana"> Botswana </option>
                                    <option value="Brazil"> Brazil </option>
                                    <option value="British Indian Ocean Territory">
                                      {" "}
                                      British Indian Ocean Territory{" "}
                                    </option>
                                    <option value="British Virgin Islands">
                                      {" "}
                                      British Virgin Islands{" "}
                                    </option>
                                    <option value="Brunei"> Brunei </option>
                                    <option value="Bulgaria"> Bulgaria </option>
                                    <option value="Burkina Faso">
                                      {" "}
                                      Burkina Faso{" "}
                                    </option>
                                    <option value="Burundi"> Burundi </option>
                                    <option value="Cambodia"> Cambodia </option>
                                    <option value="Cameroon"> Cameroon </option>
                                    <option value="Canada"> Canada </option>
                                    <option value="Cape Verde">
                                      {" "}
                                      Cape Verde{" "}
                                    </option>
                                    <option value="Cayman Islands">
                                      {" "}
                                      Cayman Islands{" "}
                                    </option>
                                    <option value="Central African Republic">
                                      {" "}
                                      Central African Republic{" "}
                                    </option>
                                    <option value="Chad"> Chad </option>
                                    <option value="Chile"> Chile </option>
                                    <option value="China"> China </option>
                                    <option value="Christmas Island">
                                      {" "}
                                      Christmas Island{" "}
                                    </option>
                                    <option value="Cocos-Keeling Islands">
                                      {" "}
                                      Cocos-Keeling Islands{" "}
                                    </option>
                                    <option value="Colombia"> Colombia </option>
                                    <option value="Comoros"> Comoros </option>
                                    <option value="Congo"> Congo </option>
                                    <option value="Congo, Dem. Rep. of (Zaire)">
                                      {" "}
                                      Congo, Dem. Rep. of (Zaire){" "}
                                    </option>
                                    <option value="Cook Islands">
                                      {" "}
                                      Cook Islands{" "}
                                    </option>
                                    <option value="Costa Rica">
                                      {" "}
                                      Costa Rica{" "}
                                    </option>
                                    <option value="Croatia"> Croatia </option>
                                    <option value="Cuba"> Cuba </option>
                                    <option value="Curacao"> Curacao </option>
                                    <option value="Cyprus"> Cyprus </option>
                                    <option value="Czech Republic">
                                      {" "}
                                      Czech Republic{" "}
                                    </option>
                                    <option value="Denmark"> Denmark </option>
                                    <option value="Diego Garcia">
                                      {" "}
                                      Diego Garcia{" "}
                                    </option>
                                    <option value="Djibouti"> Djibouti </option>
                                    <option value="Dominica"> Dominica </option>
                                    <option value="Dominican Republic">
                                      {" "}
                                      Dominican Republic{" "}
                                    </option>
                                    <option value="East Timor">
                                      {" "}
                                      East Timor{" "}
                                    </option>
                                    <option value="Easter Island">
                                      {" "}
                                      Easter Island{" "}
                                    </option>
                                    <option value="Ecuador"> Ecuador </option>
                                    <option value="Egypt"> Egypt </option>
                                    <option value="El Salvador">
                                      {" "}
                                      El Salvador{" "}
                                    </option>
                                    <option value="Equatorial Guinea">
                                      {" "}
                                      Equatorial Guinea{" "}
                                    </option>
                                    <option value="Eritrea"> Eritrea </option>
                                    <option value="Estonia"> Estonia </option>
                                    <option value="Ethiopia"> Ethiopia </option>
                                    <option value="Falkland Islands">
                                      {" "}
                                      Falkland Islands{" "}
                                    </option>
                                    <option value="Faroe Islands">
                                      {" "}
                                      Faroe Islands{" "}
                                    </option>
                                    <option value="Fiji"> Fiji </option>
                                    <option value="Finland"> Finland </option>
                                    <option value="France"> France </option>
                                    <option value="French Antilles">
                                      {" "}
                                      French Antilles{" "}
                                    </option>
                                    <option value="French Guiana">
                                      {" "}
                                      French Guiana{" "}
                                    </option>
                                    <option value="French Polynesia">
                                      {" "}
                                      French Polynesia{" "}
                                    </option>
                                    <option value="Gabon"> Gabon </option>
                                    <option value="Gambia"> Gambia </option>
                                    <option value="Georgia"> Georgia </option>
                                    <option value="Germany"> Germany </option>
                                    <option value="Ghana"> Ghana </option>
                                    <option value="Gibraltar">
                                      {" "}
                                      Gibraltar{" "}
                                    </option>
                                    <option value="Greece"> Greece </option>
                                    <option value="Greenland">
                                      {" "}
                                      Greenland{" "}
                                    </option>
                                    <option value="Grenada"> Grenada </option>
                                    <option value="Guadeloupe">
                                      {" "}
                                      Guadeloupe{" "}
                                    </option>
                                    <option value="Guam"> Guam </option>
                                    <option value="Guatemala">
                                      {" "}
                                      Guatemala{" "}
                                    </option>
                                    <option value="Guinea"> Guinea </option>
                                    <option value="Guinea-Bissau">
                                      {" "}
                                      Guinea-Bissau{" "}
                                    </option>
                                    <option value="Guyana"> Guyana </option>
                                    <option value="Haiti"> Haiti </option>
                                    <option value="Honduras"> Honduras </option>
                                    <option value="Hong Kong SAR China">
                                      {" "}
                                      Hong Kong SAR China{" "}
                                    </option>
                                    <option value="Hungary"> Hungary </option>
                                    <option value="Iceland"> Iceland </option>
                                    <option value="India"> India </option>
                                    <option value="Indonesia">
                                      {" "}
                                      Indonesia{" "}
                                    </option>
                                    <option value="Iran"> Iran </option>
                                    <option value="Iraq"> Iraq </option>
                                    <option value="Ireland"> Ireland </option>
                                    <option value="Israel"> Israel </option>
                                    <option value="Italy"> Italy </option>
                                    <option value="Ivory Coast">
                                      {" "}
                                      Ivory Coast{" "}
                                    </option>
                                    <option value="Jamaica"> Jamaica </option>
                                    <option value="Japan"> Japan </option>
                                    <option value="Jordan"> Jordan </option>
                                    <option value="Kazakhstan">
                                      {" "}
                                      Kazakhstan{" "}
                                    </option>
                                    <option value="Kenya"> Kenya </option>
                                    <option value="Kiribati"> Kiribati </option>
                                    <option value="Kuwait"> Kuwait </option>
                                    <option value="Kyrgyzstan">
                                      {" "}
                                      Kyrgyzstan{" "}
                                    </option>
                                    <option value="Laos"> Laos </option>
                                    <option value="Latvia"> Latvia </option>
                                    <option value="Lebanon"> Lebanon </option>
                                    <option value="Lesotho"> Lesotho </option>
                                    <option value="Liberia"> Liberia </option>
                                    <option value="Libya"> Libya </option>
                                    <option value="Liechtenstein">
                                      {" "}
                                      Liechtenstein{" "}
                                    </option>
                                    <option value="Lithuania">
                                      {" "}
                                      Lithuania{" "}
                                    </option>
                                    <option value="Luxembourg">
                                      {" "}
                                      Luxembourg{" "}
                                    </option>
                                    <option value="Macau SAR China">
                                      {" "}
                                      Macau SAR China{" "}
                                    </option>
                                    <option value="Macedonia">
                                      {" "}
                                      Macedonia{" "}
                                    </option>
                                    <option value="Madagascar">
                                      {" "}
                                      Madagascar{" "}
                                    </option>
                                    <option value="Malawi"> Malawi </option>
                                    <option value="Malaysia"> Malaysia </option>
                                    <option value="Maldives"> Maldives </option>
                                    <option value="Mali"> Mali </option>
                                    <option value="Malta"> Malta </option>
                                    <option value="Marshall Islands">
                                      {" "}
                                      Marshall Islands{" "}
                                    </option>
                                    <option value="Martinique">
                                      {" "}
                                      Martinique{" "}
                                    </option>
                                    <option value="Mauritania">
                                      {" "}
                                      Mauritania{" "}
                                    </option>
                                    <option value="Mauritius">
                                      {" "}
                                      Mauritius{" "}
                                    </option>
                                    <option value="Mayotte"> Mayotte </option>
                                    <option value="Mexico"> Mexico </option>
                                    <option value="Micronesia">
                                      {" "}
                                      Micronesia{" "}
                                    </option>
                                    <option value="Midway Island">
                                      {" "}
                                      Midway Island{" "}
                                    </option>
                                    <option value="Moldova"> Moldova </option>
                                    <option value="Monaco"> Monaco </option>
                                    <option value="Mongolia"> Mongolia </option>
                                    <option value="Montenegro">
                                      {" "}
                                      Montenegro{" "}
                                    </option>
                                    <option value="Montserrat">
                                      {" "}
                                      Montserrat{" "}
                                    </option>
                                    <option value="Morocco"> Morocco </option>
                                    <option value="Myanmar"> Myanmar </option>
                                    <option value="Namibia"> Namibia </option>
                                    <option value="Nauru"> Nauru </option>
                                    <option value="Nepal"> Nepal </option>
                                    <option value="Netherlands">
                                      {" "}
                                      Netherlands{" "}
                                    </option>
                                    <option value="Netherlands Antilles">
                                      {" "}
                                      Netherlands Antilles{" "}
                                    </option>
                                    <option value="Nevis"> Nevis </option>
                                    <option value="New Caledonia">
                                      {" "}
                                      New Caledonia{" "}
                                    </option>
                                    <option value="New Zealand">
                                      {" "}
                                      New Zealand{" "}
                                    </option>
                                    <option value="Nicaragua">
                                      {" "}
                                      Nicaragua{" "}
                                    </option>
                                    <option value="Niger"> Niger </option>
                                    <option value="Nigeria"> Nigeria </option>
                                    <option value="Niue"> Niue </option>
                                    <option value="Norfolk Island">
                                      {" "}
                                      Norfolk Island{" "}
                                    </option>
                                    <option value="North Korea">
                                      {" "}
                                      North Korea{" "}
                                    </option>
                                    <option value="Northern Mariana Islands">
                                      {" "}
                                      Northern Mariana Islands{" "}
                                    </option>
                                    <option value="Norway"> Norway </option>
                                    <option value="Oman"> Oman </option>
                                    <option value="Pakistan"> Pakistan </option>
                                    <option value="Palau"> Palau </option>
                                    <option value="Palestinian Territory">
                                      {" "}
                                      Palestinian Territory{" "}
                                    </option>
                                    <option value="Panama"> Panama </option>
                                    <option value="Papua New Guinea">
                                      {" "}
                                      Papua New Guinea{" "}
                                    </option>
                                    <option value="Paraguay"> Paraguay </option>
                                    <option value="Peru"> Peru </option>
                                    <option value="Philippines">
                                      {" "}
                                      Philippines{" "}
                                    </option>
                                    <option value="Poland"> Poland </option>
                                    <option value="Portugal"> Portugal </option>
                                    <option value="Puerto Rico">
                                      {" "}
                                      Puerto Rico{" "}
                                    </option>
                                    <option value="Qatar"> Qatar </option>
                                    <option value="Reunion"> Reunion </option>
                                    <option value="Romania"> Romania </option>
                                    <option value="Russia"> Russia </option>
                                    <option value="Rwanda"> Rwanda </option>
                                    <option value="Samoa"> Samoa </option>
                                    <option value="San Marino">
                                      {" "}
                                      San Marino{" "}
                                    </option>
                                    <option value="Saudi Arabia">
                                      {" "}
                                      Saudi Arabia{" "}
                                    </option>
                                    <option value="Senegal"> Senegal </option>
                                    <option value="Serbia"> Serbia </option>
                                    <option value="Seychelles">
                                      {" "}
                                      Seychelles{" "}
                                    </option>
                                    <option value="Sierra Leone">
                                      {" "}
                                      Sierra Leone{" "}
                                    </option>
                                    <option value="Singapore">
                                      {" "}
                                      Singapore{" "}
                                    </option>
                                    <option value="Slovakia"> Slovakia </option>
                                    <option value="Slovenia"> Slovenia </option>
                                    <option value="Solomon Islands">
                                      {" "}
                                      Solomon Islands{" "}
                                    </option>
                                    <option value="South Africa">
                                      {" "}
                                      South Africa{" "}
                                    </option>
                                    <option value="South Georgia and the South Sandwich Islands">
                                      {" "}
                                      South Georgia and the South Sandwich
                                      Islands{" "}
                                    </option>
                                    <option value="South Korea">
                                      {" "}
                                      South Korea{" "}
                                    </option>
                                    <option value="Spain"> Spain </option>
                                    <option value="Sri Lanka">
                                      {" "}
                                      Sri Lanka{" "}
                                    </option>
                                    <option value="Sudan"> Sudan </option>
                                    <option value="Suriname"> Suriname </option>
                                    <option value="Swaziland">
                                      {" "}
                                      Swaziland{" "}
                                    </option>
                                    <option value="Sweden"> Sweden </option>
                                    <option value="Switzerland">
                                      {" "}
                                      Switzerland{" "}
                                    </option>
                                    <option value="Syria"> Syria </option>
                                    <option value="Taiwan"> Taiwan </option>
                                    <option value="Tajikistan">
                                      {" "}
                                      Tajikistan{" "}
                                    </option>
                                    <option value="Tanzania"> Tanzania </option>
                                    <option value="Thailand"> Thailand </option>
                                    <option value="Timor Leste">
                                      {" "}
                                      Timor Leste{" "}
                                    </option>
                                    <option value="Togo"> Togo </option>
                                    <option value="Tokelau"> Tokelau </option>
                                    <option value="Tonga"> Tonga </option>
                                    <option value="Trinidad and Tobago">
                                      {" "}
                                      Trinidad and Tobago{" "}
                                    </option>
                                    <option value="Tunisia"> Tunisia </option>
                                    <option value="Turkey"> Turkey </option>
                                    <option value="Turkmenistan">
                                      {" "}
                                      Turkmenistan{" "}
                                    </option>
                                    <option value="Turks and Caicos Islands">
                                      {" "}
                                      Turks and Caicos Islands{" "}
                                    </option>
                                    <option value="Tuvalu"> Tuvalu </option>
                                    <option value="U.S. Virgin Islands">
                                      {" "}
                                      U.S. Virgin Islands{" "}
                                    </option>
                                    <option value="Uganda"> Uganda </option>
                                    <option value="Ukraine"> Ukraine </option>
                                    <option value="United Kingdom">
                                      {" "}
                                      United Kingdom{" "}
                                    </option>
                                    <option value="United States">
                                      {" "}
                                      United States{" "}
                                    </option>
                                    <option value="Uruguay"> Uruguay </option>
                                    <option value="Uzbekistan">
                                      {" "}
                                      Uzbekistan{" "}
                                    </option>
                                    <option value="Vanuatu"> Vanuatu </option>
                                    <option value="Venezuela">
                                      {" "}
                                      Venezuela{" "}
                                    </option>
                                    <option value="Vietnam"> Vietnam </option>
                                    <option value="Wake Island">
                                      {" "}
                                      Wake Island{" "}
                                    </option>
                                    <option value="Wallis and Futuna">
                                      {" "}
                                      Wallis and Futuna{" "}
                                    </option>
                                    <option value="Yemen"> Yemen </option>
                                    <option value="Zambia"> Zambia </option>
                                    <option value="Zanzibar"> Zanzibar </option>
                                    <option value="Zimbabwe"> Zimbabwe </option>
                                  </select>
                    {allerrors.country ? (
                      <p style={{ color: "red" }}>{allerrors.country}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Landmark (Optional)
                    </label>

                    <input
                      type="text"
                      name="signuplandmark"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your Landmark (Optional)"
                      value={signuplandmark}
                      onChange={(e) => {
                        setsignuplandmark(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Password*
                    </label>

                    <input
                      type="password"
                      name="signuppassword"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your password"
                      value={signuppassword}
                      onChange={(e) => {
                        setsignuppassword(e.target.value);
                      }}
                    />
                    {allerrors.password ? (
                      <p style={{ color: "red" }}>{allerrors.password}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                      Confirm Password*
                    </label>

                    <input
                      type="password"
                      name="signupcon_password"
                      className="form-control"
                      style={{ borderRadius: "0px" }}
                      placeholder="Enter Your confirm password"
                      value={signupcon_password}
                      onChange={(e) => {
                        setsignupcon_password(e.target.value);
                      }}
                    />
                    {errorsignupcon_password == "" ? (
                      ""
                    ) : (
                      <p style={{ color: "red" }}>{errorsignupcon_password}</p>
                    )}
                  </div>
                  {signupselectedValue === "3" ?  

                 <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                    <label className="acounttitle tilte541 mb-2">
                    Business card*
                    </label>
                    <input type="file" name="signupbusiness_card" accept="image/*" id="file-input" class="form-control input-area"
                    //  onChange={(e) => {
                    //   setsignupbusiness_card(e.target.files[0]);
                    // }}
                    onChange={handleImageChange}
                    />
                    {errorsignupbusiness_card == "" ? (
                      ""
                    ) : (
                      <p style={{ color: "red" }}>{errorsignupbusiness_card}</p>
                    )}

                    </div>
                  : ''}
                  <div className="form-group col-12 mt-3 px-2 pb-2">
                    {success === true ? (
                      <button
                        type="button"
                        className="btn sendotpbtn"
                        style={{
                          border: "2px solid #059fe2",
                          borderRadius: "0px",
                          width: "100%",
                        }}
                        onClick={signupsubmit}
                        disbaled
                      >
                        SIGN UP
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end sign up modal */}


{/* use for email radio button after next button click popup 8 */}
<div
          className="modal fade"
          ref={fromemailnextpopup}
          id="staticBackdrop90"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel90"
          aria-hidden="true"
          style={{ outline: "none", background: "none", overflow: "auto" }}
        >
          <div className="modal-dialog newwidth">
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: "none" }}>
                <button
               ref={closefromemailnextpopup}
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <form>
                <div className="row">
                  <div className="col-lg-1"></div>

                  <div className="col-lg-10">
                    {mobilenumber && (
                      <center>
                        <div className="alert alert-success">
                          Use&nbsp;{formatMobileNumber(mobilenumber)}
                          &nbsp;mobile number.
                        </div>
                      </center>
                    )}
                    {error && (
                      <center>
                        {" "}
                        <div
                          className="alert alert-error"
                          style={{ fontSize: "14px" }}
                        >
                          {error}
                        </div>
                      </center>
                    )}
                  </div>
                  <div className="col-lg-1"></div>
                </div>
                <div className="modal-body">
                  <h5 className="sign-text  text-center mt-1 pb-4">
                  {RetriveAndForgot == 'retrive_access' ? 'Access Request' : 'Forgot Password'}
                  </h5>
                  <div className="row">
                    <div className="col-lg-12">
                     
                     
                    
                        <div className="col-lg-12">
                          <div
                            className="form-group form-row d-flex justify-content-center mt-3"
                            style={{ marginBottom: "0px!important" }}
                          >
                            <select
                              name="country"
                              className="form-select"
                              aria-label="Default select example"
                              style={{ width: "80%" }}
                              value={formData.country}
                              onChange={handleChange}
                            >
                              <option value="">Select Country Code</option>
                              <option value="+971">
                                United Arab Emirates (+971){" "}
                              </option>
                              <option value="+7840">Abkhazia (+7840) </option>
                              <option value="+93">Afghanistan (+93) </option>
                              <option value="+355">Albania (+355) </option>
                              <option value="+213">Algeria (+213) </option>
                              <option value="+1684">
                                American Samoa (+1684){" "}
                              </option>
                              <option value="+376">Andorra (+376) </option>
                              <option value="+244">Angola (+244) </option>
                              <option value="+1264">Anguilla (+1264) </option>
                              <option value="+1268">
                                Antigua and Barbuda (+1268){" "}
                              </option>
                              <option value="+54">Argentina (+54) </option>
                              <option value="+374">Armenia (+374) </option>
                              <option value="+297">Aruba (+297) </option>
                              <option value="+247">Ascension (+247) </option>
                              <option value="+61">Australia (+61) </option>
                              <option value="+672">
                                Australian External Territories (+672){" "}
                              </option>
                              <option value="+43">Austria (+43) </option>
                              <option value="+994">Azerbaijan (+994) </option>
                              <option value="+1242">Bahamas (+1242) </option>
                              <option value="+973">Bahrain (+973) </option>
                              <option value="+880">Bangladesh (+880) </option>
                              <option value="+1246">Barbados (+1246) </option>
                              <option value="+1268">Barbuda (+1268) </option>
                              <option value="+375">Belarus (+375) </option>
                              <option value="+32">Belgium (+32) </option>
                              <option value="+501">Belize (+501) </option>
                              <option value="+229">Benin (+229) </option>
                              <option value="+1441">Bermuda (+1441) </option>
                              <option value="+975">Bhutan (+975) </option>
                              <option value="+591">Bolivia (+591) </option>
                              <option value="+387">
                                Bosnia and Herzegovina (+387){" "}
                              </option>
                              <option value="+267">Botswana (+267) </option>
                              <option value="+55">Brazil (+55) </option>
                              <option value="+246">
                                British Indian Ocean Territory (+246){" "}
                              </option>
                              <option value="+1284">
                                British Virgin Islands (+1284){" "}
                              </option>
                              <option value="+673">Brunei (+673) </option>
                              <option value="+359">Bulgaria (+359) </option>
                              <option value="+226">Burkina Faso (+226) </option>
                              <option value="+257">Burundi (+257) </option>
                              <option value="+855">Cambodia (+855) </option>
                              <option value="+237">Cameroon (+237) </option>
                              <option value="+1">Canada (+1) </option>
                              <option value="+238">Cape Verde (+238) </option>
                              <option value="+345">
                                Cayman Islands (+345){" "}
                              </option>
                              <option value="+236">
                                Central African Republic (+236){" "}
                              </option>
                              <option value="+235">Chad (+235) </option>
                              <option value="+56">Chile (+56) </option>
                              <option value="+86">China (+86) </option>
                              <option value="+61">
                                Christmas Island (+61){" "}
                              </option>
                              <option value="+61">
                                Cocos-Keeling Islands (+61){" "}
                              </option>
                              <option value="+57">Colombia (+57) </option>
                              <option value="+269">Comoros (+269) </option>
                              <option value="+242">Congo (+242) </option>
                              <option value="+243">
                                Congo, Dem. Rep. of (Zaire) (+243){" "}
                              </option>
                              <option value="+682">Cook Islands (+682) </option>
                              <option value="+506">Costa Rica (+506) </option>
                              <option value="+385">Croatia (+385) </option>
                              <option value="+53">Cuba (+53) </option>
                              <option value="+599">Curacao (+599) </option>
                              <option value="+537">Cyprus (+537) </option>
                              <option value="+420">
                                Czech Republic (+420){" "}
                              </option>
                              <option value="+45">Denmark (+45) </option>
                              <option value="+246">Diego Garcia (+246) </option>
                              <option value="+253">Djibouti (+253) </option>
                              <option value="+1767">Dominica (+1767) </option>
                              <option value="+1809">
                                Dominican Republic (+1809){" "}
                              </option>
                              <option value="+670">East Timor (+670) </option>
                              <option value="+56">Easter Island (+56) </option>
                              <option value="+593">Ecuador (+593) </option>
                              <option value="+20">Egypt (+20) </option>
                              <option value="+503">El Salvador (+503) </option>
                              <option value="+240">
                                Equatorial Guinea (+240){" "}
                              </option>
                              <option value="+291">Eritrea (+291) </option>
                              <option value="+372">Estonia (+372) </option>
                              <option value="+251">Ethiopia (+251) </option>
                              <option value="+500">
                                Falkland Islands (+500){" "}
                              </option>
                              <option value="+298">
                                Faroe Islands (+298){" "}
                              </option>
                              <option value="+679">Fiji (+679) </option>
                              <option value="+358">Finland (+358) </option>
                              <option value="+33">France (+33) </option>
                              <option value="+596">
                                French Antilles (+596){" "}
                              </option>
                              <option value="+594">
                                French Guiana (+594){" "}
                              </option>
                              <option value="+689">
                                French Polynesia (+689){" "}
                              </option>
                              <option value="+241">Gabon (+241) </option>
                              <option value="+220">Gambia (+220) </option>
                              <option value="+995">Georgia (+995) </option>
                              <option value="+49">Germany (+49) </option>
                              <option value="+233">Ghana (+233) </option>
                              <option value="+350">Gibraltar (+350) </option>
                              <option value="+30">Greece (+30) </option>
                              <option value="+299">Greenland (+299) </option>
                              <option value="+1473">Grenada (+1473) </option>
                              <option value="+590">Guadeloupe (+590) </option>
                              <option value="+1671">Guam (+1671) </option>
                              <option value="+502">Guatemala (+502) </option>
                              <option value="+224">Guinea (+224) </option>
                              <option value="+245">
                                Guinea-Bissau (+245){" "}
                              </option>
                              <option value="+595">Guyana (+595) </option>
                              <option value="+509">Haiti (+509) </option>
                              <option value="+504">Honduras (+504) </option>
                              <option value="+852">
                                Hong Kong SAR China (+852){" "}
                              </option>
                              <option value="+36">Hungary (+36) </option>
                              <option value="+354">Iceland (+354) </option>
                              <option value="+91">India (+91) </option>
                              <option value="+62">Indonesia (+62) </option>
                              <option value="+98">Iran (+98) </option>
                              <option value="+964">Iraq (+964) </option>
                              <option value="+353">Ireland (+353) </option>
                              <option value="+972">Israel (+972) </option>
                              <option value="+39">Italy (+39) </option>
                              <option value="+225">Ivory Coast (+225) </option>
                              <option value="+1876">Jamaica (+1876) </option>
                              <option value="+81">Japan (+81) </option>
                              <option value="+962">Jordan (+962) </option>
                              <option value="+77">Kazakhstan (+77) </option>
                              <option value="+254">Kenya (+254) </option>
                              <option value="+686">Kiribati (+686) </option>
                              <option value="+965">Kuwait (+965) </option>
                              <option value="+996">Kyrgyzstan (+996) </option>
                              <option value="+856">Laos (+856) </option>
                              <option value="+371">Latvia (+371) </option>
                              <option value="+961">Lebanon (+961) </option>
                              <option value="+266">Lesotho (+266) </option>
                              <option value="+231">Liberia (+231) </option>
                              <option value="+218">Libya (+218) </option>
                              <option value="+423">
                                Liechtenstein (+423){" "}
                              </option>
                              <option value="+370">Lithuania (+370) </option>
                              <option value="+352">Luxembourg (+352) </option>
                              <option value="+853">
                                Macau SAR China (+853){" "}
                              </option>
                              <option value="+389">Macedonia (+389) </option>
                              <option value="+261">Madagascar (+261) </option>
                              <option value="+265">Malawi (+265) </option>
                              <option value="+60">Malaysia (+60) </option>
                              <option value="+960">Maldives (+960) </option>
                              <option value="+223">Mali (+223) </option>
                              <option value="+356">Malta (+356) </option>
                              <option value="+692">
                                Marshall Islands (+692){" "}
                              </option>
                              <option value="+596">Martinique (+596) </option>
                              <option value="+222">Mauritania (+222) </option>
                              <option value="+230">Mauritius (+230) </option>
                              <option value="+262">Mayotte (+262) </option>
                              <option value="+52">Mexico (+52) </option>
                              <option value="+691">Micronesia (+691) </option>
                              <option value="+1808">
                                Midway Island (+1808){" "}
                              </option>
                              <option value="+373">Moldova (+373) </option>
                              <option value="+377">Monaco (+377) </option>
                              <option value="+976">Mongolia (+976) </option>
                              <option value="+382">Montenegro (+382) </option>
                              <option value="+1664">Montserrat (+1664) </option>
                              <option value="+212">Morocco (+212) </option>
                              <option value="+95">Myanmar (+95) </option>
                              <option value="+264">Namibia (+264) </option>
                              <option value="+674">Nauru (+674) </option>
                              <option value="+977">Nepal (+977) </option>
                              <option value="+31">Netherlands (+31) </option>
                              <option value="+599">
                                Netherlands Antilles (+599){" "}
                              </option>
                              <option value="+1869">Nevis (+1869) </option>
                              <option value="+687">
                                New Caledonia (+687){" "}
                              </option>
                              <option value="+64">New Zealand (+64) </option>
                              <option value="+505">Nicaragua (+505) </option>
                              <option value="+227">Niger (+227) </option>
                              <option value="+234">Nigeria (+234) </option>
                              <option value="+683">Niue (+683) </option>
                              <option value="+672">
                                Norfolk Island (+672){" "}
                              </option>
                              <option value="+850">North Korea (+850) </option>
                              <option value="+1670">
                                Northern Mariana Islands (+1670){" "}
                              </option>
                              <option value="+47">Norway (+47) </option>
                              <option value="+968">Oman (+968) </option>
                              <option value="+92">Pakistan (+92) </option>
                              <option value="+680">Palau (+680) </option>
                              <option value="+970">
                                Palestinian Territory (+970){" "}
                              </option>
                              <option value="+507">Panama (+507) </option>
                              <option value="+675">
                                Papua New Guinea (+675){" "}
                              </option>
                              <option value="+595">Paraguay (+595) </option>
                              <option value="+51">Peru (+51) </option>
                              <option value="+63">Philippines (+63) </option>
                              <option value="+48">Poland (+48) </option>
                              <option value="+351">Portugal (+351) </option>
                              <option value="+1787">
                                Puerto Rico (+1787){" "}
                              </option>
                              <option value="+974">Qatar (+974) </option>
                              <option value="+262">Reunion (+262) </option>
                              <option value="+40">Romania (+40) </option>
                              <option value="+7">Russia (+7) </option>
                              <option value="+250">Rwanda (+250) </option>
                              <option value="+685">Samoa (+685) </option>
                              <option value="+378">San Marino (+378) </option>
                              <option value="+966">Saudi Arabia (+966) </option>
                              <option value="+221">Senegal (+221) </option>
                              <option value="+381">Serbia (+381) </option>
                              <option value="+248">Seychelles (+248) </option>
                              <option value="+232">Sierra Leone (+232) </option>
                              <option value="+65">Singapore (+65) </option>
                              <option value="+421">Slovakia (+421) </option>
                              <option value="+386">Slovenia (+386) </option>
                              <option value="+677">
                                Solomon Islands (+677){" "}
                              </option>
                              <option value="+27">South Africa (+27) </option>
                              <option value="+500">
                                South Georgia and the South Sandwich Islands
                                (+500){" "}
                              </option>
                              <option value="+82">South Korea (+82) </option>
                              <option value="+34">Spain (+34) </option>
                              <option value="+94">Sri Lanka (+94) </option>
                              <option value="+249">Sudan (+249) </option>
                              <option value="+597">Suriname (+597) </option>
                              <option value="+268">Swaziland (+268) </option>
                              <option value="+46">Sweden (+46) </option>
                              <option value="+41">Switzerland (+41) </option>
                              <option value="+963">Syria (+963) </option>
                              <option value="+886">Taiwan (+886) </option>
                              <option value="+992">Tajikistan (+992) </option>
                              <option value="+255">Tanzania (+255) </option>
                              <option value="+66">Thailand (+66) </option>
                              <option value="+670">Timor Leste (+670) </option>
                              <option value="+228">Togo (+228) </option>
                              <option value="+690">Tokelau (+690) </option>
                              <option value="+676">Tonga (+676) </option>
                              <option value="+1868">
                                Trinidad and Tobago (+1868){" "}
                              </option>
                              <option value="+216">Tunisia (+216) </option>
                              <option value="+90">Turkey (+90) </option>
                              <option value="+993">Turkmenistan (+993) </option>
                              <option value="+1649">
                                Turks and Caicos Islands (+1649){" "}
                              </option>
                              <option value="+688">Tuvalu (+688) </option>
                              <option value="+1340">
                                U.S. Virgin Islands (+1340){" "}
                              </option>
                              <option value="+256">Uganda (+256) </option>
                              <option value="+380">Ukraine (+380) </option>
                              <option value="+44">United Kingdom (+44) </option>
                              <option value="+1">United States (+1) </option>
                              <option value="+598">Uruguay (+598) </option>
                              <option value="+998">Uzbekistan (+998) </option>
                              <option value="+678">Vanuatu (+678) </option>
                              <option value="+58">Venezuela (+58) </option>
                              <option value="+84">Vietnam (+84) </option>
                              <option value="+1808">
                                Wake Island (+1808){" "}
                              </option>
                              <option value="+681">
                                Wallis and Futuna (+681){" "}
                              </option>
                              <option value="+967">Yemen (+967) </option>
                              <option value="+260">Zambia (+260) </option>
                              <option value="+255">Zanzibar (+255) </option>
                              <option value="+263">Zimbabwe (+263) </option>
                            </select>
                          </div>
                          <center>
                            {errors.country && (
                              <div className="error">{errors.country}</div>
                            )}
                          </center>

                          <div
                            className="form-group form-row d-flex justify-content-center mt-3"
                            style={{ marginBottom: "0px!important" }}
                          >
                            <input
                              type="number"
                              name="mobile"
                              placeholder="Enter Mobile*"
                              className="input-areabutton"
                              value={formData.mobile}
                              onChange={handleChange}
                            />
                          </div>
                          <center>
                            {errors.mobile && (
                              <div className="error">{errors.mobile}</div>
                            )}
                          </center>
                        </div>
                     

                      <br />
                    </div>

                    <div className="form-group form-row d-flex justify-content-center mt-2 mb-4">
                      <button
                        type="button"
                        onClick={handleRetriveAccess}
                        className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2 mb-4"
                      >
                       SEND OTP
                      
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
 {/* end use for email radio button after next button click popup 8    */}

    </header>
  );
};

export default Header;
