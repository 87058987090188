import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import MyAccountMenu from './MyAccountMenu';
import { getToken } from "../services/LocalStorageSrv";
import axios from 'axios';
import Loader from '../Loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
const MyorderDetail = () => {
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate()
  const localdata = getToken('data')
  const [loading, setLoading] = useState(true);
  const [myOrderData, setMyOrderData] = useState([]);
  const [myOrderAllProduct, setMyOrderAllProduct] = useState([]);
  const [myOrderAllProductCategories, setMyOrderAllProductCategories] = useState([]);

  const cartpopup = useRef(null);
  const [showModal, setShowModal] = useState(true);
  const [quantityvalidation, setQuantityValidation] = useState(0);
  const [varient, setvarient] = useState({});
  const [productids, setProductIds] = useState('');
  const [convertedArray, setConvertedArray] = useState([]);
  const [addtocartresponseMessage, setAddCartResponseMessage] = useState(null);
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState(null);
  const [totalPrices, setTotalPrices] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [quantityValues, setQuantityValues] = useState({});
  const [productSKUDetails, setRelatedSKUDetails] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState(Array(20).fill(false)); // Assuming 5 rows
  const [product_de, setProduct_de] = useState(null);
  const [cartproductDetails, setCartProductDetails] = useState(null);

  const handleAddCartAlertClick = () => {
    setError(false);
  };
  const handleAlertClick = () => {
    setResponseMessage(false);
  };

  const handleQuantityChange = (variantId, quantity, price, pro_id) => {
    varient[variantId] = parseInt(quantity);
    setTimeout(() => {
      setError('');
    }, 1000);
    setProductIds(pro_id);
    // console.log('proid:',pro_id);
    // console.log('varient detail:',variantId,quantity,varient);
    const newArray = Object.entries(varient)
      .filter(([variant_id, quantity]) => quantity > 0) // Only include entries with quantity > 0
      .map(([variant_id, quantity]) => ({ variant_id, quantity: String(quantity) })) // Map to the desired format
    // console.log("convert to:",newArray);
    setConvertedArray(newArray);
    // console.log('v:',variantId);
    // console.log('p:',price);
    // console.log('q:',quantity);
    // setQuantityValidation(quantity);
    // if (quantity != '' && quantity.length > 1) {
    //   setError('');
    // }else{
    //   setError('Please Select Size and Add Quantity');
    // }
    // if (quantity !== 0) {
    //   setError('');
    // }
    setQuantityValues((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: parseInt(quantity, 10),
    }));
    setTotalPrices((prevTotalPrices) => ({
      ...prevTotalPrices,
      [variantId]: parseFloat(price) * parseInt(quantity, 10),
    }));
  };

  useEffect(() => {

    const initialTotalPrices = {};
    Object.keys(quantityValues).forEach((variantId) => {
      initialTotalPrices[variantId] =
        parseFloat(product_de.uprice) * quantityValues[variantId];
    });
    setTotalPrices(initialTotalPrices);
    const apiUrl = process.env.REACT_APP_API_URL;
    const myOrderDet = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${apiUrl}/orders-new/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
          },
        });

        setMyOrderData(response.data.data);
        setMyOrderAllProduct(response.data.data.products);


        const categoryArray = response?.data?.data?.products?.productCategories || [];
        setMyOrderAllProductCategories(categoryArray);
        // console.log(categoryArray);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };


    myOrderDet();

  }, [id]);
  const handleAddCartButtonClick = (id, name, sku, qyt) => {
    if (cartpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(cartpopup.current);
      modal.show();
    }
    setProductIds(id);
    // console.log('proid:',id);
    const fetchProductValue = async () => {
      try {

        setLoading(true);
        const response = await axios.get(`${apiUrl}/products/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
          },
        });
        setProduct_de(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchAddCartModelValue = async () => {
      try {

        setLoading(true);
        const response = await axios.get(`${apiUrl}/products/porductVariant?product_id=${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
          },
        });
        setCartProductDetails(response.data.data);
        const updatedAttributes = {};
        response.data.data.forEach((item, index) => {
          updatedAttributes[item.id] = 0;
        });
        setvarient((prevData) => ({
          ...prevData,
          ...updatedAttributes,
        }));
        //  console.log('pro cart de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchRelatedSKUValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/getRelatedProductsBySKU?sku=${sku}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
          },
        });
        setRelatedSKUDetails(response.data.data);
        // console.log('pro cr sku:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchRelatedSKUValue();
    fetchProductValue();
    fetchAddCartModelValue();
  };
  // Function to handle form submission
  // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
  // console.log('conv array submit:',convertedArray);


  const handleAddToCartSubmit = async (e) => {
    e.preventDefault();

    // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
    //   if(quantityvalidation <= 0){
    //   setError('Please Select Size and Add Quantity.');
    // } else {
    // Perform submit action or other logic here
    // console.log('quantity array:',quantityValues);
    // console.log('price array:',totalPrices);
    // console.log('Submit:', quantityvalidation);
    // }
    // const value = parseInt(quantityvalidation);

    // if (!isNaN(quantityvalidation) && quantityvalidation > 0 && quantityvalidation <= 1) {
    if (!convertedArray[0]) {

      // console.log('cons:',convertedArray);
      setError('Please Select Size and Add Quantity.');
    } else {

      // console.log('proids:',productids);
      // console.log('varient qyt array:',convertedArray);
      // Your submission logic here
      const collectdata = {
        product_id: productids,
        product_option: convertedArray,
      };
      // console.log('add to cart submit:',collectdata);


      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/carts/add`, collectdata, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
          },
        });



        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setAddCartResponseMessage(response.data.message);

          setShowModal(false);
          toast.success(
            <div>
              <div>{response.data.message}</div>
              <button className="order-now" onClick={redirectToCart} style={{ marginTop: '10px' }}>
                Go to Cart
              </button>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );

          setTimeout(() => {
            window.location.reload(true);
          }, 2000); // 7000 milliseconds is the autoClose duration
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);

      }
    }
  };
  const redirectToCart = () => {
    // Redirect logic to the cart page; replace with your actual redirect logic
    console.log('Redirecting to the cart page...');
    // For demonstration purposes, let's assume the cart page has a path '/cart'
    // You may want to use react-router-dom or your preferred routing library for this
    // window.location.href = '/cart';
    nvg(`/cart`);
  };

  const handleProDetails = (pro_name) => {
    nvg(`/product-detail/${pro_name}`);
    window.location.reload();
  };

  return (
    <div>
      <ToastContainer />
      {loading ? <Loader /> : null}
      <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
      <section className="cart-detail-sec inner-page-bg">
        <div className="container">
          <div className="row">




            <MyAccountMenu />


            <div className="col-lg-9  col-sm-12" style={{ alignSelf: 'flex-start', marginTop: '16px' }}>
              <div className="tab-content">
                <div role="tabpanel" id="profile-update" className="tab-pane  fade active show">
                  <div className="order-block" style={{ background: '#fff', }}>
                    <h2 className="Myorder">Order No. {myOrderData.id}</h2>

                    <div className="" style={{ background: 'white', paddingLeft: '0px' }}>
                      {/* <div className="col-md-12" style={{display:'flex',justifyContent:'space-between'}}> */}

                      {/* order1 */}
                      <div className="row">


                        <div className="py-3" style={{ background: 'white', paddingLeft: '20px' }}>
                          <div className="row>">

                            {myOrderAllProduct.map((allProduct, index) => (
                              <div className="col-md-12 full-order-details">
                                <div className="product-block">
                                  <div className="left-part">
                                    <div className="product-img">

                                      <a href="javascript:void(0)" onClick={() => { nvg('/product-detail/' + allProduct.slug) }}>
                                        <img src={allProduct.thumbnail_image} alt="Product Image" />

                                      </a>
                                    </div>
                                    <div className="product-details" style={{ padding :'20px'}}>
                                      <h2 className="pro-name"> {allProduct.sku} - {allProduct.product_name} </h2>
                                      <h4 className="pro-cat"> Order Date : {allProduct.orderDate ? new Date(allProduct.orderDate).toLocaleDateString('en-GB') : ''} </h4>

                                      {allProduct.productCategories.map((allCat) => (
                                        <span set="Coverall without Reflective,Safety Clothing,Protective Coverall,Coverall,Twill Coverall,Coverall 190 GSM" className="pro-cat">{allCat.category_name}, </span>
                                      ))}

                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>Size</th>
                                            <th>Quantity</th>
                                            <th>Ordered Price</th>
                                            <th>Latest Price</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {allProduct.products_attributes.map((allAtt, index) => (


                                            <tr>
                                              <td className="size">  {typeof allAtt.item_code === 'string' ? allAtt.item_code.split("-")[1] : ''}</td>
                                              <td className="qty">  {allAtt.quantity}  </td>
                                              <td className="" style={{ color: '#000', fontWeight: 'bold' }}> AED {allAtt.product_price} </td>
                                              <td className="" style={{ color: '#000', fontWeight: 'bold' }}>AED {allProduct.uprice}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="total-price right-part" id="total-lable-price">
                                    <h2 className="label">price :</h2>
                                    <h3 className="value">AED {allProduct.subtotal}</h3>
                                    <div className="action">
                                      <a href="javascript:void(0);" onClick={() => handleAddCartButtonClick(allProduct.id, allProduct.product_name, allProduct.sku, '100')} className="order-now" role="button" style={{ color: 'white' }}>Order Now</a>


                                      {/* allProduct.cartonQty */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}


                          </div>
                        </div>




                      </div>
                      <div className="row text-right">
                        <div className="col-md-12 mr-2">
                          <h2 className="title_ord_pri">Total+Vat: AED {myOrderData.order_price}</h2>
                        </div>
                      </div>


                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>


















            </div>












          </div>

        </div>



      </section>



      <Footer />

      {/* add to cart model popup page start */}
      {showModal ? (
        <div className="modal fade" id="addToCartP" ref={cartpopup} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="addToCartPLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">

                <button type="button" className="btn-close remove-button" data-bs-dismiss="modal" aria-label="Close" style={{ background: ' #059fe2', color: '#fff', borderRadius: '0px', fontSize: '20px' }}> <i className="fa-solid fa-xmark"></i></button>

              </div>
              <form onSubmit={handleAddToCartSubmit}>
                <div className="container">

                  <div className="row">

                    <div className="col-md-12">
                      <h1 className="modal-title text-center pb-1 label"> <strong>PLEASE CONFIRM ORDER VARIANT & QUANTITY</strong></h1>
                      <p className="text-center pb-2"> {product_de && product_de.product_name ? product_de.product_name : ''}</p>

                      <div className="modal-body">
                        <ul className="list-inline">
                          <li className="list-inline-item pl-2 pb-2 mr-4">Item Group : {product_de && product_de.sku ? product_de.sku : ''} </li>
                          <li className="list-inline-item"> Carton Qty : {product_de && product_de.cartonQty ? product_de.cartonQty : '0'} </li>
                        </ul>

                        {error && (
                          <div className="alert alert-error" onClick={handleAddCartAlertClick}>
                            {error ? error : ''}
                          </div>
                        )}
                        <div className="row">

                          <div className="col-10">

                            <table className="table">
                              <thead>
                                <tr className="label_tabel">
                                  <th scope="col" > Variant </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Price </th>
                                  <th scope="col"> Total Price </th>
                                </tr>
                              </thead><tbody>

                                {cartproductDetails?.[0] ? cartproductDetails.map((variant_info, index) => (
                                  <tr id={`mp-size-${index}`} className={`outofstock${variant_info.stock}`} key={index}>
                                    <td scope="size">
                                      <div className="checkbox-col">
                                        <input type="checkbox" checked={checkboxStates[index]}
                                          onChange={() => { }} id={`size-v-${index}`} name={`quantity[${variant_info.id}]`} value={variant_info.id} />
                                        <label htmlFor={`size-v-${index}`} className="checkbox-label">&nbsp;{typeof variant_info.item_code === 'string' ? variant_info.item_code.split("-")[1] : ''}</label>
                                      </div>
                                    </td>


                                    {variant_info.stock > 0 ? (
                                      <td className="qty">
                                        <input type="number" id={product_de && product_de.uprice ? product_de.uprice : '0.00'} onChange={(e) => handleQuantityChange(variant_info.id, e.target.value, e.target.id, product_de.id)} name={`quantity[${variant_info.id}]`} defaultValue={0} min="0" max={variant_info.stock ? variant_info.stock : '0'} className="qtyIn" />
                                      </td>
                                    ) : (
                                      <td className="qty">
                                        <input type="number" name={`quantity[${variant_info.id}]`} defaultValue={0} min="0" max={variant_info.stock ? variant_info.stock : '0'} className="qtyIn disable_qyt" disabled />
                                      </td>
                                    )}

                                    <td className="label_addtocart_table"> AED {product_de && product_de.uprice ? product_de.uprice : '0.00'}
                                    </td>
                                    <td className="label_addtocart_table">AED {totalPrices[variant_info.id] || '0.00'}
                                    </td>


                                  </tr>
                                )) : ''}

                              </tbody>
                            </table>

                          </div>

                          {productSKUDetails !== null ? (
                            <div className="col-2">
                              <table className="table">
                                <thead>
                                  <tr className="label_tabel">
                                    <th scope="col">Related SKU</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productSKUDetails?.[0] ? (
                                    productSKUDetails.map((sku_info) => (
                                      <tr className="subtotal" key={sku_info.sku}>
                                        <td>
                                          <a
                                            href="javascript:void(0);"

                                            onClick={() => handleProDetails(sku_info.slug)}
                                            className=""
                                            style={{ fontWeight: '400', color: '#007bff' }}
                                          >
                                            {sku_info.sku}
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>No related SKUs found.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ''
                          )}


                        </div>

                      </div>


                      <div className="form-group form-row d-flex justify-content-center mt-2 mb-3">
                        <button type="submit" className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2">
                          Confirm Order
                        </button>
                      </div>



                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      ) : ('')}
      {/* End add to cart model popup */}

    </div>

  )
}
export default MyorderDetail;