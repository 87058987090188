import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MyAccountMenu from './MyAccountMenu';
import { getToken } from "../services/LocalStorageSrv";
import axios from 'axios';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const Rfq = () => {
   const apiUrl = process.env.REACT_APP_API_URL;
   const nvg = useNavigate()
   const localdata = getToken('data')
   const [loading, setLoading] = useState(true);
   const [myrfq, setProjects] = useState([]);
   const [category, setcategory] = useState(true)
   const [rfq, setrfq] = useState({});
//   console.log("rfq no",rfq)

  const updateDataValue2 = (key, newData) => {
   setrfq((prevData) => {
     const updatedData = { ...prevData, [key]: newData };
     return updatedData;
   });
 };
  useEffect(() => {
       const fetchRfq = async () => {
         try {
            setLoading(true);
        
            const response = await axios.get(`${apiUrl}/rfq`,{
              headers: {
                Authorization: `Bearer ${localdata.data.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json',
              },
            });
           setProjects(response.data.data);
         //   console.log('Fetched items:', response.data.data);
           const updatedAttributes2 = {};
           response.data.data.forEach((item, index) => {
             updatedAttributes2[item.order_id] = false;
           });

           setrfq((prevData) => ({
            ...prevData,
            ...updatedAttributes2,
          }));
           setLoading(false);
         } catch (error) {
           console.error('Error fetching data:', error);
           setLoading(false);
         }
       };
       fetchRfq();
      }, []);

   
      const [openCollapse, setOpenCollapse] = useState(0);

      const handleCollapseToggle = (index) => {
        setOpenCollapse(index === openCollapse ? -1 : index);
      };

   return (
      <div>
        {/* <div style={{ width: '100vw'}}>
                <Header /></div> */}
                {loading ? <Loader /> : null}
                <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
         <section id="product-listing-sec" className="inner-page-bg mb-5">
               <div className="container">
                  <div className="row" style={{gap:'5px'}}>
                  <MyAccountMenu/>
                     <div className="col-lg-8  col-sm-12  Myitems-box" style={{alignSelf:'flex-start',marginTop:'18px'}}> 
                        <div className="row">
                           
                           <h2 className="myItems">MY RFQ ({myrfq.length ? myrfq.length : '0'})</h2>
                                  {/* ITEM 1 */}
                                  {myrfq.map((project,index) => (   
// console.log('in:',index),
                               
                              <div className="mt-2" key={index}>
                                 
                                 <div className="d-grid gap-2 item1 rfq-item1" >
                                      <button
                                        className="btn gap-adjust d-flex align-items-center"
                                        onClick={() => {
                                          rfq[project.order_id] === false
                                            ? updateDataValue2(project.order_id, true)
                                            : updateDataValue2(project.order_id, false);
                                          handleCollapseToggle(index);
                                        }}
                                        type="button"
                                      >
                                        <span className="rfq-id">RFQ Id: </span>
                                        <span className="order-id">{project.order_id}</span>
                                        <span className="separator"> | </span>
                                        <span className="item-count">{project.items.length ? project.items.length : '0'} Items</span>
                                        <span className="separator"> | </span>
                                        <span className="placed-on">Placed on {new Date(project.placed_on).toLocaleDateString('en-GB')}</span>
                                      </button>
                                    </div>
                                    <div className="collection-collapse-block-content" style={{ display: index === openCollapse ? 'block' : 'none' }}>
                                       <div className="collection-brand-filter" style={{ border: '1.5px solid #ccc', paddingLeft: '20px', paddingRight :'20px' }}>
                                          <div className="card-body">

                                 {project.items.map((getitems,index) => (     
                                        <div className="row order-details border-bottom mb-2"  >
                                          <div className="col-md-6 col-sm-6 col-6">
                                             <h4 className="mt-4">
                                                <span className="order-details-heading">Product: </span> {getitems.product_or_category_details}
                                             </h4>
                                             <h4 className="mt-2">AED</h4>
                                             <h4 className="mt-2 mb-2 created"><span>CREATED</span></h4>
                                          </div>
                                         <div className="col-md-6 col-sm-6 col-6">
                                          <h4 className="mt-4">
                                             <span className="order-details-heading">Qty: </span>{getitems.quantity}
                                          </h4>
                                         </div>



                                        </div>
                                  ))}

                                      
                                        
                                          </div>
                                       </div>
                                    </div>
                              </div>
                             

                                  ))}



                        </div>
                     </div>
                  </div>
               </div>
            </section>


      
         <Footer />
      </div>

   )
}
export default Rfq;