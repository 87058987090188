import React, { useEffect, useState, useRef } from "react";

import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const CancelOrder = () => {
    const cartpopup = useRef(null);
    // const {id} = useParams();
    const location = useLocation();
   const id = new URLSearchParams(location.search).get('order_id');
    const apiUrl = process.env.REACT_APP_API_URL;
    const nvg = useNavigate();
    // const localdata = getToken("data");
    const [loading, setLoading] = useState(true);
    const [orderdata, setOrderData] = useState([]);
    // const configToken = {
    //   headers: {
    //       Authorization: `Bearer ${localdata.data.token}`,
    //   },
    // };
    useEffect(() => {
      
    
         const fetchOrderDetail = async () => {
          try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/orders/${id}`);
            
        
            // Assuming response.data is an object containing profile data
            setOrderData(response?.data?.data);
            // console.log('order Data:', response.data.data);
    
            
            setLoading(false);
          } catch (error) {
            console.error('Error fetching user profile data:', error);
            // console.error('Axios error details:', error.response); // Log the response for more details
            setLoading(false);
          }
         };
    
        
         
         fetchOrderDetail();
      }, []);

    return (
      
        <div style={{ overflow: 'hidden' }}>
  {loading ? <Loader /> : null}
  <ToastContainer />
  <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
  <section className="thank-you-sec inner-page-bg">
 <div className="container">
  <div className="row">
   <div className="col-md-6 order-success-block text-center">
     <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/delete.png`} alt="Order Success" className="icon" />
      <h2 className="text-uppercase blue-color">Order Failed</h2>
       <p>Opps, your order has payment is Failed</p>
        <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/order-success-image.png`} alt="Order Successful" className="order-image" />
      </div>


      <div className="col-md-6 order-detail-block">
       <h5 className="text-uppercase">Order Details:</h5>
        <h2 className="text-uppercase"> Payment Status: <span>Failed</span></h2>
          <p> Your order has been Cancelled , due to payment Failure. </p>

          <div className="action">
          <a href="javascript:void(0);" onClick={() => {
                            nvg("/shop-now");
                          }} className="continue-shopping">Continue Shopping </a>
          

        </div>
     </div>
    </div>
   </div>
  </section>

            <Footer />
        </div>

    )
}
export default CancelOrder;