// import React from 'react'
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { getToken } from "../services/LocalStorageSrv";
// import Img1 from "./images/product_img03.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import {Scrollbars} from 'react-custom-scrollbars';
import axios from "axios";
import Loader from "../Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';
const Home = () => {
  const localdata = getToken("data");
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredGroup, setIsHoveredGroup] = useState({});
  // console.log("just checking ",isHoveredGroup)
  const options1 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: true,
    margin: 10,
    responsive: {
      1100: {
        items: 1,
      },
      724: {
        items: 1,
      },
      500: {
        items: 1,
      },
      370: {
        items: 1,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };
  const options2 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1400: {
        items: 10,
      },
      1100: {
        items: 8,
      },

      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };
  const options3 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1400: {
        items: 10,
      },
      1100: {
        items: 8,
      },

      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const options4 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1100: {
        items: 7,
      },
      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const options5 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1100: {
        items: 7,
      },
      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const options6 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1100: {
        items: 7,
      },
      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const options7 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1400: {
        items: 10,
      },
      1100: {
        items: 8,
      },
      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const options8 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1400: {
        items: 10,
      },
      1100: {
        items: 8,
      },
      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const options9 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1100: {
        items: 7,
      },
      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const options10 = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    // animateOut: 'slideOutUp',
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1100: {
        items: 7,
      },
      1024: {
        items: 5,
      },
      724: {
        items: 4,
      },
      500: {
        items: 3,
      },
      370: {
        items: 2,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  // category filter start here
  const [brand, setbrand] = useState(true);
  const [category, setcategory] = useState(true);
  const [price, setprice] = useState(true);
  const [discount, setdiscount] = useState(false);
  const [filter, setfilter] = useState(false);
  const [currentwdith, setcurrentwdith] = useState(window.innerWidth);

  const [showsidebar, setshowsidebar] = useState(false);
  const [sortby, setsortby] = useState(false);
  const [subcategory, setsubcategory] = useState(false);
  const [acdropdown, setacdropdown] = useState(false);

  // home page api

  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [homedata, setHomeData] = useState([]);
  const [subcategorydata, setsubcategorydata] = useState({});
  const [hometopofferdata, setHomeTopOfferData] = useState([]);
  const [homesliderdata, setHomeSliderData] = useState([]);
  const [homebottomofferdata, setBottomOfferData] = useState([]);


  const updateDataValue2 = (key, newData) => {
    setIsHoveredGroup((prevData) => {
      const updatedData = { ...prevData, [key]: newData };
      return updatedData;
    });
  };
  // console.log("var of homesliderdata", homesliderdata);
  useEffect(() => {
    // console.log('token:',localdata)
    const requestOptions = {
      headers: localdata
        ? {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          }
        : {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
    };
    // console.log(requestOptions);
    const fetchHomeItems = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${apiUrl}/homeweb`, requestOptions);
        setHomeData(response?.data?.data);
        
        const updatedAttributes2 = {};
        response.data.data.categories.forEach((item, index) => {
          updatedAttributes2[item.slug] = false;
        });
        setIsHoveredGroup((prevData) => ({
          ...prevData,
          ...updatedAttributes2,
        }));
        // console.log("slider images", response.data);
        setHomeSliderData(response?.data?.data?.slider);
        setHomeTopOfferData(response?.data?.data?.topOffer);
        setBottomOfferData(response?.data?.data?.bottomOffer);
        // console.log('Fetched topsaleProducts:', response?.data?.data?.topsaleProducts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchHomeItems();
  }, []);
  const [wishlist, setWishlist] = useState([]);
  const handleAddWishlist = async (product_id) => {
   
    const collectdata = {
      product_id: product_id,
    };
 
    const fetchwishlistAd = async () => {
      // setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addToWishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          // setProductData(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== product_id) : []);
          
          setWishlist(response.data.success);
          
          setTimeout(() => {
            window.location.reload();
          }, 4000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistAd();
  };
  
  const handleRemoveWishlist = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistRemove = async () => {
      // setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          
          setWishlist(false);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistRemove();
  };
  const [AddWhishlistIcon, setAddWhishlistIcon] = useState(false);
  const [RemoveWhishlistIcon, setRemoveWhishlistIcon] = useState(true);
  const [AddWhishlistId, setAddWhishlistId] = useState(true);
  const handleAddWishlistChange = async (product_id) => {
    
    setAddWhishlistIcon(true);
    setAddWhishlistId(product_id);
    // console.log('r ids:',product_id);
    // console.log('true r ids:',AddWhishlistIcon);

}

const handleRemoveWishlistChange = async (product_id) => {

  setRemoveWhishlistIcon(false);
  setAddWhishlistId(product_id);
  // console.log('add ids:',product_id);
  // console.log('false add ids:',RemoveWhishlistIcon);
}
  return loading ? (
    <Loader />
  ) : (
    <div>
      <ToastContainer />
     {/* Top .owl-carousel  */}
     <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
     <section style={{ marginBottom: "0px" }}>
        <div className="container-fluid p-0 m-0">
          <div className="row">
            <div className="firstbigimageowl p-0">
              <OwlCarousel className="owl-theme" {...options1}>
                {homesliderdata
                  ? homesliderdata.map((slider_info, index) => (
                      // {homesliderdata?.[0] && homesliderdata?.map((slider_info,index) => (
                      <div className="item1" key={index}>
                        {/* <figure> */}
                        <h3 className="">
                          <img
                            src={slider_info.image}
                            alt={`slider${index}`}
                            className="Topbackground"
                          />
                          {/* </figure> */}
                        </h3>
                      </div>
                    ))
                  : ""}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      {/* CATEGORIES SECTION */}
      <section className="three-adv-sec mb-4">
        <div className="container-fluid">
          {/* <div className="col-xl-12 col-lg-12"> */}
          <div className="white-bg">
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between",alignItems:'stretch' }}
            >
              <div className="col-lg-3 py-3">
                <div className="card">
                  <div className=" collection-filter category-page-side">
                    {/* side-bar colleps block stat */}
                    <div className="collection-filter-block creative-card creative-inner category-side">
                      <div className="">
                        <div className="categoryList">
                          <h4>CATEGORIES</h4>
                        </div>

                        {/* <div
                          className="categoryList"
                          style={{ position: "relative" }}
                        >
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="https://sbmmarketplace.com/backend/public/uploads/2022/06/bnp.jpg%20FOR%20ICON"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp; Hand Protection{" "}
                          </a>
                          <div className="cat">
                            <ul>
                              <li>
                                <a href="">Impact Gloves</a>
                              </li>
                              <li>
                                <a href="">Anti-Vibration Gloves</a>
                              </li>
                            </ul>
                          </div>

                          <div className="cat2">
                            <ul>
                              <li>
                                <a href="">Welding Gloves</a>
                              </li>
                              <li>
                                <p>Red</p>
                              </li>
                              <li>
                                <p>Yellow</p>
                              </li>
                              <li>
                                <p>Green</p>
                              </li>
                              <li>
                                <p>Grey</p>
                              </li>
                              <li>
                                <p>16 Inch</p>
                              </li>
                              <li>
                                <p>14 Inch</p>
                              </li>
                              <li>
                                <p>Single Palm Welding Gloves</p>
                              </li>
                              <li>
                                <p>Tig Welding Gloves</p>
                              </li>
                              <li>
                                <p>Hockey Palm</p>
                              </li>
                              <li>
                                <p>Double Palm Welding Gloves</p>
                              </li>
                              <li>
                                <a href="">Leather Working Gloves</a>
                              </li>
                              <li>
                                <p>Double Palm</p>
                              </li>
                              <li>
                                <p>Single Palm</p>
                              </li>
                              <li>
                                <p>Patch Palm</p>
                              </li>
                              <li>
                                <a href="">Disposable Gloves</a>
                              </li>
                              <li>
                                <p>Blue</p>
                              </li>
                              <li>
                                <p>Black</p>
                              </li>

                              <li>
                                <p>Double Palm Welding Gloves</p>
                              </li>
                              <li>
                                <a href="">Leather Working Gloves</a>
                              </li>
                              <li>
                                <p>Double Palm</p>
                              </li>
                              <li>
                                <p>Single Palm</p>
                              </li>
                              <li>
                                <p>Patch Palm</p>
                              </li>
                              <li>
                                <a href="">Disposable Gloves</a>
                              </li>
                              <li>
                                <p>Blue</p>
                              </li>
                              <li>
                                <p>Black</p>
                              </li>
                            </ul>
                          </div>

                          <div className="cat3">
                            <ul>
                              <li>
                                <a href="">Chemical Gloves</a>
                              </li>
                              <li>
                                <p>Nitrile Flocklined</p>
                              </li>
                              <li>
                                <p>PVC Chemical Gloves</p>
                              </li>
                              <li>
                                <p>Latex Flocklined</p>
                              </li>
                              <li>
                                <a href="">Dotted Gloves</a>
                              </li>
                              <li>
                                <p>PVC</p>
                              </li>
                              <li>
                                <p>Double Side</p>
                              </li>
                              <li>
                                <p>Single Side</p>
                              </li>
                              <li>
                                <p>Polka</p>
                              </li>
                              <li>
                                <a href="">Knitted Gloves</a>
                              </li>
                              <li>
                                <p>600 Grams</p>
                              </li>
                              <li>
                                <p>400 Grams</p>
                              </li>
                              <li>
                                <p>450 Grams</p>
                              </li>
                              <li>
                                <p>500 Grams</p>
                              </li>
                            </ul>
                          </div>
                        </div> */}
{homedata.categories.map((item,index)=>(
  index < 9 ? <div className="categoryList" onMouseEnter={() => updateDataValue2(item.slug,true)}
        onMouseLeave={() => updateDataValue2(item.slug,false)}>
                          <a href="" onClick={(e)=>{e.preventDefault();nvg("/product-category-detail",{ state: { name: item.slug, cat_id:item.id } })}} className="catimage" onMouseEnter={() => updateDataValue2(item.slug,true)}
        onMouseLeave={() => updateDataValue2(item.slug,false)}>
                            <span>
                              <img
                                // src="    https://sbmmarketplace.com/backend/public/uploads/2022/06/saety-shoe-icon-03.jpg"
                                src={item.logo}
                                alt=""
                                style={{ width: "21px" }}
                                onMouseEnter={() => updateDataValue2(item.slug,true)}
        // onMouseLeave={() => updateDataValue2(item.slug,false)}
                              />
                            </span>
                            {/* &nbsp;Safety Shoes */}
                            &nbsp;{item.category_name}
                          </a>
                         {localdata ? 
                            <div className="cat" style={{display:isHoveredGroup[item.slug] ? 'block' : 'none'}} onMouseEnter={() => {updateDataValue2(item.slug,true)}}
        // onMouseLeave={() => updateDataValue2(item.slug,false)}
        >
                            <ul style={{height:'100%',overflowY:'scroll'}}>
        {item.child_categoires ? item.child_categoires.map((item2,index)=>(
                              <li className="activeonhover" onMouseEnter={() => {setsubcategorydata(item2.child_categoires)}}  >
                                <a href=""  onClick={(e)=>{e.preventDefault();nvg("/product-category-detail",{ state: { name: item2.slug, cat_id:item2.id } })}}>{item2.category_name}</a>
                                
                                {subcategorydata[0] ? <div className="cat2"
                                //  style={{display:isHoveredGroup[item.slug] ? 'block' : 'none'}}
                                 onMouseEnter={() => {updateDataValue2(item.slug,true);setsubcategorydata(item2.child_categoires)}}
                              // onMouseLeave={() => updateDataValue2(item.slug,false)}
                              >
                                                  <ul>
                                                  {subcategorydata[0] ? subcategorydata.map((item3,index3)=>(
                                                    <li>
                                                      <a href="" onClick={(e)=>{e.preventDefault();nvg("/product-category-detail",{ state: { name: item3.slug, cat_id:item3.id } })}}>{item3.category_name}</a>
                                                    </li>
                                                    )) : ''}
                                                  </ul>
                                                </div> : ''}
                            
                              </li>
                                )) : ''}
                              {/* <li>
                                <a href="">Formal Shoes</a>
                              </li> */}
                            </ul>
                          </div> : ''}

                            
                          
                        
                          

                          

                          {/* <div className="cat3" style={{display:isHovered ? 'block' : 'none'}} onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
                            <ul>
                              <li>
                                <a href="">Low Ankle</a>
                              </li>
                              <li>
                                <p>SBP Low Ankle</p>
                              </li>
                              <li>
                                <p>SIP Low Ankle</p>
                              </li>
                              <li>
                                <p>S3 Low Ankle</p>
                              </li>
                              <li>
                                <p>S3 SRC Low Ankle</p>
                              </li>
                              <li>
                                <p>SIP SRC Low Ankle</p>
                              </li>
                              <li>
                                <p>S2 SRC Low Ankle</p>
                              </li>
                              <li>
                                <p>S3 SRC Low Ankle</p>
                              </li>
                              <li>
                                <p>S3 SRC HI CI Low Ankle</p>
                              </li>
                              <li>
                                <p>S3 SRC HI CI ESD Low Ankle</p>
                              </li>
                              <li>
                                <p>SBP WRU Low Ankle</p>
                              </li>
                              <li>
                                <p>SI SRC Low Ankle</p>
                              </li>
                              <li>
                                <a href="">Protective Gumboot</a>
                              </li>
                              <li>
                                <p>S5 Protective Gumboot</p>
                              </li>
                              <li>
                                <a href="">Water Resistant Safety Shoes</a>
                              </li>
                            </ul>
                          </div> */}



                        </div> : ''
))}
                        {/* <div className="categoryList">
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="./images/autoimg.png"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp;Automotive
                          </a>

                          <div className="cat">
                            <ul>
                              <li>
                                <a href="">Bike Accessories</a>
                              </li>
                              <li>
                                <p>Motorcycle Helmets</p>
                              </li>
                              <li>
                                <p>Bike Lights</p>
                              </li>
                              <li>
                                <p>Bike Riding Gears</p>
                              </li>
                              <li>
                                <p>Bike Silencers</p>
                              </li>
                              <li>
                                <p>Bike Locks</p>
                              </li>
                              <li>
                                <a href="">Car Lightings</a>
                              </li>
                              <li>
                                <p>Tail Light</p>
                              </li>
                              <li>
                                <p>Headlight</p>
                              </li>
                              <li>
                                <p>Fog Lights</p>
                              </li>
                              <li>
                                <p>LED Projection Lights</p>
                              </li>
                            </ul>
                          </div>

                          <div className="cat2">
                            <ul>
                              <li>
                                <a href="">Spare Parts</a>
                              </li>
                              <li>
                                <p>Brake Pads</p>
                              </li>
                              <li>
                                <p>Brake Shoes</p>
                              </li>
                              <li>
                                <p>Brake Rotors</p>
                              </li>
                              <li>
                                <p>Dicky Lifter</p>
                              </li>
                              <li>
                                <p>Shock Absorber</p>
                              </li>
                              <li>
                                <a href="">Car Care & Cleaning</a>
                              </li>
                              <li>
                                <p>Car Polishes</p>
                              </li>
                              <li>
                                <p>Scratch Removers</p>
                              </li>
                              <li>
                                <p>Car Vacuum Cleaner</p>
                              </li>

                              <li>
                                <a href="">Automotive Tools</a>
                              </li>
                              <li>
                                <p>Air Compressors Inflators</p>
                              </li>
                              <li>
                                <p>Vehicle Maintenance Tools</p>
                              </li>
                            </ul>
                          </div>

                          <div className="cat3">
                            <ul>
                              <li>
                                <a href="">Automotive Filters</a>
                              </li>
                              <li>
                                <p>Oil Filter</p>
                              </li>
                              <li>
                                <p>Air Filter</p>
                              </li>

                              <li>
                                <a href="">Clutch Parts & Accessories</a>
                              </li>
                              <li>
                                <p>Clutch Set</p>
                              </li>
                              <li>
                                <p>Clutch Release Bearing</p>
                              </li>
                              <li>
                                <a href="">
                                  Hydraulic Jacks & Lifting Equipments
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="categoryList">
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="  https://sbmmarketplace.com/backend/public/uploads/2022/06/head-and-face-protection.jpg"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp;Hand & Face Protection
                          </a>

                          <div className="cat">
                           
                            <ul>
                              <li>
                                <a href="">Safety Helmet</a>
                              </li>
                              <li>
                                <p>Pin Lock</p>
                              </li>
                              <li>
                                <p>4 Ratchet Lock Helmet</p>
                              </li>
                              <li>
                                <p>Chin Strap</p>
                              </li>
                              <li>
                                <p>Ratchet</p>
                              </li>
                              <li>
                                <p>Fiber Metal</p>
                              </li>
                              <li>
                                <p>Full brim</p>
                              </li>
                              <li>
                                <p>ABS</p>
                              </li>
                              <li>
                                <p>Without Cup</p>
                              </li>
                              <li>
                                <p>With Cup</p>
                              </li>

                              <li>
                                <a href="">Face Shield</a>
                              </li>
                              <li>
                                <p>Polycarbonate</p>
                              </li>
                              <li>
                                <p>Universal Head Gear</p>
                              </li>
                              <li>
                                <p>Polycarbonate Chipping Lens</p>
                              </li>

                              <li>
                                <p>Welding Helmet</p>
                              </li>
                              <li>
                                <p>Fully adjustable ratchet headgear</p>
                              </li>
                              <li>
                                <p>Faceshield Bracket</p>
                              </li>

                              <li>
                                <p>Fully adjustable ratchet</p>
                              </li>
                              <li>
                                <p>Faceshield Headgear</p>
                              </li>

                              <li>
                                <p>Faceshield with bracket</p>
                              </li>
                            </ul>
                            
                          </div>

                          <div className="cat2">
                            <ul>
                              <li>
                                <a href="">Cap</a>
                              </li>
                              <li>
                                <p>Bump Cap</p>
                              </li>
                              <li>
                                <p>Clip Cap</p>
                              </li>
                              <li>
                                <p>Disposable</p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="categoryList">
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="    https://sbmmarketplace.com/backend/public/uploads/2022/06/hearing-protection.jpg"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp;Hearing Protection
                          </a>
                          <div className="cat">
                            <ul>
                              <li>
                                <a href="">Multi Position Ear Muff</a>
                              </li>
                              <li>
                                <a href="">Uncorded</a>
                              </li>
                            </ul>
                          </div>

                          <div className="cat2">
                            <ul>
                              <li>
                                <a href="">Corded</a>
                              </li>

                              <li>
                                <a href="">Reusable</a>
                              </li>

                              <li>
                                <a href="">Dielectric</a>
                              </li>
                            </ul>
                          </div>

                          <div className="cat3">
                            <ul>
                              <li>
                                <a href="">Ear Pulg</a>
                              </li>

                              <li>
                                <a href="">Helmet Mount Ear Muff</a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="categoryList">
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="    https://sbmmarketplace.com/backend/public/uploads/2022/06/respereatiory-protection.jpg"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp;Respiratory Protection{" "}
                          </a>

                          <div className="cat">
                            <ul>
                              <li>
                                <a href="">Multi-Purpose</a>
                              </li>
                              <li>
                                <a href="">Retainer</a>
                              </li>
                            </ul>
                          </div>

                          <div className="cat2">
                            <ul>
                              <li>
                                <a href="">Filter</a>
                              </li>

                              <li>
                                <a href="">Cartridge With Filter</a>
                              </li>

                              <li>
                                <a href="">Cartridge</a>
                              </li>

                              <li>
                                <p>Organic</p>
                              </li>
                            </ul>
                          </div>

                          <div className="cat3">
                            <ul>
                              <li>
                                <a href="">Disposable Mask</a>
                              </li>
                              <li>
                                <p>3ply</p>
                              </li>
                              <li>
                                <p>N95</p>
                              </li>
                              <li>
                                <p>KN95</p>
                              </li>

                              <li>
                                <p>FFP2</p>
                              </li>
                              <li>
                                <p>FFP3</p>
                              </li>
                              <li>
                                <p>Cup Shape</p>
                              </li>
                              <li>
                                <p>With Valve</p>
                              </li>

                              <li>
                                <p>Without Valve</p>
                              </li>
                              <li>
                                <p>Flat Fold</p>
                              </li>
                              <li>
                                <p>Non-Medical</p>
                              </li>
                              <li>
                                <p>Medical</p>
                              </li>
                              <li>
                                <p>Welding Mask</p>
                              </li>
                              <li>
                                <a href="">Industrial Face Mask</a>
                              </li>
                              <li>
                                <p>Half Mask</p>
                              </li>
                              <li>
                                <p>Full Mask</p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="categoryList">
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="    https://sbmmarketplace.com/backend/public/uploads/2022/06/saftety-clothing.jpg"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp;Safety Clothing
                          </a>
                          <div className="cat">
                            <ul>
                              <li>
                                <a href="">T-Shirt</a>
                              </li>
                              <li>
                                <p>Half Sleeve</p>
                              </li>
                              <li>
                                <p>Full Sleeve</p>
                              </li>
                              <li>
                                <a href="">Leather Items</a>
                              </li>
                              <li>
                                <p>WELDING APRON</p>
                              </li>
                              <li>
                                <p>WELDING SLEEVE</p>
                              </li>
                              <li>
                                <p>WELDING LEG GUARD</p>
                              </li>
                            </ul>
                          </div>

                          <div className="cat2">
                            <ul>
                              <li>
                                <a href="">Safety Jacket</a>
                              </li>

                              <li>
                                <p>Without Pocket</p>
                              </li>
                              <li>
                                <p>With Pocket</p>
                              </li>

                              <li>
                                <p>With Zipper</p>
                              </li>
                              <li>
                                <p>2 Reflective</p>
                              </li>

                              <li>
                                <p>4 Reflectives</p>
                              </li>
                              <li>
                                <p>With Piping</p>
                              </li>
                              <li>
                                <p>With Net</p>
                              </li>
                              <li>
                                <p>Fabric</p>
                              </li>
                              <li>
                                <p>Life Jacket</p>
                              </li>
                              <li>
                                <a href="">Protective Coverall</a>
                              </li>
                              <li>
                                <p>Coverall</p>
                              </li>
                              <li>
                                <p>Pant & Shirt</p>
                              </li>
                              <li>
                                <p>Trouser</p>
                              </li>
                              <li>
                                <p>Winter Jackets</p>
                              </li>
                              <li>
                                <p>Are Flash Kit</p>
                              </li>

                              <li>
                                <p>PVC</p>
                              </li>
                              <li>
                                <p>Air Flash Coverall</p>
                              </li>
                            </ul>
                            
                          </div>
                        </div>

                        <div className="categoryList">
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="    https://sbmmarketplace.com/backend/public/uploads/2022/06/fall-protection.jpg"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp;Fall Protection
                          </a>

                          <div className="cat">
                            <ul>
                              <li>
                                <a href="">Retractable Fall Arrest</a>
                              </li>
                              <li>
                                <p>Single Length Retractable</p>
                              </li>
                              <li>
                                <p> Double Length Retractable</p>
                              </li>
                              <li>
                                <a href="">Harness</a>
                              </li>
                              <li>
                                <p>Twin Webbing Lanyard</p>
                              </li>
                              <li>
                                <p>Suspension Trauma</p>
                              </li>
                              <li>
                                <p>Integrated Work-Positioning Belt</p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="categoryList">
                          <a href="" className="catimage">
                            <span>
                              <img
                                src="    https://sbmmarketplace.com/backend/public/uploads/2022/06/hard-wear-and-tools.jpg"
                                alt=""
                                style={{ width: "21px" }}
                              />
                            </span>
                            &nbsp;Hardware & Tools
                          </a>
                          <div className="cat">
                            <ul>
                              <li>
                                <a href="">TATA AGRICO </a>
                              </li>
                              <li>
                                <p>Diamond Blade Continuous Rim</p>
                              </li>
                              <li>
                                <p>Insulated Wire Stripper</p>
                              </li>
                              <li>
                                <p>Screwdriver YNB Slotted Head</p>
                              </li>
                              <li>
                                <p>Screwdriver Two in One</p>
                              </li>
                              <li>
                                <p>Pruning Secateur Roll Cut With</p>
                              </li>
                              <li>
                                <p>Dip Sleeve</p>
                              </li>
                              <li>
                                <p>Sliding T Bar</p>
                              </li>
                              <li>
                                <p>Water Pump Piler</p>
                              </li>
                              <li>
                                <p>T-Socket Spanner</p>
                              </li>
                              <li>
                                <p>T-Allen Key</p>
                              </li>
                              <li>
                                <a href="">Hoist</a>
                              </li>
                              <li>
                                <p>Chain Hoist</p>
                              </li>
                              <li>
                                <p>Lever Hoist</p>
                              </li>
                              <li>
                                <p>Micro Electric Hoist</p>
                              </li>
                              <li>
                                <p>Electric Chain Hoist</p>
                              </li>
                            </ul>
                          </div>

                          <div className="cat2">
                            <ul>
                              <li>
                                <a href="">Pulley</a>
                              </li>

                              <li>
                                <a href="">Life Bouy</a>
                              </li>

                              <li>
                                <a href="">Switchborad Matting</a>
                              </li>
                              <li>
                                <p>Class 4</p>
                              </li>
                              <li>
                                <p>Class B</p>
                              </li>
                            </ul>
                          </div>

                          <div className="cat3">
                            <ul>
                              <li>
                                <a href="">Cotton Rags</a>
                              </li>

                              <li>
                                <p>8Kg</p>
                              </li>
                              <li>
                                <a href="">Spill Kit</a>
                              </li>
                              <li>
                                <p>Universal Bonded Type Sorbent</p>
                              </li>
                              <li>
                                <p>Pad</p>
                              </li>
                              <li>
                                <p>Oil Sorbent Pad</p>
                              </li>
                              <li>
                                <p>Oil Spill Kit</p>
                              </li>
                              <li>
                                <p>Chemical Absorbent Pads</p>
                              </li>
                              <li>
                                <a href="">Ice Cooler</a>
                              </li>

                              <li>
                                <p>70 Liters</p>
                              </li>
                            </ul>
                          </div>
                        </div> */}

                        <div onClick={()=>{nvg('/product-category')}}>
                          <a className="category" href="javascript:void(0);" onClick={()=>{nvg('/product-category')}}>
                            See All Categories
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 py-3">
                <div className="row givegap desginforheight">
                  <div className="col-lg-7 pick1 height2">
                    <a
                      style={{
                        width: "100%",
                        display: "block",
                        height: "100%",
                      }}
                      href={hometopofferdata.offer_top_1_url}
                    >
                      <img
                        src={hometopofferdata.offer_top_1}
                        style={{ width: "100%", height: "100%" }}
                        alt="..."
                      />
                    </a>
                  </div>

                  <div className="col-lg-5 newsize3458" style={{ display: "block",height:'100%' }}>
                    <a className="extrapadding2 finalwidth" style={{height:'50%',display:'flex',justifyContent:'center'}} href={hometopofferdata.offer_top_2_url}>
                      <img
                        src={hometopofferdata.offer_top_2}
                        alt="..."
                        style={{ height:'100%',paddingBottom: "10px",width:'78%' }}
                      />
                    </a>

                    <a className="finalwidth" style={{height:'50%',display:'flex',justifyContent:'center'}} href={hometopofferdata.offer_top_3_url}>
                      <img
                        src={hometopofferdata.offer_top_3}
                        style={{ height: "100%",width:'78%' }}
                        alt="..."
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>

    {/* Best Selling Products  */}
    <section style={{ marginBottom: "22px",padding:'0px 24px' }}>
        <div className="white-bg">
          <h2 className="BestSelling">Best Selling Products</h2>
          <OwlCarousel className="owl-theme" {...options2}>
            {homedata?.topsaleProducts &&
              homedata?.topsaleProducts.map((top_selling_info, index) => (
                <div className={localdata ? "item" : "itematv"} key={index}>
                 <div className="heartimg">
                    {localdata ? (
                      top_selling_info.wishlist === true ? (
                        <a  onClick={() =>
                          {handleRemoveWishlist(top_selling_info.id);handleRemoveWishlistChange(top_selling_info.id)}} href="javascript:void(0);" className="addToWish">
                            {RemoveWhishlistIcon == false && top_selling_info.id == AddWhishlistId ? (
                          <i className="fa fa-heart"></i>
                          ):(
                            <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                            alt="Wishlist"
                          />
                            
                            )}
                        </a>
                      ) : (
                        <a  onClick={() =>
                          {handleAddWishlist(top_selling_info.id);handleAddWishlistChange(top_selling_info.id)}} href="javascript:void(0);" className="addToWish">
                          {AddWhishlistIcon == true && top_selling_info.id == AddWhishlistId ? (
                             <img
                             src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                             alt="Wishlist"
                           /> 
                          ):(
                          <i className="fa fa-heart"></i>
                          )}
                        
                          </a>
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      nvg("/product-detail/" + top_selling_info.slug);
                    }}
                  >
                    <h3 className="owlimg1 py-1">
                      {" "}
                      <img
                        src={top_selling_info.main_image}
                        alt={top_selling_info.product_name}
                      />
                    </h3>
                  </a>
                  <a
                    href="javascript:void(0);"
                    style={{ color: "#333" }}
                    onClick={() => {
                      nvg("/product-detail/" + top_selling_info.slug);
                    }}
                  >
                    <span className="textimage">
                      <center>{top_selling_info.product_name.length > 37 ?  `${top_selling_info.product_name.substr(0,37)}..` : top_selling_info.product_name}</center>
                    </span>
                  </a>
                  {localdata ? (
                    <span className="text2image">
                      <center>
                        <h2
                          className="mt-2"
                          style={{
                            color: "#000",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          AED <span>{top_selling_info.uprice}</span>
                        </h2>
                      </center>
                    </span>
                  ) : (
                    <span className="text2image" style={{display:'none'}}>
                      <center>
                        <h2
                          className="mt-2"
                          style={{
                            color: "#000",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          AED <span>{top_selling_info.regular_price}</span>
                        </h2>
                      </center>
                    </span>
                  )}
                </div>
              ))}
          </OwlCarousel>
        </div>
      </section>

    {/* EAR PROTECTION IMAGE SECTION */}
    <section>
        <div className="container-fluid">
          {/* <div className="2imagecol" style={{ display: 'flex', justifyContent: 'centre', gap: '10px' }}> */}
          <div className="row twoimagecol">
            <div className="col-xl-6 col-lg-6 col-md-6 left">
              <div
                dangerouslySetInnerHTML={{
                  __html: homebottomofferdata.offer_bottom_1_contents,
                }}
              />

              {homebottomofferdata.offer_bottom_1 && (
                <div>
                  {" "}
                  <img
                    src={homebottomofferdata.offer_bottom_1}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 right">
              <div
                dangerouslySetInnerHTML={{
                  __html: homebottomofferdata.offer_bottom_2_contents,
                }}
              />

              {homebottomofferdata.offer_bottom_2 && (
                <div>
                  {" "}
                  <img
                    src={homebottomofferdata.offer_bottom_2}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>

      {/* .......Shoes Safety.......  */}

      {homedata.catProductsList &&
        homedata.catProductsList.map((cat_product_info, index) => (
          <section style={{ marginBottom: "20px",padding:'0px 24px' }}>
            <div className="white-bg">
              <h2 className="BestSelling">
                {cat_product_info.category_name}{" "}
                <span>
                  <a className="allbutton">
                    <button
                      type="button"
                      className="btn btn-primary custombtn2"
                      onClick={() => {
                        nvg("/product-category-detail", {
                          state: {
                            name: cat_product_info.slug,
                            cat_id: cat_product_info.id,
                          },
                        });
                      }}
                    >
                      VIEW ALL
                    </button>
                  </a>
                </span>
              </h2>

              <OwlCarousel className="owl-theme" {...options3}>
                {cat_product_info.products &&
                  cat_product_info.products.map(
                    (cat_child_product_info, index) => (
                      <div className={localdata ? "item" : "itematv"}>
                        <div className="heartimg">
                        {localdata ? (
                      cat_child_product_info.wishlist === true ? (
                        <a  onClick={() =>
                          {handleRemoveWishlist(cat_child_product_info.id);handleRemoveWishlistChange(cat_child_product_info.id)}} href="javascript:void(0);" className="addToWish">
                            {RemoveWhishlistIcon && AddWhishlistId === cat_child_product_info.id ? (
                          <i className="fa fa-heart"></i>
                          ):(
                            <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                            alt="Wishlist"
                          />
                            
                            )}
                        </a>
                      ) : (
                        <a  onClick={() =>
                          {handleAddWishlist(cat_child_product_info.id);handleAddWishlistChange(cat_child_product_info.id)}} href="javascript:void(0);" className="addToWish">
                          {AddWhishlistIcon && AddWhishlistId === cat_child_product_info.id ? (
                             <img
                             src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                             alt="Wishlist"
                           /> 
                          ):(
                          <i className="fa fa-heart"></i>
                          )}
                        
                          </a>
                      )
                    ) : (
                      ""
                    )}
                          {/* {localdata ? (
                            cat_child_product_info.wishlist === true ? (
                              <a
                                href="javascript:void(0);"
                                className="addToWish"
                              >
                                <img
                                  src={${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png}
                                  alt="Wishlist"
                                />
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0);"
                                className="addToWish"
                              >
                                <i className="fa fa-heart"></i>
                              </a>
                            )
                          ) : (
                            ""
                          )} */}
                        </div>
                        {/* <h3 className="owlimg1"> <img src="./images/s1.jpg" alt="" /></h3> */}
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            nvg(
                              "/product-detail/" + cat_child_product_info.slug
                            );
                          }}
                        >
                          <h3 className="owlimg1">
                            {" "}
                            <img
                              src={cat_child_product_info.main_image}
                              alt={cat_child_product_info.product_name}
                            />
                          </h3>
                        </a>
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            nvg(
                              "/product-detail/" + cat_child_product_info.slug
                            );
                          }}
                          style={{ color: "#333" }}
                        >
                          <span className="textimage">
                            {/* <center>
                              {cat_child_product_info.product_name}
                            </center> */}
                      <center>{cat_child_product_info.product_name.length > 37 ? `${cat_child_product_info.product_name.substr(0,37)}..` : cat_child_product_info.product_name}</center>

                          </span>
                        </a>
                        <br />
                        {localdata ? (
                          <span className="text2image">
                            <center>
                              <h2
                                style={{
                                  color: "#000",
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                AED{" "}
                                <span> {cat_child_product_info.uprice}</span>
                              </h2>
                            </center>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  )}
              </OwlCarousel>
            </div>
          </section>
        ))}

      {/* ....... Our Top Brands......... */}
      <section style={{ marginBottom: "20px",padding:'0px 24px' }}>
        <div className="white-bg">
          <h2 className="BestSelling">Our Top Brands</h2>
          <OwlCarousel className="owl-theme" {...options7}>
            {homedata.brands &&
              homedata.brands.map((brand_info, index) => (
                <div className="item2">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      nvg("/brand-product/" + brand_info.id);
                    }}
                    style={{ color: "#333" }}
                  >
                    <h3 className="">
                      <img
                        src={brand_info.brand_logo}
                        alt={brand_info.brand_name}
                      />
                    </h3>
                  </a>
                </div>
              ))}
          </OwlCarousel>
        </div>
      </section>

      {/* Respiratory Protection */}

      {homedata.catProductsList1 &&
        homedata.catProductsList1.map((cat_product_info1, index) => (
          <section style={{ marginBottom: "20px",padding:'0px 24px' }}>
            <div className="white-bg">
              <h2 className="BestSelling">
                {cat_product_info1.category_name}{" "}
                <span>
                  <a className="allbutton">
                    <button
                      type="button"
                      className="btn btn-primary custombtn2"
                      onClick={() => {
                        nvg("/product-category-detail", {
                          state: {
                            name: cat_product_info1.slug,
                            cat_id: cat_product_info1.id,
                          },
                        });
                      }}
                    >
                      VIEW ALL
                    </button>
                  </a>
                </span>
              </h2>

              <OwlCarousel className="owl-theme" {...options8}>
                {cat_product_info1.products &&
                  cat_product_info1.products.map(
                    (cat_child_product_info1, index) => (
                      <div className={localdata ? "item" : "itematv"}>
                         <div className="heartimg">
                        {localdata ? (
                      cat_child_product_info1.wishlist === true ? (
                        <a  onClick={() =>
                          {handleRemoveWishlist(cat_child_product_info1.id);handleRemoveWishlistChange(cat_child_product_info1.id)}} href="javascript:void(0);" className="addToWish">
                            {RemoveWhishlistIcon && AddWhishlistId === cat_child_product_info1.id ? (
                          <i className="fa fa-heart"></i>
                          ):(
                            <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                            alt="Wishlist"
                          />
                            
                            )}
                        </a>
                      ) : (
                        <a  onClick={() =>
                          {handleAddWishlist(cat_child_product_info1.id);handleAddWishlistChange(cat_child_product_info1.id)}} href="javascript:void(0);" className="addToWish">
                          {AddWhishlistIcon && AddWhishlistId === cat_child_product_info1.id ? (
                             <img
                             src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                             alt="Wishlist"
                           /> 
                          ):(
                          <i className="fa fa-heart"></i>
                          )}
                        
                          </a>
                      )
                    ) : (
                      ""
                    )}
                          {/* {localdata ? (
                            cat_child_product_info1.wishlist === true ? (
                              <a
                                href="javascript:void(0);"
                                className="addToWish"
                              >
                                <img
                                  src={${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png}
                                  alt="Wishlist"
                                />
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0);"
                                className="addToWish"
                              >
                                <i className="fa fa-heart"></i>
                              </a>
                            )
                          ) : (
                            ""
                          )} */}
                        </div>

                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            nvg(
                              "/product-detail/" + cat_child_product_info1.slug
                            );
                          }}
                        >
                          <h3 className="owlimg1">
                            {" "}
                            <img
                              src={cat_child_product_info1.main_image}
                              alt={cat_child_product_info1.product_name}
                            />
                          </h3>
                        </a>
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            nvg(
                              "/product-detail/" + cat_child_product_info1.slug
                            );
                          }}
                          style={{ color: "#333" }}
                        >
                          <span className="textimage">
                            {/* <center>
                              {cat_child_product_info1.product_name}
                            </center> */}
                            <center>{cat_child_product_info1.product_name.length > 37 ? `${cat_child_product_info1.product_name.substr(0,37)}..` : cat_child_product_info1.product_name}</center>

                          </span>
                        </a>
                        <br />
                        {localdata ? (
                          <span className="text2image">
                            <center>
                              <h2
                                style={{
                                  color: "#000",
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                AED{" "}
                                <span> {cat_child_product_info1.uprice}</span>
                              </h2>
                            </center>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  )}
              </OwlCarousel>
            </div>
          </section>
        ))}

      {/* ............Quality section.............. */}
      <section className="quality-sec mb-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="white-bg">
                <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <div className="quality-block">
                      <img
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/walletimg.png`}
                        alt="Great Value"
                      />
                      <h4>Great Value</h4>
                      <p>
                        With every purchase from SBM, you get optimum value for
                        your money.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <div className="quality-block">
                      <img
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/checkimg.png`}
                        alt="Trustpay"
                      />
                      <h4>Trustpay</h4>
                      <p>
                        Your payments are in safe hands. Shop worry-free with
                        us.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <div className="quality-block">
                      <img
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/check2.png`}
                        alt="100% Secure Payments"
                      />
                      <h4>100% Secure Payments</h4>
                      <p>
                        Be it any form of payment, the secure gateway ensures
                        everything is flawless.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <div className="quality-block">
                      <img
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/help-centre.png`}
                        alt="Help Center"
                      />
                      <h4>Help Center</h4>
                      <p>
                        With 24x7 customer support, you can enjoy a world class
                        service experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
