import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom'
import { FaUser } from "react-icons/fa";
import { getToken } from "../../services/LocalStorageSrv";
import { removeToken } from "../../services/LocalStorageSrv";
import Logout from "../../Pages/Logout";
// import Loader from '../../Loader';
const Vtheaderright = () => {
  const [loading, setLoading] = useState(true);
    const localdata = getToken('data')
    // console.log(localdata.data.token)
  return (

  
  <div className="header-right">
    {/* {loading ? <Loader /> : null} */}
    {localdata  ?
     <div className="user-login">
    <NavLink to="#">
      <span className='showmobileuser'> <FaUser />  </span>
      <span className='hidemobileuser'> My Account <small>({localdata.data.vendor_code})</small> </span>
    </NavLink>
    <div className="user-setiing">
      <ul>
        <li className="paddinginmobile">
          <NavLink to="/profile">
            My Profile </NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/invoice">
            Invoices
          </NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/myorders">
            My Orders</NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/myitems">
            My Items</NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/rfq">
            My RFQ</NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/whishlist">
            Wishlist</NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/address">
            Address</NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/password">
            Password</NavLink>
        </li>
        <li className="paddinginmobile">
          <NavLink to="/download-video">
            Download Videos</NavLink>
        </li>
        <li>
        <Logout />
         
        </li>
      </ul>
    </div>
  </div>
    :
    <div className="user-login">
    <a href="javascript:void(0);" >
      <i className="fa fa-user" aria-hidden="true" />
      <span>Login</span>
    </a>
  </div>   
}

  <div>
    {/* <b-modal id="modal-multi-1" modal-class="signin-modal" >
      <button type="button" className="close" >
        <img src="./images/icons/popup-close-btn.png" alt="Popup close" />
      </button>
      <h5 className="modal-title text-center mt-5 pb-4">Sign In </h5>
      <vtlogin>
        <div className="col-md-12 col-12">
          <div className="row">
            <div className="forgot text-left col-6">
              <a href="javascript:void(0)" className="active">Retrive Access</a>
            </div>
            <div className="forgot text-right col-6">
              <a href="javascript:void(0)">Forgot Password?</a>
            </div>
          </div>
        </div>
        <div className="socialmedia-row mb-4">
        </div> 
        <div className="signin-footer pt-3 pb-3">
          <div className="signup-text text-center">
            <h6>
              Don’t have an account?
              <a href="javascript:void(0)" className="active" >SIGN UP NOW</a>
            </h6>
            <span>By signing up you agree to our
              <a href="#">Terms of Use</a></span>
          </div>
        </div>
      </vtlogin></b-modal> */}
    {/* <b-modal  size="lg" id="modal-signup" modal-class="signup-modal" hide-footer hide-header>
      <button type="button" className="close" >
        <img src="./images/icons/popup-close-btn.png" alt="Popup close" />
      </button>
      <h5 className="modal-title text-center mt-5 pb-4">Sign Up</h5>
      <vtregister>
      </vtregister></b-modal> */}
    {/*vendor*/}
    {/* <b-modal  id="modal-vender" modal-class="signup-modal" hide-footer hide-header>
      <button type="button" className="close">
        <img src="./images/icons/popup-close-btn.png" alt="Popup close" />
      </button>
      <h5 className="modal-title text-center mt-5 pb-4">
        Access Request
      </h5> */}
      {/* <vtvender>
      </vtvender> */}
      {/* </b-modal> */}
    {/*Forgot Pass*/}
    {/* <b-modal  id="modal-forgotpass" modal-class="signup-modal" hide-footer hide-header>
      <button type="button" className="close">
        <img src="./images/icons/popup-close-btn.png" alt="Popup close" />
      </button>
      <h5 className="modal-title text-center mt-5 pb-4">
        Forgot Password
      </h5> */}
      {/* <vtvender> */}
        {/* <VTForgotPass/> */}
      {/* </vtvender> */}
      {/* </b-modal> */}
  </div>
</div>

  )
}

export default Vtheaderright