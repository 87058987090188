import React, { useEffect, useState } from "react";
import Header from '../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { getToken } from "../services/LocalStorageSrv";
import axios from 'axios';
import ReactPaginate from "react-paginate";
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const Blog= () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
const [loading, setLoading] = useState(true);
const [myblog, setBlogDetails] = useState([]);
const [mycategory, setCategoryDetails] = useState([]);
const [currentpageno, setcurrentpageno] = useState(1);
const [totalPages, setTotalPages] = useState(1);
useEffect(() => {
  const fetchBlogData= async () => {
    try {
      
       setLoading(true);
       const response = await axios.get(`${apiUrl}/blog?page=${currentpageno}`,{
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
          },
        });
      setBlogDetails(response.data.data);
      setTotalPages(response.data.data.last_page);
      // console.log('blog:',response.data.data.last_page);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchCategoryData= async () => {
    try {
      
       setLoading(true);
       const response = await axios.get(`${apiUrl}/productCategory`,{
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
          },
        });
      setCategoryDetails(response.data.data.data);
      // console.log('cat:',response.data.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  fetchBlogData();
  fetchCategoryData();
},[currentpageno]);
const handlePageChange = (e) => {
  console.log('e:',e);
  setcurrentpageno(e);
  // handlePageChange("page", e);
};
  
  return (

    <div>
{loading ? <Loader /> : null}
<Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
       <section class="blog-detail-sec inner-page-bg">
   
   <div class="container-fluid">
     <div class="hedding-text d-flex justify-content-center">
       <h2 class="text-capitalize">Blogs</h2>
     </div>
     
    
     
      <div className="row" >
         <div className="row blogsection" style={{display:'flex'}}>
         
         
       <div class="col-lg-8 col-sm-12 post-detail">
       {myblog.data?.[0] ? (
                  myblog.data.map((blog,index) => (
         <div class="post-block1" onClick={()=>{nvg('/blog-detail/' + blog.slug)}}  key={index}>
           <img src={blog.blog_image} alt={blog.blog_name} />
           <div class="comment-block1">
            <a href="javascript:void(0);"  onClick={()=>{nvg('/blog-detail/' + blog.slug)}}><h2 className="post-heading">{blog.blog_name ? blog.blog_name : ''}</h2></a>
            <div className="entry-content1">
            <p dangerouslySetInnerHTML={{__html: blog.blog_description}}></p>
            </div>

             <div className="post-footer1">
              <div className="post-meta">
                <div className="post-date1">{new Date(blog.blog_date).toLocaleDateString('en-GB')}</div>
                <div className="social-share" key={index}>
                  <span style={{paddingRight:'15px'}}>Share:</span>
                  <ul style={{display:'flex',paddingRight:'20px'}}  key={index}>
                    <li><a href=""><i class="fa-brands fa-facebook-f" style={{paddingRight:'15px'}}></i></a></li>
                    <li><i class="fa-brands fa-twitter" style={{paddingRight:'15px'}}></i></li>
                    <li><i class="fa-brands fa-linkedin-in" style={{paddingRight:'10px'}}></i></li>
                  
                  </ul>
                </div>
              </div>
             </div>

           </div>
         </div>
 ))
 ) : (
   <div class="post-block1">
     Blog Not Available.
   </div>
 )}

{myblog.data?.[0] ? (
                  <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={(selected) => {
                      handlePageChange(selected.selected + 1);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                  ) : (
                    ''
                  )} 
       </div>
       

       <div class="col-lg-4 col-sm-12 sidebar" style={{width:'32%',alignSelf:'flex-start'}}>
        {/* <div className="row"> */}
        <div className="popular-post-block "><br/>
          <h2 className="block-title text-uppercase">POPULAR POST</h2>
        </div>
        <div className="popular-post1-block ">
          <h2 className="block-title text-uppercase">POPULAR TAGS</h2>
          <ul>
<br/>
{mycategory?.[0] ? (
                  mycategory.map((cat,index) => (
            <li>
              <a href="javascript:void(0);" onClick={() => {
                                      nvg("/product-category-detail", {
                                        state: {
                                          name: cat.slug,
                                          cat_id: cat.id,
                                        },
                                      });
                                    }} className="popular-tags">{cat.category_name}</a>
            </li>
))
) : (
  <li>
    Category Not Available.
  </li>
)}

          </ul>
        </div>
        {/* </div> */}
        



      
       
       
       </div>

       </div>
      
       
       </div>
      




  

      












       
      
     
        
    
   
    </div>
 </section>




      <Footer />
    </div>

  )
}

export default Blog;