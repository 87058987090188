import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';
// import Loader from '../Loader';
const PrivacyPolicy = () => {
  //   const [showsidebar, setshowsidebar] = useState(false);

  return (

    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
                 <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section class="bulkorder-sec pb-5 mt-5">
        <div class="container">

          <div class="row d-flex justify-content-center">

            <div class="bulkorder-heading text-center mt-5">
              <h3> Privacy Policy </h3>
            </div>
            < div className="row w-75 ml-auto mr-auto mt-4 ct_privacy_para" >
              <p className="bold"> Last Updated: 20.06.2023</p>

              <p>Welcome to SBM Market Place ("us", "we", or "our")! We are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect the personal information you provide when using our website www.sbmmarketplace.com (the "Website"). Please read this Privacy Policy carefully to understand our practices regarding your personal information.</p>
              <div className="privacysmalltext">
                <p>A privacy policy is a statement or a legal document (in privacy law) that discloses some or all of the ways a party gathers, uses, discloses, and manages a customer or client's data.</p>
              </div>
              <div className="privacysmalltext">
                <p>"All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties".</p>
                </div>


                <div className="privacysmalltext">
                <p>"The Website Policies and Terms & Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, the Customers' are encouraged to frequently visit these sections in order to be updated about the changes on the website. Modifications will be effective on the day they are posted".</p>
                </div>

                <div className="privacysmalltext">
                <p>By accessing or using our website, you consent to the collection, use, disclosure, and processing of your personal information as described in this Privacy Policy. If you do not agree with the practices described in this Privacy Policy, please do not use the Website.</p>
                </div>

                 
                <div className="privacysmall">
                <p className="bold"> Nature of Business:</p>
                <p>SBM Market place is an e-commerce platform that offers a wide range of products to our customers. We strive to provide a user-friendly and secure online shopping environment where individuals can browse, select, and purchase various goods conveniently. Our business involves the collection and processing of personal information to facilitate the purchase, delivery, and customer support processes.</p>
                </div>


                <div className="privacysmall">
                <p className="bold">Description of the Business:</p>
                <p>At SBM Market Place, our mission is to provide our customers with high-quality products, exceptional service, and a delightful shopping experience. Our website features an extensive range of products across multiple categories, catering to diverse customer preferences. We partner with reliable suppliers and manufacturers to ensure that our customers receive authentic and reliable products.</p>
                </div>


                <div className="privacysmalltext">
                <p>Our user-friendly interface allows customers to easily navigate through our product catalog, add items to their shopping cart, and proceed with secure online payments. We strive to provide accurate product descriptions, images, and pricing information to assist customers in making informed purchasing decisions.</p>
                </div>

                <p className="info">Information We Collect</p>



                <div className="privacysmall">
                <p className="bold">1.1. Personal Information:</p>
                <p>We may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, billing and shipping addresses, payment information, and any other information you provide while using our website.</p>
                </div>



                <div className="privacysmall">
                <p className="bold">1.2. Usage Data:</p>
                <p>We may also collect non-personal information about your interactions with our Website, such as your IP address, browser type, operating system, referring URL, pages visited, and the dates and times of your visits.</p>
                </div>

                <p className="info">Use of Information</p>

                <p className="info">2.1. Personal Information:</p>

                 <p className="infotext">We use the personal information you provide to us for the following purposes:</p>
                <p>To process and fulfill your orders and provide the requested products and services.</p>
                <p>To communicate with you regarding your orders, inquiries, and customer support.</p>
                 <p>To send you promotional offers, newsletters, and marketing communications, subject to your preferences.</p>
                 <p>To personalize your experience on our Website and tailor content and recommendations.</p>
                 <p>To improve our products, services, and Website based on your feedback and usage patterns.</p>
                 <p>To protect the security and integrity of our Website and prevent fraud or other unauthorized activities.</p>
                 <p>To comply with applicable laws, regulations, or legal requirements.</p>

                 <p className="info">2.2. Usage Data:</p>

                 <div className="privacysmall">
                
                <p>We may use non-personal information for statistical analysis, research, and monitoring of our Website's usage patterns, trends, and user demographics. This information helps us to understand and enhance the user experience on our Website.</p>
                </div>
                <p className="bold">Sharing of Information</p>
                <p className="info2">3.1. Service Providers:</p>
                <div className="privacysmall">
                <p>We may engage third-party service providers to assist us in operating and maintaining our Website, such as payment processors, shipping companies, and marketing partners. These service providers may have access to your personal information only to perform services on our behalf and are obligated to protect your information.</p>
                </div>

                <p className="info2">3.2. Legal Requirements:</p>
                <div className="privacysmall">
                <p>We may disclose your personal information if required by law or in good faith belief that such action is necessary to comply with applicable laws, regulations, legal processes, or government requests.</p>
                </div>

                <p className="info2">3.3. Business Transfers:</p>
                <div className="privacysmall">
                <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity as part of the transaction. We will notify you via email or prominent notice on our Website of any change in ownership or use of your personal information.</p>
                </div>

                <p style={{paddingTop:'20px'}}>Data Security We take reasonable measures to protect the personal information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.</p>
               
                <p className="infotext" style={{fontWeight:'bold'}}>Your Choices and Rights</p>

                <p className="info2">5.1. Access, Correction, and Deletion:</p>
                <div className="privacysmall">
                <p>You have the right to access, correct, update, or delete your personal information. You can manage your account settings or contact us directly to exercise these rights. We will respond to your request within a reasonable timeframe.</p>
                </div>

                <p className="info2">5.2. Marketing Communications:</p>
                <div className="privacysmall">
                <p>You may choose to opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in such communications. Even if you opt-out, we may still send you non-promotional emails related to your orders and account.</p>
                </div>

                <p className="info2">Method of Payment, Card Types accepted and Currency:</p>
                <div className="privacysmall">
                <p>Method of Payment must include all the details related to payment such as Payment method, Card Types accepted, Currency etc. "We accept payments online using Visa and MasterCard credit/debit card in AED (or any other agreed currency)". The logo of the accepted cards are to be mentioned on their Home page and on their Payment check out page. Delivery & Shipping Policy: Once the order is placed the process they will be following to deliver/ship the items/goods, conditions and the time duration for the products to reach the customers. Recommended statement is "(Website) will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE". Policy of multiple shipments has to be mentioned on their website. At the time of checkout disclose to the cardholder, the possibility that multiple shipments may result in multiple postings to the cardholder's monthly statement. Refund/Return Policy: Refund policy is the Compensation paid to a customer for over-invoicing or for returned goods which is to be mentioned on the website if allowed and the conditions under which refunds will be given to the customers. Recommended statement is "Refunds will be done only through the Original Mode of Payment". Governing Law and Jurisdiction: Law governing the Merchants business, the Customer and the transactions carried out while the customer is using the Ecommerce website. "Any purchase, dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of UAE".</p>
                </div>

                <p style={{paddingTop:'20px'}}>Children's Privacy Our Website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you believe we have inadvertently collected personal information from a child under 13, please contact us, and we will promptly delete such information.</p>

                <p style={{paddingTop:'20px'}}>Third-Party Links Our Website may contain links to third-party websites or services that are not owned or controlled by us. This Privacy Policy does not apply to these third-party websites or services. We encourage you to read the privacy policies of any third-party websites or services you visit.</p>

                <p style={{paddingTop:'20px'}}>Changes to this Privacy Policy We reserve the right to update or modify this Privacy Policy at any time, and any changes will be effective upon posting the revised version on our Website. We will notify you of any material changes through a notice on our website or by email. Your continued use of the Website after any changes to this Privacy Policy constitutes your acceptance of such changes.</p>

                <p style={{paddingTop:'20px'}}>In addition to offering a seamless shopping experience, we prioritize customer satisfaction. Our dedicated customer support team is available to address inquiries, provide assistance, and resolve any issues that may arise during the shopping process. We aim to establish long-lasting relationships with our customers by providing excellent post-purchase support, including order tracking, returns, and exchanges.</p>
                Terms & Conditions
                <p style={{paddingTop:'20px'}}>At SBM Market Place, we are committed to staying up-to-date with industry best practices and legal requirements to protect your privacy and personal information. We take the security and confidentiality of your data seriously and have implemented robust measures to safeguard it.</p>
                <p style={{paddingTop:'20px'}}>By using our website and providing your personal information, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, disclosure, and protection of your information as described herein..</p>
                <p style={{paddingTop:'20px'}}>Please take a moment to review our complete Privacy Policy for a detailed understanding of how we handle your personal information, including the types of data we collect, how we use it, whom we share it with, and your rights and choices regarding your information.</p>
                <p style={{paddingTop:'20px'}}>If you have any questions or concerns about our Privacy Policy or our practices, please contact our customer support team, and we will be happy to assist you.</p>
                <p> <span style={{fontWeight:'bold'}}>  Call Us at: </span><span>+971 4 2241 807</span></p>
                <p> <span style={{fontWeight:'bold'}}> e-mail us at: </span><span>app@sbmmarketplace.com</span></p>
                <p> <span style={{fontWeight:'bold'}}> Address: </span><span> Souvenir Building Materials LLC</span></p>
                 <p>Showroom # 2, 4 & 5, Al Baker Building</p>
                 <p>Yousuf Baker Road, Dubai, UAE</p>
                 <p style={{fontWeight:'bold'}}>PO Box:41376</p>
                 <p style={{paddingTop:'30px'}}> <span style={{fontWeight:'bold',}}> Country of merchant domicile:</span><span> United Arab Emirates</span></p>
                  <p style={{paddingTop:'20px'}}>Thank you for trusting SBM Market Place with your personal information.</p>




            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>

  )
}

export default PrivacyPolicy;