import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import MyAccountMenu from './MyAccountMenu';
import { getToken } from "../services/LocalStorageSrv";
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import axios from 'axios';
import Loader from '../Loader';
import { toast, ToastContainer } from 'react-toastify';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import ReactPaginate from "react-paginate";
import { Table, Pagination, Form, Row, Col } from 'react-bootstrap';
// import ReactTable from 'react-table';
const Invoice = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const nvg = useNavigate()
    const datepic1 = useRef(null)
    const localdata = getToken('data')
    const [loading, setLoading] = useState(false);
    const [Tabcontent, setTab] = useState("Outstanding Invoices");
    const [allInvoices, setAllInvoices] = useState([]);
    const [allOutstandingInvoices, setAllOutstandingInvoices] = useState([]);
    const [filteredData1, setFilteredData1] = useState([]); // all invoice
    const [filteredData2, setFilteredData2] = useState([]); // outstanding

    const [date1, setdate1] = useState(new Date());
    const [date2, setdate2] = useState('Saturday, February 1, 2020');

    

    const handleDate1 = () => {
      // console.log('date1:',datepic1);
      // datepic1.current.click
      if(datepic1.current){
        datepic1.current.onclick();
      }
    };
// console.log('all inv:',allInvoices);
// console.log('all out in:',allOutstandingInvoices);
    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      // Add more columns as needed
    ];
  
    // const handleSearch = (e) => {
    //   const keyword = e.target.value.toLowerCase();
    //   const filtered = allInvoices.filter((item) =>
    //     Object.values(item).some(
    //       (value) => typeof value === 'string' && value.toLowerCase().includes(keyword)
    //     )
    //   );
    //   setFilteredData(filtered);
    // };
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const formatDate = (inputDate) => {
    const [day, month, year] = inputDate.split('/');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
 
  const [fromselectedDate, setFromSelectedDate] = useState('Saturday, February 1, 2020');
  const [toselectedDate, setToSelectedDate] = useState(null);
// const [selectedDate, setSelectedDate] = useState(new Date());
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
});
// console.log('get today date:',formattedDate);
// setToSelectedDate(formattedDate);
  const handleFromDateChange = (date) => {
    setFromSelectedDate(date);
  };
  const handleToDateChange = (date) => {
    setToSelectedDate(date);
  };
  function apiformatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);
  
    // Get the year, month, and day from the parsed date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    // Return the formatted date string in the format 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
  }
  const [data, setData] = useState([]);
  const [allInvoiceSum, setAllInvoiceSum] = useState('0.00');
  const [allOutstandingInvoiceSum, setAllOutstandingInvoiceSum] = useState('0.00');
  const searchDateWise = async () => {
   
    const today = new Date();
    const formattedDate = formatDate(today.toLocaleDateString('en-GB'));
    const fromD = apiformatDate(date2);
    const toD = apiformatDate(date1);
       const fetchInvoices1 = async () => {
        setLoading(true);
         try {
            
        
            const response = await axios.get(`${apiUrl}/GetInvoiceDetails?CustomerCode=${localdata.data.vendor_code}&FromDate=${fromD}&ToDate=${toD}`,{
              headers: {
                Authorization: `Bearer ${localdata.data.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json',
              },
            });
            setData(response.data);
            // console.log('all in:',response.data);
          let invoice_list = [];
          let totalInoviceAED = 0;
          let totalOutinvoiceAED = 0;
          response.data.forEach((in_info) => {
            if (in_info.DocStatus === 'C') {
              // Category: Completed invoices
              invoice_list.push(in_info);
              totalInoviceAED += in_info.Amount || 0;
            } else if (in_info.DocStatus === 'O') {
              // Category: Outstanding invoices
              invoice_list.push(in_info);
              totalOutinvoiceAED += in_info.Amount || 0;
              
            }
          });
          // console.log('Total AED all:', totalInoviceAED);
          // console.log('Total AED out:', totalOutinvoiceAED);
          setAllInvoiceSum(totalInoviceAED);
          setAllOutstandingInvoiceSum(totalOutinvoiceAED);
          // Assuming you have state variables to store the categorized invoices
          // Set the state variables accordingly
          setAllInvoices(invoice_list.filter(invoice => invoice.DocStatus === 'C'));
          setFilteredData1(invoice_list.filter(invoice => invoice.DocStatus === 'C'));
          setAllOutstandingInvoices(invoice_list.filter(invoice => invoice.DocStatus === 'O'));
          setFilteredData2(invoice_list.filter(invoice => invoice.DocStatus === 'O'));
          // setData(invoice_list.filter(invoice => invoice.DocStatus === 'C'));
          //  console.log('Fetched items:', invoice_list.filter(invoice => invoice.DocStatus === 'C'));
           setLoading(false);
         } catch (error) {
           console.error('Error fetching data:', error);
           setLoading(false);
         }
       };
       fetchInvoices1();
  }
useEffect(() => {
 
  const today = new Date();
  const formattedDate = formatDate(today.toLocaleDateString('en-GB'));
  const fromD = apiformatDate(date2);
  const toD = apiformatDate(date1);
     const fetchInvoices = async () => {
      
       try {
          setLoading(true);
      
          const response = await axios.get(`${apiUrl}/GetInvoiceDetails?CustomerCode=${localdata.data.vendor_code}&FromDate=${fromD}&ToDate=${toD}`,{
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              'Content-Type': 'application/json; charset=UTF-8',
              'Access-Control-Allow-Origin': '*',
              Accept: 'application/json',
            },
          });
          setData(response.data);
          // console.log('all in:',response.data);
        let invoice_list = [];
        let totalInoviceAED = 0;
        let totalOutinvoiceAED = 0;
        response.data.forEach((in_info) => {
          if (in_info.DocStatus === 'C') {
            // Category: Completed invoices
            invoice_list.push(in_info);
            totalInoviceAED += in_info.Amount || 0;
          } else if (in_info.DocStatus === 'O') {
            // Category: Outstanding invoices
            invoice_list.push(in_info);
            totalOutinvoiceAED += in_info.Amount || 0;
            
          }
        });
        setAllInvoiceSum(totalInoviceAED);
        setAllOutstandingInvoiceSum(totalOutinvoiceAED);
        // Assuming you have state variables to store the categorized invoices
        // Set the state variables accordingly
        setAllInvoices(invoice_list.filter(invoice => invoice.DocStatus === 'C'));
        setFilteredData1(invoice_list.filter(invoice => invoice.DocStatus === 'C'));
        setAllOutstandingInvoices(invoice_list.filter(invoice => invoice.DocStatus === 'O'));
        setFilteredData2(invoice_list.filter(invoice => invoice.DocStatus === 'O'));
        // setData(invoice_list.filter(invoice => invoice.DocStatus === 'C'));
        //  console.log('Fetched items:', invoice_list.filter(invoice => invoice.DocStatus === 'C'));
         setLoading(false);
       } catch (error) {
         console.error('Error fetching data:', error);
         setLoading(false);
       }
     };
     fetchInvoices();
    }, []);

    // pagination for all invoice
    const pageSize2 = 15;
    const [currentPage2, setCurrentPage2] = useState(1);
    const [searchQuery2, setSearchQuery2] = useState('');
    const [sortedField2, setSortedField2] = useState('');
    const [sortDirection2, setSortDirection2] = useState('');
    
    // Initialize filteredData with the original data
    // const [filteredData1, setFilteredData1] = useState(allOutstandingInvoices);
    // console.log('set all:',filteredData1)
    // Function to handle search input change
    const handleSearchInputChange2 = (e) => {
      const query = e.target.value.toLowerCase(); // Convert search query to lowercase
      setSearchQuery2(query);
      setCurrentPage2(1); // Reset to the first page when searching
      filterData2(query); // Filter data based on search query
    };
  
    // Function to filter data based on search query
    const filterData2 = (query) => {
      const filtered2 = data.filter((item) => {
        if (typeof item.DocNum === 'string') {
          return item.DocNum.toLowerCase().includes(query);
        } else if (typeof item.DocNum === 'number') {
          return item.DocNum.toString().includes(query); // Convert number to string and then check inclusion
        }
        return false; // Return false for other data types
      });
      setFilteredData1(filtered2); // Update filteredData1 with filtered results
    };
    
    // Function to handle sorting
    const handleSort2 = (field) => {
     if (field === sortedField2) {
       setSortDirection2(sortDirection1 === 'asc' ? 'desc' : 'asc');
     } else {
       setSortedField2(field);
       setSortDirection2('asc');
     }
    };
    
    // Function to handle page change
    const handlePageChange2 = (page) => {
     setCurrentPage2(page);
    };
    
    // Calculate total number of pages
    const totalPages2 = Math.ceil(filteredData2.length / pageSize2);
    
    // Calculate start and end index of items for the current page
    const startIndex2 = (currentPage2 - 1) * pageSize2;
    const endIndex2 = Math.min(startIndex2 + pageSize2, filteredData2.length);
    
    // Sort filtered data based on the selected field and direction
    const sortedData2 = [...filteredData2].sort((a, b) => {
    // Check if the fields exist and are strings
    if (typeof a[sortedField2] === 'string' && typeof b[sortedField2] === 'string') {
    if (sortDirection2 === 'asc') {
     return a[sortedField2].localeCompare(b[sortedField2]);
    } else {
     return b[sortedField2].localeCompare(a[sortedField2]);
    }
    } else {
    // Handle cases where the fields are not strings
    // For example, you can choose to move these items to the end of the sorted list
    // or handle them in another way based on your requirements
    return 0;
    }
    });
    
    // Slice data array to get items for the current page
    const currentPageData2 = sortedData2.slice(startIndex2, endIndex2);
    
    const prevPage2 = () => {
     if (currentPage2 > 2) {
       setCurrentPage2(currentPage2 - 1);
     }
    };
    
    const nextPage2 = () => {
     if (currentPage2 < totalPages2) {
       setCurrentPage2(currentPage2 + 1);
     }
    };

// pagination for all outstanding

const pageSize1 = 15;
const [currentPage1, setCurrentPage1] = useState(1);
const [searchQuery1, setSearchQuery1] = useState('');
const [sortedField1, setSortedField1] = useState('');
const [sortDirection1, setSortDirection1] = useState('');

// Initialize filteredData with the original data
// const [filteredData1, setFilteredData1] = useState(allOutstandingInvoices);
// console.log('set all:',filteredData1)
// Function to handle search input change

const handleSearchInputChange1 = (e) => {
  const query = e.target.value.toLowerCase(); // Convert search query to lowercase
  setSearchQuery1(query);
  setCurrentPage1(1); // Reset to the first page when searching
  filterData1(query); // Filter data based on search query
};

// Function to filter data based on search query
const filterData1 = (query) => {
  const filtered1 = data.filter((item) => {
    if (typeof item.DocNum === 'string') {
      return item.DocNum.toLowerCase().includes(query);
    } else if (typeof item.DocNum === 'number') {
      return item.DocNum.toString().includes(query); // Convert number to string and then check inclusion
    }
    return false; // Return false for other data types
  });
  setFilteredData2(filtered1); // Update filteredData1 with filtered results
};

// Function to handle sorting
const handleSort1 = (field) => {
 if (field === sortedField1) {
   setSortDirection1(sortDirection1 === 'asc' ? 'desc' : 'asc');
 } else {
   setSortedField1(field);
   setSortDirection1('asc');
 }
};

// Function to handle page change
const handlePageChange1 = (page) => {
 setCurrentPage1(page);
};

// Calculate total number of pages
const totalPages1 = Math.ceil(filteredData1.length / pageSize1);

// Calculate start and end index of items for the current page
const startIndex1 = (currentPage1 - 1) * pageSize1;
const endIndex1 = Math.min(startIndex1 + pageSize1, filteredData1.length);

// Sort filtered data based on the selected field and direction
const sortedData1 = [...filteredData1].sort((a, b) => {
// Check if the fields exist and are strings
if (typeof a[sortedField1] === 'string' && typeof b[sortedField1] === 'string') {
if (sortDirection1 === 'asc') {
 return a[sortedField1].localeCompare(b[sortedField1]);
} else {
 return b[sortedField1].localeCompare(a[sortedField1]);
}
} else {
// Handle cases where the fields are not strings
// For example, you can choose to move these items to the end of the sorted list
// or handle them in another way based on your requirements
return 0;
}
});

// Slice data array to get items for the current page
const currentPageData1 = sortedData1.slice(startIndex1, endIndex1);

const prevPage1 = () => {
 if (currentPage1 > 1) {
   setCurrentPage1(currentPage1 - 1);
 }
};

const nextPage1 = () => {
 if (currentPage1 < totalPages1) {
   setCurrentPage1(currentPage1 + 1);
 }
};
    
  
    const changeFormatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so we add 1
      const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
      return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
    };

    const changeFilterFormatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    };
    
    // console.log('test new date:',changeFilterFormatDate(new Date())); // Output: "Wednesday, February 7, 2024"
    const [isChecked, setIsChecked] = useState(false);
    const [inputValue, setInputValue] = useState('0.00');
  
    const handleCheckboxChange = (event) => {
      const checked = event.target.checked;
      setIsChecked(checked);
      setInputValue(checked ? allOutstandingInvoiceSum : '0.00');
    };
  //  console.log('input v:',inputValue);
return (
        <div>
          <ToastContainer />
          {loading ? <Loader /> : null}
 <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
            <section id="product-listing-sec" className="inner-page-bg mb-5">
                <div className="container">
                    <div className="row">
                    <MyAccountMenu/>
                        <div className="col-lg-9  col-sm-12  Myitems-box" style={{ alignSelf: 'flex-start', marginTop: '18px' }}>
                            <div className="row">
                                <h2 className="myItems">Invoices</h2>
                            </div>
                       
                       <div className="row datepicker">
                          <div className="col-5">
                            <div className="fromdate">
                             <label  style={{lineHeight: '41px'}}>From date :</label>
                             <div className="b-form-btn-label-control dropdown b-form-datepicker form-control" style={{
      display: 'flex',
      alignItems: 'stretch',
      height: 'auto',
      padding: '0',
      backgroundImage: 'none'
    }}>
      <DatePicker
        id="clickdatepic1"
        // selected={toselectedDate}
        onChange={(data) => {setdate2(data)}}
        customInput={
          <button type="button"  className="btn h-auto react-datepicker-ignore-onclickoutside">
            <svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="calendar" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi-calendar b-icon bi">
              <g>
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
              </g>
            </svg>
          </button>
        }
      />
      <div className="dropdown-menu">
        {/* Here you can render your datepicker dropdown content */}
      </div>
      <label htmlFor="clickdatepic1" className="form-control" 
      // onClick={handleDate1}
     
        // selected={selectedDate}
        // onChange={handleDateChange}
      >
        {/* {changeFilterFormatDate(new Date())} */}
        {changeFilterFormatDate(date2)}
      </label>
      {/* <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="EEEE, MMMM d, yyyy" // Date format like "February 7, 2024"
      /> */}
    </div>
                             {/* <DatePicker
  selected={fromselectedDate}
  onChange={date => handleFromDateChange(date)}
  dateFormat="EEEE, MMMM d, yyyy" // Format the date
/> */}
                          
                            </div>
                           </div>
                            <div className="col-5">
                                <div className="todate">
                                    <label style={{lineHeight: '41px'}}>To date :</label>
                                    {/* <DatePicker
  selected={toselectedDate}
  onChange={date => handleToDateChange(date)}
  dateFormat="EEEE, MMMM d, yyyy" // Format the date
/> */}
 <div className="b-form-btn-label-control dropdown b-form-datepicker form-control" style={{
      display: 'flex',
      alignItems: 'stretch',
      height: 'auto',
      padding: '0',
      backgroundImage: 'none'
    }}>
      <DatePicker
        id="clickdatepic"
        // selected={toselectedDate}
        onChange={(data) => {setdate1(data)}}
        customInput={
          <button type="button"  className="btn h-auto react-datepicker-ignore-onclickoutside">
            <svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="calendar" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi-calendar b-icon bi">
              <g>
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
              </g>
            </svg>
          </button>
        }
      />
      <div className="dropdown-menu">
        {/* Here you can render your datepicker dropdown content */}
      </div>
      <label htmlFor="clickdatepic" className="form-control" 
      // onClick={handleDate1}
     
        // selected={selectedDate}
        // onChange={handleDateChange}
      >
        {/* {changeFilterFormatDate(new Date())} */}
        {changeFilterFormatDate(date1)}
      </label>
      {/* <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="EEEE, MMMM d, yyyy" // Date format like "February 7, 2024"
      /> */}
    </div>
                                   
                                    
                                    </div>
                                </div>      
                                        <div className="col-2">
                                            <button type="button" id="modalBtn" className="btn btn-vaultex col-12 rounded-0 text-uppercase"
                                            style={{fontSize: '15.5px',fontWeight: '500',    marginTop: '43px'}}
                                            onClick={() => {
                                              searchDateWise()
                                            }}
                                            > Search 
                                            </button>
                                        </div>
                                 
                        </div>
                        <hr className="dashed"/>
                       

                        <div className="row datepicker">
                            <div className="col-4">
                                <div className="fromdate" style={{lineHeight: '31px'}}>
                                    <label> Total Outstanding : </label>
                                    <div> {typeof allOutstandingInvoiceSum === 'number' ? allOutstandingInvoiceSum.toFixed(2) : '0.00'} </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="todate" style={{lineHeight: '31px'}}>
                                    <label> Amount to be paid : </label>
                                <div>
                               {isChecked ?    
                                    <input type="number" value={typeof inputValue === 'number' ? inputValue.toFixed(2) : '0.00'}
          onChange={(event) => setInputValue(event.target.value)}/>
          : 
<input type="number" defaultValue="0.00"
          onChange={(event) => setInputValue(event.target.value)}/>
                               }
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                        <label  style={{lineHeight: '28px'}}>
        <input
         type="checkbox"
         checked={isChecked}
         onChange={handleCheckboxChange}
        />
        &nbsp;Full Amount Pay
      </label>
                                    {/* <input type="checkbox"/> Full Amount Pay  */}
                                    <button data-v-bb4fc452="" type="button" className="btn btn-vaultex col-12 rounded-0 text-uppercase" style={{fontSize: '15.5px',fontWeight: '500'}}> Make Payment </button>
                        </div>
                               
                    </div>
<br/>
    <section id="tab-section" className="product-tab-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs">
                <div className="tabs tab_width" style={{ width: "100%" }}>
                  <div className="">
                    <ul
                      role="tablist"
                      className="nav nav-tabs"
                      id="__BVID__148__BV_tab_controls_"
                    >
                      <li role="presentation" className="nav-item" style={{fontSize: '18px'}}>
                        <button
                          type="button"
                          onClick={() => {
                            setTab("All Invoices");
                          }}
                          className={`nav-link ${
                            Tabcontent === "All Invoices" ? "active" : "inactive"
                          }`}
                        >
                          All Invoices
                        </button>
                      </li>
                      <li role="presentation" className="nav-item"  style={{fontSize: '18px'}}>
                        <button
                          type="button"
                          onClick={() => {
                            setTab("Outstanding Invoices");
                          }}
                          className={`nav-link ${
                            Tabcontent === "Outstanding Invoices" ? "active" : "inactive"
                          }`}
                        >
                          Outstanding Invoices
                        </button>
                      </li>
                  
                  </ul>
                    
                  </div>

                  <div
                    className="tab-content"
                    id="__BVID__55__BV_tab_container_"
                  >
                    <div
                      style={{
                        display: Tabcontent == "All Invoices" ? "block" : "none"                        
                      }}
                    >
                       <div style={{
                        alignItems: 'flex-end',
                        display: 'flex',
                        // flexDirection: 'column'
                      }}>
                               {/* Search input */}
                      <input
        type="text"
        placeholder="Search..."
        value={searchQuery2}
        onChange={handleSearchInputChange2}
    
                      
                      style={{
                        lineHeight: '35px',
                        padding: '3px',
                        margin: '10px',
                        textAlign: 'justify',
                      }}
                       />
                            </div> 
                       <table id="my-table1" role="table" aria-busy="false" aria-colcount="6" className="table b-table calltable table-striped">
                      <thead role="rowgroup" className="">
                        
                        <tr role="row" className="">
                        <th onClick={() => handleSort1('')}>
    <strong>Select</strong>&nbsp; 
      {sortedField1 === '' && (
        <span className="sr-only">
          {sortDirection1 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField1 === '' && (
        <i className={`fa fa-sort${sortDirection1 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
                             <th onClick={() => handleSort1('DocNum')}>
      <strong>Number</strong>&nbsp; 
      {sortedField1 === 'DocNum' && (
        <span className="sr-only">
          {sortDirection1 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField1 === 'DocNum' && (
        <i className={`fa fa-sort${sortDirection1 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
    <th onClick={() => handleSort1('DocDate')}>
    <strong>Date</strong>&nbsp; 
      {sortedField1 === 'DocDate' && (
        <span className="sr-only">
          {sortDirection1 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField1 === 'DocDate' && (
        <i className={`fa fa-sort${sortDirection1 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
    <th onClick={() => handleSort1('Amount')}>
    <strong>Total Amount</strong>&nbsp; 
      {sortedField1 === 'Amount' && (
        <span className="sr-only">
          {sortDirection1 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField1 === 'Amount' && (
        <i className={`fa fa-sort${sortDirection1 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
                           
                            <th>
                                <div>View</div>
                            </th>
                            <th>
                                <div>Action</div>
                            </th>
                        </tr>
                         </thead>
                            
                            <tbody role="rowgroup">
                            {/* {allInvoices.map((invoice_info, index) => ( */}
        {currentPageData1.map((invoice_info, index) => (
                   
                        <tr key={index}>
    
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>
                                <div >
                                    -
                                </div>
                            </td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>{invoice_info.DocNum}</td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>{changeFormatDate(invoice_info.DocDate)} </td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}> AED <i >{invoice_info.Amount}</i></td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>
                                <i>
                                <a href={`https://sbmmarketplace.com/backend/public/api/invoice_Test_PDF/${invoice_info.DocNum}`} download="invoice.pdf">
                   
                       <i className='fa fa-file-pdf-o fa-2x' style={{color: '#007bff'}}></i>
                                    {/* <FontAwesomeIcon icon={faFilePdf} className="fa-2x" /> */}
                                    </a>
                                </i>
                            </td>
                            <td  style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>
                                <div >
                                    <a style={{background: '#00a500',padding: '9px 37px'}}href="javascript:void(0);" className="makepayment"> Paid </a>
                                </div>
                            </td>
                      </tr>
                      
                      ))}
                      {/* <div className=" col-12 ">
                        No invoice available.
                      </div> */}  
    
    
                        </tbody>
                </table>
                <ul className="pagination" role="navigation" aria-label="Pagination">
  <li>
    <div>
      <a
        className=""
        tabIndex="0"
        role="button"
        aria-disabled={currentPage1 === 1}
        aria-label="Previous page"
        onClick={prevPage1}
        disabled={currentPage1 === 1}
      >
        Previous
      </a>
      <a role="button" tabIndex="0" aria-label={`Page ${currentPage1}`}>
        Page {currentPage1} of {totalPages1}
      </a>
      <a
        className=""
        tabIndex="0"
        role="button"
        aria-disabled={currentPage1 === totalPages1}
        aria-label="Next page"
        onClick={nextPage1}
        disabled={currentPage1 === totalPages1}
      >
        Next
      </a>
    </div>
  </li>
</ul>   
                      
                    
                
                    </div>
                  
                    <div
                      style={{
                        display: Tabcontent == "Outstanding Invoices" ? "block" : "none",
                       
                      }}
                    >
                      <div style={{
                        alignItems: 'flex-end',
                        display: 'flex',
                        // flexDirection: 'column'
                      }}>
                               {/* Search input */}
                      <input
        type="text"
        placeholder="Search..."
        value={searchQuery1}
        onChange={handleSearchInputChange1}
    
                      
                      style={{
                        lineHeight: '35px',
                        padding: '3px',
                        margin: '10px',
                        textAlign: 'justify',
                      }}
                       />
                            </div> 
                        <table id="my-table1" role="table" aria-busy="false" aria-colcount="6" className="table b-table calltable table-striped">
                            
                            <thead role="rowgroup" className="">
                            
                        <tr role="row" className="">
                        <th onClick={() => handleSort2('')}>
    <strong>Select</strong>&nbsp; 
      {sortedField2 === '' && (
        <span className="sr-only">
          {sortDirection2 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField2 === '' && (
        <i className={`fa fa-sort${sortDirection2 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
                             <th onClick={() => handleSort2('DocNum')}>
      <strong>Number</strong>&nbsp; 
      {sortedField2 === 'DocNum' && (
        <span className="sr-only">
          {sortDirection2 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField2 === 'DocNum' && (
        <i className={`fa fa-sort${sortDirection2 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
    <th onClick={() => handleSort2('DocDate')}>
    <strong>Date</strong>&nbsp; 
      {sortedField2 === 'DocDate' && (
        <span className="sr-only">
          {sortDirection2 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField2 === 'DocDate' && (
        <i className={`fa fa-sort${sortDirection2 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
    <th onClick={() => handleSort2('Amount')}>
    <strong>Total Amount</strong>&nbsp; 
      {sortedField2 === 'Amount' && (
        <span className="sr-only">
          {sortDirection2 === 'asc' ? ' (Click to sort Descending)' : ' (Click to sort Ascending)'}
        </span>
      )}
      {sortedField2 === 'Amount' && (
        <i className={`fa fa-sort${sortDirection2 === 'asc' ? '-asc' : '-desc'}`} aria-hidden="true"></i>
      )}
    </th>
                           
                            <th>
                                <div>View</div>
                            </th>
                            <th>
                                <div>Action</div>
                            </th>
                        </tr>
                         </thead>
                            
                            <tbody role="rowgroup">
                            {/* {allInvoices.map((invoice_info, index) => ( */}
        {currentPageData2.map((invoice_info, index) => (
                   
                        <tr key={index}>
    
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>
                                <div >
                                    <input type="checkbox" />
                                </div>
                            </td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>{invoice_info.DocNum}</td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>{changeFormatDate(invoice_info.DocDate)} </td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}> AED <i >{invoice_info.Amount}</i></td>
                            <td style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>
                                <i>
                                <a href={`https://sbmmarketplace.com/backend/public/api/invoice_Test_PDF/${invoice_info.DocNum}`} download="invoice.pdf">
                   
                       <i className='fa fa-file-pdf-o fa-2x' style={{color: '#007bff'}}></i>
                                    {/* <FontAwesomeIcon icon={faFilePdf} className="fa-2x" /> */}
                                    </a>
                                </i>
                            </td>
                            <td  style={{padding: '24px',
        verticalAlign: 'middle',
        borderTop: '1px solid #dee2e6'}}>
                                <div >
                                    <a href="javascript:void(0);" className="makepayment"> Payment </a>
                                </div>
                            </td>
                      </tr>
                      
                      ))}
                      {/* <div className=" col-12 ">
                        No invoice available.
                      </div> */}  
    
    
                        </tbody>
                               </table>
                               {/* Render pagination controls */}
          {/* <div>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button key={page} onClick={() => handlePageChange(page)} disabled={currentPage === page}>
                {page}
              </button>
            ))}
          </div> */}
                            {/* Pagination controls */}
                            <ul className="pagination" role="navigation" aria-label="Pagination">
      <li>
        <div>
          <a
            className=""
            tabIndex="0"
            role="button"
            aria-disabled={currentPage2 === 1}
            aria-label="Previous page"
            onClick={prevPage2}
            disabled={currentPage2 === 1}
          >
            Previous
          </a>
          <a role="button" tabIndex="0" aria-label={`Page ${currentPage2}`}>
            Page {currentPage2} of {totalPages2}
          </a>
          <a
            className=""
            tabIndex="0"
            role="button"
            aria-disabled={currentPage2 === totalPages2}
            aria-label="Next page"
            onClick={nextPage2}
            disabled={currentPage2 === totalPages2}
          >
            Next
          </a>
        </div>
      </li>
    </ul>             
                     
                    </div>
                   

                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>




















                        </div>
                    
                    </div>

                </div>
            </section>



            <Footer />
        </div>

    )
}
export default Invoice;