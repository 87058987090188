import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Select from "react-select";
import axios from "axios";
import { getToken } from "../services/LocalStorageSrv";
import { MdErrorOutline } from "react-icons/md";
import Loader from "../Loader";
import { Helmet } from 'react-helmet';
const BulkOrder = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [description, setdescription] = useState("");
  const [errorname, seterrorname] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [errorphone, seterrorphone] = useState("");
  const [errordescription, seterrordescription] = useState("");
  const [errorproduct, seterrorproduct] = useState("");
  const [productcode, setproductcode] = useState({});
  const [productqty, setproductqty] = useState("");
  const [productlist, setproductlist] = useState([]);
  const [totalproduct, settotalproduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);

  const handleAddCategory = () => {
    const newentry = [
      { product_or_category_details: productcode.value, quantity: productqty },
    ];
    settotalproduct((olddata) => [...olddata, ...newentry]);
    setproductcode({});
    setproductqty("");
  };

  const bulkordersubmit = async () => {
    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      description !== "" &&
      totalproduct.length !== 0
    ) {
      try {
        setLoading(true);
        const formdata = {
          business_or_customer: 0,
          description: description,
          email: email,
          items: totalproduct,
          name: name,
          phone: phone,
        };
        const response = await axios.post(`${apiUrl}/rfq/create`, formdata, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        if (response.data.success == true) {
          setsuccess(true);
          setTimeout(() => {
            setsuccess(false);
            nvg("/rfq");
          }, 5000);
        }
        setLoading(false);
        seterrordescription("");
        setdescription("");
        seterrorproduct("");
        seterrorphone("");
        setphone("");
        seterroremail("");
        setemail("");
        seterrorname("");
        setname("");
        settotalproduct([]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else {

      if (totalproduct.length == 0) {
        seterrorproduct("Please Select Atleast One product");
      } else {
        seterrorproduct("");
      }
      if (name == "") {
        seterrorname("Name is Required");
      } else {
        seterrorname("");
      }
      if (email == "") {
        seterroremail("Email is Required");
      } else {
        seterroremail("");
      }
      if (phone == "") {
        seterrorphone("Phone is Required");
      } else {
        seterrorphone("");
      }
      if (description == "") {
        seterrordescription("Description is Required");
      } else {
        seterrordescription("");
      }
    }
  };

  useEffect(() => {
    const fetchproduct = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/getAllProductsSKU`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        const product = response.data.data.map((product) => ({
          label: product.sku,
          value: product.sku,
          ...product,
        }));
        setproductlist(product);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchproduct();
  }, []);

  return (
    <div>
      {loading ? <Loader /> : null}
      <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section className="bulkorder-sec pb-5">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="bulkorder-heading text-center mt-5">
              <h3>Buying for Your Business ? Get the Best Quotes</h3>
              <p className="mt-2">
                Fill in the below fields to help us serve you better.
              </p>
            </div>
          </div>

          <div className="mt-5"></div>
          <div className="row d-flex justify-content-center extramargin21 pb-2">
          {errorproduct == "" ? (
                  ""
                ) : (
                  <div class="col-12 " style={{width:'60%'}} >
                  <div class=" alert alert-danger mx-0" style={{width:'100%',textAlign:'center'}} role="alert">
                    {/* {errorproduct} */}
                    Error occured
                  </div>
                  </div>
                )} 
            </div>
          {totalproduct.map((item, index) => (
            <div className="row d-flex justify-content-center extramargin21">
              <div className="col-xl-6 col-lg-6 firstinput">
                <div className="form-group infor-row">
                  <label className="label-text mb-2">Product code*</label>
                  {/* <Select  placeholder="Product Code" value={productcode} onChange={(e) => {setproductcode(e)}} styles={{borderRadius:'0px'}} options={productlist} /> */}
                  <input
                    type="text"
                    name="product code"
                    value={item.product_or_category_details}
                    // placeholder="Product Qty"
                    readOnly
                    className="qtycls form-control rounded-0 "
                  />
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 firstinput">
                <div className="form-group infor-row">
                  <label className="label-text mb-2">Qty*</label>
                  <input
                    type="number"
                    name="quantity"
                    value={item.quantity}
                    readOnly
                    className="qtycls form-control rounded-0 "
                  />
                </div>
              </div>
            </div>
          ))}

         
          <div className="row d-flex justify-content-center extramargin21">
            <div className="col-xl-6 col-lg-6 firstinput">
              <div className="form-group infor-row">
                <label className="label-text mb-2">Product code*</label>
                <div style={{position:'relative',border:!totalproduct[0] ? errorproduct !== '' ? '1px solid red' : '' : ''}}>
                               <Select
                  placeholder="Product Code"
                  value={productcode}
                  onChange={(e) => {
                    setproductcode(e);
                  }}
                  style={{border:!totalproduct[0] ? errorproduct !== '' ? '1px solid red' : '' : '',borderRadius: "0px"}}
                  // styles={{  }}
                  options={productlist}
                />
                                {!totalproduct[0] ? errorproduct !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px',backgroundColor:'white'}}><MdErrorOutline color="red" /></span> : '' : ''}

                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 firstinput">
              <div className="form-group infor-row">
                <label className="label-text mb-2">Qty*</label>
                <div style={{position:'relative'}}>
            
                <input
                  type="number"
                  name="quantity"
                  value={productqty}
                  // placeholder="Product Qty"
                  onChange={(e) => {
                    setproductqty(e.target.value);
                  }}
                  style={{position:'relative',border:!totalproduct[0] ? errorproduct !== '' ? '1px solid red' : '' : ''}}
                  className="qtycls form-control rounded-0 "
                />
                                                {!totalproduct[0] ? errorproduct !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px',backgroundColor:'white'}}><MdErrorOutline color="red" /></span> : '' : ''}
</div>
              </div>
            </div>
          </div>

          <div className="row mb-3 mt-3 d-flex justify-content-center extramargin21">
            <div className="col-xl-12 longinput">
              <div className="add-productbox">
                {productcode !== "" && productqty !== "" && productqty !== '0' ? (

                  <button
                    className="w-100 btn rfq_addbtn text-center text-uppercase"
                    type="button"
                    style={{borderRadius:'0px'}}
                    onClick={handleAddCategory}
                  >
                    + Add Product / Category
                  </button>
                ) : (
                  <button
                    className="w-100 btn rfq_addbtn text-center text-uppercase disabled"
                    type="button"
                  >
                    + Add Product / Category
                  </button>
                )}

                {/* {errorproduct == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errorproduct}
                  </p>
                )} */}
              </div>
            </div>
          </div>

          <div className="row d-flex d-flex justify-content-center extramargin21">
            <div className="col-xl-6 col-lg-6 firstinput">
              <div className="form-group infor-row">
                <label className="label-text mb-2">Name*</label>
                <div style={{position:'relative'}}>
                                 <input
                  type="text"
                  id="name"
                  name="name"
                  style={{border:errorname !== '' ? '1px solid red' : ''}}
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  className="form-control rounded-0"
                />
                {errorname !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}
                {/* {errorname == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{errorname}</p>
                )} */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 firstinput"></div>
          </div>

          <div className="row d-flex justify-content-center extramargin21">
            <div className="col-xl-6 col-lg-6 firstinput">
              <div className="form-group infor-row">
                <label className="label-text mb-2">Email*</label>
                <div style={{position:'relative'}}>
                <input
                  type="email"
                  id="email"
                  style={{border:erroremail !== '' ? '1px solid red' : ''}}
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  name="email"
                  className="form-control rounded-0"
                />
                                {erroremail !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}
</div>
                {/* {erroremail == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{erroremail}</p>
                )} */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 firstinput">
              <div className="form-group infor-row">
                <label className="label-text mb-2">Phone No.*</label>
                <div style={{position:'relative'}}>

     
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  minLength={10}
                  style={{border:errorphone !== '' ? '1px solid red' : ''}}

                  maxLength={10}
                  value={phone}
                  onChange={(e) => {
                    setphone(e.target.value);
                  }}
                  className="form-control rounded-0"
                />

{errorphone !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}

                {/* {errorphone == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{errorphone}</p>
                )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center extramargin21">
            <div className="col-xl-12 longinput">
              <div className="form-group infor-row">
                <label className="label-text mb-2">Description*</label>
                <div style={{position:'relative'}}>
                <textarea
                  id="description"
                  name="description"
                  className="form-control rounded-0"
                  style={{border:errordescription !== '' ? '1px solid red' : ''}}

                  value={description}
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                  rows="3"
                  placeholder="Write a product description here ..."
                ></textarea>
                                {errordescription !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}

                {/* {errordescription == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{errordescription}</p>
                )} */}
                </div>
              </div>
              {success == false ? (
                ""
              ) : (
                <p className="alert alert-success text-center mt-2" role="alert">
                  {" "}
                  Bulk order items added successfully!{" "}
                </p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 d-flex justify-content-center">
              <div className="personalinfo-btn">
                <button
                  type="button"
                  onClick={bulkordersubmit}
                  className="btn btn-continue rounded-0 text-uppercase pt-2 pb-2 mt-3 pl-5 pr-5"
                >
                  Submit RFQ
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default BulkOrder;
