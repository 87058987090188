import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { getToken } from "../services/LocalStorageSrv";
import axios from 'axios';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const BlogDetail= () => {
  const {name} = useParams();
  // console.log(name);
    const apiUrl = process.env.REACT_APP_API_URL;
    const nvg = useNavigate()
    const localdata = getToken('data')
    const [loading, setLoading] = useState(true);
    const [myblog, setBlogDetails] = useState([]);
    const [mycategory, setCategoryDetails] = useState([]);
    const [currentpageno, setcurrentpageno] = useState(1);
    const [checkoutdetail, setCheckoutDetails] = useState([]);

    useEffect(() => {
      const fetchBlogData= async () => {
        try {
          
           setLoading(true);
           const response = await axios.get(`${apiUrl}/blogweb/${name}`,{
              headers: {
                Authorization: `Bearer ${localdata.data.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json',
              },
            });
          setBlogDetails(response.data.data);
          console.log('blog d:',response.data.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
      const fetchCategoryData= async () => {
        try {
          
           setLoading(true);
           const response = await axios.get(`${apiUrl}/productCategory`,{
              headers: {
                Authorization: `Bearer ${localdata.data.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json',
              },
            });
          setCategoryDetails(response.data.data.data);
          // console.log('cat:',response.data.data.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };

      // const fetchCheckout= async () => {

      //   const paymentDetails = {
      //     Currency: 'AED',
      //     TransactionHint: 'CPT:Y;VCC:Y;',
      //     OrderID: '123456',
      //     OrderName: 'Order123',
      //     Channel: 'Web',
      //     Amount: '10.00',
      //     Customer: 'Demo Merchant',
      //     UserName: 'Demo_fY9c',
      //     Password: 'Comtrust@20182018',
      //   };
      //   try {
          
      //      setLoading(true);
      //      const response = await axios.post(`${apiUrl}/paymentCheckout`, paymentDetails, {
      //         headers: {
      //           Authorization: `Bearer ${localdata.data.token}`,
      //     'Content-Type': 'application/json; charset=UTF-8',
      //     'Access-Control-Allow-Origin': '*',
      //     Accept: 'application/json',
      //         },
      //       });
      //     setCheckoutDetails(response);
      //     console.log('checkout:',response);
      //     setLoading(false);
      //   } catch (error) {
      //     console.error("Error fetching data:", error);
      //     setLoading(false);
      //   }
      // };

      // fetchCheckout();
      fetchBlogData();
      fetchCategoryData();
    },[]);

  return (

    <div>
{loading ? <Loader /> : null}
<Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
  <section className="blog-detail-sec inner-page-bg">
   
    <div className="container">
      <div className="hedding-text d-flex justify-content-center">
        <h2 className="text-capitalize">Blog Detail</h2>
      </div>
      <div className="row">
      <div className="col-xl-8 col-lg-8 col-md-12 post-details">
             
             <div className="post-block">
            
               <div>
               <div className="post-thumbnail">
                 <img src={myblog.blog_image ? myblog.blog_image : "" } alt="" />
               
               </div>

               <div className="post-content">
                 <h2 className="post-heading">
                   <h2>{myblog.blog_name}</h2>
                 </h2>
                 <div className="post-meta1">
                   <div className="post-date">{new Date(myblog.blog_date).toLocaleDateString('en-GB')}</div>
                   {/* <a href="javascript:void(0);" className="category-name">SHOES</a> */}
                 </div>

                 <div className="entry-content">
                 <p dangerouslySetInnerHTML={{ __html: myblog.blog_description }}></p>
                 </div>
               </div>
               </div>


               <div className="social-share">
                 <span>Share</span>
                 <ul>
                   <li>
                     <a href="javascript:void(0);" className="share-network-facebook"><i class="fa-brands fa-facebook-f"></i></a>
                   </li>
                   <li>
                     <a href="javascript:void(0);" class="share-network-twitter"><i class="fa-brands fa-twitter"></i></a>
                   </li>
                   <li>
                     <a href="javascript:void(0);" class="share-network-linkedin"><i class="fa-brands fa-linkedin-in"></i></a>
                   </li>
                 </ul>
               </div>

               <div className="comment-block">
                 <div>
                   <h2>Leave a Reply</h2>
                   <form id="post_comment_form">
                     <div className="row">
                       <div className="col-md-6">
                         <div className="form-field">
                           <label>Your Name*</label>
                           <input id="name" type="text" name="name" className="form-control input-area" />
                         </div>
                       </div>
                       <div className="col-md-6">
                         <div className="form-field">
                           <label>Email *</label>
                           <input id="email" type="email" name="email" className="form-control input-area" />
                         </div>
                       </div>
                       <div className="col-md-12">
                         <div className="form-field">
                           <label>Comment</label>
                           <textarea id="comment" name="comment" className="form-control"></textarea>
                         </div>
                       </div>
                       <div className="col-md-12 d-flex justify-content-end">
                         <div className="action-btn">
                           <input type="submit" id="comment_submit" name="comment_submit" value="Submit" className="form-submit" />
                         </div>
                       </div>
                     </div>
                   </form>
                 </div>
               </div>

              
             </div>
         
             <div className="recent-post-block">
                 <div className="hedding-text ">
                   <h2>Recent Post</h2>

                   <div className="row">
                     <div className="col-md-4 post-item">
                       <div className="post-img">
                         <a href="javascript:void(0);">
                           <img
                             src="	https://sbmmarketplace.com/img/recent-post01.2b7fabd5.png"
                             alt="Recenet Post"
                           />
                         </a>
                       </div>
                       <div className="post-heading">
                         <h2>
                           <a href="javascript:void(0);"
                           >consectetur adipiscing elit, sed do eiusmod tempor incididunt
                             ut labore et dolore magna aliqua</a>
                           
                         </h2>
                       </div>
                       <div className="post-meta">
                         <div className="post-date">December 21, 2020</div>
                       </div>
                     </div>
                    
                   </div>
                 </div>
               </div>
         </div>


        <div className="col-lg-4 col-sm-12 sidebar" style={{width:'32%',alignSelf:'flex-start'}}>
        
        <div className="popular-post-block ">
          <h2 className="block-title text-uppercase">POPULAR POST</h2>
        </div>
        <div className="popular-post1-block ">
          <h2 className="block-title text-uppercase">POPULAR TAGS</h2>
          <ul>
<br/>
{mycategory?.[0] ? (
                  mycategory.map((cat,index) => (
            <li>
              <a href="javascript:void(0);" onClick={() => {
                                      nvg("/product-category-detail", {
                                        state: {
                                          name: cat.slug,
                                          cat_id: cat.id,
                                        },
                                      });
                                    }} className="popular-tags">{cat.category_name}</a>
            </li>
))
) : (
  <li>
    Category Not Available.
  </li>
)}

          </ul>
        </div>
  
        



      
       
       
       </div>


      </div>
       
    </div>
  </section>
  <Footer />
  
       </div>

  )
}

export default BlogDetail;