import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MyAccountMenu from "./MyAccountMenu";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';
const Address = () => {
  // category filter start here
  const addresspopup = useRef(null);
  const [brand, setbrand] = useState(true);
  const [category, setcategory] = useState(true);
  const [price, setprice] = useState(true);
  const [discount, setdiscount] = useState(false);
  const [filter, setfilter] = useState(false);
  const [currentwdith, setcurrentwdith] = useState(window.innerWidth);

  const [showsidebar, setshowsidebar] = useState(false);
  const [sortby, setsortby] = useState(false);
  const [subcategory, setsubcategory] = useState(false);
  const [acdropdown, setacdropdown] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  const [loading, setLoading] = useState(true);
  const [myaddresslist, setAddressList] = useState([]);
  const [userDatas, setUserdetail] = useState([]);
  const [addressList, setDefaultAddressList] = useState(/* initial value */);
  const [selectedValue, setSelectedValue] = useState(/* initial value */);
  const [updateaddress, setUpdateAddress] = useState([]);
  const [addressIdToDelete, setAddressIdToDelete] = useState("");

  const [addressAllinfo, setAllinfo] = useState("");

  const configToken = {
    headers: {
      Authorization: `Bearer ${localdata.data.token}`,
    },
  };
  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/user`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setUserdetail(response?.data?.data);
        // console.log('Fetched address:', response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchAddress = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/addresses`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setAddressList(response?.data?.data);
        // console.log('Fetched address:', response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchUser();
    fetchAddress();
  }, [selectedValue]);

  const [formDataDelete, setFormDataDelete] = useState({
    address_id: "",
    // Add other form fields if needed
  });

  const handleGetAddressIdChange = (e) => {
    const { name, value } = e.target;
    setFormDataDelete({
      ...formDataDelete,
      [name]: value,
    });
  };



  const handleDeleteAddress = async (address_id) => {
    const collectdata = {
      address_id: address_id,
    };
    // console.log("del add:", collectdata);
    const fetchDeleteAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addresses/delete`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setResponseMessage(response.data.message);
          setTimeout(() => window.location.reload(), 3000);
          // console.log('succeess',response.data.message);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error deleting address");
        }
      } catch (error) {
        console.error("An error occurred while deleting the address", error);
      }
    };
    fetchDeleteAd();
  };

  const handleSetDefaultAddrs = (event) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
    const collectdata = {
      address_id: selectedValue,
    };
    // console.log('ad id:',selectedValue)
    const fetchDefaultAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/setDefaultAddress`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setResponseMessageDefault(response.data.message);
          // setTimeout(() => window.location.reload(), 3000);
          // console.log('succeess',response.data.message);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error deleting address");
        }
      } catch (error) {
        console.error("An error occurred while deleting the address", error);
      }
    };
    fetchDefaultAd();
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    landmark: "",
    otp: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [responseMessagedefault, setResponseMessageDefault] = useState("");
  const [addaddressresponseMessage, setaddaddressresponseMessage] =
    useState("");
  const [errorOTPmsg, setErrorOTPMessage] = useState("");
  const [successResponseMessage, setsuccessResponseMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [addAddress, setAddAddress] = useState(false);
  const [editaddressresponseMessage, setEditaddressresponseMessage] =
    useState("");
  const [edittAddress, setEdittAddress] = useState(false);
  const handleAlertClick = () => {
    setResponseMessage(false);
  };
  const handleAlertDeleteClick = () => {
    setResponseMessage(false);
    setResponseMessageDefault(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // setAddressList((prevData) => ({ ...prevData, [name]: value }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    // setAddressList((prevData) => ({ ...prevData, [name]: value }));
    setEditFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors2((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate headline
    if (!formData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!formData.pincode) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    if (!formData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!formData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!formData.state) {
      newErrors.state = "State is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const fetchAddadressData = async (event) => {
    const collectdata = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      pincode: formData.pincode,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      landmark: formData.landmark,
      // phone: event.target.phone.value,
      // address: event.target.address.value,
      // city: event.target.city.value,
      // state: event.target.state.value,
      // country: event.target.country.value,
      // landmark: event.target.landmark.value,
    };
    setAllinfo(collectdata);
    // console.log('all inf:',collectdata);
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/passwordSendOtp`, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      if (response.data.success === true) {
        setAddAddress(response.data.success);
        setaddaddressresponseMessage(response.data.message);
        // setTimeout(() => {
        //   setaddaddressresponseMessage('');
        // }, 3000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddAddressSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      // If the form is not valid, don't submit
      return;
    }

    fetchAddadressData();
  };
  const handleOTPm = () => {
    setTimeout(() => {
      setaddaddressresponseMessage("");
      setEditaddressresponseMessage("");
    }, 2000);
  };
  const validateForm1 = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!formData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!formData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!formData.state) {
      newErrors.state = "State is required";
      valid = false;
    }
    //  Validate
    if (!formData.otp) {
      newErrors.otp = "OTP is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const fetchsubmitAddadressData = async () => {
    // console.log('all add info new:',addressAllinfo);
    // console.log('otp n:',formData.otp);
    const collectdata1 = {
      name: addressAllinfo.name,
      email: addressAllinfo.email,
      phone_number: addressAllinfo.phone,
      pincode: addressAllinfo.pincode,
      address: addressAllinfo.address,
      city: addressAllinfo.city,
      state: addressAllinfo.state,
      country: addressAllinfo.country,
      landmark: addressAllinfo.landmark,
      otp: formData.otp,
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/addresses/add`,
        collectdata1,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.data.success === true) {
        setsuccessResponseMessage(response.data.message);
        toast.success(
          <div>
            <div>{response.data.message}</div>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    } catch (err) {
      if (err.response?.data?.message) {
        setErrorOTPMessage(err.response?.data?.message);
      }
    }
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    if (!validateForm1()) {
      // If the form is not valid, don't submit
      return;
    }

    fetchsubmitAddadressData();
  };

  const [EditFormData, setEditFormData] = useState({
    name: "",
    email: "",
    phone: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    landmark: "",
    otp: "",
  });
  const [editItemId, setEditItemId] = useState(null);
  const handleEditAdd = (data) => {
    setEditFormData({
      name: data.name,
      email: data.email,
      phone: data.phone,
      pincode: data.pincode,
      address: data.address,
      city: data.city,
      state: data.state,
      country: data.country,
      landmark: data.landmark,
      otp: "",
    });
    setEditItemId(data.id);
  };

  // for edit address code
  const validateForm2 = () => {
    let valid = true;
    const newErrors = {};

    // Validate headline
    if (!EditFormData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!EditFormData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(EditFormData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!EditFormData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!EditFormData.pincode) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    if (!EditFormData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!EditFormData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!EditFormData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!EditFormData.state) {
      newErrors.state = "State is required";
      valid = false;
    }

    setErrors2(newErrors);
    return valid;
  };

  const fetchAddressEditData = async (event) => {
    const collectdata = {
      name: EditFormData.name,
      email: EditFormData.email,
      phone: EditFormData.phone,
      pincode: EditFormData.pincode,
      address: EditFormData.address,
      city: EditFormData.city,
      state: EditFormData.state,
      country: EditFormData.country,
      landmark: EditFormData.landmark,
    };
    setAllinfo(collectdata);
    // console.log('all inf:',collectdata);
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/passwordSendOtp`, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      // console.log("efit form response of it", response);
      if (response.data.success === true) {
        setEdittAddress(response.data.success);
        setEditaddressresponseMessage(response.data.message);
        setTimeout(() => {
          setEditaddressresponseMessage("");
        }, 3000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEditInfoFormSubmit = (event) => {
    event.preventDefault();
    if (!validateForm2()) {
      // If the form is not valid, don't submit
      return;
    }
    // console.log("checking validation");
    fetchAddressEditData();
  };

  const validateForm3 = () => {
    let valid = true;
    const newErrors = {};

    // Validate headline
    if (!EditFormData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!EditFormData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(EditFormData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!EditFormData.phone) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!EditFormData.pincode) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    if (!EditFormData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!EditFormData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!EditFormData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }
    if (!EditFormData.state) {
      newErrors.state = "State is required";
      valid = false;
    }
    if (!EditFormData.otp) {
      newErrors.otp = "otp is required";
      valid = false;
    }
    setErrors2(newErrors);
    return valid;
  };

  const fetchAddressUpdateData = async (event) => {
    const collectdata3 = {
      name: addressAllinfo.name,
      email: addressAllinfo.email,
      phone_number: addressAllinfo.phone,
      pincode: addressAllinfo.pincode,
      address: addressAllinfo.address,
      city: addressAllinfo.city,
      state: addressAllinfo.state,
      country: addressAllinfo.country,
      landmark: addressAllinfo.landmark,
      otp: EditFormData.otp,
      address_id: editItemId,
    };
    // console.log("update v:", collectdata3);
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/addresses/update`,
        collectdata3,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.data.success === true) {
        // setsuccessResponseMessage(response.data.message);

        toast.success(
          <div>
            <div>{response.data.message}</div>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
        setLoading(false);
      } else {
        setErrorOTPMessage(response?.data?.message);
        setLoading(false);
        // console.log('erroD:',response?.data?.message);
      }
    } catch (err) {
      if (err.response?.data?.message) {
        setErrorOTPMessage(err.response?.data?.message);
        setLoading(false);
      }
    }
  };
  const handleEditUpdateSubmit = (event) => {
    event.preventDefault();
    if (!validateForm3()) {
      // If the form is not valid, don't submit
      return;
    }
    fetchAddressUpdateData();
  };

  const handleAddressPopup = () => {
    if (addresspopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(addresspopup.current);
      modal.show();
    }
  }
  return (
    <div>
      <ToastContainer />
      {loading ? <Loader /> : null}
      <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section id="product-listing-sec" className="inner-page-bg mb-5">
        <div className="container">
          <div className="row">
            <MyAccountMenu />
            <div className="col-lg-9 addressbox ">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="add">Address</h2>
                  {responseMessage && (
                    <div
                      className="alert alert-error"
                      onClick={handleAlertDeleteClick}
                    >
                      {responseMessage}
                    </div>
                  )}
                  {responseMessagedefault && (
                    <div
                      className="alert alert-success"
                      onClick={handleAlertDeleteClick}
                    >
                      {responseMessagedefault}
                    </div>
                  )}
                  {successResponseMessage && (
                    <div
                      className="alert alert-success"
                      onClick={handleAlertDeleteClick}
                    >
                      {successResponseMessage}
                    </div>
                  )}
                  <div className="add2">
                    <a
                      onClick={() => handleAddressPopup()}
                      href="javascript:void(0)"
                    >
                      <i className="fa-regular fa-plus icplus"></i>&nbsp;
                       <span className="address3">ADD DELIVERY ADDRESS</span>
                      
                    </a>
                  </div>
                </div>

                {myaddresslist.map((adds, index) => (
                  <div className=" col-lg-6 col-md-6 ">
                    <div className="card addcard">
                      <div className="addHeading">
                        {adds.name}, {adds.phone}
                        <span className="threedot">
                          {/* <i className="fa-solid fa-ellipsis-vertical"></i> */}
                          <div className="dropdown">
                            <button
                              className="dot-dot secondary"
                              style={{
                                border: "none",
                                background: "#f9f9f9",
                                opacity: "1",
                              }}
                              type="three dots"
                              id="dropdownMenu"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis-vertical" />
                            </button>
                            <ul className="dropdown-menu     edititem" >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() => handleEditAdd(adds)}
                                >
                                 
                                  &nbsp;Edit
                                </a>
                              </li>

                              <li>
                                <a
                                  className="dropdown-item" style={{paddingTop:'10px'}}
                                  href="javascript:void(0);"
                                  onClick={() => handleDeleteAddress(adds.id)}
                                >
                                 
                                  &nbsp;Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>
                      <div className="address-content text-capitalize text-bold">
                        {" "}
                        {adds.address}
                        <br />
                        <span>
                          {adds.city}, {adds.state}, {adds.country} <br />
                          {adds.pincode}
                        </span>
                      </div>

                      <p className="card-text"></p>
                      <p className="card-text">
                        <small className="text-muted"></small>
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {myaddresslist && myaddresslist.length > 0 ? (
                <div className="row">
                  <div className="col-6">
                    <div id="primary">
                      <div className="tool-bar">
                        <label className="address-label">
                          Set Default Address :
                        </label>
                        <div className="dropdown-box">
                          <select
                            name="sortby"
                            defaultValue={selectedValue}
                            onChange={handleSetDefaultAddrs}
                          >
                            {myaddresslist.map((adds1) => (
                              <option
                                value={adds1.id}
                                selected={
                                  adds1.id === userDatas.address_id
                                    ? true
                                    : false
                                }
                              >
                                {`${adds1.address}, ${adds1.country}, ${adds1.state}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <br></br>

              {
                // myaddresslist.map(
                //   (adds1, index) =>
                //     myaddresslist.find((adds1) => adds1.id === editItemId) &&
                //     editItemId !== null &&
                //     adds1.id === editItemId &&
                editItemId == null || editItemId == "" ? (
                  ""
                ) : (
                  <div id="editAdressForm" class="row">
                    <div class="col-md-12">
                      <h6 class="label text-uppercase">
                        {" "}
                        Edit Delivery Address
                      </h6>
                      <br></br>
                      <div>
                        <form onSubmit={handleEditInfoFormSubmit}>
                          <div>
                            <input
                              type="hidden"
                              name="address_id"
                              value={editItemId}
                            />
                            <div class="row">
                              <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    Your Name*
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    class="form-control input-area rounded-0"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.name}
                                  />
                                  {errors2.name && (
                                    <div className="error">{errors2.name}</div>
                                  )}
                                </div>
                              </div>

                              <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    Email Address*
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    value={EditFormData.email}
                                    class="form-control input-area rounded-0"
                                  />
                                  {errors2.email && (
                                    <div className="error">{errors2.email}</div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    Phone Number*
                                  </label>
                                  <input
                                    type="number"
                                    name="phone"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.phone}
                                    class="form-control input-area rounded-0"
                                  />
                                  {errors2.phone && (
                                    <div className="error">{errors2.phone}</div>
                                  )}
                                </div>
                              </div>

                              <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    Pincode*
                                  </label>
                                  <input
                                    type="number"
                                    name="pincode"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.pincode}
                                    class="form-control input-area rounded-0"
                                  />
                                  {errors2.pincode && (
                                    <div className="error">
                                      {errors2.pincode}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-xl-12">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    Address*
                                  </label>
                                  <textarea
                                    name="address"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.address}
                                    class="form-control input-area rounded-0"
                                  />
                                  {errors2.address && (
                                    <div className="error">
                                      {errors2.address}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    City/ Town/ District*
                                  </label>
                                  <input
                                    type="text"
                                    name="city"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.city}
                                    class="form-control input-area rounded-0 "
                                  />
                                  {errors2.city && (
                                    <div className="error">{errors2.city}</div>
                                  )}
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">State*</label>
                                  <input
                                    type="text"
                                    name="state"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.state}
                                    class="form-control input-area rounded-0 "
                                  />
                                  {errors2.state && (
                                    <div className="error">{errors2.state}</div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    Country*
                                  </label>
                                  <input
                                    type="text"
                                    name="country"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.country}
                                    class="form-control input-area rounded-0 "
                                  />
                                  {errors2.country && (
                                    <div className="error">
                                      {errors2.country}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group infor-row">
                                  <label class="label-text mb-2">
                                    Landmark (Optional)
                                  </label>
                                  <input
                                    type="text"
                                    name="landmark"
                                    value={EditFormData.landmark}
                                    onChange={handleChangeEdit}
                                    class="form-control input-area rounded-0"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-8">
                              {edittAddress ? (
                                <div className="form-group col-12 mt-3 px-2 pb-2">
                                  <label className="acounttitle tilte541 mb-2">
                                    Enter OTP*
                                  </label>
                                  <input
                                    type="number"
                                    name="otp"
                                    className="form-control"
                                    style={{
                                      borderRadius: "0px",
                                     
                                    }}
                                    placeholder="Enter OTP"
                                    onChange={handleChangeEdit}
                                    value={EditFormData.otp}
                                    onClick={(e) => handleOTPm()}
                                  />
                                  {errors2.otp && (
                                    <div className="error">{errors2.otp}</div>
                                  )}

                                  {editaddressresponseMessage && (
                                    <div className="alert alert-success">
                                      {editaddressresponseMessage}
                                    </div>
                                  )}
                                  {errorOTPmsg && (
                                    <div className="alert alert-error">
                                      {errorOTPmsg}
                                    </div>
                                  )}
                                  <br></br>
                                  <br></br>
                                  <br></br>
                                  <button
                                    className="btn sendotpbtn"
                                    onClick={handleEditUpdateSubmit}
                                    style={{
                                      border: "2px solid #059fe2",
                                      borderRadius: "0px",
                                      width: "100%",
                                    }}
                                  >
                                    SAVE ADDRESS
                                  </button>
                                </div>
                              ) : (
                                <div className="form-group col-12 mt-3 px-2 pb-2">
                                  <button
                                    className="btn sendotpbtn"
                                    style={{
                                      border: "2px solid #059fe2",
                                      borderRadius: "0px",
                                      // width: "100%",
                                    }}
                                  >
                                    SEND OTP
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )
                // )
              }
            </div>
          </div>
        </div>
      </section>

      {/* add address modal */}
      <div
      ref={addresspopup}
        className="modal fade"
        id="exampleModalAddAddress"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered popaddresswidth">
          <div className="modal-content" style={{ borderRadius: "0px" }}>
            <div
              className="modal-header"
              style={{ padding: "8px", border: "none" }}
            >
              {/* <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ background: "#059fe2", borderRadius: "0px" }}
              >
                <span style={{ color: "white" }}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
            <div className="modal-body py-0">
              <div className="row">
                <div className="col-12 pb-3">
                  <h5 className="title451 text-center pb-4">
                    ADD DELIVERY ADDRESS
                  </h5>
                </div>
                <div className="px-4">
                {errors.country || errors.citstatey || errors.city || errors.address || errors.pincode || errors.phone || errors.email || errors.name ? (
              <div className="error-message-password-address mb-2">
                <p>Error occured</p>

              </div>
              ):null}

                </div>
     

              </div>
              <div className="" style={{ width: "100%" }}>
                <form onSubmit={handleAddAddressSubmit}>
                  <div className="row customrow" style={{ width: "100%" }}>
                    {/* <div className="col-6"> */}
                    <div className="form-group col-md-6 pb-lg-0 pb-2 col-sm-6 col-xs-12 px-2">
                    <label className="acounttitle tilte541 mb-2">First Name*</label>
                    <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="name"
                        className={`old-password-password form-control ${errors.name ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your First Name"
                        onChange={handleChange}
                        value={formData.name}
                      />
                      {errors.name && <i className=" warning-icon-address"> <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
                  </div>


                    <div className="form-group col-md-6 col-sm-6 col-xs-12 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Email Address*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="email"
                        name="email"
                        className={`old-password-password form-control ${errors.email ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your Email"
                        onChange={handleChange}
                        value={formData.email}
                      />
                           {errors.email && <i className="warning-icon-address"> <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
             
                   
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Phone Number*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="number"
                        name="phone"
                        className={`old-password-password form-control ${errors.phone ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your number"
                        onChange={handleChange}
                        value={formData.phone}
                      />
                                 {errors.phone && <i className="warning-icon-address">
                                 <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
        
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Pincode*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="number"
                        name="pincode"
                        className={`old-password-password form-control ${errors.pincode ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your Pincode"
                        onChange={handleChange}
                        value={formData.pincode}
                      />
                                      {errors.pincode && <i className=" warning-icon-address"> <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
          
                    </div>
                    <div className="form-group col-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Address*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="address"
                        className={`old-password-password form-control ${errors.address ? 'error-border-password' : ''}`}
                        style={{
                          borderRadius: "0px",
                          height: "55px",
                        }}
                        placeholder="Enter Your address"
                        onChange={handleChange}
                        value={formData.address}
                      />
                                            {errors.address && <i className=" warning-icon-address">
                                            <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
   
                    </div>

                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        City/ Town/ District*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="city"
                        className={`old-password-password form-control ${errors.city ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your city"
                        onChange={handleChange}
                        value={formData.city}
                      />
                      {errors.city && <i className=" warning-icon-address">
                      <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
          
                    </div>
                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        State*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="state"
                        className={`old-password-password form-control ${errors.state ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your state"
                        onChange={handleChange}
                        value={formData.state}
                      />
                       {errors.state && <i className=" warning-icon-address">
                       <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
          
                    </div>
                    <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Country*
                      </label>
                      <div className="input-with-icon-address">
                      <input
                        type="text"
                        name="country"
                        className={`old-password-password form-control ${errors.country ? 'error-border-password' : ''}`}
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your country"
                        onChange={handleChange}
                        value={formData.country}
                      />
                           {errors.country && <i className=" warning-icon-address">
                           <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg></i>}
                    </div>
       
                    </div>

                    <div className="form-group col-12 mt-3 px-2">
                      <label className="acounttitle tilte541 mb-2">
                        Landmark (Optional)
                      </label>
                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                      <input
                        type="text"
                        name="landmark"
                        className="form-control"
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Your Landmark (Optional)"
                        onChange={handleChange}
                        value={formData.landmark}
                      />

                     
                    </div>

                    {addAddress ? (
                      <div className="form-group col-12 mt-3 px-2 pb-2">
                        <label className="acounttitle tilte541 mb-2">
                          Enter OTP*
                        </label>
                        <input
                          type="number"
                          name="otp"
                          className="form-control"
                          style={{ borderRadius: "0px", width: "50% ", marginBottom :'2px' }}
                          placeholder="Enter OTP"
                          onChange={handleChange}
                          value={formData.otp}
                          onClick={(e) => handleOTPm()}
                        />
                        {errors.otp && (
                          <div className="error">{errors.otp}</div>
                        )}

                        {addaddressresponseMessage && (
                          <div className="alert-adress alert-success" style={{width :'50%'}}>
                            {addaddressresponseMessage}
                          </div>
                        )}
                        {errorOTPmsg && (
                          <div className="alert-adress alert-error mt-2"  style={{width :'50%'}}>{errorOTPmsg}</div>
                        )}
                        <br></br>
                        <br></br>
                        <br></br>
                        <button
                          onClick={handleAddSubmit}
                          className="btn sendotpbtn-address-page"
                         
                        >
                          SAVE ADDRESS
                        </button>
                      </div>
                    ) : (
                      <div className="form-group col-12 mt-3 px-2 pb-2">
                        <button
                          className="btn sendotpbtn"
                          style={{
                            border: "2px solid #059fe2",
                            borderRadius: "0px",
                            width: "100%",
                          }}
                        >
                          SEND OTP
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End add address modal */}

      <Footer />
    </div>
  );
};
export default Address;
