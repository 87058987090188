import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../services/LocalStorageSrv';

const Logout = () => {
  const navigate = useNavigate();

  const removeAuth = () => {
    // Assuming removeToken is removing the authentication token from localStorage
    removeToken('data');

    // Navigate to the home page after logging out
    navigate('/');
  };

  return (
    <a onClick={removeAuth} href="/">
      Logout
    </a>
  );
};

export default Logout;
