import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import MyAccountMenu from './MyAccountMenu';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const Notification = () => {
    const [loading, setLoading] = useState(false);

    return (
        <div>
           {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
{loading ? <Loader /> : null}
<Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
            <section id="product-listing-sec" className="inner-page-bg mb-5">
                <div className="container">
                    <div className="row">
                    <MyAccountMenu/>
                        <div className="col-lg-9  col-sm-12  Myitems-box" style={{ alignSelf: 'flex-start', marginTop: '17px', }}>
                            <div className="row">

                                <h2 className="myItems">Notification List (0)</h2>
                                {/* ITEM 1 */}

                               
                            </div>
                       
                       
                           
                       
                        </div>
                    
                    
                    





                    </div>


                 



                </div>
            </section>



            <Footer />
        </div>

    )
}
export default Notification;