import React, { useEffect, useState, useRef } from "react";

import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const ResetPassword = () => {

    const location = useLocation();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailAndVendorCode = urlParams.get('email');
    const [email, vendorCode] = emailAndVendorCode.split('?vendor_code=');

//   console.log('Email:', email);
//   console.log('Vendor Code:', vendorCode);


    const {token} = useParams();
    // Now you have access to the parameters
    // console.log('Token:', token);


    const cartpopup = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const nvg = useNavigate();
    const localdata = getToken("data");
    const [loading, setLoading] = useState(true);
    const [passdata, setpassData] = useState([]);
    const [passDataMsg, setpassDataMsg] = useState('');
   /* const configToken = {
      headers: {
          Authorization: `Bearer ${localdata.data.token}`,
      },
    };*/
    useEffect(() => {
      
    
         const fetchpasswordDetail = async () => {
            const formdata = {
                token: token,
                email: email,
                vendor_code: vendorCode
            }
          try {
            setLoading(true);
            const response = await axios.post(`${apiUrl}/resetPasswordChekTokenWeb`,formdata);
            
            if (response.data.success == true) {
            // Assuming response.data is an object containing profile data
            setpassData(response?.data?.data);
            
            // console.log('reset p Data:', response.data.data);
    
            
            setLoading(false);
            }else if (response.data.success == false) {
                setpassDataMsg(response?.data?.message);
                setLoading(false);
            }
          } catch (err) {
            if (err.response?.data?.message) {
                setpassDataMsg(err.response?.data?.message);
                setLoading(false);
            }
            setLoading(false);
          }
         };
    
        
         
         fetchpasswordDetail();
      }, []);
      const [password, setpassword] = useState("");
      const [newpassword, setnewpassword] = useState("");
      const [errorpassword_input, seterrorpassword_input] = useState("");
      const [errornewpassword_input, seterrornewpassword_input] = useState("");
      const [passmatch, setpassMatch] = useState("");
      const validatePassword = () => {
        // Your password validation logic here
        if (password.length < 6) {
            seterrorpassword_input('Password must be at least 6 characters');
        } else {
            seterrorpassword_input('');
        }
      };
    
      const validateNewPassword = () => {
        // Your new password validation logic here
        if (newpassword.length < 6) {
            seterrornewpassword_input('New password must be at least 6 characters');
        } else {
            seterrornewpassword_input('');
        }
      };
  const resetpasswordsubmit = async () => {
    // console.log('jhsdfjk');
  
      try {
        setLoading(true);
        const formdata = {
            confirm_password: newpassword, 
            email: passdata.email,
            password: password,
            token: passdata.token,
            vendor_code:passdata.vendor_code
          
        };
        // console.log('data list:',formdata);
        const response = await axios.post(`${apiUrl}/resetPasswordweb`, formdata, {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        if (response.data.success == true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          
          setTimeout(() => {
            // window.location.href = response.data.data.link;
            // window.location.href = "/thank-you/" + response.data.data.order_id;
            nvg("/")
           }, 2000);
        }
        setLoading(false);
      } catch (error) {
        setpassMatch(error.response.data.errors);
        //  console.log('name erro:',error.response.data.errors.confirm_password);
        setLoading(false);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } 

    return (
      
        <div style={{ overflow: 'hidden' }}>
  {loading ? <Loader /> : null}
  <ToastContainer />
  <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
  <div className="container ChagePassFrom">
 <h2 className="label_resetp">Reset password</h2>
 

 {passDataMsg && (
                      <center>
                        {" "}
                        <div
                          className="alert alert-error"
                          style={{ fontSize: "14px" }}
                        >
                          {passDataMsg}
                        </div>
                      </center>
                    )}
 {/* {passmatch.confirm_password == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{passmatch.confirm_password}</p>
                )}   */}

{passDataMsg ? '' :
  <form id="changePassword" method="POST">
   <div classNameName="row">
    <div className="col-12">
     <div className="form-group form-row">
      <input readonly="readonly" id="vendor-input" type="text" name="vendor_code" value={passdata.vendor_code} placeholder="Vendor Code" className="form-control input-area"/>

      <input readonly="readonly" id="email" type="text" name="email" placeholder="Email address" className="form-control input-area email-input" value={passdata.email}/>
     </div>
    </div>

    <div className="col-12">
     <div className="form-group form-row">
      <input id="password" type="password" name="password" placeholder="Enter New Password*" className="form-control input-area"
      onChange={(e) => {
        setpassword(e.target.value);
      }}
      />
       {passmatch.password == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{passmatch.password}</p>
                )}  
       {/* {errorpassword_input === '' ? (
            ''
          ) : (
            <p style={{ color: 'red' }}>{errorpassword_input}</p>
          )} */}
       
     </div>
    </div>

    <div className="col-12">
     <div className="form-group form-row">
      <input id="confirm_password" type="password" name="password" placeholder="Confirm Password*" className="form-control input-area"
       onChange={(e) => {
        setnewpassword(e.target.value);
      }}
      />
     </div>
     {passmatch.confirm_password == "" ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{passmatch.confirm_password}</p>
                )}  
     {/* {errornewpassword_input === '' ? (
            ''
          ) : (
            <p style={{ color: 'red' }}>{errornewpassword_input}</p>
          )} */}
             
    </div>
   </div>
<br/>


   <div className="row mt-3 mb-4">
    <div className="col-12">
     <button  type="button"
                        onClick={resetpasswordsubmit} className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2"> Continue </button>
    </div>
   </div>

 </form>
 }
 <br/>
</div>

            <Footer />
        </div>

    )
}
export default ResetPassword;