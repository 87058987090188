import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import MyAccountMenu from './MyAccountMenu';
import { getToken } from "../services/LocalStorageSrv";
import axios from 'axios';
import Loader from '../Loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
const Password = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate()
  const localdata = getToken('data')
  const [loading, setLoading] = useState(false);

  const [brand, setbrand] = useState(true)
  const [category, setcategory] = useState(true)
  const [price, setprice] = useState(true)
  const [discount, setdiscount] = useState(false)
  const [filter, setfilter] = useState(false)
  const [currentwdith, setcurrentwdith] = useState(window.innerWidth)

  const [addAddress, setAddAddress] = useState(false);
  const [showsidebar, setshowsidebar] = useState(false);
  const [sortby, setsortby] = useState(false);
  const [subcategory, setsubcategory] = useState(false);
  const [acdropdown, setacdropdown] = useState(false);

  const [errorOTPmsg, setErrorOTPMessage] = useState('');
  const [errorOTPmsg1, setErrorOTPMessage1] = useState('');
  const [errors, setErrors] = useState({});
  const [addPassword, setPassword] = useState(false);
  const [addPasswordresponseMessage, setpasswordresponseMessage] = useState('');
  const [passwordAllinfo, setAllinfo] = useState('');
  const [error, setError] = useState("");
  const [successResponseMessage, setsuccessResponseMessage] = useState('');

  const handleAlertClick = () => {
    setsuccessResponseMessage(false);

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setAddressList((prevData) => ({ ...prevData, [name]: value }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    con_password: '',
    otp: ''
  });
  const validateForm1 = () => {
    let valid = true;
    const newErrors = {};


    if (!formData.old_password) {
      newErrors.old_password = 'Old Password is required';
      valid = false;
    }

    if (!formData.new_password) {
      newErrors.new_password = 'New Password is required';
      valid = false;
    }

    if (!formData.con_password) {
      newErrors.con_password = 'Confirm Password is required';
      valid = false;
    }

    if (formData.new_password === formData.con_password) {

    } else {
      // If new_password is not equal to con_password
      newErrors.con_password = 'New Password & Confirm Password does not match';
      // Set an error message indicating that the passwords do not match
      valid = false;
      // Set the valid variable to false, indicating that the form is not valid
    }
    setErrors(newErrors);
    return valid;
  };
  const fetchPasswordData = async (event) => {


    const collectdata = {
      old_password: formData.old_password,
      new_password: formData.new_password,
      con_password: formData.con_password,

    };
    setAllinfo(collectdata);
    // console.log('all inf:',collectdata);
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/passwordSendOtp`, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      if (response.data.success === true) {
        setAddAddress(response.data.success);
        setpasswordresponseMessage(response.data.message);

        setError("");
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorOTPMessage1(error.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleChangePasswordSubmit = (event) => {
    event.preventDefault();
    if (!validateForm1()) {
      // If the form is not valid, don't submit
      return;
    }

    fetchPasswordData();
  };
  const handleOTPm = () => {
    setTimeout(() => {
      setpasswordresponseMessage('');
    }, 1000);
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = {};


    if (!formData.old_password) {
      newErrors.old_password = 'Old Password is required';
      valid = false;
    }

    if (!formData.new_password) {
      newErrors.new_password = 'New Password is required';
      valid = false;
    }

    if (!formData.con_password) {
      newErrors.con_password = 'Confirm Password is required';
      valid = false;
    }

    if (formData.new_password === formData.con_password) {

    } else {
      // If new_password is not equal to con_password
      newErrors.con_password = 'New Password & Confirm Password does not match';
      // Set an error message indicating that the passwords do not match
      valid = false;
      // Set the valid variable to false, indicating that the form is not valid
    }

    if (!formData.otp) {
      newErrors.otp = 'OTP is required';
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const fetchsubmitPasswordData = async () => {
    // console.log('all add info new:',addressAllinfo);
    // console.log('otp n:',formData.otp);
    // const collectdata = {
    //    old_password: formData.old_password,
    //    new_password: formData.new_password,
    //    con_password: formData.con_password,

    // };
    // setAllinfo(collectdata);
    const collectdata1 = {
      current_password: formData.old_password,
      new_password: formData.new_password,
      confirm_new_password: formData.con_password,
      otp: formData.otp

    }
    // console.log("col:",collectdata1);

    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/changepassword`, collectdata1, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      if (response.data.success === true) {
        setsuccessResponseMessage(response.data.message);
        //   setTimeout(() => {
        //     window.location.reload(true);
        //   }, 1000);
        setErrorOTPMessage1('');
        setFormData({
          old_password: '',
          new_password: '',
          con_password: '',
          otp: ''
        });
        setLoading(false);
      } else {
        setErrorOTPMessage1(response?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorOTPMessage1(error.response?.data?.message);
        setLoading(false);
      }
    }

  };
  const handleAddSubmit = (event) => {
    event.preventDefault();


    if (!validateForm()) {
      // If the form is not valid, don't submit
      return;
    }
    fetchsubmitPasswordData();

  };

  return (
    <div>
      <ToastContainer />
      {loading ? <Loader /> : null}
      <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section id="product-listing-sec" className="inner-page-bg mb-5">
        <div className="container">
          <div className="row">
            <MyAccountMenu />
            <div className="col-lg-9  col-sm-12  Myitems-box" style={{ alignSelf: 'flex-start', marginTop: '17px' }}>
          

      
              <form onSubmit={handleChangePasswordSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <h2 className="myItems myItems-changepassword">CHANGE PASSWORD</h2>

                    {errors.old_password || errors.new_password || errors.con_password ? (
                    <div className="error-message-password mb-2" >
                      Error occurred 
                    </div>
                  ) : null}

                  {addPasswordresponseMessage && (
                          <div className="error-message-password" >
                            {addPasswordresponseMessage}
                          </div>
                        )}

                {errorOTPmsg1 && (
                <div className="error-message-password">
                  {errorOTPmsg1}
                </div>
              )}

                {successResponseMessage && (
                <div className="error-message-password" onClick={handleAlertClick}>
                  {successResponseMessage}
                </div>
              )}


                    <div className="field">
                      <label className="curpassword">Current Password *</label>
                      
                      <div className="input-container-icon mb-2">
                        <input
                          className={`old-password-password  form-control ${errors.old_password ? 'error-border-password' : ''}`}
                          type="password"
                          name="old_password"
                          placeholder="Enter your current password"
                          onChange={handleChange}
                          value={formData.old_password}
                        />
                        {errors.old_password && (
                          <div className="warning-icon">
                            <i className="">
                            <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg>
                            </i>
                          </div>
                        )}

                      </div>


                      <label className="curpassword">New Password *</label>

                      <div className="input-container-icon mb-2">
                        <input
                          className={`old-password-password  form-control ${errors.new_password ? 'error-border-password' : ''}`}
                          type="password"
                          name="new_password"
                          placeholder="Enter New password"
                          onChange={handleChange}
                          value={formData.new_password}
                        />
                        {errors.new_password && (
                          <div className="warning-icon">
                            <i className="">
                            <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg>
                            </i>
                          </div>
                        )}

                      </div>



                      {/* <input className={`old-password form-control ${errors.new_password ? 'error-border-password' : ''}`} type="password" name="new_password" placeholder="Enter new password" onChange={handleChange} value={formData.new_password}
                      /> */}


                      <label className="curpassword">Confirm Password *</label>
                      <div className="input-container-icon mb-2"  >
                        <input
                          className={`old-password-password  form-control ${errors.con_password ? 'error-border-password' : ''}`}
                          type="password"
                          name="con_password"
                          placeholder="Confirm your password"
                          onChange={handleChange}
                          value={formData.con_password}
                        />
                        {errors.con_password && (
                          <div className="warning-icon">
                            <i className="">
                            <svg  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group">
                            <path id="Vector" d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" fill="#c41a1a"/>
                            </g>
                            </svg>
                            </i>
                          </div>
                        )}

                      </div>




                      {/* <input className={`old-password form-control ${errors.con_password ? 'error-border-password' : ''}`} type="password" name="con_password" placeholder="Confirm your password" onChange={handleChange} value={formData.con_password}
                      /> */}

                    </div>

                  </div>

                </div>


                <div className="" >
                  {addAddress ? (
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="field">
                          <label className="curpassword">Enter OTP *</label>
                          <input className="old-password-password form-control" type="number" name="otp" placeholder="Enter OTP" onChange={handleChange} value={formData.otp} onClick={(e) => handleOTPm()}
                          />
                          {errors.otp && <div className="error">{errors.otp}</div>}
                        </div> <br></br>
                      </div>
                      <div className="col-lg-6" >

                      </div>

                      <br></br>
                      <div className="form-group col-6 d-flex justify-content-end" >
                  
                        <button onClick={handleAddSubmit}
                          className="btn sendotpbtn"
                          style={{
                            border: "2px solid #059fe2",
                            borderRadius: "0px",
                            width: '40%',
                          }}
                        >
                          SAVE CHANGES
                        </button>
                      </div>
                    </div>
                  ) : (
                    
                    <div className="form-group col-lg-6 d-flex justify-content-end" style={{ paddingRight : '10px'}}>
                      <button
                        className="btn sendotpbtn"
                        style={{
                          border: "2px solid #059fe2",
                          borderRadius: "0px",
                          width: '30%'
                        }}
                      >
                        SEND OTP
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>



          </div>
        </div>
      </section>



      <Footer />
    </div>

  )
}
export default Password;