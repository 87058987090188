import Footer from "../components/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { getToken } from "../services/LocalStorageSrv";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet';
const Categorylist = () => {
  const nvg = useNavigate()
  const [Loading,setLoading] = useState(true)
  const [catedata,setcatedata] = useState([])
  const apiUrl = process.env.REACT_APP_API_URL;
  const localdata = getToken('data')
  // console.log("error is here",catedata);
  const configToken = {
     headers: {
         Authorization: `Bearer ${localdata.data.token}`,
     },
   };

   useEffect(() => {

    const fetchCategoryDetail = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${apiUrl}/productCategory`, configToken);
          setcatedata(response.data.data.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user profile data:', error);
          setLoading(false);
        }
       };
       fetchCategoryDetail();
  }, []);
  return (
    Loading == true ? "" : <div>
      {/* <div style={{ width: "100vw" }}>
        <Header />
      </div> */}
      {/* <Header /> */}
      <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section id="product-listing-sec" className="inner-page-bg mb-3">
        <div className="container p-0">
          <div className="row">
            {catedata.map((item,index)=>(
              <div className="col-xl-4 col-md-6 col-xs-12 smpadding">
              <div
                className="card shadow-sm mb-4 rounded"
                style={{ borderColor: "white" }}
              >
                <div
                  className="card-body d-flex align-items-center"
                  style={{ gap: "4px" }}
                >
                  <div className="imgcomtainer">
                    <img
                      // src="./images/icons/23.png"
                      src={item.logo}
                      style={{ width: "60px" }}
                      alt=""
                    />
                  </div>
                  <div className="detailcontainer">
                    <h4 className="card-title title2">{item.category_name}</h4>
                    <p className="card-subtitle mb-2 text-body-secondary desc2" dangerouslySetInnerHTML={{__html: item.category_description}}>
                      {/* Hand Protection */}
                    </p>
                    <p style={{cursor:'pointer'}} onClick={()=>{nvg("/product-category-detail",{ state: { name: item.slug, cat_id:item.id } })}} className="link2">
                      VISIT NOW
                    </p>
                  </div>
                </div>
              </div>
            </div>
            ))}
           
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="card shadow-sm mb-4 rounded"
                style={{ borderColor: "white" }}
              >
                <div
                  className="card-body" >
                  <div className="detailcontainer">
                    {catedata.map((item,index)=>(
                    <>
                    <h2 className="card-title title3" style={{marginTop:index == 0 ? '3px' : '35px'}}>{item.category_name}</h2>
                    
                    <div className="row">
                      {item.child_categoires.map((item2,index2)=>(
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4" style={{cursor:'pointer'}} onClick={()=>{nvg("/product-category-detail",{ state: { name: item2.slug, cat_id:item2.id } })}}>{item2.category_name}</h5></div>
                      ))}
                    </div>
                    </>
                    ))}

                    {/* <h2 className="card-title title3 mt-5">Safety Shoes </h2>
                    
                    <div className="row">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Impact Gloves </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Anti-Vibration Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Welding Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Leather Working Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Disposable Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Chemical Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Cut Resistant Gloves   </h5></div>
                    </div>

                    <h2 className="card-title title3 mt-5">Automotive  </h2>
                    
                    <div className="row">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Dotted Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Knitted Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Driving Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Chainextra Stainless Steel Wrist Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Breathable Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Cut Resistant Gloves   </h5></div>
                    </div> */}

{/* 
                    <h2 className="card-title title3 mt-5">Head & Face Protection  </h2>
                    
                    <div className="row">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Impact Gloves </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Anti-Vibration Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Chainextra Stainless Steel Wrist Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Breathable Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Cut Resistant Gloves   </h5></div>
                    </div>
                    

                    <h2 className="card-title title3 mt-5">Hearing Protection  </h2>
                    
                    <div className="row">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Impact Gloves </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Anti-Vibration Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Welding Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Leather Working Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Disposable Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Chemical Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Dotted Gloves  </h5></div>
                    </div>

                    <h2 className="card-title title3 mt-5">Fall Protection   </h2>
                    
                    <div className="row">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Impact Gloves </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Anti-Vibration Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Welding Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Leather Working Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Disposable Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Chemical Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Dotted Gloves  </h5></div>
                    </div>

                    <h2 className="card-title title3 mt-5">Hardware & Tools   </h2>
                    
                    <div className="row">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Impact Gloves </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Anti-Vibration Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Welding Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Leather Working Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Disposable Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Chemical Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Dotted Gloves  </h5></div>
                    </div> */}

                    {/* <h2 className="card-title title3 mt-5">Road Safety   </h2>
                    
                    <div className="row">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Impact Gloves </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Anti-Vibration Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Welding Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Leather Working Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Disposable Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4"> Chemical Gloves  </h5></div>
                     <div className="col-xl-2 col-lg-2 col-md-3 col-12"> <h5 className="title4">  Dotted Gloves  </h5></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Categorylist;
