import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { getToken } from "../services/LocalStorageSrv";
import MyAccountMenu from './MyAccountMenu';
import axios from 'axios';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const Profile = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const nvg = useNavigate()
    const localdata = getToken('data')
    // console.log('Token1:', localdata.data.token);
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState([]);
    const configToken = {
        headers: {
            Authorization: `Bearer ${localdata.data.token}`,
        },
    };

    useEffect(() => {
        const ProfileData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/userProfile`, configToken);
                //console.log('Fetched Data:', response.data.data);

                // Assuming response.data is an object containing profile data
                setProfileData(response.data.data);

                setLoading(false);
            } catch (error) {
                console.error('Error fetching user profile data:', error);
                console.error('Axios error details:', error.response); // Log the response for more details
                setLoading(false);
            }
        };

        ProfileData();
    }, [setProfileData]);




    const redirectToUpdateProfile = () => {
        // Use the push method to navigate to another page
        nvg('/update-profile');
    };

    return (
        <div>
            {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
            {loading ? <Loader /> : null}
            <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
            <section id="product-listing-sec" className="inner-page-bg mb-5">
                <div className="container">
                    <div className="row">
                        <MyAccountMenu />
                        <div className="col-lg-9  col-sm-12  Myitems-box" style={{ alignSelf: 'flex-start', marginTop: '18px' }}>
                            <div className="row">

                                <h2 className="myItems">Pofile Details</h2>
                                {/* ITEM 1 */}

                                {/* test my top */}
                                <div className="mt-2">


                                    <div className="card-body">
                                        <div className="row mb-2 gap-5">
                                            {profileData.profilepic ? (
                                                <div className="order-details border-bottom profile-half ">
                                                    <h4 className="mt-4">
                                                        <span className="order-details-heading1">Profile Pic*</span>
                                                    </h4>

                                                    <img src={profileData.profilepic} className="imgView" alt="" />
                                                </div>
                                            ) : (
                                                <div className="order-details border-bottom profile-half ">
                                                    <h4 className="mt-4">
                                                        <span className="order-details-heading1">Profile Pic*</span>
                                                    </h4>

                                                    <img src="./images/profile-img1.jpg" className="imgView" alt="" />
                                                </div>
                                            )}
                                            {profileData.business_card ? (

                                                <div className="order-details border-bottom  profile-half" >
                                                    <h4 className="mt-4">
                                                        <span className="order-details-heading1">Business Logo*</span>
                                                    </h4>
                                                    <img src={profileData.business_card} className="imgView" alt="" />
                                                </div>

                                            ) : (
                                                <div className="order-details border-bottom profile-half" >
                                                    <h4 className="mt-4">
                                                        <span className="order-details-heading1">Business Logo*</span>
                                                    </h4>
                                                    <img src="./images/profile-img1.jpg" className="imgView" alt="" />
                                                </div>
                                            )}
                                        </div>
                                        </div>
                                </div>

                                {/* test my bottem */}
                                {/* ITEM-2 */}
                                <div className="mt-2">


                                    <div className="card-body">
                                        <div className="row  mb-2 gap-5">
                                            <div className="order-details border-bottom profile-half"  >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Vender Code:</span>
                                                </h4>

                                                <h4 className="Vendercode">{profileData.vendor_code ? profileData.vendor_code : ''}</h4>
                                            </div>
                                            <div className=" order-details border-botto profile-half" >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Your Name:</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.name ? profileData.name : ''}</h4>
                                            </div>
                                        </div>


                                        <div className="row gap-5 mb-2">
                                            <div className="order-details  border-bottom profile-half" >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Username:</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.first_name ? profileData.first_name : ''} {profileData.last_name ? profileData.last_name : ''}</h4>

                                            </div>

                                            <div className="order-details  border-bottom profile-half" >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Email Address:</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.email ? profileData.email : ''}</h4>
                                            </div>



                                        </div>

                                    </div>
                                </div>

                                {/* ITEM-3 */}
                                <div className="mt-2">


                                    <div className="card-body">
                                        <div className="row  mb-2">
                                            <div className="order-details border-bottom profile-half"  >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Phone Number:</span>
                                                </h4>

                                                <h4 className="Vendercode">{profileData.mobile ? profileData.mobile : ''}</h4>
                                            </div>
               

                                        </div>


                                        <div className="row order-details border-bottom mb-2">
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Address:</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.address ? profileData.address : ''}</h4>

                                            </div>

                                        </div>
                                    </div>
                                </div>


                                {/* ITEM-4 */}
                                <div className="mt-2">


                                    <div className="card-body">
                                        <div className="row mb-2 gap-5">
                                            <div className="order-details border-bottom profile-half" >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">City/Town/District :</span>
                                                </h4>

                                                <h4 className="Vendercode">{profileData.city ? profileData.city : ''}</h4>
                                            </div>
                                            <div className="rder-details border-bottom profile-half" >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Country:</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.country ? profileData.country : ''}</h4>
                                            </div>

                                        </div>


                                        <div className="row order-details border-bottom mb-2">
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Landmark(Optional):</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.landmark ? profileData.landmark : ''}</h4>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* ITEM-5 */}
                                <div className="mt-2">
                                    <div className="card-body">
                                        <div className="row  mb-2 " >

                                            <div className="col-md-12" >
                                                <h2 className="profile-infobox">Management Person Details</h2>
                                            </div>

                                            <div className="row mb-2 gap-5">

                                            <div className="order-details border-bottom profile-half"  >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Management Person Name:</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.sales_person_first_name ? profileData.sales_person_first_name : ''}</h4>

                                            </div>
                                            <div className="order-details border-bottom profile-half "  >
                                                <h4 className="mt-4">
                                                    <span className="order-details-heading1">Management Email:</span>
                                                </h4>
                                                <h4 className="Vendercode">{profileData.sales_person_email ? profileData.sales_person_email : ''}</h4>
                                            </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row" style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px' }}>
                                <div className="col-md-12">
                                    <div className="personalinfo-btn">
                                        <button onClick={redirectToUpdateProfile} className="btn btn-btn-save rounded-0 text-uppercase pt-2 pb-2 mt-3">
                                            Update Profile
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>








                    </div>






                </div>
            </section>



            <Footer />
        </div>

    )
}
export default Profile;