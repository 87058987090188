import { useState } from "react";

function Demo(){
  const msg="Welcome Divya"  
  const [msgdata,setMsgdata]=useState("gggg");
return (
    <>
     <h1>{msgdata}</h1>
     <button onClick={()=>{
        setMsgdata("Set new val Akul");
     }}> click</button>
    </>

);


}

export default Demo