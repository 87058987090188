import { hover } from "@testing-library/user-event/dist/hover";
import React, { useEffect, useState, useRef } from "react";
// import Header from '.../components/Header/Header.css';
import { Link, useNavigate, useParams  } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { MdErrorOutline } from "react-icons/md";
import {
  getToken,
  storeproductid,
  getproductid,
} from "../services/LocalStorageSrv";
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Magnifier } from "react-image-magnifiers";
import ModalVideo from "react-modal-video";
import ReactPlayer from "react-player";
import "react-modal-video/css/modal-video.min.css";
import { Helmet } from 'react-helmet';
// import Cookies from 'js-cookie';
const ProductDetail = () => {
  const getcookies = getproductid("productid");
  // console.log("recently view product:", getcookies);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const handleMainImageClick = (index) => {
    setMainImageIndex(index);
  };
  const quickpopupclose = useRef(null);
  const cartpopup = useRef(null);
  const quickpopup = useRef(null);
  const downloadpopup = useRef(null);
  const { name } = useParams();
  // console.log(name);
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  // console.log('Fetched Userid:', localdata.data.id);
  const [isInWishlist, setIsInWishlist] = useState([]);
  // const [wishlist, setWishlist] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [productGalleryData, setProductGalleryData] = useState([]);
  const [productGalleryDataEmpty, setproductGalleryDataEmpty] = useState([]);
  const [ProSKU, setProSKU] = useState([]);
  const [ProductAttributes, setProductAttributes] = useState([]);
  const [ProductBrand, setProductBrand] = useState([]);
  const [rotateImagePath, setrotateImagePath] = useState([]);
  const [productIcon, setProductIcon] = useState([]);
  const [productFAQ, setProductFAQ] = useState([]);
  const [productTechDocument, setproductTechDocument] = useState([]);
  const [productFeatureVideo, setProductFeatureVideo] = useState([]);
  const [productRelateds, setproductRelateds] = useState([]);
  const [recentlyRelateds, setrecentlyRelateds] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const configToken = {
    headers: {
      Authorization: `Bearer ${localdata.data.token}`,
    },
  };
  const [Tabcontent, setTab] = useState("Description");
  // add to car state
  const [cartproductId, setCartProductId] = useState(null);
  const [product_de, setProduct_de] = useState(null);
  const [cartproductDetails, setCartProductDetails] = useState(null);
  const [productSKUDetails, setRelatedSKUDetails] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState(Array(20).fill(false)); // Assuming 5 rows
  const [activeInput, setActiveInput] = useState(null);

  const [quantityvalidation, setQuantityValidation] = useState(0);
  const [quantityValues, setQuantityValues] = useState({});
  const [totalPrices, setTotalPrices] = useState({});
  const [error, setError] = useState("");
  const [productids, setProductIds] = useState("");
  const [convertedArray, setConvertedArray] = useState([]);
  const [varient, setvarient] = useState({});
  const [addtocartresponseMessage, setAddCartResponseMessage] = useState(null);
  
  const handleAddCartAlertClick = () => {
    setError(false);
  };
  const handleQuantityChange = (variantId, quantity, price, pro_id) => {
    varient[variantId] = parseInt(quantity);
    setTimeout(() => {
      setError("");
    }, 1000);
    setProductIds(pro_id);
    // console.log('proid:',pro_id);
    // console.log('varient detail:',variantId,quantity,varient);
    const newArray = Object.entries(varient)
      .filter(([variant_id, quantity]) => quantity > 0) // Only include entries with quantity > 0
      .map(([variant_id, quantity]) => ({
        variant_id,
        quantity: String(quantity),
      })); // Map to the desired format
    // console.log("convert to:",newArray);
    setConvertedArray(newArray);
    // console.log('v:',variantId);
    // console.log('p:',price);
    // console.log('q:',quantity);
    // setQuantityValidation(quantity);
    // if (quantity != '' && quantity.length > 1) {
    //   setError('');
    // }else{
    //   setError('Please Select Size and Add Quantity');
    // }
    // if (quantity !== 0) {
    //   setError('');
    // }
    setQuantityValues((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: parseInt(quantity, 10),
    }));
    setTotalPrices((prevTotalPrices) => ({
      ...prevTotalPrices,
      [variantId]: parseFloat(price) * parseInt(quantity, 10),
    }));
  };
  const [productCookieIds, setProductCookieIds] = useState([]);
  const [ProductName, setProductName] = useState([]);

  useEffect(() => {
    
   
    const initialTotalPrices = {};
    Object.keys(quantityValues).forEach((variantId) => {
      initialTotalPrices[variantId] =
        parseFloat(product_de.uprice) * quantityValues[variantId];
    });
    setTotalPrices(initialTotalPrices);

    const fetchProductDetail = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/productweb/${name}`,
          configToken
        );

        // Assuming response.data is an object containing profile data
        setProductData(response?.data?.data);
        setProductName(response?.data?.data.product_name);
        setWishlist(response?.data?.data?.wishlist);
        setProductAttributes(response?.data?.data?.products_attributes);
        setProductIcon(response?.data?.data?.product_icons);
        setProductFAQ(response?.data?.data?.faqDetails);
        setproductTechDocument(response?.data?.data?.tech_documents);
        setProductFeatureVideo(response?.data?.data?.featurevideos);
        // response?.data?.data?.featurevideos.map((item, index) => videoRef((prevData) => [...prevData,useRef(null)]))
        

        setProductBrand(response?.data?.data?.product_brand);
        setproductGalleryDataEmpty(response.data.data.gallery);
        
          let slid_img = []
          
          if(response.data.data.rotateImagePath == '')
          {
            slid_img = [response.data.data.main_image,...response.data.data.gallery]
            setProductGalleryData(slid_img);
            // console.log('slid  imggg:',slid_img);
          }else{
            slid_img = ['360-view',response.data.data.main_image,...response.data.data.gallery]
            setProductGalleryData(slid_img);
            // console.log('slid 360  imggg:',slid_img);
          }


        setrotateImagePath(response.data.data.rotateImagePath);
        // console.log('rots:',response?.data?.data?.rotateImagePath);
        // console.log('wish Data:',response?.data?.data?.id );
        // const handleCookies = (productId) => {
          // Save product ID to cookie on click
          // Cookies.set('product_id', productId);
          // const updatedProductIds = [String(productId), ...productCookieIds.slice(0, 3)]; // Keep the latest 4 product IDs
        //  if(response.data.data.id){
                 if(getcookies !== null){
                  if (!getcookies.includes(response.data.data.id)) {
                    const updatedProductIds2 = [...getcookies];
                    updatedProductIds2.unshift(response.data.data.id)
            // const updatedProductIds = [...productCookieIds, String(productId)];  
            // Cookies.set('product_ids', JSON.stringify(updatedProductIds), { expires: 7 }); // Set expiration for 7 days
            // console.log("co:", getcookies);
            // console.log("p id:", response.data.data.id);
            // console.log("test:", updatedProductIds2);
            // console.log("test lenght:", updatedProductIds2.length);
            if(updatedProductIds2.length > 4){     
              // console.log("greter than",updatedProductIds2)
              storeproductid(updatedProductIds2.slice(0, 4));
            }else{
              // console.log("greter no than",updatedProductIds2)

              storeproductid(updatedProductIds2);
            }
                  }
        }else{
                   storeproductid([response.data.data.id]);
                 }
        //  }
          // setProductCookieIds(updatedProductIds);
        // };
        // handleCookies(response?.data?.data?.id);
        // const storedProductIds = Cookies.get('product_ids');
        //   try {
        //     if (storedProductIds) {
        //       storeproductid(JSON.parse(storedProductIds));
        //       setProductCookieIds(JSON.parse(storedProductIds));

        //     }
        //   } catch (error) {
        //     // Handle JSON parsing error
        //     console.error('Error parsing JSON:', error);
        //     // You may want to reset the cookie or handle the error in a different way
        //   }
        

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile data:", error);
        // console.error('Axios error details:', error.response); // Log the response for more details
        setLoading(false);
      }
    };

    const queryParams = new URLSearchParams();
    queryParams.set("order", "DESC");
    queryParams.set("limit", "4");
    queryParams.set("sku", name);
    const projectRelateds = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${apiUrl}/getProductsBySKU?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        // console.log('Related Data:', response.data.data);
        setproductRelateds(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching best sellers data:", error);
        setLoading(false);
      }
    };

    if (getcookies && getcookies.length > 0) {
      const recentlyViewPro = async () => {
        try {
          setLoading(true);

          const response = await axios.get(
            `${apiUrl}/productsByIds?${getcookies
              .map((id) => `include[]=${id}`)
              .join("&")}&sort=asc`,
            {
              headers: {
                Authorization: `Bearer ${localdata.data.token}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
              },
            }
          );
          // console.log('Related Data:', response.data.data);
          setrecentlyRelateds(response.data.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching best sellers data:", error);
          setLoading(false);
        }
      };
      recentlyViewPro();
    }
    fetchProductDetail();
    projectRelateds();
  }, [name, quantityValues,ProductName]);

  
  const [formData, setFormData] = useState({
    headline: "",
    comment: "",
    productId: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [responseMessageVideo, setResponseVideoMessage] = useState("");

  const handleAlertClick = () => {
    setResponseMessage(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const [headline, setheadline] = useState("");
  const [comment, setcomment] = useState("");
  const [errorheadline, seterrorheadline] = useState("");
  const [errorcomment, seterrorcomment] = useState("");
  const [errorfeedback, seterrorfeedback] = useState("");
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate headline
    if (!formData.headline.trim()) {
      newErrors.headline = "Headline is required";
      valid = false;
    }

    // Validate comment
    if (!formData.comment.trim()) {
      newErrors.comment = "Comment is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      headline !== "" &&
      comment !== ""
    ) {
    // if (!validateForm()) {
    //   // If the form is not valid, don't submit
    //   return;
    // }
    const productId = e.target.productId.value;

    const collectdata = {
      title: e.target.headline.value,
      comment: e.target.comment.value,
      proid: productId, // You need to define username
      userId: localdata.data.id,
    };
    // console.log(collectdata);
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/productreview/create`,
        collectdata,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }
      );

      if (response.data.success === true) {
        // Assuming you have a function like storeToken to handle token storage
        // storeToken(collectdata);
        setResponseMessage('Your Feedback Submitted Successfully!');
        toast.success(
          <div>
            <div>Your Feedback Submitted Successfully!</div>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
        // console.log('succeess',response.data.message);
        setLoading(false);
      }
        seterrorheadline("");
        seterrorcomment("");
        setLoading(false);
    } catch (error) {
      if (errors.response?.data?.message) {
        setErrors(errors.response?.data?.message);
      }
      console.error("Error submitting review:", error);
    }
  }
  if (headline == "") {
    seterrorheadline("Headline is Required");
  } else {
    seterrorheadline("");
  }
  if (comment == "") {
    seterrorcomment("Comment is Required");
  } else {
    seterrorcomment("");
  }
  if (headline == "" && comment == "") {
    seterrorfeedback("Validation Error.");
  } else {
    seterrorfeedback("");
  }
    setFormData({
      headline: "",
      comment: "",
    });
  };

  // start add to cart api logic and all

  const inputRefs = useRef(
    Array(5)
      .fill(null)
      .map(() => React.createRef())
  );

  const handleInputChange = (index) => {
    // Toggle the checkbox state for the clicked row
    setCheckboxStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });

    // Set the active input to the clicked input
    setActiveInput(index);
  };

  const handleClickOutside = (event) => {
    // Uncheck all checkboxes when clicking outside of any input
    if (
      !inputRefs.current.some(
        (ref) => ref.current && ref.current.contains(event.target)
      )
    ) {
      setCheckboxStates(Array(5).fill(false));
      setActiveInput(null);
    }
  };
  const handleAddCartButtonClick = (id, name, sku, qyt) => {
    if (quickpopupclose.current) {
      // Trigger the click event on the button
      quickpopupclose.current.click();
    }
    if (cartpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(cartpopup.current);
      modal.show();
    }
    setProductIds(id);
    // console.log('proid:',id);
    const fetchProductValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/products/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setProduct_de(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchAddCartModelValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/products/porductVariant?product_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setCartProductDetails(response.data.data);
        const updatedAttributes = {};
        response.data.data.forEach((item, index) => {
          updatedAttributes[item.id] = 0;
        });
        setvarient((prevData) => ({
          ...prevData,
          ...updatedAttributes,
        }));
        //  console.log('pro cart de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchRelatedSKUValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/getRelatedProductsBySKU?sku=${sku}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setRelatedSKUDetails(response.data.data);
        // console.log('pro cr sku:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchRelatedSKUValue();
    fetchProductValue();
    fetchAddCartModelValue();
  };

  const handleDownloadClick = (id) => {
    if (downloadpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(downloadpopup.current);
      modal.show();
    }
    const collectdata = {
      product_id: id,
    };
    const fetchDownloadData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${apiUrl}/createProductVideoRequest`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);
          setResponseVideoMessage(response.data.message);
          // console.log('succeess:',response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchDownloadData();
  };

  // Function to handle form submission
  // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {

  const handleAddToCartSubmit = async (e) => {
    e.preventDefault();

    // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
    //   if(quantityvalidation <= 0){
    //   setError('Please Select Size and Add Quantity.');
    // } else {
    // Perform submit action or other logic here
    // console.log('quantity array:',quantityValues);
    // console.log('price array:',totalPrices);
    // console.log('Submit:', quantityvalidation);
    // }
    // const value = parseInt(quantityvalidation);

    // if (!isNaN(quantityvalidation) && quantityvalidation > 0 && quantityvalidation <= 1) {
    if (!convertedArray[0]) {
      // console.log('cons:',convertedArray);
      setError("Please Select Size and Add Quantity.");
    } else {
      // console.log('proids:',productids);
      // console.log('varient qyt array:',convertedArray);
      // Your submission logic here
      const collectdata = {
        product_id: productids,
        product_option: convertedArray,
      };
      // console.log('add to cart submit:',collectdata);

      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/carts/add`, collectdata, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });

        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setAddCartResponseMessage(response.data.message);

          setShowModal(false);
          toast.success(
            <div>
              <div>{response.data.message}</div>
              <button
                className="order-now"
                onClick={redirectToCart}
                style={{ marginTop: "10px" }}
              >
                Go to Cart
              </button>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );

          setTimeout(() => {
            window.location.reload(true);
          }, 2000); // 7000 milliseconds is the autoClose duration
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const redirectToCart = () => {
    // Redirect logic to the cart page; replace with your actual redirect logic
    console.log("Redirecting to the cart page...");
    // For demonstration purposes, let's assume the cart page has a path '/cart'
    // You may want to use react-router-dom or your preferred routing library for this
    // window.location.href = '/cart';
    nvg(`/cart`);
  };
  const [isOpenV, setOpenV] = useState(false);

  const openVModal = () => {
    setOpenV(true);
  };

  const closeVModal = () => {
    setOpenV(false);
  };
  const handleProDetails = (pro_name) => {
    nvg(`/product-detail/${pro_name}`);
    window.location.reload();
  };
  // const [wishlist, setWishlist] = useState([]);

  const handleAddWishlistQuick = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addToWishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          // setProductData(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== product_id) : []);

          setWishlist(response.data.success);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistAd();
  };

  const handleRemoveWishlistQuick = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistRemove = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );

          setWishlist(false);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistRemove();
  };
  const handleAddWishlist = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addToWishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          // setProductData(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== product_id) : []);

          setWishlist(response.data.success);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistAd();
  };

  const handleRemoveWishlist = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistRemove = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );

          setWishlist(false);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistRemove();
  };

  const handleAddWishlistOther = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };

    const fetchwishlistAdOther = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addToWishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          // setProductData(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== product_id) : []);

          setWishlist(response.data.success);
          //  setTimeout(() => {
          //    window.location.reload(true);
          //  }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistAdOther();
  };

  const handleRemoveWishlistOther = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistRemoveOther = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );

          setWishlist(false);
          //  setTimeout(() => {
          //    window.location.reload(true);
          //  }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistRemoveOther();
  };

  const [AddWhishlistIcon, setAddWhishlistIcon] = useState(false);
  const [RemoveWhishlistIcon, setRemoveWhishlistIcon] = useState(true);
  const [AddWhishlistId, setAddWhishlistId] = useState([]);
  const handleAddWishlistChange = async (product_id) => {
    setAddWhishlistIcon(true);
    setAddWhishlistId(product_id);
    // console.log('r ids:',AddWhishlistId);
    // console.log('true r ids:',AddWhishlistIcon);
  };

  const handleRemoveWishlistChange = async (product_id) => {
    setRemoveWhishlistIcon(false);
    setAddWhishlistId(product_id);
    // console.log('add ids:',AddWhishlistId);
    // console.log('false add ids:',RemoveWhishlistIcon);
  };

  const [productDetails, setProductDetails] = useState(null);
  const [productId, setProductId] = useState(null);
  const handleQuickButtonClick = (id) => {
    if (quickpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(quickpopup.current);
      modal.show();
    }
    setProductId(id);
    //  console.log('id:',id);
    const fetchQuickModelValue = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/products/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setProductDetails(response.data.data);
        setproductGalleryDataEmpty(response.data.data.gallery);
        // setProductGalleryData(response?.data?.data?.gallery);
        let slid_img = []
          
          if(response.data.data.rotateImagePath == '')
          {
            slid_img = [response.data.data.main_image,...response.data.data.gallery]
            setProductGalleryData(slid_img);
            // console.log('Q slid  imggg:',slid_img);
          }else{
            slid_img = ['360-view',response.data.data.main_image,...response.data.data.gallery]
            setProductGalleryData(slid_img);
            // console.log('Q slid 360  imggg:',slid_img);
          }

          // console.log('sku n:',response.data.data.sku);
          setProSKU(response?.data?.data?.sku);
          setWishlist(response?.data?.data?.wishlist);
          setrotateImagePath(response.data.data.rotateImagePath);
        //   console.log('pro de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchQuickModelValue();
  };

  let videoRef = []
  // console.log('videal get:',videoRef)
  // const useVideoRefs = (productFeatureVideo) => {
  //   const videoRefs = productFeatureVideo.reduce((acc, video) => {
  //     acc[video.id] = useRef(null);
  //     return acc;
  //   }, {});
  
  //   return videoRefs;
  // };

  // const videoRefs = useVideoRefs(productFeatureVideo);

  // const togglePlayPause = (videoId) => {
  //   const video = videoRefs[videoId].current;

  //   if (video.paused) {
  //     video.play();
  //   } else {
  //     video.pause();
  //   }
  // };
  const [playstop, setplaystop] = useState('na');
  const handlePlayClick = (event) => {
    // Access the video source URL from the data-src attribute
    const videoSrc = event.currentTarget.getAttribute('data-src');

    // Find the corresponding video element using querySelector
    const video = document.querySelector(`video[data-src="${videoSrc}"]`);
    // console.log('all v:',video)
    // Check if the video element is available
    if (video.paused) {
      // Play the video
      video.play();
    }else {
          video.pause();
        }
  };
  const handleDownloadSheet = (event) => {
  
    const clickedUrl = event;
    // console.log('Clicked URL:', clickedUrl);
  
    // Create a temporary link element
    // const downloadLink = document.createElement('a');
    // downloadLink.href = clickedUrl;
    // downloadLink.download = 'download.jpg'; // Set the desired filename for the downloaded image
  
    // // Append the link to the document and trigger a click event
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
  
    // // Remove the temporary link from the document
    // document.body.removeChild(downloadLink);
  };
  // const DataSheetImageURL = "https://ayurherbals.co/cdn/shop/files/0e7dda1810181d9c2ee2fc35f277f5b0.png?v=1695731190&width=300";

  // const TabImageURL = "https://ayurherbals.co/cdn/shop/files/0e7dda1810181d9c2ee2fc35f277f5b0.png?v=1695731190&width=300";
  const downloadDataSheetImage = async (DataSheetImageURL) => {
    try {
      const response = await fetch(DataSheetImageURL);
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = getFileNameFromUrl(DataSheetImageURL);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const downloadTabImage = async (TabImageURL) => {
    try {
      const response = await fetch(TabImageURL);
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = getFileNameFromUrl(TabImageURL);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const getFileNameFromUrl = (url) => {
    const urlParts = url.split('/');
    const fileNameWithParams = urlParts[urlParts.length - 1];
  
    // Remove query parameters from the filename
    const fileName = fileNameWithParams.split('?')[0];

    return fileName;
  };
  
  return (
    <div>
      {loading ? <Loader /> : null}      
      <ToastContainer />
      <Helmet>
       <title>{`${ProductName} | Vaultex`}</title>
      </Helmet>
      <section className="product-detail-sec inner-page  inner-page-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 slider-part">
            

              <div className="flexslider galleries" style={{border:'none'}}>
                
                <div>
                  <div className="card-carousel gallery-active" style={{background:'none'}}>

                    <div className="thumbnails thum_imges_slider"
                      style={{ position: "relative" }}
                    >
                      {productGalleryData == '' || productGalleryData == null ? (
                        <Carousel 
                        showArrows={false}
                        showIndicators={false}
                        showStatus={false} 
                        autoPlay={false}
                      >
                        <img
                          src={productData.main_image}
                          className="slider_img_border"
                          style={{ borderColor: '#059fe2'}}
                        />
                      </Carousel>
                          ) : (
                            <Carousel 
                            showArrows={false}
                            showIndicators={false}
                            showStatus={false} 
                            autoPlay={true}
                            interval={4000}  
                          >

                            {productGalleryData.map((image, index) => (
                              image == '360-view' ? 
                               <>
                               <iframe src={`${process.env.REACT_APP_360_URL}/${productData.sku}/edit.html`} width="600" height="600"></iframe>
                               <img src={`${process.env.REACT_APP_360_URL}/${productData.sku}/images/img_0_0_0.jpg`} className="slider_img_border" />
                               </>
                               : 
                              <img
                                key={index}
                                src={image}
                                className="slider_img_border"
                              />

                            ))}
                          </Carousel>
                          
)}
  
                      {
                        productData.video == null ? (
                          ""
                        ) : (
                          <div className="thumbnail-image">
                            <video
                              href="javascript:void(0);"
                              className="slider-img-pro-detail slider_img_border"
                              role="button"
                              onClick={openVModal}
                            >
                              <source
                                src={productData.video}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        )
                      
                      }
                      <div style={{ width: "60px", margin: "10px" }}>
                        <ModalVideo
                          channel="custom"
                          isOpen={isOpenV}
                          url={productData.video}
                          onClose={closeVModal}
                        />
                      </div>

                      
                     
                    </div>

                  </div>
                 
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 info-part">
              <h2 className="product-name text-uppercase">
                {" "}
                {productData.product_name ? productData.product_name : ""}{" "}
              </h2>
              <p className="sku-no text-uppercase">
                Sku :{productData.sku ? productData.sku : ""}
              </p>
              <div className="action">
                <a href="#" target="_blank" className="shar-btn">
                  <img
                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/share.png`}
                  />
                  <span> Share </span>
                </a>
                {/* {productRelated.wishlist === true ? (
                                 <a href="javascript:;" className="whish-list-btn"><img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} alt="Wishlist" /></a>
                                 ) : (
                                    <a href="javascript:;" className="whish-list-btn"><i className="fa fa-heart" ></i></a>
                                    
                                    )} */}
                {wishlist === false ? (
                  <a
                    onClick={
                      () => handleAddWishlist(productData.id)
                      // handleRemoveWishlist(productData.id)
                    }
                    href="javascript:void(0);"
                    className="whish-list-btn"
                  >
                    <img
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                    />
                    {/* {isInWishlist ? (
      <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} />
      ) : ( <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`} /> 
     )} */}
                    <span>Wish</span>
                  </a>
                ) : (
                  <a
                    onClick={() =>
                      // handleAddWishlist(productData.id)
                      handleRemoveWishlist(productData.id)
                    }
                    href="javascript:void(0);"
                    className="whish-list-btn"
                  >
                    <img
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                    />
                    {/* {isInWishlist ? (
      <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} />
      ) : ( <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`} /> 
     )} */}

                    <span>Wish</span>
                  </a>
                )}

                {productData.video !== null ? (
                  <a
                    href="javascript:void(0);"
                    className="download-btn"
                    onClick={() => handleDownloadClick(productData.id)}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/download.png`}
                    />
                    <span>Download Video</span>
                  </a>
                ) : (
                  ""
                )}
              </div>

              <div className="available-size">
                <label>Available Variations: </label>
                <p>
                  {ProductAttributes.map((itemCode) => (
                    <span>
                      &nbsp;&nbsp;{" "}
                      {typeof itemCode.item_code === "string"
                        ? itemCode.item_code.split("-")[1]
                        : ""}
                    </span>
                  ))}
                </p>
              </div>
              {ProductBrand && ProductBrand.brand_name ?  
  <div className="available-size">
    <label>Brand: </label>
    <p>
      <span>{ProductBrand.brand_name}</span>
    </p>
  </div>
  : ''}
              <div className="price-block">
                <label>Price:</label>
                <p>
                  <ins>
                    <span>
                      AED {productData.uprice ? productData.uprice : ""}
                    </span>
                  </ins>
                  <del>
                    <span>
                      AED{" "}
                      {productData.regular_price
                        ? productData.regular_price
                        : ""}
                    </span>
                  </del>
                </p>
              </div>
              {productData.download_datasheet && (
  <div className="download-datasheet">
    <a
    href="javascript:void(0);" onClick={()=>{downloadDataSheetImage(productData.download_datasheet)}}
      // href={productData.download_datasheet}
      className="text-uppercase"
      // download="datasheet.jpg"
    >
      <i aria-hidden="true" className="fa-solid fa-file"></i>{" "}
      Download DataSheet
    </a>
  </div>
)}


              <div className="order-now-btn">
                <p>
                  <a
                    href="javascript:void(0);"
                    onClick={() =>
                      handleAddCartButtonClick(
                        productData.id,
                        productData.product_name,
                        productData.sku,
                        productData.cartonQty,
                        productData.products_attributes[0].id
                      )
                    }
                    className="order-now"
                    role="button"
                  >
                    <img
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/cart_icon.png`}
                    />{" "}
                    Add to Cart{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="tab-section" className="product-tab-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs">
                <div className="tabs tab_width" style={{ width: "100%" }}>
                  <div className="">
                    <ul
                      role="tablist"
                      className="nav nav-tabs"
                      id="__BVID__148__BV_tab_controls_"
                    >
                      <li role="presentation" className="nav-item">
                        <button
                          type="button"
                          onClick={() => {
                            setTab("Description");
                          }}
                          className={`nav-link ${
                            Tabcontent === "Description" ? "active" : "inactive"
                          }`}
                        >
                          Description
                        </button>
                      </li>
                      <li role="presentation" className="nav-item">
                        <button
                          type="button"
                          onClick={() => {
                            setTab("Specs");
                          }}
                          className={`nav-link ${
                            Tabcontent === "Specs" ? "active" : "inactive"
                          }`}
                        >
                          Specs
                        </button>
                      </li>
                      <li role="presentation" className="nav-item">
                        <button
                          type="button"
                          onClick={() => {
                            setTab("Add Feedback");
                          }}
                          className={`nav-link ${
                            Tabcontent === "Add Feedback"
                              ? "active"
                              : "inactive"
                          }`}
                        >
                          Add Feedback
                        </button>
                      </li>
                      {productTechDocument && productTechDocument.length > 0 && (
                        <li role="presentation" className="nav-item">
                          <button
                            type="button"
                            onClick={() => {
                              setTab("Tech Document");
                            }}
                            className={`nav-link ${
                              Tabcontent === "Tech Document" ? "active" : "inactive"
                            }`}
                          >
                            Tech Document
                          </button>
                        </li>
                      )}
                      {productFAQ && productFAQ.length > 0 && (
                        <li role="presentation" className="nav-item">
                          <button
                            type="button"
                            onClick={() => {
                              setTab("FAQs");
                            }}
                            className={`nav-link ${
                              Tabcontent === "FAQs" ? "active" : "inactive"
                            }`}
                          >
                            FAQs
                          </button>
                        </li>
                      )}
                      {productFeatureVideo && productFeatureVideo.length > 0 && (
                        <li role="presentation" className="nav-item">
                          <button
                            type="button"
                            onClick={() => {
                              setTab("Features Videos");
                            }}
                            className={`nav-link ${
                              Tabcontent === "Features Videos"
                                ? "active"
                                : "inactive"
                            }`}
                          >
                            Features Videos
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>

                  <div
                    className="tab-content"
                    id="__BVID__55__BV_tab_container_"
                  >
                    <div
                      style={{
                        display: Tabcontent == "Description" ? "block" : "none",
                        margin: "30px",
                      }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {productIcon.map((icon, index) => (
                              <div className="col-md-2 mb-2 ">
                                <img
                                  key={index}
                                  src={icon}
                                  alt={`Product Icon ${index + 1}`}
                                  className="rounded img-thumbnail"
                                />
                              </div>
                            ))}
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>

                      <hr />
                      <h4>
                        <strong>Product Name:</strong>{" "}
                        {productData.product_name
                          ? productData.product_name
                          : ""}{" "}
                      </h4>
                      <h4>
                        <strong>SKU:</strong>{" "}
                        {productData.sku ? productData.sku : ""}
                      </h4>
                      {productData.description && (
                        <div>
                          <h4>
                            <strong>Product Description:</strong>
                          </h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: productData.description,
                            }}
                          ></p>
                        </div>
                      )}
                      {productData.features && (
                        <div>
                          <h4>
                            <strong>Features:</strong>
                          </h4>
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: productData.features,
                              }}
                            ></p>
                          </div>
                        </div>
                      )}
                      {productData.packaging_delivery_descr && (
                        <div>
                          <h5>
                            <strong>Packaging & Delivery:</strong>
                          </h5>
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: productData.packaging_delivery_descr,
                              }}
                            ></p>
                          </div>
                        </div>
                      )}
                      <div
                        role="tabpanel"
                        aria-hidden="true"
                        className="tab-pane"
                        id="__BVID__151"
                        aria-labelledby="__BVID__151___BV_tab_button__"
                        style={{ display: "none" }}
                      >
                        <h4>
                          <strong>Product Specification:</strong>
                        </h4>
                      </div>
                    </div>

                    <div
                      style={{
                        display: Tabcontent == "Specs" ? "block" : "none",
                        margin: "30px",
                      }}
                    >
                      <h4>
                        <strong>Product Specification:</strong>
                      </h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: productData.specification,
                        }}
                      ></p>
                    </div>
                    <div
                      style={{
                        display:
                          Tabcontent == "Add Feedback" ? "block" : "none",
                        margin: "30px",
                      }}
                    >
                     
                      {/* {responseMessage && <div  className="alert alert-success">{responseMessage}</div>} */}
                      {errorfeedback == "" ? (
                  ""
                ) : (
                  <div class="col-12 " style={{width:'100%'}} >
                  <div class=" alert alert-danger mx-0" style={{width:'100%',textAlign:'center'}} role="alert">
                    {/* {errorproduct} */}
                    Validation Error.
                  </div>
                  </div>
                )} 
                      <form className="review-form" onSubmit={handleSubmit}>
                        <input
                          type="hidden"
                          name="productId"
                          value={productData.id}
                          readOnly
                        />
                        <div className="form-col ">
                          <label className="labeltwo" >Add a Headline:</label>
                          <input
                            type="text"
                            id="headline"
                            name="headline"
                            style={{border:errorheadline !== '' ? '1px solid red' : ''}}
                            placeholder="ex: Very good or bad experience!"
                            className="form-control input-area"
                            value={headline}
                            onChange={(e) => {
                              setheadline(e.target.value);
                            }}
                          />
                          {errorheadline !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}
                          {/* {errors.headline && (
                            <div className="error">{errors.headline}</div>
                          )} */}
                        </div>
                        <div className="form-col">
                          <label className="labeltwo">Write your review:</label>
                          <textarea
                            name="comment"
                            style={{border:errorcomment !== '' ? '1px solid red' : ''}}
                            rows="5"
                            id="comment"
                            placeholder="Write you experience in brief ..."
                            className="form-control input-area"
                            value={comment}
                            onChange={(e) => {
                              setcomment(e.target.value);
                            }}
                          />
                          {/* {errors.comment && (
                            <div className="error">{errors.comment}</div>
                          )} */}
                          {errorcomment !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}
                        </div>
                        <div></div>
                        
                        {responseMessage && (
                          <div className="form-col">
                        <div
                          className="alert alert-success"
                          style={{
                            fontSize: '18px',
    lineHeight: '26px',
    color: '#333',
    marginBottom: '15px',
    margin: '0px',
                          }}
                        >
                          <center className="text-center" style={{marginLeft: '357px'}}>{responseMessage}</center>
                        </div></div>
                      )}
                        <div className="submit-btn">
                          <button type="submit" value="Submit">
                            Submit Now
                          </button>
                        </div>
                      </form>
                    </div>
                    {productTechDocument && productTechDocument.length > 0 && (
                      <div
                        style={{
                          display: Tabcontent == "Tech Document" ? "block" : "none",
                          margin: "30px",
                        }}
                        id="tech_document"
                      >
                        <h2 className="techDocTab">
                        Download the tech douments for more details:
                        </h2>
                        <br />
                        <div className="col-md-12">
                          <div className="row">
                           
                            {productTechDocument.map((techdoc, index) => (
                              <div className="row">

                                <div className="tech-doc-block col-md-6">
                                  <a href="javascript:void(0);" onClick={()=>{downloadTabImage(techdoc)}}>
                                    <i aria-hidden="true" className="fa-solid fa-file"></i> &nbsp;Title: {techdoc.split('/').pop()} <i aria-hidden="true" className="fa fa-arrow-down"></i>
                                  </a>
                                  
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {productFAQ && productFAQ.length > 0 && (
                      <div
                        style={{
                          display: Tabcontent == "FAQs" ? "block" : "none",
                          margin: "30px",
                        }}
                      >
                        <h2 className="faqTitle">
                          Most Frequently asked questions:
                        </h2>
                        <br />
                        <div className="col-md-12">
                          <div className="row">

                            {productFAQ.map((faq, index) => (
                           index == 0 ?  
                           <div
                           className="accordion"
                           id={`accordionExample${index}`}
                           key={index}
                         >
                           <div className="accordion-item ">
                             {/* <h2 className="accordion-header"> */}
                               <button
                                 className="accordion-button"
                                 type="button"
                                 data-bs-toggle="collapse"
                                 data-bs-target={`#collapse${index}`}
                                 aria-expanded="true"
                                 aria-controls={`collapse${index}`}
                               >
                                <span> {faq.title}</span>
                               </button>
                             {/* </h2> */}
                             <div
                               id={`collapse${index}`}
                               className="accordion-collapse collapse show"
                               data-bs-parent={`#accordionExample${index}`}
                               key={index}
                             >
                               <div className="accordion-body">
                                 {faq.description}
                               </div>
                             </div>



                             
                           </div>
                         </div>
                           :
                              <div
                                className="accordion"
                                id={`accordionExample${index}`}
                                key={index}
                              >
                                <div className="accordion-item ">
                                  {/* <h2 className="accordion-header"> */}
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${index}`}
                                      aria-expanded="true"
                                      aria-controls={`collapse${index}`}
                                    >
                                     <span> {faq.title}</span>
                                    </button>
                                  {/* </h2> */}
                                  <div
                                    id={`collapse${index}`}
                                    className="accordion-collapse collapse"
                                    data-bs-parent={`#accordionExample${index}`}
                                    key={index}
                                  >
                                    <div className="accordion-body">
                                      {faq.description}
                                    </div>
                                  </div>
                                </div>
                              </div>


                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {productFeatureVideo && productFeatureVideo.length > 0 && (
                      <div
                        style={{
                          display:
                            Tabcontent == "Features Videos" ? "block" : "none",
                          margin: "30px",
                        }}
                      >
                        <div className="row">
                          {productFeatureVideo.map((video) => (
                            <div className="col-lg-4 col-md-6 videofix">
                              <div className="video-block">
                                <div className="wrapper" >
                                <video
              width="320"
              height="240"
              src={video.video}
              type="video/mp4"
              controls={false}
              onClick={(e) => {handlePlayClick(e);setplaystop(video.video == playstop ? 'na' : video.video)}} data-src={video.video}
            >
                                  
                                  </video>
                            {playstop !== video.video  ?  
                            <>    
                                 <center>
                                  <div className="playpause">
                                    <a href="javascript:void(0);" className="bdr-ripple-ani-btn">
                                      <i  aria-hidden="true" className="fa fa-play"></i>
                                      </a>
                                  </div>
                                  </center>
                                 
                                  <div className="video-title-video">
                                  
                                  <h1>{video.name}</h1>
                               
                              </div>
                              </>
                              : <div className="video-title-video">
                                <h1 style={{marginTop: '-98px'}}>
                                  {video.name}
                                  </h1></div> }     
                                   
                                

                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {productRelateds.length > 0 && (
        <section className="recently-viewed-product common-products">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title">
                  <h2>
                    <span> Related Products </span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-12" style={{ marginTop: "14px" }}>
              <div className="row">
                {productRelateds.map((productRelated) => (
                  <div className=" col-lg-3 col-md-6 ">
                    <div className="card rightone" style={{ borderRadius: "0px" }}>
                      <div className="imageheart">
                        {productRelated.wishlist === false ? (
                          <a
                            onClick={
                              () => {
                                handleAddWishlistOther(productRelated.id);
                                handleAddWishlistChange(productRelated.id);
                              }
                              // handleRemoveWishlist(productData.id)
                            }
                            href="javascript:void(0);"
                            className="whish-list-btn"
                          >
                            {AddWhishlistIcon == true &&
                            productRelated.id == AddWhishlistId ? (
                              <img
                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                                alt="Wishlist"
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                              />
                            )}
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              // handleAddWishlist(productData.id)
                              {
                                handleRemoveWishlistOther(productRelated.id);
                                handleRemoveWishlistChange(productRelated.id);
                              }
                            }
                            href="javascript:void(0);"
                            className="whish-list-btn"
                          >
                            {/* <i className="fa fa-heart"></i> */}
                            {RemoveWhishlistIcon == false &&
                            productRelated.id == AddWhishlistId ? (
                              <img
                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                                alt="Wishlist"
                              />
                            )}
                          </a>
                        )}

                        {/* {productRelated.wishlist === true ? (
                                 <a href="javascript:;" className="addToWish"><img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} alt="Wishlist" /></a>
                                 ) : (
                                    <a href="javascript:;" className="addToWish"><i className="fa fa-heart" ></i></a>
                                    
                                    )} */}
                      </div>
                      <a
                        href="javascript:void(0);"
                        onClick={() =>
                          handleAddCartButtonClick(
                            productRelated.id,
                            productRelated.product_name,
                            productRelated.sku,
                            productRelated.cartonQty
                          )
                        }
                      >
                        <img
                          src={productRelated.thumbnail_image}
                          alt={productRelated.product_name}
                        />

                        <div className="style-no">
                          <h6>
                            <span className="px-2">
                            {productRelated.product_name.length > 30
                                ? `${productRelated.product_name.substr(0, 33)}...`
                                : productRelated.product_name}
                              
                            </span>
                          </h6>
                          {/* <h6><span>{productRelated.product_name}</span></h6> */}
                        </div>
                        <div className="Price-box">
                          <p>
                            <span>AED {productRelated.price}</span>
                            {productRelated.regular_price != null ? (
                              <small
                                className="text-muted"
                                style={{
                                  fontSize: "small",
                                  textDecoration: "line-through",
                                }}
                              >
                                &nbsp;&nbsp; AED {productRelated.regular_price}
                              </small>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                       

                        <div className="product-sku">
                          <p> UNIT: 
                            <span> {productRelated.product_unit
                                ? productRelated.product_unit
                                : "Pcs"} </span>
                          </p>
                          <p> SKU: 
                            <span> {productRelated.sku} </span>
                          </p>
                        </div>
                      </a>

                      {/* <div className="card-body">
                                 <h5 className="card-title">A</h5>
                                 <p className="card-text">b</p>
                                 <p className="card-text">c<small className="text-muted">d</small></p>
                              </div> */}
                     <div className="lastbutton" style={{ width: "100%" }}>
                          <button
                            className="btn btn-primary one d-flex"
                            type="button"
                            style={{
                              borderRadius: "0px",
                              width: "50%",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <span>
                              <i className="fa-regular fa-eye" />
                            </span>{" "}
                            <span  onClick={() => handleQuickButtonClick(productRelated.id)}>QUICK VIEW</span>
                          </button>
                          <button
                          className="btn btn-primary two d-flex"
                          type="button"
                          style={{borderRadius:'0px',width:'50%',justifyContent:'space-evenly',alignItems:'center',color:"white" }}
                          onClick={() => {
                            nvg("/product-detail/" + productRelated.slug);
                          }}
                        >
                          DETAILS VIEW
                        </button>
                        </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
      {getcookies && getcookies.length > 0
        ? recentlyRelateds.length > 0 && (
            <section className="recently-viewed-product common-products">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title">
                      <h2>
                        <span> Recently Viewed Products </span>
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12" style={{ marginTop: "14px" }}>
                  <div className="row">
                    {recentlyRelateds.map((rproduct) => (
                      <div className=" col-lg-3 col-md-6 ">
                        <div className="card rightone" style={{ borderRadius: "0px" }}>
                          <div className="imageheart">
                            {rproduct.wishlist === false ? (
                              <a
                                onClick={
                                  () => {
                                    handleAddWishlistOther(rproduct.id);
                                    handleAddWishlistChange(rproduct.id);
                                  }
                                  // handleRemoveWishlist(productData.id)
                                }
                                href="javascript:void(0);"
                                className="whish-list-btn"
                              >
                                {AddWhishlistIcon == true &&
                                rproduct.id == AddWhishlistId ? (
                                  <img
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                                    alt="Wishlist"
                                  />
                                ) : (
                                  <img
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                onClick={() =>
                                  // handleAddWishlist(productData.id)
                                  {
                                    handleRemoveWishlistOther(rproduct.id);
                                    handleRemoveWishlistChange(rproduct.id);
                                  }
                                }
                                href="javascript:void(0);"
                                className="whish-list-btn"
                              >
                                {/* <i className="fa fa-heart"></i> */}
                                {RemoveWhishlistIcon == false &&
                                rproduct.id == AddWhishlistId ? (
                                  <img
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                                  />
                                ) : (
                                  <img
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                                    alt="Wishlist"
                                  />
                                )}
                              </a>
                            )}

                            {/* {productRelated.wishlist === true ? (
                                 <a href="javascript:;" className="addToWish"><img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} alt="Wishlist" /></a>
                                 ) : (
                                    <a href="javascript:;" className="addToWish"><i className="fa fa-heart" ></i></a>
                                    
                                    )} */}
                          </div>
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              handleAddCartButtonClick(
                                rproduct.id,
                                rproduct.product_name,
                                rproduct.sku,
                                rproduct.cartonQty
                              )
                            }
                          >
                            <img
                              src={rproduct.main_image}
                              alt={rproduct.product_name}
                            />

                            <div className="style-no">
                              {/* <h6><span>{rproduct.product_name}</span></h6> */}
                              <h6>
                                <span  className="px-2">
                                {rproduct.product_name.length > 30
                                ? `${rproduct.product_name.substr(0, 33)}...`
                                : rproduct.product_name}
                                </span>
                              </h6>
                            </div>
                            <div className="Price-box">
                          <p>
                            <span>AED {rproduct.price}</span>
                            {rproduct.regular_price != null ? (
                              <small
                                className="text-muted"
                                style={{
                                  fontSize: "small",
                                  textDecoration: "line-through",
                                }}
                              >
                                &nbsp;&nbsp; AED {rproduct.regular_price}
                              </small>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                           
                            <div className="Order-price">
                              <span>Ordered Price : AED {rproduct.uprice}</span>
                            </div>

                            <div className="product-sku">
                          <p> UNIT: 
                            <span> {rproduct.product_unit
                                ? rproduct.product_unit
                                : "Pcs"} </span>
                          </p>
                          <p> SKU: 
                            <span> {rproduct.sku} </span>
                          </p>
                        </div>

                          </a>

                          {/* <div className="card-body">
                                 <h5 className="card-title">A</h5>
                                 <p className="card-text">b</p>
                                 <p className="card-text">c<small className="text-muted">d</small></p>
                              </div> */}
                           <div className="lastbutton" style={{ width: "100%" }}>
                          <button
                            className="btn btn-primary one d-flex"
                            type="button"
                            style={{
                              borderRadius: "0px",
                              width: "50%",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <span>
                              <i className="fa-regular fa-eye" />
                            </span>{" "}
                            <span  onClick={() => handleQuickButtonClick(rproduct.id)}>QUICK VIEW</span>
                          </button>
                          <button
                          className="btn btn-primary two d-flex"
                          type="button"
                          style={{borderRadius:'0px',width:'50%',justifyContent:'space-evenly',alignItems:'center',color:"white" }}
                          onClick={() => {
                            nvg("/product-detail/" + rproduct.slug);
                          }}
                        >
                          DETAILS VIEW
                        </button>
                        </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )
        : ""}

      {/* add to cart model popup page start */}
      {showModal ? (
        <div
          className="modal fade"
          id="addToCartP"
          ref={cartpopup}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="addToCartPLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <form onSubmit={handleAddToCartSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="modal-title text-center pb-1 label">
                        {" "}
                        <strong>PLEASE CONFIRM ORDER VARIANT & QUANTITY</strong>
                      </h1>
                      <p className="text-center pb-2">
                        {" "}
                        {product_de && product_de.product_name
                          ? product_de.product_name
                          : ""}
                      </p>

                      <div className="modal-body">
                        <ul className="list-inline">
                          <li className="list-inline-item pl-2 pb-2 mr-4">
                            Item Group :{" "}
                            {product_de && product_de.sku ? product_de.sku : ""}{" "}
                          </li>
                          <li className="list-inline-item">
                            {" "}
                            Carton Qty :{" "}
                            {product_de && product_de.cartonQty
                              ? product_de.cartonQty
                              : "0"}{" "}
                          </li>
                        </ul>

                        {error && (
                          <div
                            className="alert alert-error"
                            onClick={handleAddCartAlertClick}
                          >
                            {error}
                          </div>
                        )}
                        <div className="row">
                          <div className="col-10">
                            <table className="table">
                              <thead>
                                <tr className="label_tabel">
                                  <th scope="col"> Variant </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Price </th>
                                  <th scope="col"> Total Price </th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartproductDetails?.[0]
                                  ? cartproductDetails.map(
                                      (variant_info, index) => (
                                        <tr
                                          id={`mp-size-${index}`}
                                          className={`outofstock${variant_info.stock}`}
                                          key={index}
                                        >
                                          <td scope="size">
                                            <div className="checkbox-col">
                                              {/* <input
                                                type="checkbox"
                                                checked={checkboxStates[index]}
                                                onChange={() => {}}
                                                id={`size-v-${index}`}
                                                name={`quantity[${variant_info.id}]`}
                                                value={variant_info.id}
                                              /> */}
                    {convertedArray.find(item56 => item56.variant_id == variant_info.id) ? <FaCheckCircle color="#059fe2" style={{position:'relative',top:'-3px'}} /> : <FaCheckCircle color="#d1cfcf" style={{position:'relative',top:'-3px'}} />}

                                              <label
                                                htmlFor={`size-v-${index}`}
                                                className="checkbox-label"
                                              >
                                                &nbsp;
                                                {typeof variant_info.item_code ===
                                                "string"
                                                  ? variant_info.item_code.split(
                                                      "-"
                                                    )[1]
                                                  : ""}
                                              </label>
                                            </div>
                                          </td>
                                          {variant_info.stock > 0 ? (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                id={
                                                  product_de &&
                                                  product_de.uprice
                                                    ? product_de.uprice
                                                    : "0.00"
                                                }
                                                onChange={(e) =>
                                                  handleQuantityChange(
                                                    variant_info.id,
                                                    e.target.value,
                                                    e.target.id,
                                                    product_de.id
                                                  )
                                                }
                                                name={`quantity[${variant_info.id}]`}
                                                defaultValue={0}
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn"
                                              />
                                              {/* <input type="number" id={product_de && product_de.uprice ? product_de.uprice : '0.00'} onChange={(e) => handleQuantityChange(variant_info.id, e.target.value)} name={`quantity[${variant_info.id}]`} defaultValue={0}  min="0" max={variant_info.stock ? variant_info.stock : '0'} className="qtyIn"  /> */}
                                              {/* setvarient[variant_info.id]= e.target.value} */}
                                            </td>
                                          ) : (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                name={`quantity[${variant_info.id}]`}
                                                defaultValue={0}
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn disable_qyt"
                                                disabled
                                              />
                                            </td>
                                          )}
                                          <td className="label_addtocart_table">
                                            {" "}
                                            AED{" "}
                                            {product_de && product_de.uprice
                                              ? product_de.uprice
                                              : "0.00"}
                                          </td>
                                          <td className="label_addtocart_table">
                                            AED{" "}
                                            {totalPrices[variant_info.id] ||
                                              "0.00"}
                                          </td>
                                          {/* <td className="label_addtocart_table">AED {quantityupdatedprice && quantityupdatedprice ? quantityupdatedprice : '0.00'} </td> */}
                                          {/* updateallvalue */}
                                        </tr>
                                      )
                                    )
                                  : ""}
                              </tbody>
                            </table>
                          </div>

                          {productSKUDetails !== null ? (
                            <div className="col-2">
                              <table className="table">
                                <thead>
                                  <tr className="label_tabel">
                                    <th scope="col">Related SKU</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productSKUDetails?.[0] ? (
                                    productSKUDetails.map((sku_info) => (
                                      <tr
                                        className="subtotal"
                                        key={sku_info.sku}
                                      >
                                        <td>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() => {
                                              nvg(
                                                "/product-detail/" +
                                                  sku_info.slug
                                              );
                                            }}
                                            className=""
                                            style={{
                                              fontWeight: "400",
                                              color: "#007bff",
                                            }}
                                          >
                                            {sku_info.sku}
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>No related SKUs found.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group form-row d-flex justify-content-center mt-2 mb-3">
                        <button
                          type="submit"
                          className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2"
                        >
                          Confirm Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* End add to cart model popup */}

      {/* download video model popup page start */}

      <div
        className="modal fade"
        ref={downloadpopup}
        id="downloadVideoP"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="ownloadVideoPLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close remove-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  background: " #059fe2",
                  color: "#fff",
                  borderRadius: "0px",
                  fontSize: "20px",
                }}
              >
                {" "}
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body">
              {responseMessageVideo && (
                <div className="alert alert-success">
                  {responseMessageVideo}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* End download video model popup */}
      {/* start quick model popup */}
      <div
        className="modal fade"
        ref={quickpopup}
        id="quickViewModel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            {/* <div className="modal-header" style={{ borderBottom: "none" }}> */}
             
            {/* </div> */}
            <div className="modal-body">
            <button
               ref={quickpopupclose}
                type="button"
                className="btn-close remove-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  background: " #059fe2",
                  color: "#fff",
                  borderRadius: "0px",
                  fontSize: "20px",
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
              <div className="container">
                <div className="row mb-5">
                  <div className="col-lg-6" style={{ marginTop: "20px" }}>
                  <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 slider-part">
             

              <div className="flexslider galleries">
                <div>
                  <div className="card-carousel gallery-active">
                    <div
                      className="thumbnails"
                      style={{ position: "relative" }}
                    >
  {productGalleryData == null ? (

<Carousel 
showArrows={false}
showIndicators={false}
showStatus={false} 
autoPlay={false}
>
<img
  src={productDetails.main_image}
  className="slider_img_border"
  style={{ borderColor: '#059fe2'}}
/>
</Carousel>
  ) : (


                      <Carousel
                      showArrows={false}
                      showIndicators={false}
                      showStatus={false} 
                      autoPlay={true}
                            interval={4000}  
                      >
                        {productGalleryData.map((image, index) => (
                          
                              image == '360-view' ? 
                               <>
                               <iframe id="ifreamClass" src={`${process.env.REACT_APP_360_URL}/${ProSKU}/edit.html`} width="600" height="600"></iframe>
                               <img src={`${process.env.REACT_APP_360_URL}/${ProSKU}/images/img_0_0_0.jpg`} className="slider_img_border" />
                               </>
                               : 
                              <img
                                key={index}
                                src={image}
                                className="slider_img_border"
                              />

                            ))}
                      </Carousel>
)}

                      {productDetails && productDetails.video ? (
    <div className="thumbnail-image_quick" style={{marginTop: '115px'}}>
    <video
      href="javascript:void(0);"
      className="slider-img-pro-detail_quick"
      role="button"
      onClick={openVModal}
    >
      <source src={productDetails.video} type="video/mp4" />
    </video>
  </div>
) : null}


                     
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
                  </div>
                  </div>
                  <div
                    className="col-lg-6 col-sm-12"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="row">
                      <h2 className="popup-text text-uppercase">
                        {productDetails && productDetails.product_name
                          ? productDetails.product_name
                          : ""}
                      </h2>
                      <p className="Sku-text">
                        SKU:{" "}
                        {productDetails && productDetails.sku
                          ? productDetails.sku
                          : ""}
                      </p>

                      <div className="action action_border">
                        <a href="#" className="share1">
                          <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/share.png`}
                            alt="share"
                          />
                          <p className="share-img">Share</p>
                          <span style={{
                            background: '#e9e9e9',
                            height: '45px',
                            content: "",
                            width: '1px',
                            position: 'absolute',
                            right: '0',
                            top: '25px'
                          }}></span>
                        </a>
                        {/* {productDetails && productDetails.wishlist === true ? (
                        <a href="#" className="wish">
                           <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} alt="whishlist" />
                           <p className="share-img">Wish</p>
                        </a>
                        ) : (
                        <div className="wish">
                           <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`} alt="whishlist" />
                           <p className="share-img">Wish</p>
                        </div>
                        )} */}
                        {wishlist === false ? (
                          <a
                            onClick={() =>
                              handleAddWishlistQuick(productDetails.id)
                            }
                            href="javascript:void(0);"
                            className="whish"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                            />
                            <p className="share-img">Wish</p>
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              handleRemoveWishlistQuick(productDetails.id)
                            }
                            href="javascript:void(0);"
                            className="whish"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                            />
                            <p className="share-img">Wish</p>
                          </a>
                        )}
                      </div>

                      <div className="price1">
                        <label htmlFor="" style={{color:'#000', fontWeight:'550',fontSize: '17px'}}>Price:</label>
                        <p>
                          <span style={{fontWeight:'500'}}>
                            AED{" "}
                            {productDetails && productDetails.uprice
                              ? productDetails.uprice
                              : ""}
                          </span>
                        </p>
                        <span className="smallaed">
                          AED{" "}
                          {productDetails && productDetails.regular_price
                            ? productDetails.regular_price
                            : ""}
                        </span>
                      </div>
                      {productDetails && productDetails.shortFeature ? (
                        <div className="product-detail">
                          <div>
                            <h4 style={{color:'#000', fontWeight:'500',fontSize: '17px',lineHeight: '35px'}} className="text-uppercase label">
                              <b> Features: </b>
                              {/* Features: */}
                            </h4>
                            <div>
                              <div
                                className="mb-2" style={{color:'#000'}} 
                                dangerouslySetInnerHTML={{
                                  __html: productDetails.shortFeature,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div>
                            <p style={{color:'#565656',fontSize: '18px', lineHeight: '24px'}}
                              dangerouslySetInnerHTML={{
                                __html: productDetails.short_description,
                              }}
                            ></p>
                          </div>
                          <br />
                          <div className="read-more-btn">
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                handleProDetails(productDetails.slug)
                              }
                              className=""
                              style={{ color: "#007bff" }}
                            >
                              
                              READ MORE
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="order-now-btn">
                        <p>
                          <button
                            onClick={() =>
                              handleAddCartButtonClick(
                                productDetails.id,
                                productDetails.product_name,
                                productDetails.sku,
                                productDetails.cartonQty
                              )
                            }
                          >
                            Order Now
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end quick model popup */}
      {/* add to cart model popup for quick popup order button click oopen this modal */}
      {showModal ? (
        <div
          className="modal fade"
          id="addToCartPQuick"
          ref={cartpopup}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="addToCartPQuickLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <form onSubmit={handleAddToCartSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="modal-title text-center pb-1 label">
                        {" "}
                        <strong>PLEASE CONFIRM ORDER VARIANT & QUANTITY</strong>
                      </h1>
                      <p className="text-center pb-2">
                        {" "}
                        {product_de && product_de.product_name
                          ? product_de.product_name
                          : ""}
                      </p>

                      <div className="modal-body">
                        <ul className="list-inline">
                          <li className="list-inline-item pl-2 pb-2 mr-4">
                            Item Group :{" "}
                            {product_de && product_de.sku ? product_de.sku : ""}{" "}
                          </li>
                          <li className="list-inline-item">
                            {" "}
                            Carton Qty :{" "}
                            {product_de && product_de.cartonQty
                              ? product_de.cartonQty
                              : "0"}{" "}
                          </li>
                        </ul>

                        {error && (
                          <div
                            className="alert alert-error"
                            onClick={handleAddCartAlertClick}
                          >
                            {error ? error : ""}
                          </div>
                        )}
                        <div className="row">
                          <div className="col-10">
                            <table className="table">
                              <thead>
                                <tr className="label_tabel">
                                  <th scope="col"> Variant </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Price </th>
                                  <th scope="col"> Total Price </th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartproductDetails?.[0]
                                  ? cartproductDetails.map(
                                      (variant_info, index) => (
                                        <tr
                                          id={`mp-size-${index}`}
                                          className={`outofstock${variant_info.stock}`}
                                          key={index}
                                        >
                                          <td scope="size">
                                            <div className="checkbox-col">
                                              {/* <input
                                                type="checkbox"
                                                checked={checkboxStates[index]}
                                                onChange={() => {}}
                                                id={`size-v-${index}`}
                                                name={`quantity[${variant_info.id}]`}
                                                value={variant_info.id}
                                              /> */}
                    {convertedArray.find(item56 => item56.variant_id == variant_info.id) ? <FaCheckCircle color="#059fe2" style={{position:'relative',top:'-3px'}} /> : <FaCheckCircle color="#d1cfcf" style={{position:'relative',top:'-3px'}} />}

                                              <label
                                                htmlFor={`size-v-${index}`}
                                                className="checkbox-label"
                                              >
                                                &nbsp;
                                                {typeof variant_info.item_code ===
                                                "string"
                                                  ? variant_info.item_code.split(
                                                      "-"
                                                    )[1]
                                                  : ""}
                                              </label>
                                            </div>
                                          </td>

                                          {variant_info.stock > 0 ? (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                id={
                                                  product_de &&
                                                  product_de.uprice
                                                    ? product_de.uprice
                                                    : "0.00"
                                                }
                                                onChange={(e) =>
                                                  handleQuantityChange(
                                                    variant_info.id,
                                                    e.target.value,
                                                    e.target.id,
                                                    product_de.id
                                                  )
                                                }
                                                name={`quantity[${variant_info.id}]`}
                                                defaultValue={0}
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn"
                                              />
                                            </td>
                                          ) : (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                name={`quantity[${variant_info.id}]`}
                                                defaultValue={0}
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn disable_qyt"
                                                disabled
                                              />
                                            </td>
                                          )}

                                          <td className="label_addtocart_table">
                                            {" "}
                                            AED{" "}
                                            {product_de && product_de.uprice
                                              ? product_de.uprice
                                              : "0.00"}
                                          </td>
                                          <td className="label_addtocart_table">
                                            AED{" "}
                                            {totalPrices[variant_info.id] ||
                                              "0.00"}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  : ""}
                              </tbody>
                            </table>
                          </div>

                          {productSKUDetails !== null ? (
                            <div className="col-2">
                              <table className="table">
                                <thead>
                                  <tr className="label_tabel">
                                    <th scope="col">Related SKU</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productSKUDetails?.[0] ? (
                                    productSKUDetails.map((sku_info) => (
                                      <tr
                                        className="subtotal"
                                        key={sku_info.sku}
                                      >
                                        <td>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              handleProDetails(sku_info.slug)
                                            }
                                            className=""
                                            style={{
                                              fontWeight: "400",
                                              color: "#007bff",
                                            }}
                                          >
                                            {sku_info.sku}
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>No related SKUs found.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group form-row d-flex justify-content-center mt-2 mb-3 mx-2">
                        <button
                          type="submit"
                          className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2"
                        >
                          Confirm Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* End add to cart model popup for quick popup order button click oopen this modal */}
      <Footer />
    </div>
  );
};
export default ProductDetail;
