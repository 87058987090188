import { hover } from "@testing-library/user-event/dist/hover";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { getToken } from "../services/LocalStorageSrv";
import MyAccountMenu from "./MyAccountMenu";
import axios from "axios";
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
const UpdateProfileRequest = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  // console.log('Token1:', localdata.data.token);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  // console.log("profile dara ff",profileData)
  const [ProfileCountry, setProfileCountry] = useState([]);
  const configToken = {
    headers: {
      Authorization: `Bearer ${localdata.data.token}`,
    },
  };

  useEffect(() => {
    const ProfileDatafun = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/userProfile`, configToken);
        setProfileData(response.data.data);
        setProfileCountry(response.data.data.country);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile data:", error);
        console.error("Axios error details:", error.response); // Log the response for more details
        setLoading(false);
      }
    };

    ProfileDatafun();
  }, []);

  const initialSelectedCountry = { ProfileCountry };

  const [selectedCountry, setSelectedCountry] = useState(
    initialSelectedCountry
  );

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setProfileData({
      ...profileData,
      country: selectedCountry,
    });
  };

  //   const [formData, setFormData] = useState({
  //     name: "",
  //     email: "",
  //     // Add other form fields as needed
  //   });
  const [responseMessage, setResponseMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleAlertClick = (e) => {
    setResponseMessage("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate name
    if (!profileData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    // Validate email format
    if (!profileData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(profileData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!profileData.mobile) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!profileData.address) {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (!profileData.city) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!profileData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmitProfile = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      // If the form is not valid, don't submit
      return;
    }

    const collectdata = {
      name: profileData.name,
      email: profileData.email,
      phone: profileData.mobile,
      address: profileData.address,
      city: profileData.city,
      country: profileData.country,
      landmark: profileData.landmark,
      profilepic: profileData.profilepic,
      business_card: profileData.business_card,
    };

    setLoading(true);

    try {
      const response = await axios.post(
        `${apiUrl}/updateProfileRequest`,
        collectdata,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`, // Assuming localdata is defined
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }
      );
      if (response.data.success === true) {
        setResponseMessage(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setErrors(error.response.data.message);
      } else {
        console.error("Error submitting review:", error);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
{loading ? <Loader /> : null}
<Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section id="product-listing-sec" className="inner-page-bg mb-5">
        <div className="container">
          <div className="row">
            <MyAccountMenu />

            <div
              className="col-lg-9  col-sm-12  Myitems-box"
              style={{ alignSelf: "flex-start", marginTop: "20px" }}
            >
              <div className="row">
                <h2 className="myItems">Update Profile</h2>
                {/* ITEM 1 */}
                <div className="profile-update-form">
                  <div className="mt-2">
                    <div className="card-body">
                      <div className="row order-details border-bottom mb-2">
                        {responseMessage && (
                          <div
                            className="alert alert-success"
                            onClick={handleAlertClick}
                          >
                            {responseMessage}
                          </div>
                        )}

                        <form onSubmit={handleSubmitProfile}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="field Vendercode">
                                <label> Profile Pic* </label>
                                <input
                                  type="file"
                                  name="profilepic"
                                  id="file-input-1"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="field Vendercode">
                                <label>Company Logo*</label>
                                <input
                                  type="file"
                                  name="business_card"
                                  id="file-input"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="field Vendercode">
                                <label>Your Name*</label>
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  value={profileData.name}
                                  onChange={handleChange}
                                />
                                {errors.name && (
                                  <div className="error">{errors.name}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="field Vendercode">
                                <label>Email Address*</label>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  value={profileData.email}
                                  onChange={handleChange}
                                />
                                {errors.email && (
                                  <div className="error">{errors.email}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="field Vendercode">
                                <label>Phone Number*</label>
                                <input
                                  type="text"
                                  name="mobile"
                                  className="form-control"
                                  value={profileData.mobile}
                                  onChange={handleChange}
                                />
                                {errors.phone && (
                                  <div className="error">{errors.phone}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-12">
                              <div className="field Vendercode">
                                <label>Address*</label>
                                <textarea
                                  name="address"
                                  className="form-control"
                                  value={
                                    profileData.address
                                      ? profileData.address
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                                {errors.address && (
                                  <div className="error">{errors.address}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="field Vendercode">
                                <label>City/ Town/ District*</label>
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  value={
                                    profileData.city ? profileData.city : ""
                                  }
                                  onChange={handleChange}
                                />
                                {errors.city && (
                                  <div className="error">{errors.city}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                              <div className="field Vendercode">
                                <label>Country*</label>
                                <div className="dropdown-box">
                                  <select
                                    name="country"
                                    className="form-control"
                                    value={profileData.country}
                                    onChange={handleCountryChange}
                                  >
                                    <option value="">Select Country</option>
                                    <option value="United Arab Emirates">
                                      {" "}
                                      United Arab Emirates{" "}
                                    </option>
                                    <option value="Abkhazia"> Abkhazia </option>
                                    <option value="Afghanistan">
                                      {" "}
                                      Afghanistan{" "}
                                    </option>
                                    <option value="Albania"> Albania </option>
                                    <option value="Algeria"> Algeria </option>
                                    <option value="American Samoa">
                                      {" "}
                                      American Samoa{" "}
                                    </option>
                                    <option value="Andorra"> Andorra </option>
                                    <option value="Angola"> Angola </option>
                                    <option value="Anguilla"> Anguilla </option>
                                    <option value="Antigua and Barbuda">
                                      {" "}
                                      Antigua and Barbuda{" "}
                                    </option>
                                    <option value="Argentina">
                                      {" "}
                                      Argentina{" "}
                                    </option>
                                    <option value="Armenia"> Armenia </option>
                                    <option value="Aruba"> Aruba </option>
                                    <option value="Ascension">
                                      {" "}
                                      Ascension{" "}
                                    </option>
                                    <option value="Australia">
                                      {" "}
                                      Australia{" "}
                                    </option>
                                    <option value="Australian External Territories">
                                      {" "}
                                      Australian External Territories{" "}
                                    </option>
                                    <option value="Austria"> Austria </option>
                                    <option value="Azerbaijan">
                                      {" "}
                                      Azerbaijan{" "}
                                    </option>
                                    <option value="Bahamas"> Bahamas </option>
                                    <option value="Bahrain"> Bahrain </option>
                                    <option value="Bangladesh">
                                      {" "}
                                      Bangladesh{" "}
                                    </option>
                                    <option value="Barbados"> Barbados </option>
                                    <option value="Barbuda"> Barbuda </option>
                                    <option value="Belarus"> Belarus </option>
                                    <option value="Belgium"> Belgium </option>
                                    <option value="Belize"> Belize </option>
                                    <option value="Benin"> Benin </option>
                                    <option value="Bermuda"> Bermuda </option>
                                    <option value="Bhutan"> Bhutan </option>
                                    <option value="Bolivia"> Bolivia </option>
                                    <option value="Bosnia and Herzegovina">
                                      {" "}
                                      Bosnia and Herzegovina{" "}
                                    </option>
                                    <option value="Botswana"> Botswana </option>
                                    <option value="Brazil"> Brazil </option>
                                    <option value="British Indian Ocean Territory">
                                      {" "}
                                      British Indian Ocean Territory{" "}
                                    </option>
                                    <option value="British Virgin Islands">
                                      {" "}
                                      British Virgin Islands{" "}
                                    </option>
                                    <option value="Brunei"> Brunei </option>
                                    <option value="Bulgaria"> Bulgaria </option>
                                    <option value="Burkina Faso">
                                      {" "}
                                      Burkina Faso{" "}
                                    </option>
                                    <option value="Burundi"> Burundi </option>
                                    <option value="Cambodia"> Cambodia </option>
                                    <option value="Cameroon"> Cameroon </option>
                                    <option value="Canada"> Canada </option>
                                    <option value="Cape Verde">
                                      {" "}
                                      Cape Verde{" "}
                                    </option>
                                    <option value="Cayman Islands">
                                      {" "}
                                      Cayman Islands{" "}
                                    </option>
                                    <option value="Central African Republic">
                                      {" "}
                                      Central African Republic{" "}
                                    </option>
                                    <option value="Chad"> Chad </option>
                                    <option value="Chile"> Chile </option>
                                    <option value="China"> China </option>
                                    <option value="Christmas Island">
                                      {" "}
                                      Christmas Island{" "}
                                    </option>
                                    <option value="Cocos-Keeling Islands">
                                      {" "}
                                      Cocos-Keeling Islands{" "}
                                    </option>
                                    <option value="Colombia"> Colombia </option>
                                    <option value="Comoros"> Comoros </option>
                                    <option value="Congo"> Congo </option>
                                    <option value="Congo, Dem. Rep. of (Zaire)">
                                      {" "}
                                      Congo, Dem. Rep. of (Zaire){" "}
                                    </option>
                                    <option value="Cook Islands">
                                      {" "}
                                      Cook Islands{" "}
                                    </option>
                                    <option value="Costa Rica">
                                      {" "}
                                      Costa Rica{" "}
                                    </option>
                                    <option value="Croatia"> Croatia </option>
                                    <option value="Cuba"> Cuba </option>
                                    <option value="Curacao"> Curacao </option>
                                    <option value="Cyprus"> Cyprus </option>
                                    <option value="Czech Republic">
                                      {" "}
                                      Czech Republic{" "}
                                    </option>
                                    <option value="Denmark"> Denmark </option>
                                    <option value="Diego Garcia">
                                      {" "}
                                      Diego Garcia{" "}
                                    </option>
                                    <option value="Djibouti"> Djibouti </option>
                                    <option value="Dominica"> Dominica </option>
                                    <option value="Dominican Republic">
                                      {" "}
                                      Dominican Republic{" "}
                                    </option>
                                    <option value="East Timor">
                                      {" "}
                                      East Timor{" "}
                                    </option>
                                    <option value="Easter Island">
                                      {" "}
                                      Easter Island{" "}
                                    </option>
                                    <option value="Ecuador"> Ecuador </option>
                                    <option value="Egypt"> Egypt </option>
                                    <option value="El Salvador">
                                      {" "}
                                      El Salvador{" "}
                                    </option>
                                    <option value="Equatorial Guinea">
                                      {" "}
                                      Equatorial Guinea{" "}
                                    </option>
                                    <option value="Eritrea"> Eritrea </option>
                                    <option value="Estonia"> Estonia </option>
                                    <option value="Ethiopia"> Ethiopia </option>
                                    <option value="Falkland Islands">
                                      {" "}
                                      Falkland Islands{" "}
                                    </option>
                                    <option value="Faroe Islands">
                                      {" "}
                                      Faroe Islands{" "}
                                    </option>
                                    <option value="Fiji"> Fiji </option>
                                    <option value="Finland"> Finland </option>
                                    <option value="France"> France </option>
                                    <option value="French Antilles">
                                      {" "}
                                      French Antilles{" "}
                                    </option>
                                    <option value="French Guiana">
                                      {" "}
                                      French Guiana{" "}
                                    </option>
                                    <option value="French Polynesia">
                                      {" "}
                                      French Polynesia{" "}
                                    </option>
                                    <option value="Gabon"> Gabon </option>
                                    <option value="Gambia"> Gambia </option>
                                    <option value="Georgia"> Georgia </option>
                                    <option value="Germany"> Germany </option>
                                    <option value="Ghana"> Ghana </option>
                                    <option value="Gibraltar">
                                      {" "}
                                      Gibraltar{" "}
                                    </option>
                                    <option value="Greece"> Greece </option>
                                    <option value="Greenland">
                                      {" "}
                                      Greenland{" "}
                                    </option>
                                    <option value="Grenada"> Grenada </option>
                                    <option value="Guadeloupe">
                                      {" "}
                                      Guadeloupe{" "}
                                    </option>
                                    <option value="Guam"> Guam </option>
                                    <option value="Guatemala">
                                      {" "}
                                      Guatemala{" "}
                                    </option>
                                    <option value="Guinea"> Guinea </option>
                                    <option value="Guinea-Bissau">
                                      {" "}
                                      Guinea-Bissau{" "}
                                    </option>
                                    <option value="Guyana"> Guyana </option>
                                    <option value="Haiti"> Haiti </option>
                                    <option value="Honduras"> Honduras </option>
                                    <option value="Hong Kong SAR China">
                                      {" "}
                                      Hong Kong SAR China{" "}
                                    </option>
                                    <option value="Hungary"> Hungary </option>
                                    <option value="Iceland"> Iceland </option>
                                    <option value="India"> India </option>
                                    <option value="Indonesia">
                                      {" "}
                                      Indonesia{" "}
                                    </option>
                                    <option value="Iran"> Iran </option>
                                    <option value="Iraq"> Iraq </option>
                                    <option value="Ireland"> Ireland </option>
                                    <option value="Israel"> Israel </option>
                                    <option value="Italy"> Italy </option>
                                    <option value="Ivory Coast">
                                      {" "}
                                      Ivory Coast{" "}
                                    </option>
                                    <option value="Jamaica"> Jamaica </option>
                                    <option value="Japan"> Japan </option>
                                    <option value="Jordan"> Jordan </option>
                                    <option value="Kazakhstan">
                                      {" "}
                                      Kazakhstan{" "}
                                    </option>
                                    <option value="Kenya"> Kenya </option>
                                    <option value="Kiribati"> Kiribati </option>
                                    <option value="Kuwait"> Kuwait </option>
                                    <option value="Kyrgyzstan">
                                      {" "}
                                      Kyrgyzstan{" "}
                                    </option>
                                    <option value="Laos"> Laos </option>
                                    <option value="Latvia"> Latvia </option>
                                    <option value="Lebanon"> Lebanon </option>
                                    <option value="Lesotho"> Lesotho </option>
                                    <option value="Liberia"> Liberia </option>
                                    <option value="Libya"> Libya </option>
                                    <option value="Liechtenstein">
                                      {" "}
                                      Liechtenstein{" "}
                                    </option>
                                    <option value="Lithuania">
                                      {" "}
                                      Lithuania{" "}
                                    </option>
                                    <option value="Luxembourg">
                                      {" "}
                                      Luxembourg{" "}
                                    </option>
                                    <option value="Macau SAR China">
                                      {" "}
                                      Macau SAR China{" "}
                                    </option>
                                    <option value="Macedonia">
                                      {" "}
                                      Macedonia{" "}
                                    </option>
                                    <option value="Madagascar">
                                      {" "}
                                      Madagascar{" "}
                                    </option>
                                    <option value="Malawi"> Malawi </option>
                                    <option value="Malaysia"> Malaysia </option>
                                    <option value="Maldives"> Maldives </option>
                                    <option value="Mali"> Mali </option>
                                    <option value="Malta"> Malta </option>
                                    <option value="Marshall Islands">
                                      {" "}
                                      Marshall Islands{" "}
                                    </option>
                                    <option value="Martinique">
                                      {" "}
                                      Martinique{" "}
                                    </option>
                                    <option value="Mauritania">
                                      {" "}
                                      Mauritania{" "}
                                    </option>
                                    <option value="Mauritius">
                                      {" "}
                                      Mauritius{" "}
                                    </option>
                                    <option value="Mayotte"> Mayotte </option>
                                    <option value="Mexico"> Mexico </option>
                                    <option value="Micronesia">
                                      {" "}
                                      Micronesia{" "}
                                    </option>
                                    <option value="Midway Island">
                                      {" "}
                                      Midway Island{" "}
                                    </option>
                                    <option value="Moldova"> Moldova </option>
                                    <option value="Monaco"> Monaco </option>
                                    <option value="Mongolia"> Mongolia </option>
                                    <option value="Montenegro">
                                      {" "}
                                      Montenegro{" "}
                                    </option>
                                    <option value="Montserrat">
                                      {" "}
                                      Montserrat{" "}
                                    </option>
                                    <option value="Morocco"> Morocco </option>
                                    <option value="Myanmar"> Myanmar </option>
                                    <option value="Namibia"> Namibia </option>
                                    <option value="Nauru"> Nauru </option>
                                    <option value="Nepal"> Nepal </option>
                                    <option value="Netherlands">
                                      {" "}
                                      Netherlands{" "}
                                    </option>
                                    <option value="Netherlands Antilles">
                                      {" "}
                                      Netherlands Antilles{" "}
                                    </option>
                                    <option value="Nevis"> Nevis </option>
                                    <option value="New Caledonia">
                                      {" "}
                                      New Caledonia{" "}
                                    </option>
                                    <option value="New Zealand">
                                      {" "}
                                      New Zealand{" "}
                                    </option>
                                    <option value="Nicaragua">
                                      {" "}
                                      Nicaragua{" "}
                                    </option>
                                    <option value="Niger"> Niger </option>
                                    <option value="Nigeria"> Nigeria </option>
                                    <option value="Niue"> Niue </option>
                                    <option value="Norfolk Island">
                                      {" "}
                                      Norfolk Island{" "}
                                    </option>
                                    <option value="North Korea">
                                      {" "}
                                      North Korea{" "}
                                    </option>
                                    <option value="Northern Mariana Islands">
                                      {" "}
                                      Northern Mariana Islands{" "}
                                    </option>
                                    <option value="Norway"> Norway </option>
                                    <option value="Oman"> Oman </option>
                                    <option value="Pakistan"> Pakistan </option>
                                    <option value="Palau"> Palau </option>
                                    <option value="Palestinian Territory">
                                      {" "}
                                      Palestinian Territory{" "}
                                    </option>
                                    <option value="Panama"> Panama </option>
                                    <option value="Papua New Guinea">
                                      {" "}
                                      Papua New Guinea{" "}
                                    </option>
                                    <option value="Paraguay"> Paraguay </option>
                                    <option value="Peru"> Peru </option>
                                    <option value="Philippines">
                                      {" "}
                                      Philippines{" "}
                                    </option>
                                    <option value="Poland"> Poland </option>
                                    <option value="Portugal"> Portugal </option>
                                    <option value="Puerto Rico">
                                      {" "}
                                      Puerto Rico{" "}
                                    </option>
                                    <option value="Qatar"> Qatar </option>
                                    <option value="Reunion"> Reunion </option>
                                    <option value="Romania"> Romania </option>
                                    <option value="Russia"> Russia </option>
                                    <option value="Rwanda"> Rwanda </option>
                                    <option value="Samoa"> Samoa </option>
                                    <option value="San Marino">
                                      {" "}
                                      San Marino{" "}
                                    </option>
                                    <option value="Saudi Arabia">
                                      {" "}
                                      Saudi Arabia{" "}
                                    </option>
                                    <option value="Senegal"> Senegal </option>
                                    <option value="Serbia"> Serbia </option>
                                    <option value="Seychelles">
                                      {" "}
                                      Seychelles{" "}
                                    </option>
                                    <option value="Sierra Leone">
                                      {" "}
                                      Sierra Leone{" "}
                                    </option>
                                    <option value="Singapore">
                                      {" "}
                                      Singapore{" "}
                                    </option>
                                    <option value="Slovakia"> Slovakia </option>
                                    <option value="Slovenia"> Slovenia </option>
                                    <option value="Solomon Islands">
                                      {" "}
                                      Solomon Islands{" "}
                                    </option>
                                    <option value="South Africa">
                                      {" "}
                                      South Africa{" "}
                                    </option>
                                    <option value="South Georgia and the South Sandwich Islands">
                                      {" "}
                                      South Georgia and the South Sandwich
                                      Islands{" "}
                                    </option>
                                    <option value="South Korea">
                                      {" "}
                                      South Korea{" "}
                                    </option>
                                    <option value="Spain"> Spain </option>
                                    <option value="Sri Lanka">
                                      {" "}
                                      Sri Lanka{" "}
                                    </option>
                                    <option value="Sudan"> Sudan </option>
                                    <option value="Suriname"> Suriname </option>
                                    <option value="Swaziland">
                                      {" "}
                                      Swaziland{" "}
                                    </option>
                                    <option value="Sweden"> Sweden </option>
                                    <option value="Switzerland">
                                      {" "}
                                      Switzerland{" "}
                                    </option>
                                    <option value="Syria"> Syria </option>
                                    <option value="Taiwan"> Taiwan </option>
                                    <option value="Tajikistan">
                                      {" "}
                                      Tajikistan{" "}
                                    </option>
                                    <option value="Tanzania"> Tanzania </option>
                                    <option value="Thailand"> Thailand </option>
                                    <option value="Timor Leste">
                                      {" "}
                                      Timor Leste{" "}
                                    </option>
                                    <option value="Togo"> Togo </option>
                                    <option value="Tokelau"> Tokelau </option>
                                    <option value="Tonga"> Tonga </option>
                                    <option value="Trinidad and Tobago">
                                      {" "}
                                      Trinidad and Tobago{" "}
                                    </option>
                                    <option value="Tunisia"> Tunisia </option>
                                    <option value="Turkey"> Turkey </option>
                                    <option value="Turkmenistan">
                                      {" "}
                                      Turkmenistan{" "}
                                    </option>
                                    <option value="Turks and Caicos Islands">
                                      {" "}
                                      Turks and Caicos Islands{" "}
                                    </option>
                                    <option value="Tuvalu"> Tuvalu </option>
                                    <option value="U.S. Virgin Islands">
                                      {" "}
                                      U.S. Virgin Islands{" "}
                                    </option>
                                    <option value="Uganda"> Uganda </option>
                                    <option value="Ukraine"> Ukraine </option>
                                    <option value="United Kingdom">
                                      {" "}
                                      United Kingdom{" "}
                                    </option>
                                    <option value="United States">
                                      {" "}
                                      United States{" "}
                                    </option>
                                    <option value="Uruguay"> Uruguay </option>
                                    <option value="Uzbekistan">
                                      {" "}
                                      Uzbekistan{" "}
                                    </option>
                                    <option value="Vanuatu"> Vanuatu </option>
                                    <option value="Venezuela">
                                      {" "}
                                      Venezuela{" "}
                                    </option>
                                    <option value="Vietnam"> Vietnam </option>
                                    <option value="Wake Island">
                                      {" "}
                                      Wake Island{" "}
                                    </option>
                                    <option value="Wallis and Futuna">
                                      {" "}
                                      Wallis and Futuna{" "}
                                    </option>
                                    <option value="Yemen"> Yemen </option>
                                    <option value="Zambia"> Zambia </option>
                                    <option value="Zanzibar"> Zanzibar </option>
                                    <option value="Zimbabwe"> Zimbabwe </option>
                                  </select>
                                  {errors.country && (
                                    <div className="error">
                                      {errors.country}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="field Vendercode">
                                <label>Landmark (Optional)</label>
                                <input
                                  type="text"
                                  name="landmark"
                                  className="form-control"
                                  value={
                                    profileData.landmark
                                      ? profileData.landmark
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                                {errors.landmark && (
                                  <div className="error">{errors.landmark}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="personalinfo-btn">
                                <button
                                  type="submit"
                                  className="btn btn-save rounded-0 text-uppercase pt-2 pb-2 mt-3 "
                                >
                                  {" "}
                                  Request Update Profile{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default UpdateProfileRequest;
